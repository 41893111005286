import { useQuery } from '@tanstack/react-query';
import { SortOrder } from 'components/Sort/Sort.component';

import { API_ENDPOINTS } from 'config/endpoints';
import { SEARCH_PARAMS } from 'config/searchParams';
import useSearchParams from 'hooks/useSearchParams';
import { Project } from 'models/Project';
import { PaginationResponse } from 'models/Response';
import { ClientWithProjectStatus } from 'models/User';
import useCreditorsForClient from 'queries/CreditorsForClient';

type UseClientDataProps = {
  page?: string | number;
  clientId?: string;
};

export const useClientData = (props: UseClientDataProps) => {
  const { clientId, page } = props;

  const { getSearchParam } = useSearchParams();
  const order =
    getSearchParam(SEARCH_PARAMS.ORDER) === SortOrder.Ascending ? '1' : '-1';
  const sortBy = getSearchParam(SEARCH_PARAMS.SORT_BY) || 'lastPayment';

  const projects = useQuery<PaginationResponse<Project>>({
    queryKey: [
      `${API_ENDPOINTS.PROJECTS}`,
      { page, clientId, size: 9, sortBy: `${sortBy}:${order}` },
    ],
    enabled: !!page && !!clientId,
  });

  const creditors = useCreditorsForClient(
    clientId,
    { page },
    { enabled: !!clientId },
  );

  const singleClient = useQuery<ClientWithProjectStatus>({
    queryKey: [
      `${API_ENDPOINTS.CLIENTS}/${clientId}`,
      { includeProjectStatuses: true },
    ],
    enabled: !!clientId,
  });

  return {
    projects,
    creditors,
    singleClient,
  };
};
