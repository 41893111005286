import classNames from 'classnames';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import Button from 'components/Button';
import CustomLink from 'components/CustomLink/CustomLink.component';
import LanguagePicker from 'components/LanguagePicker';
import { APP_ROUTES } from 'config/routes';
import ClientsIcon from 'icons/Clients.icon';
import CompanyIcon from 'icons/Company.icon';
import DashboardIcon from 'icons/Dashboard.icon';
import FolderIcon from 'icons/Folder.icon';
import SidebarLogoMax from 'icons/LogoMax.icon';
import SidebarLogoMini from 'icons/LogoMini.icon';
import VectorArrowIcon from 'icons/VectorArrow.icon';
import { Role } from 'models/User';
import credentialsService from 'services/credentialsService';

import './Sidebar.style.scss';

export type SidebarProps = {
  className?: string;
};

type linksForSidebarType = {
  to: string;
  icon: JSX.Element;
  iconPosition: 'left' | 'right';
  title: string;
};

const linksForSidebar = (
  t: (message: string) => string,
  role: Role,
): Array<linksForSidebarType> =>
  role?.includes('book_keeper')
    ? [
        {
          to: APP_ROUTES.DASHBOARD,
          icon: <DashboardIcon />,
          iconPosition: 'left',
          title: t('Sidebar.dashboard'),
        },
        {
          to: APP_ROUTES.CLIENTS,
          icon: <ClientsIcon />,
          iconPosition: 'left',
          title: t('Sidebar.clients'),
        },
        // {
        //   to: APP_ROUTES.PAYMENTS,
        //   icon: <PaymentsIcon />,
        //   iconPosition: 'left',
        //   title: t('Sidebar.payments'),
        // },
      ]
    : [
        {
          to: APP_ROUTES.PROJECTS,
          icon: <FolderIcon />,
          iconPosition: 'left',
          title: t('Sidebar.projects'),
        },
        {
          to: APP_ROUTES.CREDITORS,
          icon: <ClientsIcon />,
          iconPosition: 'left',
          title: t('Sidebar.creditors'),
        },
        {
          to: APP_ROUTES.CLIENT,
          icon: <CompanyIcon />,
          iconPosition: 'left',
          title: t('Sidebar.client'),
        },
      ];

const Card: React.FC<SidebarProps> = (props) => {
  const { className } = props;
  const [isOpen, setIsOpen] = useState(false);

  const { pathname } = useLocation();

  const classes = classNames(
    'sidebar',
    `sidebar--${isOpen ? 'open' : 'closed'}`,
    className,
  );

  const { t } = useTranslation();

  const { userRole } = credentialsService;

  return (
    <nav className={classes}>
      <div className="sidebar__logo-mini">
        <SidebarLogoMini />
      </div>
      <div className="sidebar__logo-max">
        <SidebarLogoMax fillColor="#F6F7FF" />
      </div>
      <ul className="sidebar__navlist">
        {linksForSidebar(t, userRole).map((link: linksForSidebarType) => {
          const { to, title, icon, iconPosition } = link;
          const isActive = pathname.includes(to);

          return (
            <li
              key={link.title}
              className={classNames(
                'sidebar__navlist__item',
                isActive && 'sidebar__navlist__item--active',
              )}
            >
              <CustomLink
                to={to}
                title={title}
                icon={icon}
                iconPosition={iconPosition}
                className="custom-link--dashboard"
              />
            </li>
          );
        })}
      </ul>
      <Button
        className="sidebar__open-sidebar"
        onClick={() => setIsOpen((old) => !old)}
        styleType="solid"
        size="icon"
      >
        <VectorArrowIcon
          className={`vector-arrow vector-arrow--${isOpen ? 'open' : 'closed'}`}
        />
      </Button>
      <LanguagePicker className="is-language-picker--sidebar-variant" />
    </nav>
  );
};

export default Card;
