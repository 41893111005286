import React from 'react';
import { Icon } from 'models/Icon';

const BillIcon: React.FC<Icon> = (props) => {
  const { className } = props;

  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path d="M6.9375 27.5C6.02083 27.5 5.24479 27.1823 4.60938 26.5469C3.97396 25.9115 3.65625 25.1354 3.65625 24.2188V20.3125H7.625V2.5L9.5 4.375L11.375 2.5L13.2188 4.375L15.0938 2.5L16.9688 4.375L18.8438 2.5L20.7188 4.375L22.5938 2.5L24.4688 4.375L26.3438 2.5V24.2188C26.3438 25.1354 26.026 25.9115 25.3906 26.5469C24.7552 27.1823 23.9792 27.5 23.0625 27.5H6.9375ZM23.0625 25.625C23.4792 25.625 23.8177 25.4948 24.0781 25.2344C24.3385 24.974 24.4688 24.6354 24.4688 24.2188V5.625H9.5V20.3125H21.6562V24.2188C21.6562 24.6354 21.7865 24.974 22.0469 25.2344C22.3073 25.4948 22.6458 25.625 23.0625 25.625ZM11.1562 10.5625V8.6875H18.6562V10.5625H11.1562ZM11.1562 14.75V12.875H18.6562V14.75H11.1562ZM21.5625 10.5625C21.3125 10.5625 21.0938 10.4688 20.9062 10.2813C20.7188 10.0938 20.625 9.875 20.625 9.625C20.625 9.375 20.7188 9.15625 20.9062 8.96875C21.0938 8.78125 21.3125 8.6875 21.5625 8.6875C21.8125 8.6875 22.0312 8.78125 22.2188 8.96875C22.4062 9.15625 22.5 9.375 22.5 9.625C22.5 9.875 22.4062 10.0938 22.2188 10.2813C22.0312 10.4688 21.8125 10.5625 21.5625 10.5625ZM21.5625 14.5938C21.3125 14.5938 21.0938 14.5 20.9062 14.3125C20.7188 14.125 20.625 13.9062 20.625 13.6562C20.625 13.4062 20.7188 13.1875 20.9062 13C21.0938 12.8125 21.3125 12.7188 21.5625 12.7188C21.8125 12.7188 22.0312 12.8125 22.2188 13C22.4062 13.1875 22.5 13.4062 22.5 13.6562C22.5 13.9062 22.4062 14.125 22.2188 14.3125C22.0312 14.5 21.8125 14.5938 21.5625 14.5938ZM6.90625 25.625H19.7812V22.1875H5.53125V24.2188C5.53125 24.6354 5.66146 24.974 5.92188 25.2344C6.18229 25.4948 6.51042 25.625 6.90625 25.625ZM5.53125 25.625V22.1875V25.625Z" />
    </svg>
  );
};

export default BillIcon;
