import classNames from 'classnames';
import { FormApi } from 'final-form';
import React, { useState } from 'react';
import { Field, Form } from 'react-final-form';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

import Button from 'components/Button';
import LoadingButton from 'components/LoadingButton';
import PasswordInputField from 'components/PasswordInput';
import EditIcon from 'icons/Edit.icon';
import FolderIcon from 'icons/Folder.icon';
import PasswordIcon from 'icons/Password.icon';
import { PasswordRegExp } from 'validations/regularExpression';
import {
  composeValidators,
  validatePasswordRepeat,
  validateRegex,
  validateRequired,
} from 'validations/validations';
import CardLayout from '../CardLayout';

import { API_ENDPOINTS } from 'config/endpoints';
import useTanstackMutation from 'hooks/useTanstackMutation';
import './SecurityInformationCard.styles.responsive.scss';
import './SecurityInformationCard.styles.scss';

type SecurityInformationFormInputs = {
  password: string;
  repeatPassword: string;
  oldPassword: string;
};

type SecurityInformationCardProps = {
  className?: string;
  onSuccess?: () => Promise<void>;
};

const SecurityInformationCard: React.FC<SecurityInformationCardProps> = (
  props,
) => {
  const { className, onSuccess } = props;

  const classes = classNames('security-information-card', className);

  const { t } = useTranslation();

  const [isEditing, setIsEditing] = useState(false);

  const [currentStep, setCurrentStep] = useState(0);

  const { mutate, isPending } = useTanstackMutation({
    onSuccess: async () => {
      toast.success(t('Messages.passwordResetSuccessfully'));
      await onSuccess?.();
    },
    onError: async (e) => {
      const err = await e.json();
      toast.error(err.message);
    },
    onSettled: () => {
      setCurrentStep(0);
      setIsEditing(false);
    },
  });

  const handleSubmit = async (
    data: SecurityInformationFormInputs,
    methods: FormApi<
      SecurityInformationFormInputs,
      Partial<SecurityInformationFormInputs>
    >,
  ) => {
    if (currentStep === 0) {
      setCurrentStep(1);
      return;
    }
    const body = {
      newPassword: data.password,
      oldPassword: data.oldPassword,
    };
    const method = 'PATCH';
    const path = API_ENDPOINTS.PASSWORD_RESET;

    mutate(
      {
        body,
        method,
        path,
      },
      {
        onSettled: () => methods.reset(),
      },
    );
  };

  const handleGoBack = () => {
    setCurrentStep(0);
  };

  const toggleEditing = () => {
    setCurrentStep(0);
    setIsEditing((old) => !old);
  };

  return (
    <CardLayout
      className={classes}
      title={t('Register.securityInformation')}
      subtitle={t('Profile.viewAndUpdateYourSecurityInformation')}
      titleIcon={<FolderIcon />}
    >
      <Form
        onSubmit={handleSubmit}
        render={({ handleSubmit }) => {
          return (
            <form
              onSubmit={handleSubmit}
              className="security-information-card__form"
            >
              {!isEditing && (
                <Button
                  styleType="transparent"
                  icon={<EditIcon />}
                  iconPosition="left"
                  className="security-information-card__form__edit-trigger"
                  type="button"
                  onClick={() => {
                    toggleEditing();
                  }}
                />
              )}
              {currentStep === 0 ? (
                <>
                  <div className="security-information-card__form__row">
                    <Field
                      component={PasswordInputField}
                      titleicon={<PasswordIcon />}
                      title={t('Profile.oldPassword')}
                      name="oldPassword"
                      validate={composeValidators(
                        validateRequired('Form.passwordRequired'),
                      )}
                      classNameInput={classNames(
                        'security-information-card__form__row__field',
                        !isEditing &&
                          'security-information-card__form__row__field--disabled',
                      )}
                      disabled={!isEditing}
                    />
                  </div>
                  {isEditing && (
                    <div className="personal-information-card__form__row">
                      <div className="personal-information-card__form__buttons">
                        <Button
                          type="button"
                          styleType="solid-bright"
                          className="personal-information-card__form__buttons__button"
                          onClick={() => {
                            toggleEditing();
                          }}
                        >
                          {t('Buttons.cancel')}
                        </Button>
                        <Button
                          type="submit"
                          styleType="solid"
                          className="personal-information-card__form__buttons__button"
                        >
                          {t('Buttons.next')}
                        </Button>
                      </div>
                    </div>
                  )}
                </>
              ) : (
                <>
                  <div className="security-information-card__form__row">
                    <Field
                      component={PasswordInputField}
                      titleicon={<PasswordIcon />}
                      title={t('Register.password')}
                      name="password"
                      validate={composeValidators(
                        validateRequired('Form.passwordRequired'),
                        validateRegex('Form.invalidPassword', PasswordRegExp),
                      )}
                      classNameInput={classNames(
                        'security-information-card__form__row__field',
                        !isEditing &&
                          'security-information-card__form__row__field--disabled',
                      )}
                      disabled={!isEditing}
                    />
                    <Field
                      component={PasswordInputField}
                      titleicon={<PasswordIcon />}
                      title={t('Register.repeatPassword')}
                      name="passwordrepeat"
                      validate={composeValidators(
                        validateRequired('Form.passwordRequired'),
                        validatePasswordRepeat('Form.pleaseRepeatThePassword'),
                      )}
                      classNameInput={classNames(
                        'security-information-card__form__row__field',
                        !isEditing &&
                          'security-information-card__form__row__field--disabled',
                      )}
                      disabled={!isEditing}
                    />
                  </div>
                  {isEditing && (
                    <div className="personal-information-card__form__row">
                      <div className="personal-information-card__form__buttons">
                        <Button
                          type="button"
                          styleType="solid-bright"
                          className="personal-information-card__form__buttons__button"
                          onClick={() => {
                            handleGoBack();
                          }}
                        >
                          {t('Buttons.back')}
                        </Button>
                        <LoadingButton
                          isLoading={isPending}
                          type="submit"
                          styleType="solid"
                          className="personal-information-card__form__buttons__button"
                        >
                          {t('Buttons.resetPassword')}
                        </LoadingButton>
                      </div>
                    </div>
                  )}
                </>
              )}
            </form>
          );
        }}
      />
    </CardLayout>
  );
};

export default SecurityInformationCard;
