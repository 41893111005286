import { HeaderTable } from 'components/PaymentTable/PaymentTable.component';

export const defaultPaymentsHeader = (
  t: (message: string, options?: { count: number }) => string,
  isClient = false,
): Array<HeaderTable> => [
  { heading: t('Dashboard.payer'), value: 'payer' },
  { heading: t('Dashboard.payee'), value: 'payee' },
  { heading: t('Fields.bankAccount'), value: 'payeesAccount' },
  ...(isClient
    ? ([{ heading: t('Fields.purpose'), value: 'purpose' }] as const)
    : []),
  ...(isClient
    ? ([{ heading: t('Fields.paymentCode'), value: 'paymentCode' }] as const)
    : []),
  { heading: t('Dashboard.amount'), value: 'amount' },
  { heading: t('Fields.model'), value: 'model' },
  { heading: t('Fields.referenceNumber'), value: 'referenceNumber' },
  { value: 'button' },
];
