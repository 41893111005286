import Checkbox, {
  CheckBoxProps,
} from 'components/CheckBox/CheckBox.component';
import React from 'react';
import { FieldRenderProps } from 'react-final-form';

export type CheckBoxFieldProps = CheckBoxProps &
  FieldRenderProps<any, HTMLElement, any>;

const CheckBoxField: React.FC<CheckBoxFieldProps> = (props) => {
  const { input, meta, onChange } = props;

  return (
    <Checkbox
      {...props}
      error={
        (meta.touched && meta.error) ||
        (!meta.dirtySinceLastSubmit && meta.submitError)
      }
      value={input.value}
      onChange={(value: boolean) => {
        input.onChange(value);
        onChange?.(value);
      }}
    />
  );
};

export default CheckBoxField;
