import { keepPreviousData, QueryKey, useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import { PaginationResponse } from 'models/Response';
import useDebounce from './useDebounce';

interface IUseFilteredData<T> {
  debounceDelay?: number;
  searchAttribute: string;
  inputValue: string;
  offlineFilter?: boolean;
  enabled?: boolean;
  queryKey?: QueryKey;
  options?: T[];
  keepPreviousData?: boolean;
}

/**
 * Hook that provides filtered data based on a search input. It supports both online
 * and offline filtering by using a debounce mechanism and optional query or options data.
 *
 * - If offlineFilter is enabled, data is filtered locally using the search attribute.
 * - If a queryKey is provided, it fetches data via the useQuery hook with an optional
 *   debounce for the search input.
 *
 * @param debounceDelay - Optional debounce delay for search input (in ms).
 * @param inputValue - The search input to filter data by.
 * @param searchAttribute - The key to search within the data.
 * @param offlineFilter - Whether to filter data locally (default: true).
 * @param enabled - Whether the query is enabled (default: true).
 * @param queryKey - The key for fetching query data.
 * @param options - Optional predefined data to filter (used if no query is provided).
 */
export default function <T extends Record<string, any>>({
  debounceDelay,
  inputValue,
  queryKey,
  searchAttribute,
  options,
  keepPreviousData: keep = true,
  offlineFilter = true,
  enabled = true,
}: IUseFilteredData<T>) {
  const debouncedValue = debounceDelay
    ? useDebounce(inputValue?.trim(), debounceDelay)
    : inputValue;

  const searchBy = useMemo(
    () =>
      debouncedValue
        ? { searchBy: `${String(searchAttribute)}:${debouncedValue}` }
        : {},
    [debouncedValue, searchAttribute],
  );

  const { data, ...responseData } = useQuery<T[] | PaginationResponse<T>>({
    queryKey: [
      queryKey?.[0],
      {
        ...(queryKey?.[1] as Record<string, any>),
        ...searchBy,
      },
    ],
    placeholderData: keep && keepPreviousData,
    enabled: enabled && !options && !!queryKey,
  });

  const finalData = useMemo(() => {
    const dataToFilter = data || options;

    if (!dataToFilter) return [];

    if (Array.isArray(dataToFilter))
      return offlineFilter
        ? dataToFilter.filter((item) =>
            (item[searchAttribute] as string)
              ?.toLowerCase()
              .startsWith(inputValue?.trim().toLowerCase()),
          )
        : dataToFilter;

    return offlineFilter
      ? dataToFilter['items'].filter((item) =>
          (item[searchAttribute] as string)
            ?.toLowerCase()
            .startsWith(inputValue?.trim().toLowerCase()),
        )
      : dataToFilter['items'];
  }, [data, options, offlineFilter, searchAttribute, inputValue]);

  return { finalData, ...responseData };
}
