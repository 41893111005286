import classNames from 'classnames';
import React from 'react';
import { Field, Form } from 'react-final-form';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import AuthWrapper from 'components/AuthWrapper';
import InputField from 'components/InputField';
import LoadingButton from 'components/LoadingButton';
import PasswordInputField from 'components/PasswordInput';
import { API_ENDPOINTS } from 'config/endpoints';
import { APP_ROUTES } from 'config/routes';
import { SEARCH_PARAMS } from 'config/searchParams';
import useSearchParams from 'hooks/useSearchParams';
import useTanstackMutation from 'hooks/useTanstackMutation';
import EmailIcon from 'icons/Email.icon';
import PasswordIcon from 'icons/Password.icon';
import { PasswordRegExp } from 'validations/regularExpression';
import {
  composeValidators,
  validatePasswordRepeat,
  validateRegex,
  validateRequired,
} from 'validations/validations';

import './RegisterClient.styles.responsive.scss';
import './RegisterClient.styles.scss';

type RegisterClientProps = {
  className?: string;
};

type FormInputs = {
  email: string;
  password: string;
  passwordrepeat: string;
};

const RegisterClient: React.FC<RegisterClientProps> = (props) => {
  const { className } = props;

  const classes = classNames('register-client', className);

  const { t } = useTranslation();

  const { getSearchParam } = useSearchParams();
  const email = getSearchParam(SEARCH_PARAMS.EMAIL);
  const token = getSearchParam(SEARCH_PARAMS.TOKEN);

  const navigate = useNavigate();

  const { mutate, isPending } = useTanstackMutation({
    onError: () => {
      toast.error(t('Messages.registrationFailed'));
    },
    onSuccess: () => {
      toast.success(t('Register.registerSuccess'));
      navigate(APP_ROUTES.LOGIN, { replace: true });
    },
  });

  const handleSubmit = (values: FormInputs) => {
    const { password } = values;
    mutate({
      method: 'POST',
      body: { password },
      path: API_ENDPOINTS.REGISTER_CLIENT_MEMBER(token),
    });
  };

  return (
    <AuthWrapper
      className={classes}
      title={t('Authentication.setYourPassword')}
    >
      <Form<FormInputs>
        initialValues={{ email }}
        onSubmit={handleSubmit}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit} className="register-client__form">
            <InputField
              disabled
              titleicon={<EmailIcon />}
              title={t('Authentication.email')}
              name="email"
            />
            <Field
              autoComplete="off"
              component={PasswordInputField}
              titleicon={<PasswordIcon />}
              title={t('Register.password')}
              name="password"
              validate={composeValidators(
                validateRequired('Form.passwordRequired'),
                validateRegex('Form.invalidPassword', PasswordRegExp),
              )}
            />
            <Field
              autoComplete="off"
              component={PasswordInputField}
              titleicon={<PasswordIcon />}
              title={t('Register.repeatPassword')}
              name="passwordrepeat"
              validate={composeValidators(
                validateRequired('Form.passwordRequired'),
                validatePasswordRepeat('Form.pleaseRepeatThePassword'),
              )}
            />
            <LoadingButton
              isLoading={isPending}
              className="register-client__button"
            >
              {t('Buttons.savePassword')}
            </LoadingButton>
          </form>
        )}
      />
    </AuthWrapper>
  );
};

export default RegisterClient;
