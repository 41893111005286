import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';

import './Select.style.scss';

export type SelectOptionType = {
  label: string;
  value: string;
};

export type SelectProps = {
  label?: string;
  options: SelectOptionType[];
  className?: string;
  name?: string;
  icon?: React.ReactNode;
  errorMessage?: string;
  error?: string;
  iconPosition?: 'left' | 'right';
  value?: SelectOptionType;
  variant?: 'default' | 'squared' | 'line';
  onChange?: (value: SelectOptionType) => void;
  preselectFirst?: boolean;
} & Omit<
  React.DetailedHTMLProps<
    React.SelectHTMLAttributes<HTMLSelectElement>,
    HTMLSelectElement
  >,
  'onChange' | 'value'
>;

const Select: React.FC<SelectProps> = (props) => {
  const {
    className,
    label,
    icon,
    iconPosition,
    options,
    error,
    variant = 'default',
    disabled,
    value,
    onChange,
    preselectFirst = false,
  } = props;

  // for tracking whether select is open or not
  const [open, setOpen] = useState(undefined);

  const [valueState, setValueState] = useState<SelectOptionType>(value);

  useEffect(() => setValueState(value), [value]);

  useEffect(() => {
    if (preselectFirst && !value) {
      setValueState(options[0]);
      onChange(options[0]);
    }
    // eslint-disable-next-line
  }, [options]);

  const errorRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const triggerRef = useRef<HTMLDivElement>(null);
  const labelRef = useRef<HTMLParagraphElement>(null);

  useEffect(() => {
    if (!open) return;

    const onClick = () => {
      setOpen(false);
    };

    //added this so that select closes on click anywhere, not only on the click on div (for better user experience)
    document.body.addEventListener('click', onClick);

    return () => {
      document.body.removeEventListener('click', onClick);
    };
  }, [open]);

  const selectBtn = classNames('select__wrapper__btn', {
    'select__wrapper__icon--row-reverse': iconPosition === 'right',
    'select__wrapper__btn--error': !!error,
    'select__wrapper__btn--squared': variant === 'squared',
    'select__wrapper__btn--line': variant === 'line',
  });

  const iconArrow = classNames('select__wrapper__icon', {
    'select__wrapper__icon--open': open,
  });

  const select = classNames(
    'select__wrapper',
    {
      'select__wrapper--box-shadow': open,
      'select__wrapper--squared': variant === 'squared',
      'select__wrapper--line': variant === 'line',
      'select__wrapper--disabled': disabled,
    },
    className,
  );

  const content = classNames('select__wrapper__content', {
    'select__wrapper__content--open': open,
    'select__wrapper__content--close': open === false,
    'select__wrapper__content--initial': open === undefined,
  });

  const labelStyle = classNames('select__wrapper__label', {
    'select__wrapper__label--error': !!error,
  });

  return (
    <div className={select} ref={containerRef}>
      <p className={labelStyle} ref={labelRef}>
        {label}
      </p>
      <div
        ref={triggerRef}
        className={selectBtn}
        onClick={(event) => {
          if (disabled) return;
          setOpen(!open);
          event.stopPropagation();
        }}
      >
        {icon && <div className={iconArrow}>{icon}</div>}
        {valueState?.label || ' '}{' '}
      </div>
      <div className="select__wrapper__content__error" ref={errorRef}>
        {error}
      </div>
      <div
        className={content}
        style={{
          width: `${containerRef?.current?.getBoundingClientRect().width}px`,
          top: `${
            labelRef?.current?.getBoundingClientRect().height +
            triggerRef?.current?.getBoundingClientRect().height
          }px`,
        }}
      >
        {options.map((option) => (
          <div
            className="select__wrapper__content__item"
            onClick={(_e) => {
              onChange?.(option);
              setValueState(option);
              setOpen(false);
            }}
            key={option.value}
          >
            {option.label}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Select;
