import classNames from 'classnames';
import { FC, ReactNode } from 'react';

import Button from 'components/Button';

import { useTranslation } from 'react-i18next';
import './ProjectInfoCard.styles.responsive.scss';
import './ProjectInfoCard.styles.scss';

export type ProjectInfoCardProps = {
  className?: string;
  onClick?: () => void;
  data: string | number;
  text: string;
  icon?: ReactNode;
  helperText?: string;
};

const ProjectInfoCard: FC<ProjectInfoCardProps> = (props) => {
  const { className, data, text, helperText, icon, onClick } = props;

  const classes = classNames('project-info-card', className);

  const { t } = useTranslation();

  return (
    <div className={classes}>
      <div className="project-info-card__data">
        {icon}
        {data}
      </div>
      <div className="project-info-card__text">
        <span className="project-info-card__text__main">{text}</span>
        <span className="project-info-card__text__helper">{helperText}</span>
      </div>

      {!!onClick && (
        <Button
          styleType="link"
          className="project-info-card__button"
          onClick={onClick}
        >
          {t('Projects.viewPaidByMonth')}
        </Button>
      )}
    </div>
  );
};

export default ProjectInfoCard;
