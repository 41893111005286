import classNames from 'classnames';
import { createContext, PropsWithChildren, useContext } from 'react';

import './EmptyStateLayout.styles.responsive.scss';
import './EmptyStateLayout.styles.scss';

type EmptyStateLayoutProps = {
  className?: string;
  title?: string;
  description?: string;
} & PropsWithChildren;

type EmptyStateLayoutContextType = {
  title?: string;
  description?: string;
};

const EmptyStateLayoutContext = createContext<EmptyStateLayoutContextType>({
  title: '',
  description: '',
});

const useEmptyStateLayoutContext = () => useContext(EmptyStateLayoutContext);

function EmptyStateLayout(props: EmptyStateLayoutProps) {
  const { className, children, description, title } = props;

  const classes = classNames('empty-state-layout', className);

  return (
    <EmptyStateLayoutContext.Provider value={{ title, description }}>
      <div className={classes}>{children}</div>
    </EmptyStateLayoutContext.Provider>
  );
}

type EmptyStateLayoutTitleProps = {
  className?: string;
};
const EmptyStateLayoutTitle = (props: EmptyStateLayoutTitleProps) => {
  const { className } = props;
  const classes = classNames('empty-state-layout__title', className);

  const { title } = useEmptyStateLayoutContext();

  return <h2 className={classes}>{title}</h2>;
};

type EmptyStateLayoutDescriptionProps = {
  className?: string;
};
const EmptyStateLayoutDescription = (
  props: EmptyStateLayoutDescriptionProps,
) => {
  const { className } = props;
  const classes = classNames('empty-state-layout__description', className);

  const { description } = useEmptyStateLayoutContext();

  return <p className={classes}>{description}</p>;
};

type EmptyStateLayoutContentProps = {
  className?: string;
} & PropsWithChildren;
const EmptyStateLayoutContent = (props: EmptyStateLayoutContentProps) => {
  const { children, className } = props;
  const classes = classNames('empty-state-layout__content', className);

  return <div className={classes}>{children}</div>;
};

type EmptyStateLayoutFooterProps = {
  className?: string;
} & PropsWithChildren;
const EmptyStateLayoutFooter = (props: EmptyStateLayoutFooterProps) => {
  const { className, children } = props;

  const classes = classNames('empty-state-layout__footer', className);

  return <div className={classes}>{children}</div>;
};

EmptyStateLayout.Title = EmptyStateLayoutTitle;
EmptyStateLayout.Description = EmptyStateLayoutDescription;
EmptyStateLayout.Content = EmptyStateLayoutContent;
EmptyStateLayout.Footer = EmptyStateLayoutFooter;

export default EmptyStateLayout;
