import { DateTime } from 'luxon';
import { Payment } from 'models/Payment';
import { FormInputs } from './PaymentForm.component';

export const constructInitialValues = (initialValues: Payment): FormInputs => ({
  amount: initialValues.price.amount,
  currency: {
    label: initialValues.price.currency,
    value: initialValues.price.currency,
  },
  model: initialValues.model,
  payee: {
    name: initialValues.payee.name,
    address: initialValues.payee.address,
    _id: initialValues.creditorId,
    bankAccount: initialValues.payeesAccount,
  },
  payeesAccount: initialValues.payeesAccount,
  payer: {
    _id: initialValues.clientId,
    address: initialValues.payer.address,
    companyName: initialValues.payer.name,
  },
  payerAccount: initialValues.payerAccount,
  paymentCode: {
    value: `${initialValues.paymentCode}`,
    label: `${initialValues.paymentCode}`,
    message: ``,
  },
  project: {
    value: initialValues.projectId,
    label: initialValues.projects[0].name,
  },
  purpose: initialValues.purpose,
  instant: initialValues.instant,
  processmentDate:
    initialValues.processmentDate.toFormat('yyyy MM dd') <
    DateTime.now().toFormat('yyyy MM dd')
      ? DateTime.now()
      : initialValues.processmentDate,
  referenceNumber: `${initialValues.referenceNumber || ''}`,
});

export const constructRequestBody = (values: FormInputs) => ({
  purpose: values.purpose,
  payeesAccount: values.payeesAccount,
  paymentCode: Number(values.paymentCode.value),
  payee: {
    name: values.payee.name,
    address: values.payee.address,
  },
  payer: {
    name:
      values.payer.companyName ??
      `${values.payer.firstName} ${values.payer.lastName}`,
    address: values.payer.address,
  },
  payerAccount: values.payerAccount,
  price: {
    currency: values.currency.value,
    amount: Number(values.amount),
  },
  processmentDate: values.processmentDate.toFormat('yyyy.MM.dd'),
  projectId: values.project.value,
  clientId: values.payer._id,
  creditorId: values.payee._id,
  model: values.model ?? '',
  referenceNumber: values.referenceNumber ?? '',
  instant: values.instant,
});
