const clientId = 'clientid';
const creditorId = 'creditorid';
const edit = 'edit';
const email = 'email';
const page = 'page';
const pageState = 'page-state';
const paymentView = 'payment-view';
const paymentId = 'payment-id';
const projectId = 'projectId';
const search = 'search';
const sortBy = 'sort-by';
const order = 'order';
const token = 'token';

export const SEARCH_PARAMS = {
  CLIENT_ID: clientId,
  CREDITOR_ID: creditorId,
  EDIT: edit,
  EMAIL: email,
  PAGE: page,
  PAGE_STATE: pageState,
  PAYMENT_VIEW: paymentView,
  PAYMENT_ID: paymentId,
  PROJECT_ID: projectId,
  SEARCH: search,
  SORT_BY: sortBy,
  ORDER: order,
  TOKEN: token,
};
