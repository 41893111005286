import { useEffect, useRef, useState } from 'react';

/**
 * Custom hook that executes a callback function after a countdown timer reaches zero.
 * The timer starts immediately when the hook is invoked and decrements every second.
 *
 * - The callback function is invoked when the timer finishes.
 * - The countdown resets and starts over whenever the dependencies change.
 *
 * @param cb - The callback function to execute when the timer reaches zero.
 * @param deps - Dependency array that, when changed, resets the timer.
 * @param timer - Initial countdown value in seconds (default: 5).
 *
 * @returns The current countdown time.
 */

export default function (
  cb: (...args: any[]) => void,
  deps: ReadonlyArray<any>,
  timer = 5,
) {
  const [time, setTime] = useState(timer);

  const callbackRef = useRef<(...args: any[]) => void>();

  useEffect(() => {
    callbackRef.current = cb;
  }, [cb]);

  useEffect(() => {
    const timer = setInterval(() => {
      setTime((time) => {
        if (time === 0) {
          clearInterval(timer);
          callbackRef.current();
          return 0;
        } else return time - 1;
      });
    }, 1000);
    // eslint-disable-next-line
  }, deps);

  return time;
}
