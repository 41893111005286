import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Link from 'components/Link';
import Modal from 'components/Modal';
import { ModalProps } from 'components/Modal/Modal.component';
import ProjectForm, {
  ProjectFormProps,
} from 'components/ProjectForm/ProjectForm.component';
import { APP_ROUTES } from 'config/routes';
import OpenFolderIcon from 'icons/OpenFolder.icon';
import OpenInNewIcon from 'icons/OpenInNew.icon';

import './ProjectFormModal.styles.responsive.scss';
import './ProjectFormModal.styles.scss';

type ProjectFormModalProps = {
  modalConfig: ModalProps;
  formConfig?: ProjectFormProps;
};

const ProjectFormModal: React.FC<ProjectFormModalProps> = (props) => {
  const { formConfig, modalConfig } = props;

  const { t } = useTranslation();

  const classes = classNames('project-form-modal', modalConfig.className);
  const classesForm = classNames(
    'project-form-modal__form',
    formConfig?.className,
  );

  const clientName =
    formConfig.client?.companyName ??
    `${formConfig.client?.firstName} ${formConfig.client?.lastName}`;

  return (
    <Modal
      disableCloseButton
      heading={
        <>
          {t(
            `Projects.${
              formConfig?.copyFromProject ? 'copyProjectFor' : 'addAProjectFor'
            }`,
          )}
          <span className="project-form-modal__name">{clientName}</span>
        </>
      }
      iconRight={
        <>
          <OpenFolderIcon className="project-form-modal__icon" />
          {formConfig.copyFromProject && (
            <Link
              className="project-form-modal__link"
              target="_blank"
              to={`${APP_ROUTES.PROJECTS}/${formConfig.copyFromProject._id}`}
            >
              <OpenInNewIcon />
            </Link>
          )}
        </>
      }
      className={classes}
      {...modalConfig}
    >
      <ProjectForm {...formConfig} className={classesForm} />
    </Modal>
  );
};

export default ProjectFormModal;
