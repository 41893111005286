import { QueryKey } from '@tanstack/react-query';
import classNames from 'classnames';
import { MutableRefObject, useEffect, useRef, useState } from 'react';

import Button from 'components/Button';
import { useMoveThroughMenuOnKeyDown } from 'components/Combobox/Combobox.hooks';
import Input, { InputProps } from 'components/Input/Input.component';
import LoadingSpinner from 'components/LoadingSpinner';
import useDisclosure from 'hooks/useDisclosure';
import useFilteredData from 'hooks/useFilteredData';
import useInvalidateUI from 'hooks/useInvalidateUI';
import CrossIcon from 'icons/Cross.icon';
import SearchIcon from 'icons/Search.icon';
import VectorArrowIcon from 'icons/VectorArrow.icon';

import useEffectSkipFirst from 'hooks/useEffectSkipFirst';
import './AutoSuggest.styles.responsive.scss';
import './AutoSuggest.styles.scss';

type ResultRefs = {
  ref: HTMLDivElement;
  index: number;
};

export type AutoSuggestProps<T> = {
  className?: string;
  inputValue?: string;
  onInputChange?: (value: string) => void;
  value?: T;
  onChange?: (value: T) => void;
  minFetchTrigger?: number;
  searchAttribute: string;
  queryKey?: QueryKey;
  options?: T[];
  onBlur?: (...args: any[]) => void;
  onFocus?: (...args: any[]) => void;
  debounceDelay?: number;
  disableArrowIcon?: boolean;
  disableLoadingButton?: boolean;
  disableDeleteButton?: boolean;
  showSearchIcon?: boolean;
  renderItem?: (item: T) => JSX.Element;
  renderSelectedItem?: (item: T) => JSX.Element;
  renderEmptyStateComponent?: () => JSX.Element;
  disableQuery?: boolean;
  offlineFilter?: boolean;
} & Omit<InputProps, 'onChange' | 'onBlur' | 'onFocus' | 'value'>;

const AutoSuggest = <T extends Record<string, any>>(
  props: AutoSuggestProps<T>,
) => {
  const {
    className,
    inputValue,
    onInputChange,
    value,
    onChange,
    searchAttribute,
    queryKey,
    minFetchTrigger,
    disabled,
    onFocus,
    onBlur,
    options,
    debounceDelay = 200,
    disableArrowIcon = false,
    disableLoadingButton = false,
    disableDeleteButton = false,
    showSearchIcon = false,
    disableQuery = false,
    renderSelectedItem,
    renderEmptyStateComponent,
    renderItem,
    title,
    error,
    variant = 'default',
    offlineFilter = true,
    ...inputProps
  } = props;

  const classes = classNames(
    'auto-suggest',
    { 'auto-suggest--disabled': disabled },
    className,
  );

  const inputClasses = classNames('auto-suggest__input', {
    'auto-suggest__input--selected': !!value || !onChange,
  });

  const { isOpen, open, close, toggle } = useDisclosure({
    onClose: onBlur,
  });

  const iconArrow = classNames('auto-suggest__trigger__icon--arrow', {
    'auto-suggest__trigger__icon--arrow--open': isOpen,
  });

  const [innerInputValue, setInnerInputValue] = useState(
    inputValue ?? value?.[searchAttribute] ?? '',
  );

  const [inputView, setInputView] = useState(!value);

  const { finalData, isFetching } = useFilteredData<T>({
    inputValue: innerInputValue,
    searchAttribute,
    debounceDelay,
    queryKey,
    options,
    offlineFilter,
    enabled:
      (!minFetchTrigger || innerInputValue.length >= minFetchTrigger) &&
      !disabled &&
      !disableQuery,
  });

  const triggerRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const footerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const resultsRef = useRef<ResultRefs[]>([]);
  useMoveThroughMenuOnKeyDown({
    inputRef,
    resultsRef,
    fillData: onInputChange ?? setInnerInputValue,
    handleClose: close,
  });
  useInvalidateUI();

  useEffect(() => {
    const handleClose = (e: MouseEvent) => {
      if (
        e.target !== inputRef.current &&
        !resultsRef.current
          .map((r) => r.ref)
          .includes(e.target as HTMLDivElement) &&
        !triggerRef.current.contains(e.target as HTMLDivElement) &&
        isOpen
      )
        close();
    };

    document.addEventListener('click', handleClose);

    return () => document.removeEventListener('click', handleClose);
  }, [isOpen, close]);

  const handleSelect = (item: T) => {
    if (renderSelectedItem) setInputView(false);
    if (!renderSelectedItem) onInputChange?.(`${item[searchAttribute]}`);
    if (!onChange) setInnerInputValue(`${item[searchAttribute]}`);
    onChange?.(item);
    close();
  };

  useEffect(() => {
    if (value) {
      setInputView(false);
      setInnerInputValue(value[searchAttribute] ?? '');
    }
  }, [value, searchAttribute]);

  useEffect(() => {
    if (inputValue !== undefined) {
      setInnerInputValue(inputValue);
    }
  }, [inputValue]);

  useEffectSkipFirst(() => {
    if (inputView) inputRef.current.focus();
  }, [inputView]);

  return (
    <div className={classes} ref={containerRef}>
      <label className="auto-suggest__title">{title}</label>
      <div
        ref={triggerRef}
        className={classNames(
          'auto-suggest__trigger',
          `auto-suggest__trigger--${variant}`,
          { 'auto-suggest__trigger--error': !!error },
        )}
      >
        {showSearchIcon && (
          <SearchIcon className="auto-suggest__trigger__icon--search" />
        )}
        {renderSelectedItem && !!value && !inputView ? (
          <div
            onClick={(e) => {
              if (disabled) return;
              e.stopPropagation();
              setInputView(true);
              onChange?.(undefined);
            }}
            className={classNames('auto-suggest__trigger__showcase', {
              'auto-suggest__trigger__showcase--disabled': disabled,
            })}
          >
            {renderSelectedItem?.(value)}
          </div>
        ) : (
          <Input
            className={inputClasses}
            ref={inputRef}
            value={innerInputValue}
            onChange={(e) => {
              onChange?.(undefined);
              onInputChange
                ? onInputChange?.(e.target.value)
                : setInnerInputValue(e.target.value);
              open();
            }}
            disabled={disabled}
            onFocus={open}
            onClick={open}
            variant="invisible"
            {...inputProps}
          />
        )}
        <div className="auto-suggest__trigger__loading">
          {innerInputValue && isFetching && !disableLoadingButton && (
            <LoadingSpinner />
          )}
        </div>
        {innerInputValue && !disabled && !disableDeleteButton && (
          <Button
            type="button"
            icon={<CrossIcon />}
            styleType="icon"
            className="auto-suggest__trigger__icon"
            onClick={(e) => {
              e.preventDefault();
              onInputChange?.('');
              setInnerInputValue('');
              onChange?.(undefined);
              setInputView(true);
            }}
            disabled={disabled}
          />
        )}
        {!disableArrowIcon && (
          <Button
            styleType="icon"
            type="button"
            onClick={toggle}
            style={{ padding: 0 }}
            tabIndex={-1}
            disabled={disabled}
          >
            <VectorArrowIcon className={iconArrow} />
          </Button>
        )}
      </div>
      <footer className="auto-suggest__error" ref={footerRef}>
        {error}
      </footer>

      <AutoSuggestResults
        handleSelect={handleSelect}
        isOpen={
          isOpen &&
          (!minFetchTrigger || innerInputValue.length >= minFetchTrigger)
        }
        resultsRef={resultsRef}
        searchAttribute={searchAttribute}
        containerRect={containerRef?.current?.getBoundingClientRect()}
        footerRect={footerRef?.current?.getBoundingClientRect()}
        data={finalData}
        renderEmptyStateComponent={renderEmptyStateComponent}
        renderItem={renderItem}
      />
    </div>
  );
};

type AutoSuggestResultsProps<T> = {
  isOpen: boolean;
  containerRect?: DOMRect;
  footerRect?: DOMRect;
  data?: T[];
  searchAttribute: string;
  resultsRef: MutableRefObject<ResultRefs[]>;
  handleSelect: (item: T) => void;
  renderEmptyStateComponent?: () => JSX.Element;
  renderItem?: AutoSuggestProps<T>['renderItem'];
};

const AutoSuggestResults = <T extends Record<string, any>>({
  isOpen,
  containerRect,
  footerRect,
  data,
  searchAttribute,
  resultsRef,
  renderEmptyStateComponent,
  handleSelect,
  renderItem,
}: AutoSuggestResultsProps<T>) => {
  if (!isOpen) return null;

  return (
    <div
      className="auto-suggest__results"
      style={{
        width: `${containerRect?.width}px`,
        transform: `translateY(-${footerRect?.height}px)`,
      }}
    >
      {(!data || !data.length) && renderEmptyStateComponent?.()}

      {data?.map((item, index) => (
        <div
          tabIndex={-1}
          key={item[searchAttribute]}
          ref={(ref) => (resultsRef.current[index] = { ref, index })}
          onClick={() => handleSelect(item)}
          onKeyDown={(e) => e.key === 'Enter' && handleSelect(item)}
          className="auto-suggest__results__result"
        >
          {renderItem?.(item) ?? item[searchAttribute]}
        </div>
      ))}
    </div>
  );
};

export default AutoSuggest;
