import React, { FC } from 'react';

import SkeletonLoader from 'components/SkeletonLoader';
import classNames from 'classnames';

import './PaymentCardLoader.style.scss';

type PaymentCardLoadingProps = {
  className?: string;
};

const PaymentCardLoading: FC<PaymentCardLoadingProps> = (props) => {
  const { className } = props;

  const classes = classNames('payment-card-loader', className);

  return <SkeletonLoader className={classes} />;
};

export default PaymentCardLoading;
