import { QueryObserverResult, RefetchOptions } from '@tanstack/react-query';
import {
  Payment,
  PaymentSelectingMode,
  PaymentView,
  SelectedPayment,
} from 'models/Payment';
import { ProjectWithClient } from 'models/Project';
import { PaginationResponse } from 'models/Response';
import { Account } from 'models/User';
import { createContext, SetStateAction } from 'react';

export default createContext<Partial<ProjectContextProps>>(undefined);

type ProjectContextProps = {
  open: () => void;
  close: () => void;
  isOpen: boolean;
  isFetched: boolean;
  search: string;
  hasErrors: boolean;
  isEmptyClientStatePresent: boolean;
  setSearch: React.Dispatch<SetStateAction<string>>;
  queryParams: Record<string, any>;
  refetch: () => void;
  projectId: string;
  projectData: ProjectWithClient;
  isDownloadPending: boolean;
  paymentSlipsData: PaginationResponse<Payment>;
  isPaymentSlipsFetching: boolean;
  isPaymentSlipsLoading: boolean;
  selected: SelectedPayment[];
  unselected: SelectedPayment[];
  newlySelected: SelectedPayment[];
  areNoneSelected: boolean;
  areAllSelected: boolean;
  onSelect: (data: SelectedPayment, val: boolean) => void;
  selectAll: (select: boolean) => void;
  resetSelected: () => void;
  members: PaginationResponse<Account>;
  isMembersLoading: boolean;
  isProjectFetching: boolean;
  paymentSelectingMode: PaymentSelectingMode;
  setPaymentSelectingMode: React.Dispatch<SetStateAction<PaymentSelectingMode>>;
  selectedView: PaymentView;
  refetchPayments: (
    options?: RefetchOptions,
  ) => Promise<QueryObserverResult<PaginationResponse<Payment>, Error>>;
};
