import PaymentCardLoading from 'components/PaymentCard/PaymentCardLoader.component';
import React from 'react';

import './PaymentsList.styles.scss';

const PaymentsGridLoading: React.FC = () => {
  return (
    <div className="payments-grid-loading">
      {Array.from({ length: 12 }, (_, i) => i).map((i) => (
        <PaymentCardLoading key={i} />
      ))}
    </div>
  );
};

export default PaymentsGridLoading;
