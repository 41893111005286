import { APP_ROUTES } from 'config/routes';
import React, { useMemo } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import routes from 'router/routes';
import { EnhancedRouteProps } from 'router/types';
import authService from 'services/authService';
import credentialsService from 'services/credentialsService';

const EnhancedRoute: React.FC<EnhancedRouteProps> = (props) => {
  const {
    authorized: authorizedRoute = false,
    onlyPublic = false,
    element,
    authorizedRoles,
  } = props;

  const nonLoggedInRoutes = useMemo(
    () =>
      routes
        .filter((route) => route.onlyPublic)
        .map((route) => route.path)
        .filter((route) => route !== ''),
    [],
  );

  const isLoggedIn = !!credentialsService.token;
  const user = credentialsService.user;

  const location = useLocation();

  const finalRoute = useMemo(() => {
    if (
      (!authorizedRoute || authorizedRoles) &&
      !isLoggedIn &&
      !nonLoggedInRoutes.some((route) => location.pathname.includes(route))
    ) {
      return (
        <Navigate
          to={{ pathname: APP_ROUTES.LOGIN }}
          state={{ from: location.pathname }}
          replace
        />
      );
    }

    const { userRole } = credentialsService;

    if (onlyPublic && isLoggedIn) {
      return (
        <Navigate
          to={APP_ROUTES[userRole === 'book_keeper' ? 'DASHBOARD' : 'PROJECTS']}
        />
      );
    }

    if (authorizedRoles) {
      const userAllowed = authService.checkRolesForUser(user, authorizedRoles);
      if (!userAllowed) {
        return <Navigate to={APP_ROUTES['LOGOUT']} />;
      }
    }

    if (typeof element === 'function') {
      const Component = element;

      return <Component />;
    }
    return element as React.ReactElement;
  }, [
    authorizedRoute,
    authorizedRoles,
    isLoggedIn,
    onlyPublic,
    location,
    user,
    element,
    nonLoggedInRoutes,
  ]);

  return finalRoute;
};

export default EnhancedRoute;
