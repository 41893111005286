import React from 'react';
import classNames from 'classnames';
import CheckedIcon from 'icons/Checked.icon';

import './Stepper.styles.scss';

export type StepperNodeProps = {
  className: 'default' | 'active' | 'checked';
};

export type StepperTypeProps = {
  className?: string;
  numberOfNodes?: number;
  nodeIndex?: number;
};

const StepperNode: React.FC<StepperNodeProps> = (props) => {
  const { className } = props;

  const classes = classNames(
    'is-stepper-node-wrapper',
    `is-stepper-node-wrapper--${className}`,
  );

  return (
    <div className={classes}>
      <div className="is-stepper-node">
        <CheckedIcon />
      </div>
      {<div className="is-stepper-connector" />}
    </div>
  );
};

const Stepper: React.FC<StepperTypeProps> = (props) => {
  const { className, numberOfNodes = 2, nodeIndex = 0 } = props;
  const classes = classNames('is-stepper', className);

  return (
    <div className={classes}>
      {[...Array(numberOfNodes)].map((element: StepperNodeProps, index) => {
        return (
          <StepperNode
            key={index}
            className={
              index < nodeIndex
                ? 'checked'
                : index === nodeIndex
                ? 'active'
                : 'default'
            }
          />
        );
      })}
    </div>
  );
};

export default Stepper;
