import classNames from 'classnames';
import CheckBoxField from 'components/CheckBoxField';
import Input from 'components/Input';
import InputField from 'components/InputField';
import PasswordInputField from 'components/PasswordInput';
import Stepper from 'components/Stepper';
import EmailIcon from 'icons/Email.icon';
import PasswordIcon from 'icons/Password.icon';
import React, { useState } from 'react';
import { Field, Form } from 'react-final-form';

import AutoSuggest from 'components/AutoSuggest';
import ClientPreview from 'components/ClientPreview';
import ComboboxField from 'components/ComboboxField';
import PreviewCardWithSearch from 'components/PreviewCardWithSearch';
import { RadioGroupField } from 'components/RadioGroupField';
import ToggleGroup from 'components/ToggleGroup';
import ToolTip from 'components/ToolTip';
import { API_ENDPOINTS } from 'config/endpoints';
import FolderIcon from 'icons/Folder.icon';
import GridViewIcon from 'icons/GridView.icon';
import ListViewIcon from 'icons/ListView.icon';
import { PurposeSuggestion } from 'models/Payment';
import { Client, Creditor } from 'models/User';
import { useTranslation } from 'react-i18next';
import { validateRequiredObject } from 'validations/validations';
import './Catalog.styles.scss';

type CatalogProps = {
  className?: string;
};

const Catalog: React.FC<CatalogProps> = (props) => {
  const { className } = props;

  const classes = classNames('is-catalog', className);

  const [selectedClient, setSelectedClient] = useState<Client>();

  const handleSubmit = (data: any) => {
    window.alert(data);
  };

  const { t } = useTranslation();

  const [isToggledList, setIsToggledList] = useState(true);
  const [isToggledGrid, setIsToggledGrid] = useState(false);

  return (
    <div className={classes}>
      <h1>Catalog</h1>
      <div className="is-catalog__body">
        <PreviewCardWithSearch<Client>
          filterInstantly={false}
          queryKey={[API_ENDPOINTS.CLIENTS, { size: 10 }]}
          searchKey="companyName"
          title="Clients"
          className="is-catalog__preview-card"
          render={(data) =>
            data?.map((client) => (
              <ClientPreview
                key={client._id}
                name={
                  client.companyName || `${client.firstName} ${client.lastName}`
                }
                itemsIcon={<FolderIcon />}
                itemsText={t('Dashboard.project', {
                  count: client.numberProjects,
                })}
                numOfItems={client.numberProjects}
                subtext={client.bankAccount}
              />
            ))
          }
        />
        <AutoSuggest<PurposeSuggestion>
          disableDeleteButton
          searchAttribute="purpose"
          queryKey={[
            API_ENDPOINTS.GET_PAYMENT_PURPOSE_SUGGESTIONS(
              '66ace1ea5c09d3ce7b8776da',
            ),
          ]}
          title="Purpose"
        />
        <AutoSuggest<Client>
          value={selectedClient}
          onChange={setSelectedClient}
          renderSelectedItem={(item) => (
            <div>
              <p style={{ margin: 0 }}>
                {item.companyName ?? `${item.firstName} ${item.lastName}`}
              </p>
              <p style={{ margin: 0 }}>{item.vatNumber}</p>
            </div>
          )}
          renderItem={(data) => (
            <>{data.companyName ?? `${data.firstName} ${data.lastName}`}</>
          )}
          searchAttribute="name"
          offlineFilter={false}
          queryKey={[API_ENDPOINTS.CLIENTS]}
          title="Clients"
          variant="squared"
        />
        <Form
          onSubmit={handleSubmit}
          render={({ handleSubmit }) => (
            <form className="is-catalog__container" onSubmit={handleSubmit}>
              <div>
                <Input
                  titleicon={<EmailIcon></EmailIcon>}
                  title="Input Component"
                  additionalLink={{ name: 'link', path: 'www.google.com' }}
                />
                <InputField
                  titleicon={<PasswordIcon></PasswordIcon>}
                  title="Password"
                  name="inputField3"
                  additionalLink={{
                    name: 'forgot password?',
                    path: 'www.google.com',
                  }}
                />
                <RadioGroupField
                  preselectFirst={false}
                  name="group1"
                  direction="horizontal"
                  options={[
                    { id: 'option1', label: 'Option 1' },
                    { id: 'option2', label: 'Option 2' },
                  ]}
                  validate={validateRequiredObject('err')}
                />
                <Field
                  component={CheckBoxField}
                  name="checkbox"
                  label="Remember me"
                ></Field>
                <Field
                  component={PasswordInputField}
                  name="passwordField"
                  title="ss"
                ></Field>

                <ComboboxField<Creditor>
                  name="Kreditor"
                  queryKey={[
                    API_ENDPOINTS.GET_CREDITORS_FOR_CLIENT(
                      '66ace1ea5c09d3ce7b8776da',
                    ),
                  ]}
                  renderTriggerContent={(data) => (
                    <div>
                      <p>{data?.name}</p>
                      <p>
                        {data?.address.name} {data?.address.zipCode}
                      </p>
                    </div>
                  )}
                  searchAttribute="name"
                  placeholder="Select a creditor..."
                  title="Desinger je tata"
                  validate={validateRequiredObject('Greska be')}
                />
                <Stepper numberOfNodes={4} />
                <button type="submit">Submit</button>
                <ToggleGroup
                  exclusive
                  options={[
                    {
                      isToggled: isToggledList,
                      onToggle: (val) => setIsToggledList(val),
                      icon: <ListViewIcon />,
                    },
                    {
                      isToggled: isToggledGrid,
                      onToggle: (val) => setIsToggledGrid(val),
                      icon: <GridViewIcon />,
                    },
                  ]}
                />

                <ToolTip
                  content={'This is also very possible'}
                  className="is-catalog__tool-tip"
                >
                  Hello
                </ToolTip>
              </div>
            </form>
          )}
        />
      </div>

      <div style={{ backgroundColor: 'beige', width: '300px' }}></div>
    </div>
  );
};

export default Catalog;
