import { useQuery } from '@tanstack/react-query';
import classNames from 'classnames';
import { DateTime } from 'luxon';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import BackLink from 'components/BackLink';
import PaymentForm from 'components/PaymentForm';
import PaymentFormLoader from 'components/PaymentForm/PaymentFormLoader.component';
import { API_ENDPOINTS } from 'config/endpoints';
import { Payment } from 'models/Payment';
import { Creditor } from 'models/User';

import './EditPayment.styles.responsive.scss';
import './EditPayment.styles.scss';

type EditPaymentProps = {
  className?: string;
};

const EditPayment: React.FC<EditPaymentProps> = (props) => {
  const { className } = props;

  const classes = classNames('edit-payment', className);

  const navigate = useNavigate();

  const initial = sessionStorage.getItem('initialValues');
  const parsed = initial ? (JSON.parse(initial) as Payment) : undefined;

  const process = new Date(parsed?.processmentDate as unknown as string);
  const createdAt = new Date(parsed?.receiveDate as unknown as string);

  const processmentDate = DateTime.fromJSDate(process);
  const receiveDate = DateTime.fromJSDate(createdAt);

  const { data, isLoading } = useQuery<Creditor>({
    queryKey: [`${API_ENDPOINTS.CREDITORS}/${parsed.creditorId}`],
  });

  const initialValues = parsed
    ? {
        ...parsed,
        payee: data,
        processmentDate,
        receiveDate,
      }
    : undefined;

  return (
    <div className={classes}>
      <BackLink className="edit-payment__back" />
      {!isLoading ? (
        <PaymentForm
          initialValues={initialValues}
          onSuccess={() => navigate(-1)}
        />
      ) : (
        <PaymentFormLoader />
      )}
    </div>
  );
};

export default EditPayment;
