import React from 'react';

type EditIconProps = {
  className?: string;
};

const EditIcon: React.FC<EditIconProps> = (props) => {
  const { className } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      className={className}
    >
      <path d="M4.292 15.75h1.146l6.958-6.958-1.146-1.146-6.958 6.958Zm11.916-8.146-3.75-3.771.875-.875q.584-.604 1.396-.604.813 0 1.417.584l1.062 1.083q.542.521.532 1.292-.011.77-.532 1.291Zm-1.229 1.208-8.75 8.771H2.458v-3.75l8.771-8.771Zm-3.146-.583-.583-.583 1.146 1.146Z" />
    </svg>
  );
};

export default EditIcon;
