import { useQueryClient } from '@tanstack/react-query';
import classNames from 'classnames';
import React, { useEffect, useRef } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import Button from 'components/Button';
import CheckBox from 'components/CheckBox';
import { DeleteModal } from 'components/DeleteModal';
import ToolTip from 'components/ToolTip';
import { API_ENDPOINTS } from 'config/endpoints';
import { APP_ROUTES } from 'config/routes';
import { SEARCH_PARAMS } from 'config/searchParams';
import useDisclosure from 'hooks/useDisclosure';
import useSearchParams from 'hooks/useSearchParams';
import BackgroundSquares from 'icons/BackgroundSquares.icon';
import DeleteIcon from 'icons/Delete.icon';
import EditIcon from 'icons/Edit.icon';
import ErrorIcon from 'icons/Error.icon';
import OpenFolderIcon from 'icons/OpenFolder.icon';
import PaymentsIcon from 'icons/Payments.icon';
import SuccessCheckedIcon from 'icons/SuccessChecked.icon';
import { Payment, PAYMENT_STATUS, PaymentUser } from 'models/Payment';
import authService from 'services/authService';
import credentialsService from 'services/credentialsService';
import storageService, { STORAGE_KEYS } from 'services/storageService';

import './PaymentCard.scss';

const formatPaymentUser = ({ name, address }: PaymentUser) => {
  return [name, `${address.name} ${address.zipCode}`];
};

export type InfoBlockProps = {
  title: string;
  label: (string | number)[];
};

const InfoBlock: React.FC<InfoBlockProps> = (props) => {
  const { title, label } = props;

  return (
    <article className={'paymentcard__info-block'}>
      <span className="paymentcard__info-block__title">{title}</span>
      <div className="paymentcard__info-block__body">
        {label.map((row, index) => (
          <p className="paymentcard__info-block__body__label" key={index}>
            {row}
          </p>
        ))}
      </div>
    </article>
  );
};

export type PaymentCardProps = {
  paymentInfo: Payment;
  className?: string;
  isProcessing?: boolean;
  disabled?: boolean;
  isChecked?: boolean;
  hideButtons?: boolean;
  onCheck?: (value: boolean) => void;
  onSuccess?: (data?: Payment) => void;
};

const PaymentCard: React.FC<PaymentCardProps> = (props) => {
  const {
    className,
    paymentInfo,
    onCheck,
    onSuccess,
    isChecked,
    hideButtons,
    isProcessing = true,
    disabled = false,
  } = props;

  const cardRef = useRef<HTMLDivElement>(null);
  const { getSearchParam, removeSearchParam } = useSearchParams();
  const selectedPaymentId = getSearchParam(SEARCH_PARAMS.PAYMENT_ID);
  const isScrolled = storageService.getItem(STORAGE_KEYS.IS_SCROLLED);
  useEffect(() => {
    if (paymentInfo._id === selectedPaymentId && !isScrolled)
      cardRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
  }, [paymentInfo, selectedPaymentId, removeSearchParam, isScrolled]);

  const classes = classNames(
    'paymentcard',
    {
      'paymentcard--processed': paymentInfo.status === PAYMENT_STATUS.PROCESSED,
      'paymentcard--error': paymentInfo.status === PAYMENT_STATUS.ERROR,
      'paymentcard--not-selected': isProcessing && !isChecked,
      'paymentcard--clickable': isProcessing,
      'paymentcard--highlight':
        selectedPaymentId === paymentInfo._id && !isScrolled,
      disabled,
    },
    className,
  );

  const { t } = useTranslation();

  const queryClient = useQueryClient();

  const { isOpen, open, close } = useDisclosure();
  const navigate = useNavigate();

  const handleCheck = () => {
    if (!isProcessing) return;
    onCheck(!isChecked);
  };

  const isBookkeeper = authService.checkRolesForUser(credentialsService.user, [
    'book_keeper',
  ]);

  return (
    <div className={classes} onClick={handleCheck} ref={cardRef}>
      <header className="paymentcard__header">
        <span className="paymentcard__header__title">
          <OpenFolderIcon />
          <span>{paymentInfo.purpose}</span>
        </span>
        {isOpen && (
          <DeleteModal
            closeModal={close}
            heading={t('Index.deletePayment')}
            deletingObjectName={paymentInfo.purpose}
            path={`${API_ENDPOINTS.PAYMENT_SLIPS}/${paymentInfo.id}`}
            middleIcon={
              <div className="paymentcard__modal--delete__wrapper">
                <BackgroundSquares className="paymentcard__modal--delete__wrapper__background" />
                <PaymentsIcon className="paymentcard__modal--delete__wrapper__icon" />
              </div>
            }
            onSuccess={() => {
              toast.success(t('Messages.successfullyDeletedPaymentSlip'));
              queryClient.invalidateQueries({
                queryKey: [API_ENDPOINTS.PAYMENT_SLIPS],
              });
              onSuccess?.();
              close();
            }}
            onError={() => {
              toast.error(t('Messages.errorDeletingPaymentSlip'));
            }}
          />
        )}

        <div className="paymentcard__header__wrap">
          {paymentInfo.status === PAYMENT_STATUS.CHECKED && (
            <SuccessCheckedIcon
              className={classNames(
                'paymentcard__header__icon',
                'paymentcard__header__icon--checked',
              )}
            />
          )}
          {paymentInfo.status === PAYMENT_STATUS.ERROR && (
            <ToolTip
              className="paymentcard__header__tool-tip-icon"
              contentClassName="paymentcard__header__tool-tip"
              content={paymentInfo.message}
              disabled={!paymentInfo.message}
            >
              <ErrorIcon
                className={classNames(
                  'paymentcard__header__icon',
                  'paymentcard__header__icon--error',
                )}
              />
            </ToolTip>
          )}
          {paymentInfo.status === PAYMENT_STATUS.PROCESSED && (
            <SuccessCheckedIcon
              className={classNames(
                'paymentcard__header__icon',
                'paymentcard__header__icon--checked',
              )}
            />
          )}

          {!isProcessing ? (
            isBookkeeper &&
            !hideButtons && (
              <span className="paymentcard__header__controls">
                <Button
                  disabled={disabled}
                  className="paymentcard__header__controls__button"
                  styleType="transparent"
                  icon={<EditIcon />}
                  onClick={() => {
                    sessionStorage.setItem(
                      'initialValues',
                      JSON.stringify(paymentInfo),
                    );
                    navigate(APP_ROUTES.EDIT_PAYMENT);
                  }}
                />
                <Button
                  className="paymentcard__header__controls__button"
                  disabled={disabled}
                  styleType="transparent"
                  icon={<DeleteIcon />}
                  onClick={open}
                />
              </span>
            )
          ) : (
            <CheckBox
              disabled={disabled}
              value={isChecked}
              onChange={onCheck}
            />
          )}
        </div>
      </header>
      <main className="paymentcard__body">
        <div className="paymentcard__body__left">
          <InfoBlock
            title={t('Fields.payer')}
            label={formatPaymentUser(paymentInfo.payer)}
          />
          <InfoBlock
            title={t('Fields.purpose')}
            label={[paymentInfo.purpose]}
          />
          <InfoBlock
            title={t('Fields.payee')}
            label={formatPaymentUser(paymentInfo.payee)}
          />
        </div>
        <div className="paymentcard__body__right">
          <div className="paymentcard__body__right__row">
            <InfoBlock title="PC" label={[paymentInfo.paymentCode]} />
            <InfoBlock
              title={t('Fields.currency')}
              label={[paymentInfo.price.currency]}
            />
            <InfoBlock
              title={t('Fields.amount')}
              label={[paymentInfo.price.amount]}
            />
          </div>
          <InfoBlock
            title={t('Fields.bankAccount')}
            label={[paymentInfo.payeesAccount]}
          />
          <div className="paymentcard__body__right__row">
            <InfoBlock title="Model" label={[paymentInfo.model]} />
            <InfoBlock
              title="Reference number"
              label={[paymentInfo.referenceNumber]}
            />
          </div>
        </div>
      </main>
    </div>
  );
};

export default PaymentCard;
