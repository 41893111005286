import { QueryKey } from '@tanstack/react-query';
import classNames from 'classnames';
import React, { useCallback, useEffect, useRef } from 'react';

import Button from 'components/Button';
import Dropdown from 'components/Dropdown';
import Input from 'components/Input';
import { InputProps } from 'components/Input/Input.component';
import LoadingSpinner from 'components/LoadingSpinner';
import useCallbackRef from 'hooks/useCallbackRef';
import useDisclosure from 'hooks/useDisclosure';
import useFilteredData from 'hooks/useFilteredData';
import useInvalidateUI from 'hooks/useInvalidateUI';
import CrossIcon from 'icons/Cross.icon';
import VectorArrowIcon from 'icons/VectorArrow.icon';
import { useTranslation } from 'react-i18next';
import { useMoveThroughMenuOnKeyDown } from './Combobox.hooks';

import './Combobox.styles.responsive.scss';
import './Combobox.styles.scss';

export type ComboboxProps<T> = {
  className?: string;
  inputConfig?: InputProps;
  queryKey: QueryKey;
  renderTriggerContent: (data?: T) => JSX.Element;
  renderListItem?: (data?: T) => JSX.Element;
  value: T;
  onChange: (data: T) => void;
  searchAttribute: string;
  placeholder?: string;
  title?: string;
  error?: string;
  onBlur?: (...args: any[]) => void;
  onFocus?: (...args: any[]) => void;
  offlineFilter?: boolean;
  disabled?: boolean;
};

export type ResultRef = {
  ref: HTMLDivElement;
  index: number;
};

const Combobox = <T extends Record<string, any>>(props: ComboboxProps<T>) => {
  const {
    className,
    queryKey,
    inputConfig,
    renderTriggerContent,
    renderListItem,
    value,
    onChange,
    searchAttribute,
    placeholder,
    title,
    error,
    onBlur,
    onFocus,
    offlineFilter = true,
    disabled = false,
  } = props;

  const { t } = useTranslation();

  const onOpen = useCallback(() => {
    onFocus?.();
    setInputValue('');
  }, [onFocus]);

  const onClose = useCallback(() => {
    onBlur?.();
    setInputValue('');
  }, [onBlur]);

  const { isOpen, toggle, close } = useDisclosure({
    onOpen,
    onClose,
  });

  const classes = classNames(
    'combobox',
    { 'combobox--disabled': disabled },
    className,
  );
  const inputClasses = classNames(
    'combobox__menu__input',
    inputConfig?.className,
  );
  const triggerClasses = classNames('combobox__trigger', {
    'combobox__trigger--open': isOpen,
    'combobox__trigger--error': !!error,
  });
  const vectorArrowClasses = classNames(
    'combobox__trigger__icons__vector-arrow',
    {
      'combobox__trigger__icons__vector-arrow--open': isOpen,
      'combobox__trigger__icons__vector-arrow--closed': isOpen === false,
    },
  );

  const [triggerRef, setTriggerRef] = useCallbackRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const resultsRef = useRef<ResultRef[]>([]);

  const [inputValue, setInputValue] = React.useState('');

  useEffect(() => {
    if (isOpen) inputRef?.current.focus();
  }, [isOpen]);

  const { finalData, isFetching, isLoading } = useFilteredData<T>({
    debounceDelay: 500,
    inputValue,
    queryKey,
    searchAttribute,
    offlineFilter,
    enabled: !disabled,
  });
  useInvalidateUI();
  useMoveThroughMenuOnKeyDown({
    inputRef,
    resultsRef,
    fillData: (data) => setInputValue(data),
    handleClose: close,
  });

  return (
    <div className={classes}>
      <label className="combobox__title">{title}</label>
      <Dropdown
        disabled={disabled}
        className="combobox__dropdown"
        isOpen={isOpen}
        toggleOpenDropdown={toggle}
        trigger={
          <div className={triggerClasses} ref={setTriggerRef}>
            {!value && (
              <span className="combobox__trigger__placeholder">
                {placeholder}
              </span>
            )}

            {value && renderTriggerContent(value)}

            <div className="combobox__trigger__icons">
              {isFetching && !isLoading && (
                <LoadingSpinner className="combobox__trigger__icons__loading" />
              )}
              {value && !disabled && (
                <Button
                  styleType="icon"
                  className="combobox__trigger__icons__cross"
                  icon={<CrossIcon />}
                  type="button"
                  onClick={(e) => {
                    e.stopPropagation();
                    onChange(undefined);
                    close();
                  }}
                />
              )}
              {!disabled && <VectorArrowIcon className={vectorArrowClasses} />}
            </div>
          </div>
        }
      >
        <div
          className={classNames('combobox__menu', {
            'combobox__menu--error': !!error,
          })}
          style={{ width: `${triggerRef?.getBoundingClientRect().width}px` }}
        >
          <Input
            className={inputClasses}
            ref={inputRef}
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            placeholder={t('Fields.search')}
            {...inputConfig}
          />
          <div className="combobox__menu__results">
            {finalData.length ? (
              finalData.map((item, index) => (
                <div
                  key={item[searchAttribute]}
                  className="combobox__menu__results__result"
                  onClick={() => {
                    onChange(item);
                    close();
                  }}
                  tabIndex={-1}
                  ref={(ref) => {
                    resultsRef.current[index] = { ref, index };
                  }}
                  onKeyDown={(e) => {
                    if (e.key !== 'Enter') return;
                    onChange(item);
                    close();
                  }}
                >
                  {renderListItem?.(item) ?? item[searchAttribute]}
                </div>
              ))
            ) : (
              <div className="combobox__menu__results__no-data">
                {!isFetching ? t('Index.theresNoData') : <LoadingSpinner />}
              </div>
            )}
          </div>
        </div>
      </Dropdown>
      <footer className="combobox__footer">{error}</footer>
    </div>
  );
};

export default Combobox;
