import classNames from 'classnames';
import React from 'react';
import './PreviewCard.styles.responsive.scss';
import './PreviewCard.styles.scss';

export type PreviewCardProps = {
  className?: string;
  title?: string;
  titleIconLeft?: React.ReactNode;
  titleIconRight?: React.ReactNode;
  button?: React.ReactNode;
  children?: React.ReactNode;
  infoGrouping?: React.ReactNode;
  headerContent?: React.ReactNode;
  isContentInline?: boolean;
};

const PreviewCard: React.FC<PreviewCardProps> = (props) => {
  const {
    className,
    title,
    titleIconLeft,
    titleIconRight,
    button,
    children,
    infoGrouping,
    headerContent,
    isContentInline,
  } = props;
  const classes = classNames('preview-card', className);

  return (
    <section className={classes}>
      <div className="preview-card__heading">
        <div className="preview-card__heading__upper">
          <p className="preview-card__heading__upper__name">
            {titleIconLeft && (
              <span className="preview-card__heading__upper__name__title-icon">
                {titleIconLeft}
              </span>
            )}
            {title && (
              <span className="preview-card__heading__upper__name__title">
                {title}
              </span>
            )}
            {titleIconRight && (
              <span className="preview-card__heading__upper__name__title-icon">
                {titleIconRight}
              </span>
            )}
          </p>
          <div>{infoGrouping}</div>
          {isContentInline && headerContent}
          {button}
        </div>
        {!isContentInline && headerContent}
      </div>
      <div className="preview-card__content">
        <div className="preview-card__content__wrap">{children}</div>
      </div>
    </section>
  );
};

export default PreviewCard;
