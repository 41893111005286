import React from 'react';

const EmailIcon: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="20"
      width="20"
      viewBox="0 0 20 20"
    >
      <path d="M3.562 16.167q-.729 0-1.229-.5-.5-.5-.5-1.229V5.562q0-.729.5-1.229.5-.5 1.229-.5h12.876q.729 0 1.229.5.5.5.5 1.229v8.876q0 .729-.5 1.229-.5.5-1.229.5ZM10 11.208 3.562 7.479v6.959h12.876V7.479Zm0-1.916 6.438-3.73H3.562ZM3.562 7.479V5.562v8.876Z" />
    </svg>
  );
};

export default EmailIcon;
