import { keepPreviousData, useInfiniteQuery } from '@tanstack/react-query';
import { DEFAULT_LIMIT, DEFAULT_PAGE } from 'constant';
import { useMemo } from 'react';

type InfiniteScrollParams = {
  API_ENDPOINT: string;
  size?: number;
  enabled?: boolean;
  queryParams?: Record<string, string | number>;
};

export default function <T>({
  API_ENDPOINT,
  size = DEFAULT_LIMIT,
  enabled,
  queryParams,
}: InfiniteScrollParams) {
  const { data, isLoading, fetchNextPage, hasNextPage, ...rest } =
    useInfiniteQuery<{
      items: T[];
      totalPages: number;
      totalItems: number;
      currentPage: number;
    }>({
      placeholderData: keepPreviousData,
      queryKey: [API_ENDPOINT, { size, ...queryParams }],
      initialPageParam: DEFAULT_PAGE,
      getNextPageParam: (lastPage, allPages) => {
        const hasNoItems = lastPage.items.length === 0;
        const isFinalPageReached = allPages.length === lastPage.totalPages;

        const hasNextPage = !(hasNoItems || isFinalPageReached);

        const nextPage = hasNextPage ? allPages.length + 1 : undefined;

        return nextPage;
      },
      enabled: enabled,
    });

  const items = useMemo(() => {
    return data?.pages
      ? data.pages.map((page) => page.items).flatMap((curr) => curr)
      : [];
  }, [data]);

  return {
    data,
    isLoading,
    fetchNextPage,
    hasMore: hasNextPage,
    items,
    dataLength: items.length,
    ...rest,
  };
}
