import classNames from 'classnames';
import { DateTime } from 'luxon';
import React, { FocusEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Button from 'components/Button';
import ChevronIcon from 'icons/Chevron.icon';
import DoubleChevronIcon from 'icons/DoubleChevron.icon';
import CalendarPage from './CalendarPage.component';

import './Calendar.scss';

export type CalendarProps = {
  className?: string;
  onClick?: (date: DateTime) => void;
  calendarSize?: 'small' | 'medium' | 'large';
  value?: DateTime;
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void;
  minDate?: DateTime;
  maxDate?: DateTime;
};

const Calendar: React.FC<CalendarProps> = (props) => {
  const {
    className,
    onClick,
    value,
    onBlur,
    minDate,
    maxDate,
    calendarSize = 'small',
  } = props;

  const { t } = useTranslation();

  const [date, setDate] = useState(value.isValid ? value : DateTime.now());
  const [pickedDate, setPickedDate] = useState(
    value.isValid ? value : DateTime.now(),
  );

  const classes = classNames(
    'calendar',
    `calendar--${calendarSize}`,
    className,
  );

  const nextMonthHandle = () => {
    setDate(date.plus({ months: 1 }));
  };

  const previousMonthHandle = () => {
    setDate(date.minus({ months: 1 }));
  };

  const nextYearHandle = () => {
    setDate(date.plus({ year: 1 }));
  };

  const previousYearHandle = () => {
    setDate(date.minus({ year: 1 }));
  };

  const onDatePickHandle = (date: DateTime) => {
    setPickedDate(date);
  };

  const onResetClick = () => {
    setDate(DateTime.now());
    setPickedDate(DateTime.now());
    onClick?.(DateTime.now());
  };

  return (
    <div className={classes} onBlur={onBlur}>
      <header className="calendar__header">
        <Button
          onClick={previousYearHandle}
          styleType="transparent"
          type="button"
          icon={<DoubleChevronIcon />}
          size="icon"
        />
        <Button
          onClick={previousMonthHandle}
          styleType="transparent"
          type="button"
          icon={<ChevronIcon />}
          size="icon"
        />
        <span>
          {`${t(`Month.${date.toFormat('MMMM').toLowerCase()}`)} ${date.year}`}
        </span>
        <Button
          onClick={nextMonthHandle}
          styleType="transparent"
          type="button"
          icon={<ChevronIcon right />}
          size="icon"
        />
        <Button
          onClick={nextYearHandle}
          styleType="transparent"
          type="button"
          icon={<DoubleChevronIcon right />}
          size="icon"
        />
      </header>
      <main className="calendar__body">
        <CalendarPage
          pageDate={date}
          pickedDate={pickedDate}
          onClick={onDatePickHandle}
          minDate={minDate}
          maxDate={maxDate}
        />
      </main>
      <footer className="calendar__footer">
        <Button onClick={onResetClick} styleType="transparent" type="button">
          {t('Buttons.today')}
        </Button>
        <Button
          onClick={() => onClick?.(pickedDate)}
          styleType="transparent"
          type="button"
        >
          {t('Buttons.apply')}
        </Button>
      </footer>
    </div>
  );
};

export default Calendar;
