import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import CreditorsTable, {
  CreditorTableHeader,
} from 'components/CreditorsTable/CreditorsTable.component';
import PaginationBar, {
  PaginationBarProps,
} from 'components/PaginationBar/PaginationBar.component';
import SkeletonLoader from 'components/SkeletonLoader';
import { Creditor } from 'models/User';

import './PaginatedCreditorsTable.styles.responsive.scss';
import './PaginatedCreditorsTable.styles.scss';

export type PaginatedCreditorsTableProps = {
  className?: string;
  creditorsList: Creditor[];
  paginationConfig: PaginationBarProps;
  isLoading?: boolean;
  loaderLength?: number;
  emptyMessage?: string;
};

const headers = (
  t: (message: string, options?: { count: number }) => string,
): Array<CreditorTableHeader> => [
  { heading: t('Fields.name', { count: 1 }), value: 'name' },
  { heading: t('Fields.address'), value: 'address' },
  { heading: t('Fields.bankAccount'), value: 'bankAccount' },
  { heading: t('Fields.numberOfPaymentSlips'), value: 'numberOfPaymentSlips' },
  { heading: '', value: 'button' },
];

const PaginatedCreditorsTable: React.FC<PaginatedCreditorsTableProps> = (
  props,
) => {
  const {
    className,
    creditorsList,
    paginationConfig,
    isLoading,
    emptyMessage,
    loaderLength = 9,
  } = props;

  const classes = classNames('paginated-creditors-table', className);

  const { t } = useTranslation();

  return (
    <div className={classes}>
      {!!creditorsList.length && !isLoading && (
        <CreditorsTable headers={headers(t)} creditorsList={creditorsList} />
      )}

      {isLoading && (
        <>
          <SkeletonLoader className="paginated-creditors-table__loading__header" />
          {Array.from({ length: loaderLength }).map((_, i) => (
            <SkeletonLoader
              className="paginated-creditors-table__loading__row"
              key={i}
            />
          ))}
        </>
      )}

      {!isLoading && creditorsList.length === 0 && (
        <p className="paginated-creditors-table__no-data">
          {emptyMessage ?? t('Messages.noCreditorsData')}
        </p>
      )}

      <PaginationBar
        className="paginated-creditors-table__pagination-bar"
        {...paginationConfig}
      />
    </div>
  );
};

export default PaginatedCreditorsTable;
