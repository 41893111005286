import classNames from 'classnames';
import React, { PropsWithChildren, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import Button from 'components/Button';
import Dropdown from 'components/Dropdown';
import LoadingButton from 'components/LoadingButton';
import useDisclosure from 'hooks/useDisclosure';
import CrossThinIcon from 'icons/CrossThin.icon';
import VectorArrowIcon from 'icons/VectorArrow.icon';
import { PaymentSelectingMode } from 'models/Payment';
import utils from 'utils';

type DropdownButtonConfig = {
  onToggle: () => void;
  icon?: ReactNode;
  label: string;
};
export type ProjectSelectedPaymentsActionsButtonProps = {
  disabledAction?: boolean;
  disabled?: boolean;
  selectingMode: PaymentSelectingMode;
  onToggle: (mode: PaymentSelectingMode) => void;
  buttonsConfig: DropdownButtonConfig[];
  reset: () => void;
  isPending: boolean;
  onClickActions: OnClickActions;
} & PropsWithChildren;

export type OnClickActions = {
  [k in PaymentSelectingMode]?: () => void;
};

const ProjectSelectedPaymentsActionsButton: React.FC<
  ProjectSelectedPaymentsActionsButtonProps
> = (props) => {
  const {
    selectingMode,
    onToggle,
    reset,
    isPending,
    onClickActions,
    buttonsConfig,
    disabled = false,
    disabledAction = false,
  } = props;

  const { t } = useTranslation();
  const { isOpen, toggle, close } = useDisclosure();

  const confirmationButtonClasses = classNames(
    'project__header__browser__buttons__toggle--on--processing',
  );

  return selectingMode === PaymentSelectingMode.None ? (
    <Dropdown
      isOpen={isOpen}
      toggleOpenDropdown={toggle}
      trigger={
        <Button
          className="project__header__browser__buttons__toggle"
          styleType="solid-white"
          icon={<VectorArrowIcon />}
          iconPosition="right"
          disabled={disabled}
        >
          {t('Buttons.selectFor')}
        </Button>
      }
    >
      <ul className="project__header__browser__buttons__toggle__list">
        {buttonsConfig.map((button) => (
          <li key={button.label}>
            <Button
              icon={button.icon}
              onClick={button.onToggle}
              styleType="dropdown"
            >
              {button.label}
            </Button>
          </li>
        ))}
      </ul>
    </Dropdown>
  ) : (
    <div className="project__header__browser__buttons__toggle--on">
      <LoadingButton
        disabled={disabledAction}
        onClick={() => {
          onClickActions[selectingMode]();
          close();
        }}
        isLoading={isPending}
        styleType={`confirmation-${
          selectingMode === PaymentSelectingMode.Processing
            ? 'green'
            : selectingMode === PaymentSelectingMode.Error
            ? 'red'
            : 'blue'
        }`}
        className={confirmationButtonClasses}
      >
        {t(`Buttons.submit${utils.capitalizeFirstLetter(selectingMode)}`)}
      </LoadingButton>
      <Button
        icon={<CrossThinIcon />}
        styleType="destructive"
        className="project__header__browser__buttons__toggle--on__cancel"
        onClick={() => {
          reset();
          onToggle(PaymentSelectingMode.None);
        }}
      />
    </div>
  );
};

export default ProjectSelectedPaymentsActionsButton;
