import { useQuery } from '@tanstack/react-query';
import classNames from 'classnames';
import React, { useCallback } from 'react';

import BackLink from 'components/BackLink';
import AccountInformationCard from 'components/ProfileCards/AccountInformationCard';
import PersonalInformationCard from 'components/ProfileCards/PersonalInformationCard';
import SecurityInformationCard from 'components/ProfileCards/SecurityInformationCard';
import SkeletonLoader from 'components/SkeletonLoader';
import { API_ENDPOINTS } from 'config/endpoints';
import { Account, Bookkeeper, User } from 'models/User';
import authService from 'services/authService';
import credentialsService from 'services/credentialsService';

import PhoneInformationCard from 'components/ProfileCards/PhoneInformationCard';
import './Profile.styles.responsive.scss';
import './Profile.styles.scss';

type ProfileProps = {
  className?: string;
};

const Profile: React.FC<ProfileProps> = (props) => {
  const { className } = props;

  const classes = classNames('profile', className);

  const { user } = credentialsService;
  const isBookkeeper = authService.checkRolesForUser(user, ['book_keeper']);

  const {
    data: bookKeeperData,
    isFetching: isFetchingBookkeeper,
    refetch: refetchBookkeeper,
  } = useQuery<Bookkeeper>({
    queryKey: [`${API_ENDPOINTS.BOOKKEEPERS}/${user._id}`],
    enabled: isBookkeeper,
  });

  const {
    data: memberData,
    isFetching: isFetchingMember,
    refetch: refetchClient,
  } = useQuery<Account>({
    queryKey: [`${API_ENDPOINTS.CLIENT_MEMBERS}/${user._id}`],
    enabled: !isBookkeeper,
  });

  const isFetching = isFetchingBookkeeper || isFetchingMember;

  const onRefetch = useCallback(async () => {
    const refetch = isBookkeeper ? refetchBookkeeper : refetchClient;

    try {
      const { data } = await refetch();

      if (!data || !data.email) throw new Error();

      credentialsService.removeUser();
      credentialsService.saveUser(data as User);
    } catch {
      console.error('Error');
    }
  }, [refetchBookkeeper, refetchClient, isBookkeeper]);

  if (isFetching) {
    return (
      <div className={classes}>
        <BackLink />
        <SkeletonLoader className="profile__personal-information-loader" />
        <SkeletonLoader className="profile__account-information-loader" />
        <SkeletonLoader className="profile__security-information-loader" />
      </div>
    );
  }

  return (
    <div className={classes}>
      <BackLink />
      {(bookKeeperData || memberData) && (
        <>
          <PersonalInformationCard
            initialData={bookKeeperData ?? memberData}
            onSuccess={onRefetch}
          />
          {isBookkeeper ? (
            <AccountInformationCard
              initialData={{
                ...bookKeeperData,
                address: bookKeeperData.address.name,
                zipCode: bookKeeperData.address.zipCode,
              }}
              onSuccess={onRefetch}
            />
          ) : (
            <PhoneInformationCard
              initialValues={{
                phone: memberData.phone,
              }}
              onSuccess={onRefetch}
            />
          )}
          <SecurityInformationCard onSuccess={onRefetch} />
        </>
      )}
    </div>
  );
};

export default Profile;
