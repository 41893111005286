import { useQuery, useQueryClient } from '@tanstack/react-query';
import classNames from 'classnames';
import { DateTime } from 'luxon';
import React, { useEffect, useMemo, useState } from 'react';
import { Form } from 'react-final-form';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

import AddressForm from 'components/AddressForm';
import AutoSuggestField from 'components/AutoSuggestField';
import Button from 'components/Button';
import CurrencyInputField from 'components/CurrencyInputField';
import CreditorForm from 'components/Forms/CreditorForm';
import InputField from 'components/InputField';
import LoadingButton from 'components/LoadingButton';
import Modal from 'components/Modal';
import { SelectOptionType } from 'components/Select/Select.component';
import SelectField from 'components/SelectField';
import { API_ENDPOINTS } from 'config/endpoints';
import useDisclosure from 'hooks/useDisclosure';
import useTanstackMutation from 'hooks/useTanstackMutation';
import ArrowIcon from 'icons/Arrow.icon';
import EditIcon from 'icons/Edit.icon';
import VectorArrowIcon from 'icons/VectorArrow.icon';
import { Payment, paymentCodes, PurposeSuggestion } from 'models/Payment';
import { Project, ProjectWithClient } from 'models/Project';
import { PaginationResponse } from 'models/Response';
import { Client, Creditor } from 'models/User';
import { shouldDisableFormSubmit } from 'utils';
import {
  composeValidators,
  validateModel,
  validateReferenceNumber,
  validateRequired,
  validateRequiredObject,
  validateRequiredSelect,
} from 'validations/validations';
import SuccessModal from './SucessModal.component';
import { constructInitialValues, constructRequestBody } from './utils';

import ProcessDateField from 'components/ProcessDateField';
import './PaymentForm.styles.responsive.scss';
import './PaymentForm.styles.scss';

type PaymentFormProps = {
  className?: string;
  onSuccess?: () => void;
  onError?: () => void;
  initialValues?: Payment;
  initialProject?: ProjectWithClient;
};

type PaymentCodeOptions = {
  label: string;
  value: string;
  message: string;
};

export type FormInputs = {
  project: SelectOptionType;
  payer: Partial<Client>;
  payee: Pick<Creditor, '_id' | 'address' | 'bankAccount' | 'name'>;
  purpose: string;
  paymentCode: PaymentCodeOptions;
  processmentDate: DateTime;
  instant: boolean;
  currency: SelectOptionType;
  amount: number;
  payeesAccount: string;
  payerAccount: string;
  model: string;
  referenceNumber: string;
};

const PaymentForm: React.FC<PaymentFormProps> = (props) => {
  const { className, onSuccess, onError, initialValues, initialProject } =
    props;

  const classes = classNames('payment-form', className);

  const { t } = useTranslation();

  const queryClient = useQueryClient();

  const [clientId, setClientId] = useState(
    initialValues?.clientId || initialProject?.clientId || '',
  );

  const { data: projects } = useQuery<PaginationResponse<Project>>({
    queryKey: [API_ENDPOINTS.PROJECTS, { clientId }],
    enabled: !!clientId && !initialProject,
  });

  const { mutateAsync, isPending, error } = useTanstackMutation();

  const { isOpen, open, close } = useDisclosure();
  const {
    isOpen: isOpenCreditor,
    open: openCreditor,
    close: closeCreditor,
  } = useDisclosure();

  const {
    isOpen: isOpenSuccessfully,
    open: openSuccess,
    close: closeSuccess,
  } = useDisclosure();

  useEffect(() => {
    if (initialProject) setClientId(initialProject?.clientId);
  }, [initialProject]);

  const onSubmit = async (values: FormInputs) => {
    if (!values.instant && !values.processmentDate)
      return { processmentDate: 'Form.required' };

    const createOrUpdatePayment = async () => {
      const path =
        API_ENDPOINTS.PAYMENT_SLIPS +
        (initialValues?._id ? `/${initialValues._id}` : '');

      const method = initialValues ? 'PATCH' : 'POST';
      const body = constructRequestBody(values);

      try {
        const data = (await mutateAsync({ body, method, path })) as Payment;

        queryClient.invalidateQueries({
          queryKey: [API_ENDPOINTS.PAYMENT_SLIPS],
        });
        queryClient.invalidateQueries({
          queryKey: [API_ENDPOINTS.PROJECTS, { clientId: data.clientId }],
        });
        queryClient.invalidateQueries({
          queryKey: [`${API_ENDPOINTS.PROJECTS}/${data.projectId}`],
        });

        const notificationType = initialValues ? 'Updated' : 'Created';
        toast.success(t(`Messages.paymentSlip${notificationType}Successfully`));

        if (!initialValues) openSuccess();

        return true;
      } catch {
        const notificationType = initialValues ? 'Updating' : 'Creating';
        toast.error(t(`Messages.error${notificationType}PaymentSlip`));
        onError?.();

        return false;
      }
    };

    const success = await createOrUpdatePayment();

    success && onSuccess?.();

    if (error) return error;
  };

  const constructedInitialValues = useMemo(
    () => (initialValues ? constructInitialValues(initialValues) : undefined),
    [initialValues],
  );

  return (
    <Form<FormInputs>
      onSubmit={onSubmit}
      initialValues={
        constructedInitialValues
          ? constructedInitialValues
          : initialProject
          ? {
              project: {
                label: initialProject.name,
                value: initialProject._id,
              },
              payer: initialProject.clients[0],
              payerAccount: initialProject.clients[0].bankAccount,
              processmentDate: DateTime.now(),
              instant: false,
            }
          : undefined
      }
      keepDirtyOnReinitialize
      render={({ handleSubmit, ...formState }) => {
        const { form, values } = formState;

        const resetFormValues = () => {
          form.setConfig('keepDirtyOnReinitialize', false);
          form.reset();
          form.restart();
          form.setConfig('keepDirtyOnReinitialize', true);
          closeSuccess();
        };

        const handleClientSelect = (data: Client) => {
          setClientId(`${data?._id ?? ''}`);
          form.change('project', undefined);
          form.change('payee', undefined);
          form.change('payeesAccount', '');
          form.change('payerAccount', data.bankAccount);
          form.resetFieldState('project');
          form.resetFieldState('payee');
          form.resetFieldState('payeesAccount');
        };

        return (
          <form className={classes} onSubmit={handleSubmit}>
            <div className="payment-form__content">
              <div className="payment-form__content__side">
                <SelectField
                  name="project"
                  variant="line"
                  options={
                    projects?.items.map((project) => ({
                      label: `${project.name}`,
                      value: project._id,
                    })) || []
                  }
                  validate={validateRequiredSelect(t('Form.required'))}
                  label={t('Fields.project')}
                  icon={<VectorArrowIcon />}
                  disabled={!clientId || !!initialProject || !!initialValues}
                />
                <AutoSuggestField<Client>
                  name="payer"
                  className="payment-form__auto-suggest-select"
                  title={t('Fields.payer')}
                  searchAttribute="name"
                  offlineFilter={false}
                  disabled={!!initialProject || !!initialValues}
                  queryKey={[API_ENDPOINTS.CLIENTS]}
                  validate={validateRequiredObject('Form.required')}
                  onChange={handleClientSelect}
                  variant="squared"
                  renderItem={(data) => (
                    <>
                      {data?.companyName ??
                        `${data?.firstName} ${data?.lastName}`}
                    </>
                  )}
                  renderSelectedItem={(data) => (
                    <div className="payment-form__auto-suggest-select__showcase">
                      <p>
                        {data?.companyName ??
                          `${data?.firstName} ${data?.lastName}`}
                      </p>
                      <p>
                        {data?.address.name} {data?.address.zipCode}
                      </p>
                    </div>
                  )}
                />
                <AutoSuggestField<PurposeSuggestion>
                  disableDeleteButton
                  disableLoadingButton
                  disableArrowIcon
                  inputOrSelectMode="input"
                  disableQuery={!clientId}
                  renderEmptyStateComponent={null}
                  minFetchTrigger={3}
                  name="purpose"
                  searchAttribute="purpose"
                  title={t('Fields.purpose')}
                  variant="squared"
                  maxLength={40}
                  autoComplete="off"
                  className="payment-form__content__side__purpose"
                  queryKey={[
                    API_ENDPOINTS.GET_PAYMENT_PURPOSE_SUGGESTIONS(clientId),
                  ]}
                  validate={composeValidators(
                    validateRequired(t('Form.required')),
                  )}
                />
                <div className="payment-form__creditor">
                  {isOpen && values.payee && (
                    <Modal closeModal={close} disableCloseButton>
                      <AddressForm<Creditor>
                        path={`${API_ENDPOINTS.CREDITORS}/${values.payee._id}`}
                        initialValues={values.payee.address}
                        onSuccess={(data) => {
                          form.change('payee', data);
                          form.change('payeesAccount', data.bankAccount);
                          close();
                        }}
                      />
                    </Modal>
                  )}
                  {values.payee && (
                    <Button
                      className="payment-form__creditor__change-address"
                      styleType="link"
                      icon={<EditIcon />}
                      type="button"
                      onClick={open}
                      size="icon"
                    >
                      {t('Buttons.changeAddress')}
                    </Button>
                  )}
                  {!!clientId && !values.payee && (
                    <Button
                      className="payment-form__creditor__change-address"
                      styleType="link"
                      icon={<EditIcon />}
                      type="button"
                      onClick={openCreditor}
                      size="icon"
                    >
                      {t('Buttons.addNewCreditor')}
                    </Button>
                  )}
                  <AutoSuggestField<Creditor>
                    name="payee"
                    title={t('Fields.payee')}
                    queryKey={[
                      API_ENDPOINTS.GET_CREDITORS_FOR_CLIENT(clientId),
                    ]}
                    validate={validateRequiredObject(t('Form.required'))}
                    searchAttribute="name"
                    onChange={(data) => {
                      form.change('payeesAccount', data?.bankAccount || '');
                    }}
                    placeholder={t('Index.selectACreditor')}
                    disabled={!values.payer}
                    className="payment-form__auto-suggest-select"
                    variant="squared"
                    renderSelectedItem={(data) => (
                      <div className="payment-form__auto-suggest-select__showcase">
                        <p>{data?.name}</p>
                        <p>
                          {data?.address.name} {data?.address.zipCode}
                        </p>
                      </div>
                    )}
                  />
                </div>
              </div>
              <div className="payment-form__content__side payment-form__content__side--right">
                <ProcessDateField
                  isChecked={values?.instant}
                  onDateChange={(value) =>
                    form.change('processmentDate', value as DateTime)
                  }
                  initialValues={
                    initialValues
                      ? {
                          instant: constructedInitialValues.instant,
                          processmentDate:
                            constructedInitialValues.processmentDate,
                        }
                      : undefined
                  }
                />
                <div className="payment-form__content__side__row">
                  <div className="payment-form__content__side__row">
                    <AutoSuggestField<PaymentCodeOptions>
                      disableDeleteButton
                      inputOrSelectMode="select"
                      name="paymentCode"
                      searchAttribute="value"
                      className="payment-form__content__side__row__input--payment-code"
                      options={Object.entries(paymentCodes).map(
                        ([refNum, message]) => ({
                          label: refNum,
                          value: refNum,
                          message: message,
                        }),
                      )}
                      onChange={(data) => {
                        if (!values.purpose)
                          form.change('purpose', data?.message);
                      }}
                      validate={composeValidators(
                        validateRequired(t('Form.required')),
                      )}
                      title={t('Fields.paymentCode')}
                      variant="squared"
                      autoComplete="off"
                    />
                    <SelectField
                      preselectFirst
                      name="currency"
                      options={[{ label: 'RSD', value: 'RSD' }]}
                      label={t('Fields.currency')}
                      variant="squared"
                      icon={<VectorArrowIcon />}
                      validate={validateRequiredSelect(t('Form.required'))}
                      className="payment-form__content__side__row__select"
                    />
                  </div>
                  <div className="payment-form__content__side__row--amount">
                    <CurrencyInputField
                      name="amount"
                      value={`${values.amount}`}
                      title={t('Fields.amount')}
                      validate={validateRequired(t('Form.required'))}
                      autoComplete="off"
                      className="is-input--squared"
                    />
                  </div>
                </div>
                <div className="payment-form__content__side__row">
                  <InputField
                    name="payeesAccount"
                    title={t('Fields.bankAccount')}
                    variant="squared"
                    validate={composeValidators(
                      validateRequired(t('Form.required')),
                    )}
                    disabled
                  />
                </div>
                <div className="payment-form__content__side__row">
                  <InputField
                    className="payment-form__content__side__row__field--model"
                    name="model"
                    title={t('Fields.model')}
                    variant="squared"
                    tabIndex={isOpen ? -1 : 0}
                    validate={validateModel}
                  />
                  <InputField
                    key={values.model}
                    name="referenceNumber"
                    title={t('Fields.referenceNumber')}
                    variant="squared"
                    validate={validateReferenceNumber(values.model)}
                    autoComplete="off"
                  />
                </div>
              </div>
            </div>
            <div className="payment-form__footer">
              <LoadingButton
                isLoading={isPending}
                type="submit"
                styleType="solid-gray"
                icon={<ArrowIcon />}
                iconPosition="right"
                className="payment-form__submit"
                disabled={
                  initialValues &&
                  shouldDisableFormSubmit(formState) &&
                  (!!formState.error ||
                    values.processmentDate.toFormat('yyyy MM dd') ===
                      initialValues.processmentDate.toFormat('yyyy MM dd'))
                }
              >
                {t('Buttons.submit')}
              </LoadingButton>
            </div>
            {isOpenSuccessfully && (
              <SuccessModal
                form={form}
                values={values}
                closeModal={closeSuccess}
                resetFormValues={resetFormValues}
              />
            )}
            {isOpenCreditor && (
              <Modal closeModal={closeCreditor}>
                <CreditorForm
                  clientId={clientId}
                  onSuccess={(data) => {
                    form.change('payee', {
                      _id: data._id,
                      name: data.name,
                      address: { ...data.address },
                      bankAccount: data.bankAccount,
                    });
                    form.change('payeesAccount', data.bankAccount);
                    closeCreditor();
                  }}
                />
              </Modal>
            )}
          </form>
        );
      }}
    />
  );
};

export default PaymentForm;
