import React from 'react';

const SuccessCheckedIcon: React.FC<{ className?: string }> = ({
  className,
}) => {
  return (
    <svg
      width="84"
      height="75"
      viewBox="0 0 84 75"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M29.2564 75L21.8974 63.4228L7.44872 60.5705L9.15385 47.4832L0 37.5L9.15385 27.6007L7.44872 14.4295L21.8974 11.6611L29.2564 0L42 5.45302L54.8333 0L62.1923 11.6611L76.5513 14.4295L74.8462 27.6007L84 37.5L74.8462 47.4832L76.5513 60.5705L62.1923 63.4228L54.8333 75L42 69.547L29.2564 75ZM32.7564 65.1007L42 61.4094L51.5128 65.1007L57.3462 57.047L67.3974 54.6141L66.4103 44.8826L73.3205 37.5L66.4103 29.9497L67.3974 20.2181L57.3462 17.953L51.3333 9.89933L42 13.5067L32.4872 9.89933L26.6538 17.953L16.6923 20.2181L17.5897 29.9497L10.6795 37.5L17.5897 44.8826L16.6923 54.7819L26.6538 57.047L32.7564 65.1007ZM38.141 49.1611L58.9615 29.7819L54.3846 25.8389L38.141 40.9396L29.7949 32.5503L24.9487 36.9128L38.141 49.1611Z"
        fill="#076215"
      />
    </svg>
  );
};

export default SuccessCheckedIcon;
