import React from 'react';
import { useNavigate } from 'react-router-dom';

import Button from 'components/Button';
import Modal from 'components/Modal';
import { APP_ROUTES } from 'config/routes';
import { FormApi } from 'final-form';
import SuccessCheckedIcon from 'icons/SuccessChecked.icon';
import { useTranslation } from 'react-i18next';
import { FormInputs } from './PaymentForm.component';

type SuccessModalProps = {
  closeModal: () => void;
  values: FormInputs;
  form: FormApi<FormInputs, Partial<FormInputs>>;
  resetFormValues: () => void;
};

const SuccessModal: React.FC<SuccessModalProps> = (props) => {
  const { closeModal, values, form, resetFormValues } = props;

  const navigate = useNavigate();

  const { t } = useTranslation();

  const handleClose = () => {
    const url = `${APP_ROUTES.PROJECTS}/${values.project.value}`;
    navigate(url, { replace: true });
  };

  return (
    <Modal
      disableCloseButton
      className="success-modal"
      closeModal={closeModal}
      onClose={handleClose}
    >
      <div className="success-modal__body">
        <p className="success-modal__body__text">{t('Form.paymentOrder')}</p>
        <p className="success-modal__body__text">{t('Form.saved')}</p>
        <SuccessCheckedIcon className="success-modal__body__icon" />
        <div className="success-modal__body__footer">
          <div className="success-modal__body__footer__new-payment-buttons">
            <Button
              styleType="solid-bright"
              className="success-modal__body__footer__new-payment-buttons__button"
              onClick={resetFormValues}
            >
              {t('Buttons.createNewOrderFromStart')}
            </Button>
            <Button
              styleType="solid-bright"
              className="success-modal__body__footer__new-payment-buttons__button"
              onClick={() => {
                form.change('payee', undefined);
                form.change('payeesAccount', undefined);
                form.resetFieldState('payee');
                form.resetFieldState('payeesAccount');
                closeModal();
              }}
            >
              {t('Buttons.copyExistingOrder')}
            </Button>
          </div>
          <div className="success-modal__body__footer__close">
            <Button
              styleType="solid-gray"
              onClick={handleClose}
              className="success-modal__body__footer__close__button"
            >
              {t('Buttons.goBackToProject')}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default SuccessModal;
