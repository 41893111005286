import classNames from 'classnames';
import { DateTime } from 'luxon';
import React from 'react';

import Button from 'components/Button';

import { useTranslation } from 'react-i18next';
import './CalendarPage.scss';

export type CalendarPageProps = {
  pageDate?: DateTime;
  pickedDate?: DateTime;
  onClick?: (date: DateTime) => void;
  minDate?: DateTime;
  maxDate?: DateTime;
};

const CalendarPage: React.FC<CalendarPageProps> = (props) => {
  const { pageDate, pickedDate, onClick, minDate, maxDate } = props;

  const { t } = useTranslation();

  const currentMonthDates = Array.from(
    { length: pageDate?.daysInMonth },
    (_, i) => pageDate?.set({ day: i + 1 }),
  );

  const lastMonthDates = Array.from(
    { length: pageDate?.startOf('month').weekday - 1 },
    (_, i) => pageDate?.startOf('month').minus({ days: i + 1 }),
  ).reverse();

  let mappedDates = [...lastMonthDates, ...currentMonthDates];

  const nextMonthDates = Array.from(
    {
      length: (mappedDates.length > 35 ? 42 : 35) - mappedDates.length,
    },
    (_, i) => pageDate?.startOf('month').plus({ months: 1 }).plus({ days: i }),
  );

  mappedDates = [...mappedDates, ...nextMonthDates];

  const mappedDaysJSX = mappedDates.map((date, index) => {
    const dateIso = date.toISODate();
    const minDateIso = minDate?.toISODate();
    const maxDateIso = maxDate?.toISODate();

    const isDisabled =
      (minDate && minDateIso > dateIso) || (maxDate && maxDateIso < dateIso);

    return (
      <Button
        onClick={() => {
          onClick(date);
        }}
        size="icon"
        styleType="transparent"
        key={index}
        className={classNames(
          'calendar-page__button',
          pickedDate?.startOf('day').equals(date.startOf('day'))
            ? 'picked'
            : '',
        )}
        disabled={isDisabled}
        type="button"
      >
        {date.day}
      </Button>
    );
  });

  return (
    <div className="calendar-page">
      <header className="calendar-page__header">
        <span>{t('Calendar.Mon')}</span>
        <span>{t('Calendar.Tue')}</span>
        <span>{t('Calendar.Wed')}</span>
        <span>{t('Calendar.Thu')}</span>
        <span>{t('Calendar.Fri')}</span>
        <span>{t('Calendar.Sat')}</span>
        <span>{t('Calendar.Sun')}</span>
      </header>
      <main className="calendar-page__body">{mappedDaysJSX}</main>
    </div>
  );
};

export default CalendarPage;
