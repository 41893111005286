import {
  keepPreviousData as keep,
  QueryKey,
  useQuery,
} from '@tanstack/react-query';
import classNames from 'classnames';
import React, { useEffect, useMemo } from 'react';

import Input from 'components/Input';
import LoadingSpinner from 'components/LoadingSpinner';
import PreviewCard, {
  PreviewCardProps,
} from 'components/PreviewCard/PreviewCard.component';
import useDebounce from 'hooks/useDebounce';
import SearchIcon from 'icons/Search.icon';
import { PaginationResponse } from 'models/Response';
import { useTranslation } from 'react-i18next';

import './PreviewCardWithSearch.styles.responsive.scss';
import './PreviewCardWithSearch.styles.scss';

type PreviewCardWithSearchProps<T> = {
  className?: string;
  render: (data?: T[]) => React.ReactNode;
  queryKey: QueryKey;
  searchKey: keyof T | string;
  onFetch?: (data?: T[]) => void;
  filterInstantly?: boolean;
  keepPreviousData?: boolean;
  enabled?: boolean;
  EmptyStateComponent?: React.ReactNode;
  NotEnabledComponent?: React.ReactNode;
} & Omit<PreviewCardProps, 'headerContent'>;

const PreviewCardWithSearch = <T,>(props: PreviewCardWithSearchProps<T>) => {
  const {
    className,
    render,
    queryKey,
    searchKey,
    onFetch,
    EmptyStateComponent,
    NotEnabledComponent,
    enabled = true,
    filterInstantly = true,
    keepPreviousData = true,
    ...rest
  } = props;

  const classes = classNames('preview-card-with-search', className);

  const [search, setSearch] = React.useState('');
  const debouncedValue = useDebounce(search, 200);

  const { t } = useTranslation();

  const queryKeyFinal = useMemo(
    () => [
      queryKey[0],
      {
        ...(queryKey[1] as Record<string, any>),
        ...(debouncedValue && debouncedValue.length >= 3
          ? { searchBy: `${searchKey as string}:${debouncedValue}` }
          : {}),
      },
    ],
    [debouncedValue, queryKey, searchKey],
  );

  const { data, isRefetching, isLoading, isStale, isFetched } = useQuery<T>({
    queryKey: queryKeyFinal,
    placeholderData: keepPreviousData && keep,
    enabled: enabled && (debouncedValue === '' || debouncedValue.length >= 3),
  });

  const filteredData = useMemo(() => {
    if (!data) return [];

    if (typeof data === typeof Array<T>)
      return filterInstantly
        ? (data as T[]).filter((item) =>
            (item[searchKey as keyof T] as string).startsWith(search),
          )
        : (data as T[]);

    return filterInstantly
      ? (data as PaginationResponse<T>).items.filter((item) =>
          (item[searchKey as keyof T] as string).startsWith(search),
        )
      : (data as PaginationResponse<T>).items;
  }, [data, search, searchKey, filterInstantly]);

  useEffect(() => {
    if (filteredData && !isStale) onFetch?.(filteredData);
  }, [onFetch, filteredData, isStale]);

  return (
    <PreviewCard
      className={classes}
      headerContent={
        <div
          className={classNames('preview-card-with-search__search', {
            'preview-card-with-search__search--inline': rest.isContentInline,
          })}
        >
          <SearchIcon className="preview-card-with-search__search__lens" />
          <Input
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            placeholder={t('Fields.search')}
            className="preview-card-with-search__search__input"
          />
          {isRefetching && (
            <LoadingSpinner className="preview-card-with-search__search__loader" />
          )}
        </div>
      }
      {...rest}
    >
      {filteredData.length > 0 ? (
        render(filteredData)
      ) : (
        <div className="preview-card-with-search__no-data">
          {isLoading && <LoadingSpinner />}
          {isFetched &&
            enabled &&
            (debouncedValue.length < 3
              ? EmptyStateComponent
              : t('Index.theresNoData'))}
          {!enabled && NotEnabledComponent}
        </div>
      )}
    </PreviewCard>
  );
};

export default PreviewCardWithSearch;
