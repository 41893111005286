import classNames from 'classnames';
import { DateTime } from 'luxon';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Button from 'components/Button';
import CreditorDetailsForm from 'components/CreditorDetailsForm';
import DataDisplayRow from 'components/DataDisplayRow';
import useDisclosure from 'hooks/useDisclosure';
import EditIcon from 'icons/Edit.icon';
import { Creditor } from 'models/User';

import './CreditorInfoCard.styles.responsive.scss';
import './CreditorInfoCard.styles.scss';

type CreditorInfoCardProps = {
  className?: string;
  creditor: Creditor;
  clientId: string;
  isFetching: boolean;
};

const CreditorInfoCard: React.FC<CreditorInfoCardProps> = (props) => {
  const { className, creditor, clientId, isFetching } = props;

  const classes = classNames('creditor-info-card', className);

  const { isOpen, open, close } = useDisclosure();
  const { t } = useTranslation();

  return (
    <div className={classes}>
      <div className="creditor-info-card__header">
        <h2 className="creditor-info-card__header__title">{creditor?.name}</h2>
        <Button
          className="creditor-info-card__header__edit"
          styleType="solid-bright"
          size="small"
          icon={<EditIcon />}
          onClick={open}
        >
          {t('Profile.edit')}
        </Button>
      </div>
      <div className="creditor-info-card__body">
        <div className="creditor-info-card__body__part">
          <DataDisplayRow
            title={t('Register.address')}
            data={creditor?.address.name}
          />
          <DataDisplayRow
            title={t('Register.bankAccount')}
            data={creditor?.bankAccount}
          />
        </div>
        <div className="creditor-info-card__body__part">
          <DataDisplayRow
            title={t('Index.lastEdited')}
            data={DateTime.fromJSDate(new Date(creditor?.updatedAt)).toFormat(
              'dd.MM.yyyy, hh:mm',
            )}
          />
          <DataDisplayRow
            title={'First payment'}
            data={creditor?.firstPaymentOn?.toString()}
          />
        </div>
      </div>
      {isOpen && (
        <CreditorDetailsForm
          clientId={clientId}
          closeModal={close}
          isLoading={isFetching}
          data={creditor}
        />
      )}
    </div>
  );
};

export default CreditorInfoCard;
