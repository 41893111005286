import classNames from 'classnames';
import React from 'react';

import Toggle, { ToggleProps } from 'components/Toggle/Toggle.component';

import './ToggleGroup.styles.responsive.scss';
import './ToggleGroup.styles.scss';

type ToggleGroupProps = {
  className?: string;
  exclusive?: boolean;
  options: Omit<ToggleProps, 'className'>[];
};

const ToggleGroup: React.FC<ToggleGroupProps> = (props) => {
  const { className, exclusive, options } = props;

  const classes = classNames('toggle-group', className);

  return (
    <div className={classes}>
      {options.map((option, index) => {
        const { onToggle } = option;

        const handleChange = (value: boolean) => {
          if (exclusive) {
            options.forEach((opt) => opt !== option && opt.onToggle(false));
          }
          onToggle(value);
        };
        return (
          <Toggle
            key={index}
            {...option}
            onToggle={handleChange}
            className="toggle-group__toggle"
          />
        );
      })}
    </div>
  );
};

export default ToggleGroup;
