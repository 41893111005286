import { useQueryClient } from '@tanstack/react-query';
import classNames from 'classnames';
import React from 'react';
import { Form } from 'react-final-form';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

import InputField from 'components/InputField';
import LoadingButton from 'components/LoadingButton';
import { API_ENDPOINTS } from 'config/endpoints';
import useTanstackMutation from 'hooks/useTanstackMutation';
import BillIcon from 'icons/Bill.icon';
import EmailIcon from 'icons/Email.icon';
import UserIcon from 'icons/User.icon';
import { Creditor } from 'models/User';
import { shouldDisableFormSubmit } from 'utils';
import {
  BankAccountRegExp,
  ZipCodeRegExp,
} from 'validations/regularExpression';
import {
  composeValidators,
  validateBankAccountChecksum,
  validateBankAccountLength,
  validateRegex,
  validateRequired,
} from 'validations/validations';

import './CreditorForm.styles.responsive.scss';
import './CreditorForm.styles.scss';

type CreditorFormInputs = {
  name: string;
  address: string;
  zipCode: string;
  bankAccount: string;
};

export type CreditorFormProps = {
  className?: string;
  initialValues?: Creditor;
  clientId: string;
  onSuccess?: (data?: Creditor) => void;
};

const CreditorForm: React.FC<CreditorFormProps> = (props) => {
  const { className, initialValues, clientId, onSuccess } = props;

  const classes = classNames('creditor-form', className);

  const { t } = useTranslation();

  const queryClient = useQueryClient();

  const { mutate, isPending } = useTanstackMutation({
    onSuccess: (data: Creditor) => {
      const notificationType = !initialValues ? 'Created' : 'Updated';

      toast.success(t(`Messages.creditor${notificationType}Successfully`));

      queryClient.invalidateQueries({
        queryKey: [API_ENDPOINTS.GET_CREDITORS_FOR_CLIENT(clientId)],
      });
      if (initialValues)
        queryClient.invalidateQueries({
          queryKey: [`${API_ENDPOINTS.CREDITORS}/${initialValues?._id}`],
        });

      if (!initialValues) {
        queryClient.invalidateQueries({
          queryKey: [API_ENDPOINTS.CURRENT_CLIENT],
        });
      }

      onSuccess?.(data);
    },
    onError: async (error) => {
      const err = await error.json();
      if (err['code']) {
        toast.error(t(`Messages.${err['code']}`));
        return;
      }
      const notificationType = !initialValues ? 'Creating' : 'Updating';

      toast.error(t(`Messages.error${notificationType}Creditor`));
    },
  });

  const handleSubmit = async (data: CreditorFormInputs) => {
    const payload = {
      ...data,
      clientId,
      address: {
        name: data.address,
        zipCode: data.zipCode,
      },
    };

    const base_path = API_ENDPOINTS.CREDITORS;
    const path = base_path + (initialValues ? `/${initialValues?._id}` : '');

    const method = !initialValues ? 'POST' : 'PATCH';

    mutate({
      path,
      method,
      body: payload,
    });
  };

  return (
    <Form<CreditorFormInputs>
      initialValues={
        initialValues
          ? {
              name: initialValues.name,
              address: initialValues.address.name,
              zipCode: initialValues.address.zipCode,
              bankAccount: initialValues.bankAccount,
            }
          : undefined
      }
      onSubmit={handleSubmit}
      render={({ handleSubmit, ...rest }) => (
        <form onSubmit={handleSubmit} className={classes}>
          <InputField
            titleicon={<UserIcon />}
            title={t('Register.creditor')}
            name="name"
            validate={validateRequired('Form.creditorRequired')}
            placeholder={t('Form.enterFirstOrLastNameOrCompanyName')}
            className="creditor-form__field"
          />
          <InputField
            titleicon={<EmailIcon />}
            title={t('Register.address')}
            name="address"
            validate={validateRequired('Form.requiredAddress')}
            className="creditor-form__field"
          />
          <InputField
            titleicon={<EmailIcon />}
            title={t('Register.zipCode')}
            name="zipCode"
            validate={composeValidators(
              validateRequired('Form.requiredZipCode'),
              validateRegex('Form.invalidZipCode', ZipCodeRegExp),
            )}
            className="creditor-form__field"
          />
          <InputField
            titleicon={<BillIcon />}
            title={t('Register.bankAccount')}
            name="bankAccount"
            validate={composeValidators(
              validateRequired('Form.requiredBankAccount'),
              validateRegex('Form.invalidBankAccount', BankAccountRegExp),
              validateBankAccountLength('Form.invalidBankAccount'),
              validateBankAccountChecksum('Form.invalidBankAccountFormat'),
            )}
            className="creditor-form__field"
          />
          <LoadingButton
            isLoading={isPending}
            disabled={!!initialValues && shouldDisableFormSubmit(rest)}
            className="creditor-form__button"
            styleType="solid-gray"
          >
            {t('Buttons.save')}
          </LoadingButton>
        </form>
      )}
    />
  );
};

export default CreditorForm;
