export const STORAGE_KEYS = {
  TOKEN: 'token',
  EXPIRING_TIME: 'expire',
  PARTICIPANT: 'participant',
  SETTINGS_POPPED: 'customer-profile-settings-popped',
  IS_SCROLLED: 'is-scrolled',
};

export const SESSION_STORAGE_KEYS = {
  PROJECT: 'project',
};

export default {
  ram: {} as Record<string, string>,
  setItem(key: string, value: Record<string, any> | string) {
    const old = JSON.parse(localStorage.getItem(key));

    try {
      if (!old) {
        localStorage.setItem(key, JSON.stringify(value));
      } else {
        localStorage.setItem(
          key,
          JSON.stringify({ ...old, ...(value as Record<string, any>) }),
        );
      }
    } catch (e) {
      console.warn(e);
      this.ram[key] = JSON.stringify(value);
    }
  },
  removeItem(key: string): void {
    try {
      localStorage.removeItem(key);
    } catch (e) {
      console.warn(e);
      this.ram[key] = undefined;
    }
  },
  getItem<T>(key: string): T {
    try {
      return JSON.parse(localStorage.getItem(key));
    } catch (e) {
      console.warn(e);
      return JSON.parse(this.ram[key]);
    }
  },
};
