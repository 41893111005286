import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import AccountPreview from 'components/AccountPreview';
import Button from 'components/Button';
import LoadingSpinner from 'components/LoadingSpinner';
import PreviewCard from 'components/PreviewCard';
import PlusIcon from 'icons/Plus.icon';
import UserIcon from 'icons/User.icon';
import { Account } from 'models/User';

import './AccountsList.styles.responsive.scss';
import './AccountsList.styles.scss';

type AccountsListProps = {
  className?: string;
  accounts: Account[];
  isLoading: boolean;
  onClick: () => void;
};

const AccountsList: React.FC<AccountsListProps> = (props) => {
  const { className, accounts, isLoading, onClick } = props;

  const classes = classNames('accounts-list', className);

  const { t } = useTranslation();

  return (
    <PreviewCard
      className={classes}
      title={t('Index.admins')}
      titleIconLeft={<UserIcon className="accounts-list__header__icon" />}
      button={
        accounts.length === 0 && !isLoading ? (
          <Button
            styleType="shadow"
            size="icon"
            icon={<PlusIcon className="accounts-list__header__button__icon" />}
            onClick={onClick}
          />
        ) : undefined
      }
    >
      {isLoading ? (
        <div className="accounts-list--loading">
          <LoadingSpinner />
        </div>
      ) : accounts.length > 0 ? (
        accounts.map((acc) => <AccountPreview key={acc.email} account={acc} />)
      ) : (
        <div className="accounts-list__no-data">
          {t('Index.thereAreNoUsersForTheSelectedClient')}
        </div>
      )}
    </PreviewCard>
  );
};

export default AccountsList;
