import { MutableRefObject, useEffect } from 'react';
import { ResultRef } from './Combobox.component';

interface IUseFocusOnKeyDown {
  inputRef: MutableRefObject<HTMLInputElement>;
  resultsRef: MutableRefObject<ResultRef[]>;
  fillData: (value: string) => void;
  handleClose?: () => void;
}

export const useMoveThroughMenuOnKeyDown = ({
  inputRef,
  resultsRef,
  fillData,
  handleClose,
}: IUseFocusOnKeyDown) => {
  useEffect(() => {
    let curr = -1;
    const handleChangeFocusOnKeyDown = (e: KeyboardEvent) => {
      const handleFocusChange = (increment: number) => {
        resultsRef.current = resultsRef.current.filter((r) => r.ref);
        if (resultsRef.current.length === 0) return;

        if (document.activeElement === inputRef.current) {
          e.preventDefault();
          if (increment > 0) {
            curr = 0;
            inputRef.current.blur();
            resultsRef.current[curr].ref.focus();
          } else {
            curr = resultsRef.current.length - 1;
            inputRef.current.blur();
            resultsRef.current[curr].ref.focus();
          }
        } else if (
          resultsRef.current
            .map((r) => r.ref)
            .includes(document.activeElement as HTMLDivElement)
        ) {
          const resultsLength = resultsRef.current.length;
          const nextToFocus = curr + increment;

          if (nextToFocus >= resultsLength) {
            curr = 0;
            resultsRef.current[curr].ref.focus();
          } else if (nextToFocus < 0) {
            curr = resultsLength - 1;
            resultsRef.current[curr].ref.focus();
          } else {
            curr = nextToFocus;
            resultsRef.current[nextToFocus].ref.focus();
          }
          e.preventDefault();
        }
      };

      const handleFillTerm = () => {
        if (
          resultsRef.current
            .map((r) => r.ref)
            .includes(document.activeElement as HTMLDivElement)
        )
          fillData(document.activeElement.firstChild.nodeValue);
      };
      switch (e.key) {
        case 'ArrowDown':
          handleFocusChange(1);
          break;
        case 'ArrowUp':
          handleFocusChange(-1);
          break;
        case 'ArrowRight':
          handleFillTerm();
          break;
        case 'Escape':
          handleClose?.();
          break;
        default:
          break;
      }
    };

    window.addEventListener('keydown', handleChangeFocusOnKeyDown);

    return () =>
      window.removeEventListener('keydown', handleChangeFocusOnKeyDown);
  }, [resultsRef, inputRef, fillData, handleClose]);
};
