import classNames from 'classnames';
import React from 'react';
import { NavLink } from 'react-router-dom';
import './CustomLink.style.scss';

type CustomLinkProps = {
  to: string;
  title?: string;
  className?: string;
  icon?: React.ReactNode;
  iconPosition?: 'left' | 'right';
};

const CustomLink: React.FC<CustomLinkProps> = (props) => {
  const { to, title, className, icon, iconPosition } = props;

  const classes = classNames('custom-link', className, {
    'custom-link__icon--row-reverse': iconPosition === 'right',
  });

  return (
    <NavLink to={to} className={classes}>
      {icon && <div className="custom-link__icon">{icon}</div>}
      <span className="custom-link__text">{title}</span>
    </NavLink>
  );
};

export default CustomLink;
