import classNames from 'classnames';
import { calculateToolTipPosition } from 'components/ToolTip/utils';
import useInvalidateUI from 'hooks/useInvalidateUI';
import React, { forwardRef, useEffect, useMemo, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import './Dropdown.style.scss';

export type DropdownProps = {
  className?: string;
  disabled?: boolean;
  trigger?: React.ReactNode;
  matchParrentSize?: boolean;
  children: React.ReactNode;
  isOpen: boolean;
  toggleOpenDropdown?: () => void;
};

export type DropdownRef = {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
};

const Dropdown: React.ForwardRefRenderFunction<DropdownRef, DropdownProps> = (
  props,
  ref,
) => {
  const {
    className,
    trigger,
    children,
    toggleOpenDropdown,
    isOpen,
    matchParrentSize = true,
    disabled = false,
  } = props;

  const contentRef = useRef<HTMLDivElement>(null);
  const headerRef = useRef<HTMLDivElement>(null);

  const [dropUp, setDropUp] = useState(false);

  const windowHeight = window.innerHeight;
  const windowWidth = window.innerWidth;

  const dropdownClasses = classNames('dropdown');
  const contentClasses = useMemo(
    () =>
      classNames(
        'dropdown__content',
        {
          'dropdown__content--up': dropUp,
          'dropdown__content--open': isOpen,
        },
        className,
      ),
    [dropUp, isOpen, className],
  );
  const triggerClasses = classNames('dropdown__trigger');

  useEffect(() => {
    const headerHeight = headerRef?.current?.getBoundingClientRect().height;
    const dropHeight = headerRef?.current?.getBoundingClientRect().top;
    const contentHeight = contentRef?.current?.getBoundingClientRect().height;

    setDropUp(windowHeight < dropHeight + headerHeight + contentHeight);
  }, [windowHeight, windowWidth]);

  useEffect(() => {
    if (!isOpen) return;

    const handleToggleDropdown = (e: MouseEvent) => {
      const isAChildOfTrigger = headerRef.current.contains(
        e.target as HTMLElement,
      );
      const isAChildOfContent = contentRef.current.contains(
        e.target as HTMLElement,
      );

      if (
        e.target !== headerRef.current &&
        !isAChildOfTrigger &&
        !isAChildOfContent
      )
        toggleOpenDropdown();
    };

    document.addEventListener('click', handleToggleDropdown);

    return () => {
      document.removeEventListener('click', handleToggleDropdown);
    };
  }, [isOpen, toggleOpenDropdown]);

  const position = calculateToolTipPosition(
    headerRef.current?.getBoundingClientRect(),
    contentRef.current?.getBoundingClientRect(),
    4,
    'bottom',
  );

  useInvalidateUI();

  return (
    <div className={dropdownClasses}>
      <div
        ref={headerRef}
        className={triggerClasses}
        onClick={() => {
          if (disabled) return;
          toggleOpenDropdown();
        }}
      >
        {trigger}
      </div>
      {ReactDOM.createPortal(
        <div
          className={contentClasses}
          ref={contentRef}
          style={{
            ...position,
            width: matchParrentSize
              ? headerRef?.current?.getBoundingClientRect()?.width
              : 'auto',
          }}
        >
          {children}
        </div>,
        document.getElementById('root'),
      )}
    </div>
  );
};

export default forwardRef(Dropdown);
