import classNames from 'classnames';
import React from 'react';
import { Field, Form } from 'react-final-form';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

import LoadingButton from 'components/LoadingButton';
import PasswordInputField from 'components/PasswordInput';
import { API_ENDPOINTS } from 'config/endpoints';
import { APP_ROUTES } from 'config/routes';
import { SEARCH_PARAMS } from 'config/searchParams';
import useSearchParams from 'hooks/useSearchParams';
import useTanstackMutation from 'hooks/useTanstackMutation';
import PasswordIcon from 'icons/Password.icon';
import { AuthPasswordForgot } from 'models/AuthBody';
import { useTranslation } from 'react-i18next';
import { PasswordRegExp } from 'validations/regularExpression';
import {
  composeValidators,
  validatePasswordRepeat,
  validateRegex,
  validateRequired,
} from 'validations/validations';
import RegisterStep from '../../Register/RegisterStep';

import Button from 'components/Button';
import ArrowBackIcon from 'icons/ArrowBack.icon';
import '../../Register/RegisterStep/RegisterStep.styles.scss';

type ForgotPasswordConfirmationProps = {
  className: string;
};

const ForgotPasswordConfirmation: React.FC<ForgotPasswordConfirmationProps> = (
  props,
) => {
  const { className } = props;
  const classes = classNames('is-register-form', className);

  const navigate = useNavigate();
  const { t } = useTranslation();

  const { getSearchParam } = useSearchParams();
  const token = getSearchParam(SEARCH_PARAMS.TOKEN);

  const { mutate, isPending: isLoading } = useTanstackMutation({
    onSuccess: () => {
      toast.success(t('Authentication.passwordResetSuccessfully'));
      navigate(APP_ROUTES.LOGIN);
    },
    onError: () => {
      toast.error(t('Authentication.passwordResetFailed'));
    },
  });

  const handleSubmit = ({ password }: AuthPasswordForgot) =>
    mutate({
      body: { password },
      method: 'POST',
      path: `${API_ENDPOINTS.FORGOT_PASSWORD}/${token}`,
    });

  return (
    <RegisterStep stepper={false} title={t('Authentication.passwordRecovery')}>
      <Form
        onSubmit={handleSubmit}
        render={({ handleSubmit }) => {
          return (
            <form onSubmit={handleSubmit} className={classes}>
              <div className="is-register-form__inputs">
                <Field
                  component={PasswordInputField}
                  titleicon={<PasswordIcon />}
                  title={t('Authentication.password')}
                  name="password"
                  validate={composeValidators(
                    validateRequired('Form.passwordRequired'),
                    validateRegex('Form.invalidPassword', PasswordRegExp),
                  )}
                />
                <Field
                  component={PasswordInputField}
                  titleicon={<PasswordIcon />}
                  title={t('Authentication.repeatPassword')}
                  name="passwordRepeat"
                  validate={composeValidators(
                    validateRequired('Form.passwordRequired'),
                    validatePasswordRepeat('Form.pleaseRepeatThePassword'),
                  )}
                />
              </div>
              <div className="is-register-form__buttons">
                <Button
                  styleType="transparent"
                  icon={<ArrowBackIcon />}
                  iconPosition="left"
                  onClick={() => navigate(APP_ROUTES.LOGIN)}
                  type="button"
                >
                  {t('Buttons.back')}
                </Button>
                <LoadingButton
                  styleType="solid-bright"
                  isLoading={isLoading}
                  className="is-register__submit"
                >
                  {t('Buttons.save')}
                </LoadingButton>
              </div>
            </form>
          );
        }}
      />
    </RegisterStep>
  );
};

export default ForgotPasswordConfirmation;
