import SkeletonLoader from 'components/SkeletonLoader';
import React, { useContext } from 'react';
import authService from 'services/authService';
import credentialsService from 'services/credentialsService';
import ProjectContext from '../provider/Project.context';

const ProjectSubtitle: React.FC = () => {
  const { isProjectFetching, projectData } = useContext(ProjectContext);

  const clientName =
    projectData?.clients[0].companyName ??
    `${projectData?.clients[0].firstName} ${projectData?.clients[0].lastName}`;

  const isClient = authService.checkRolesForUser(credentialsService.user, [
    'client',
  ]);

  if (isClient) return null;

  return isProjectFetching ? (
    <SkeletonLoader className="project__body__client--loader" />
  ) : (
    <h2 className="project__body__client">{clientName}</h2>
  );
};

export default ProjectSubtitle;
