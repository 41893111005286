import classNames from 'classnames';
import { DateTime } from 'luxon';
import React from 'react';

import Button from 'components/Button';
import Calendar from 'components/Calendar';
import { CalendarProps } from 'components/Calendar/Calendar.component';
import Dropdown from 'components/Dropdown';
import Input from 'components/Input';
import { InputProps } from 'components/Input/Input.component';
import useDisclosure from 'hooks/useDisclosure';
import useInvalidateUI from 'hooks/useInvalidateUI';
import CalendarIcon from 'icons/Calendar.icon';
import { useDatePickerState } from './DatePicker.hooks';

import './DatePicker.styles.responsive.scss';
import './DatePicker.styles.scss';

export type DatePickerProps = {
  className?: string;
  value?: DateTime;
  onChange?: (val: DateTime) => void;
  minDate?: DateTime;
  maxDate?: DateTime;
} & Omit<InputProps, 'value'> &
  CalendarProps;

const format = 'dd.MM.yyyy';

const DatePicker: React.FC<DatePickerProps> = (props) => {
  const {
    className,
    value,
    onChange,
    onBlur,
    calendarSize,
    minDate,
    maxDate,
    ...inputConfig
  } = props;

  const classes = classNames('date-picker', className);

  const { isOpen, toggle } = useDisclosure();

  const [internalValue, handleChange] = useDatePickerState({
    value,
    onChange,
  });

  useInvalidateUI();

  return (
    <div className={classes}>
      <Input
        variant="squared"
        className="date-picker__input"
        value={internalValue}
        onClick={(e) => e.stopPropagation()}
        onChange={(e) => handleChange(e.target.value)}
        onBlur={(e) => {
          if (!DateTime.fromFormat(internalValue, format).isValid)
            handleChange('');
          onBlur?.(e);
        }}
        {...inputConfig}
      />
      <Dropdown
        className="date-picker__dropdown-content"
        matchParrentSize={false}
        isOpen={isOpen}
        toggleOpenDropdown={toggle}
        trigger={
          <Button
            styleType="solid-bright"
            disabled={inputConfig.disabled}
            icon={<CalendarIcon />}
            className="date-picker__trigger"
            type="button"
            size="icon"
          />
        }
      >
        <Calendar
          key={internalValue}
          calendarSize={calendarSize}
          onClick={(date) => {
            handleChange(date.toFormat(format));
            toggle();
          }}
          value={DateTime.fromFormat(internalValue, format)}
          onBlur={(e) => {
            if (!DateTime.fromFormat(internalValue, format).isValid)
              handleChange('');
            onBlur?.(e);
          }}
          minDate={minDate}
          maxDate={maxDate}
        />
      </Dropdown>
    </div>
  );
};

export default DatePicker;
