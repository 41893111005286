import { AuthBody, Token } from 'models/AuthBody';
import { Role, User } from 'models/User';
import storageService, { STORAGE_KEYS } from './storageService';

export default {
  get user() {
    return storageService.getItem<User>(STORAGE_KEYS.PARTICIPANT);
  },

  get token(): string {
    return storageService.getItem<string>(STORAGE_KEYS.TOKEN);
  },

  get expiringMoment(): number {
    return storageService.getItem<number>(STORAGE_KEYS.EXPIRING_TIME);
  },

  get userRole(): Role {
    return this.user?.role;
  },

  saveAuthBody(authBody: AuthBody) {
    const expiringMoment = Date.now() + authBody.tokens.expiresIn * 1000;

    storageService.setItem(STORAGE_KEYS.PARTICIPANT, authBody.user);
    storageService.setItem(STORAGE_KEYS.EXPIRING_TIME, `${expiringMoment}`);
    storageService.setItem(STORAGE_KEYS.TOKEN, authBody.tokens.accessToken);
  },

  saveToken(tokens: Token) {
    const expiringMoment = Date.now() + tokens.expiresIn * 1000;

    storageService.removeItem(STORAGE_KEYS.TOKEN);
    storageService.removeItem(STORAGE_KEYS.EXPIRING_TIME);
    storageService.setItem(STORAGE_KEYS.TOKEN, tokens.accessToken);
    storageService.setItem(STORAGE_KEYS.EXPIRING_TIME, `${expiringMoment}`);
  },

  removeToken() {
    storageService.removeItem(STORAGE_KEYS.TOKEN);
    storageService.removeItem(STORAGE_KEYS.EXPIRING_TIME);
  },

  removeAuthBody() {
    storageService.removeItem(STORAGE_KEYS.PARTICIPANT);
    storageService.removeItem(STORAGE_KEYS.TOKEN);
    storageService.removeItem(STORAGE_KEYS.EXPIRING_TIME);
  },

  removeUser() {
    storageService.removeItem(STORAGE_KEYS.PARTICIPANT);
  },

  saveUser(user: User) {
    storageService.setItem(STORAGE_KEYS.PARTICIPANT, user);
  },
};
