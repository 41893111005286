import classNames from 'classnames';
import Button from 'components/Button';
import InputField from 'components/InputField';
import LoadingButton from 'components/LoadingButton';
import { API_ENDPOINTS } from 'config/endpoints';
import useTanstackMutation from 'hooks/useTanstackMutation';
import EditIcon from 'icons/Edit.icon';
import PhoneIcon from 'icons/Phone.icon';
import React, { useCallback, useState } from 'react';
import { Form } from 'react-final-form';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import credentialsService from 'services/credentialsService';
import { shouldDisableFormSubmit } from 'utils';
import { PhoneRegExp } from 'validations/regularExpression';
import {
  composeValidators,
  validateRegex,
  validateRequired,
} from 'validations/validations';
import CardLayout from '../CardLayout';
import './PhoneInformationCard.styles.responsive.scss';
import './PhoneInformationCard.styles.scss';

type PhoneFormInputs = {
  phone: string;
};

type PhoneInformationCardProps = {
  className?: string;
  initialValues: PhoneFormInputs;
  onSuccess?: () => Promise<void>;
};

const PhoneInformationCard: React.FC<PhoneInformationCardProps> = (props) => {
  const { className, initialValues, onSuccess } = props;

  const classes = classNames('phone-information-card', className);

  const { t } = useTranslation();

  const [isEditing, setIsEditing] = useState(false);

  const { mutate, isPending } = useTanstackMutation({
    onSuccess: async () => {
      toast.success(t('Messages.successfullyUpdatedAccountInformation'));
      await onSuccess?.();
      setIsEditing(false);
    },
    onError: () => {
      toast.error('Messages.failedUpdatingAccountInformation');
    },
    onSettled: () => setIsEditing(false),
  });

  const handleSubmit = useCallback(
    (data: PhoneFormInputs) => {
      const memberId = credentialsService.user._id;
      mutate({
        method: 'PATCH',
        path: `${API_ENDPOINTS.CLIENT_MEMBERS}/${memberId}`,
        body: { phone: data.phone },
      });
    },
    [mutate],
  );

  return (
    <CardLayout
      className={classes}
      title={t('Register.accountInformation')}
      subtitle={t('Profile.viewAndUpdateYourAccountInformation')}
      titleIcon={<PhoneIcon />}
    >
      <Form<PhoneFormInputs>
        initialValues={initialValues}
        onSubmit={handleSubmit}
        render={({ handleSubmit, ...rest }) => (
          <form
            onSubmit={handleSubmit}
            className="phone-information-card__form"
          >
            {!isEditing && (
              <Button
                styleType="transparent"
                icon={<EditIcon />}
                iconPosition="left"
                className="phone-information-card__form__edit-trigger"
                type="button"
                onClick={() => {
                  setIsEditing((p) => !p);
                }}
              />
            )}
            <InputField
              name="phone"
              title={t('Register.phone')}
              titleicon={<PhoneIcon />}
              className={classNames(
                'phone-information-card__form__row__field',
                !isEditing &&
                  'phone-information-card__form__row__field--disabled',
              )}
              disabled={!isEditing}
              validate={composeValidators(
                validateRegex('Form.phoneNumberInvalid', PhoneRegExp),
                validateRequired('Form.phoneNumberRequired'),
              )}
            />
            {isEditing && (
              <div className="phone-information-card__form__buttons">
                <LoadingButton
                  type="submit"
                  styleType="solid"
                  className="phone-information-card__form__buttons__button"
                  isLoading={isPending}
                  disabled={shouldDisableFormSubmit(rest)}
                >
                  {t('Buttons.saveChanges')}
                </LoadingButton>
                <Button
                  type="button"
                  styleType="solid-bright"
                  className="phone-information-card__form__buttons__button"
                  onClick={() => setIsEditing(false)}
                >
                  {t('Buttons.cancel')}
                </Button>
              </div>
            )}
          </form>
        )}
      />
    </CardLayout>
  );
};

export default PhoneInformationCard;
