import { Icon } from 'models/Icon';
import React from 'react';

const CrossIcon: React.FC<Icon> = (props) => {
  const { className } = props;

  return (
    <svg
      className={className}
      width="33"
      height="33"
      viewBox="0 0 33 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line
        x1="23.6172"
        y1="9.5139"
        x2="9.51611"
        y2="23.615"
        stroke="black"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <line
        x1="9.51634"
        y1="9.70898"
        x2="23.6174"
        y2="23.8101"
        stroke="black"
        strokeWidth="3"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default CrossIcon;
