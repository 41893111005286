import classNames from 'classnames';
import React from 'react';
import { Link as LinkDOM, LinkProps as LinkDOMProps } from 'react-router-dom';

import './Link.styles.responsive.scss';
import './Link.styles.scss';

type LinkProps = {
  className?: string;
  variant?: 'default' | 'icon' | 'button';
} & LinkDOMProps;

const Link: React.FC<LinkProps> = (props) => {
  const { className, variant = 'default', ...rest } = props;

  const classes = classNames('link', `link--${variant}`, className);

  return <LinkDOM className={classes} {...rest} />;
};

export default Link;
