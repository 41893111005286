import { useCallback, useState } from 'react';

type UseDisclosureProps = {
  onOpen?: () => void;
  onClose?: () => void;
  initiallyOpen?: boolean;
};

/**
 * Custom hook to manage the open/close state of a component.
 *
 * @param props - Optional properties for the hook.
 * @param props.onOpen - Callback function to be called when the component is opened.
 * @param props.onClose - Callback function to be called when the component is closed.
 * @returns An object containing the current state (`isOpen`) and functions to open (`open`) and close (`close`) the component.
 */

export default function (props?: UseDisclosureProps) {
  const { onOpen, onClose, initiallyOpen } = props ?? {};

  const [isOpen, setIsOpen] = useState(initiallyOpen);

  const open = useCallback(() => {
    if (isOpen) return;

    setIsOpen(true);
    onOpen?.();
  }, [isOpen, onOpen]);

  const close = useCallback(() => {
    if (!isOpen) return;

    setIsOpen(false);
    onClose?.();
  }, [isOpen, onClose]);

  const toggle = useCallback(() => {
    if (isOpen) close();
    if (!isOpen) open();
  }, [isOpen, open, close]);

  return {
    isOpen,
    open,
    close,
    toggle,
  };
}
