import classNames from 'classnames';
import Button from 'components/Button';
import InputField from 'components/InputField';
import Modal from 'components/Modal';
import ArrowBackIcon from 'icons/ArrowBack.icon';
import EmailIcon from 'icons/Email.icon';
import React, { useCallback, useState } from 'react';
import { Form } from 'react-final-form';
import { useNavigate } from 'react-router-dom';
import { EmailRegExp } from 'validations/regularExpression';
import {
  composeValidators,
  validateRegex,
  validateRequired,
} from 'validations/validations';
import RegisterStep from '../../Register/RegisterStep';

import LoadingButton from 'components/LoadingButton';
import { APP_ROUTES } from 'config/routes';
import RecoveryKey from 'icons/RecoveryKey.icon';
import { useTranslation } from 'react-i18next';

import { API_ENDPOINTS } from 'config/endpoints';
import useTanstackMutation from 'hooks/useTanstackMutation';
import '../../Register/RegisterStep/RegisterStep.styles.scss';
import './ForgotPasswordEmailConfirmation.styles.scss';

type ForgotPasswordEmailConfirmationProps = {
  className?: string;
};

type FormInputs = {
  email: string;
};

const ForgotPasswordEmailConfirmation: React.FC<
  ForgotPasswordEmailConfirmationProps
> = (props) => {
  const { className } = props;
  const classes = classNames('is-register-form', className);

  const { t } = useTranslation();

  const navigate = useNavigate();

  const [modalOpen, setModalOpen] = useState(false);

  const { mutateAsync, isPending } = useTanstackMutation({
    onSuccess: () => setModalOpen(true),
  });

  const handleSubmit = useCallback(
    async (body: FormInputs) => {
      try {
        await mutateAsync({
          body,
          method: 'POST',
          path: API_ENDPOINTS.FORGOT_PASSWORD,
        });
      } catch (error) {
        const errorObject = await (error as Response).json();

        if (errorObject['message']?.includes('email'))
          return { email: t('Messages.emailInvalid') };
      }
    },
    [mutateAsync, t],
  );

  return (
    <RegisterStep stepper={false} title={t('Authentication.passwordRecovery')}>
      <Form
        onSubmit={handleSubmit}
        render={({ handleSubmit, submitting, submitSucceeded }) => (
          <form onSubmit={handleSubmit} className={classes}>
            <div className="is-register-form__inputs">
              <InputField
                titleicon={<EmailIcon />}
                title={t('Authentication.email')}
                name="email"
                validate={composeValidators(
                  validateRequired('Form.emailRequired'),
                  validateRegex('Form.invalidEmail', EmailRegExp),
                )}
              />
            </div>
            <div className="is-register-form__buttons">
              <Button
                styleType="transparent"
                type="button"
                icon={<ArrowBackIcon />}
                onClick={() => {
                  navigate(APP_ROUTES.LOGIN);
                }}
              >
                {t('Buttons.back')}
              </Button>
              <LoadingButton
                className="forgot-password-email__submit"
                styleType="solid-bright"
                iconPosition="right"
                isLoading={isPending}
                disabled={submitSucceeded || submitting}
              >
                {t('Buttons.sendInstructions')}
              </LoadingButton>
            </div>
            {modalOpen && (
              <Modal
                disableCloseButton
                closeModal={() => setModalOpen(false)}
                heading={t('Authentication.passwordRecovery')}
                className="forgot-password-email__modal"
              >
                <p className="forgot-password-email__modal__description">
                  {t(
                    'Authentication.weveSentYouAConfirmationCodePleaseCheckYourEmail',
                  )}
                </p>
                <RecoveryKey className="forgot-password-email__modal__icon" />
                <Button
                  styleType="solid-bright"
                  className="forgot-password-email__modal__button"
                  onClick={() => setModalOpen(false)}
                >
                  {t('Buttons.confirm')}
                </Button>
              </Modal>
            )}
          </form>
        )}
      />
    </RegisterStep>
  );
};

export default ForgotPasswordEmailConfirmation;
