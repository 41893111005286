import { Icon } from 'models/Icon';
import React from 'react';

const ArrowIcon: React.FC<Icon> = ({ className }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      height="40"
      width="40"
      viewBox="0 0 40 40"
    >
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="m29.208 27.458-1.958-1.916 4.083-4.125H5v-2.792h26.375l-4.083-4.083 1.958-1.959 7.417 7.459Z"
      />
    </svg>
  );
};

export default ArrowIcon;
