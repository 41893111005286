import classNames from 'classnames';
import React from 'react';
import './HeaderProfileMenu.style.scss';

type HeaderProfileMenuProps = {
  className?: string;
  name?: string;
  userIcon?: React.ReactNode;
  userIconPosition?: 'left' | 'right';
  dropdownIcon?: React.ReactNode;
  open?: boolean;
};

const HeaderProfileMenu: React.FC<HeaderProfileMenuProps> = (props) => {
  const { className, name, userIcon, userIconPosition, dropdownIcon, open } =
    props;

  const HeaderProfileMenuClasses = classNames('header-profile-menu', className);
  const HeaderProfileMenuNameClasses = classNames('header-profile-menu__name', {
    'header-profile-menu__name__icon--row-reverse':
      userIconPosition === 'right',
  });
  const dropdownIconClasses = classNames('header-profile-menu__dropdown-icon', {
    'header-profile-menu__dropdown-icon--open': open,
  });

  return (
    <div className={HeaderProfileMenuClasses}>
      <p className={HeaderProfileMenuNameClasses}>
        {userIcon}{' '}
        <span className="header-profile-menu__name__text">{name}</span>
      </p>
      {dropdownIcon && (
        <div className={dropdownIconClasses}> {dropdownIcon} </div>
      )}
    </div>
  );
};

export default HeaderProfileMenu;
