import React from 'react';
import { Icon } from 'models/Icon';

const HouseIcon: React.FC<Icon> = (props) => {
  const { className } = props;

  return (
    <svg
      className={className}
      width="20"
      height="20"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M3.27539 21.7504V8.67539L12.0004 2.15039L20.7254 8.67539V21.7504H13.1004V14.8754H10.9004V21.7504H3.27539ZM6.12539 18.9254H8.05039V12.0504H15.9504V18.9254H17.8754V10.1254L12.0004 5.67539L6.12539 10.1254V18.9254Z" />
    </svg>
  );
};

export default HouseIcon;
