import classNames from 'classnames';
import React from 'react';
import { Field, Form } from 'react-final-form';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { createSearchParams, useNavigate } from 'react-router-dom';

import Button from 'components/Button';
import LoadingButton from 'components/LoadingButton';
import PasswordInputField from 'components/PasswordInput';
import { API_ENDPOINTS } from 'config/endpoints';
import { APP_ROUTES } from 'config/routes';
import useTanstackMutation from 'hooks/useTanstackMutation';
import ArrowBackIcon from 'icons/ArrowBack.icon';
import PasswordIcon from 'icons/Password.icon';
import { Bookkeeper } from 'models/User';
import storageService from 'services/storageService';
import { PasswordRegExp } from 'validations/regularExpression';
import {
  composeValidators,
  validatePasswordRepeat,
  validateRegex,
  validateRequired,
} from 'validations/validations';
import RegisterStep from '../RegisterStep';

type RegisterSecurityProps = {
  className: string;
};

const RegisterSecurityInformation: React.FC<RegisterSecurityProps> = (
  props,
) => {
  const { className } = props;
  const classes = classNames('is-register-form', className);

  const navigate = useNavigate();
  const { t } = useTranslation();

  const { mutate, isPending } = useTanstackMutation<Bookkeeper>({
    onSuccess: (data) => {
      const emailSearchParam = `?${createSearchParams({ email: data.email })}`;
      storageService.removeItem('user');
      navigate({
        pathname: APP_ROUTES.REGISTER_SUCCESS,
        search: emailSearchParam,
      });
    },
    onError: async (error) => {
      const errorObject = await (error as Response).json();

      if (errorObject['message'])
        toast.error(errorObject['message'], {
          style: {
            wordBreak: 'break-word',
          },
        });
    },
  });

  const handleSubmit = (data: { password: string }) => {
    const { firstName, lastName, vatNumber, email, phone, address, zipCode } =
      storageService.getItem<any>('user');

    const userData: any = {
      firstName,
      lastName,
      email,
      vatNumber,
      password: data.password,
      phone,
      address: {
        name: address,
        zipCode: zipCode,
      },
    };

    mutate({
      body: userData,
      method: 'POST',
      path: API_ENDPOINTS.BOOKKEEPERS,
    });
  };

  return (
    <RegisterStep stepperIndex={2} title={t('Register.securityInformation')}>
      <Form
        onSubmit={handleSubmit}
        initialValues={storageService.getItem('user')}
        render={({ handleSubmit }) => {
          return (
            <form onSubmit={handleSubmit} className={classes}>
              <div className="is-register-form__inputs">
                <Field
                  autoComplete="off"
                  component={PasswordInputField}
                  titleicon={<PasswordIcon />}
                  title={t('Register.password')}
                  name="password"
                  validate={composeValidators(
                    validateRequired('Form.passwordRequired'),
                    validateRegex('Form.invalidPassword', PasswordRegExp),
                  )}
                />
                <Field
                  autoComplete="off"
                  component={PasswordInputField}
                  titleicon={<PasswordIcon />}
                  title={t('Register.repeatPassword')}
                  name="passwordrepeat"
                  validate={composeValidators(
                    validateRequired('Form.passwordRequired'),
                    validatePasswordRepeat('Form.pleaseRepeatThePassword'),
                  )}
                />
              </div>
              <div className="is-register-form__buttons">
                <Button
                  className="is-button--on-click-translate"
                  styleType="transparent"
                  type="button"
                  icon={<ArrowBackIcon />}
                  onClick={() => {
                    navigate('../account-information');
                  }}
                >
                  {t('Buttons.back')}
                </Button>
                <LoadingButton
                  className="register__submit"
                  styleType="solid-bright"
                  iconPosition="right"
                  isLoading={isPending}
                >
                  {t('Buttons.submit')}
                </LoadingButton>
              </div>
            </form>
          );
        }}
      />
    </RegisterStep>
  );
};

export default RegisterSecurityInformation;
