import React from 'react';
import { Field, Form } from 'react-final-form';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import AuthWrapper from 'components/AuthWrapper';
import CheckBoxField from 'components/CheckBoxField';
import CustomLink from 'components/CustomLink/CustomLink.component';
import InputField from 'components/InputField';
import LoadingButton from 'components/LoadingButton';
import PasswordInputField from 'components/PasswordInput';
import { API_ENDPOINTS } from 'config/endpoints';
import { APP_ROUTES } from 'config/routes';
import useTanstackMutation from 'hooks/useTanstackMutation';
import EmailIcon from 'icons/Email.icon';
import PasswordIcon from 'icons/Password.icon';
import { AuthBody } from 'models/AuthBody';
import credentialsService from 'services/credentialsService';
import { EmailRegExp } from 'validations/regularExpression';
import {
  composeValidators,
  validateRegex,
  validateRequired,
} from 'validations/validations';

import './Login.styles.scss';

type LoginProps = Record<string, any>;

type LoginValues = {
  email: string;
  password: string;
  rememberMe: boolean;
};

const Login: React.FC<LoginProps> = (props) => {
  const navigate = useNavigate();

  const { t } = useTranslation();

  const { saveAuthBody } = credentialsService;

  const { mutate, isPending: isLoading } = useTanstackMutation<AuthBody>({
    onSuccess: (data) => {
      saveAuthBody(data);
      navigate(
        APP_ROUTES[data.user.role === 'book_keeper' ? 'DASHBOARD' : 'PROJECTS'],
      );
    },
    onError: async (e) => {
      const err = await e.json();
      toast.error(t(`Messages.${err.code}`));
    },
  });

  const onSubmit = (formData: LoginValues) =>
    mutate({
      path: `${API_ENDPOINTS.AUTH}/login`,
      method: 'POST',
      body: formData,
    });

  return (
    <AuthWrapper title={t('Authentication.pleaseLogIn')} className="is-login">
      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit }) => {
          return (
            <form className="is-login__form" onSubmit={handleSubmit}>
              <div className="is-login__form__inputs">
                <InputField
                  titleicon={<EmailIcon />}
                  title={t('Authentication.email')}
                  name="email"
                  validate={composeValidators(
                    validateRequired('Form.emailRequired'),
                    validateRegex('Form.invalidEmail', EmailRegExp),
                  )}
                />
                <Field
                  component={PasswordInputField}
                  name="password"
                  title={t('Authentication.password')}
                  titleicon={<PasswordIcon />}
                  validate={validateRequired('Form.passwordRequired')}
                />
                <CustomLink
                  to={APP_ROUTES.FORGOT_PASSWORD_EMAIL_CONFIRMATION}
                  title={t('Authentication.forgotPassword')}
                  className="is-login__form__forgot-password"
                />
              </div>
              <div className="is-login__form__checkbox">
                <Field
                  component={CheckBoxField}
                  name="rememberMe"
                  label={t('Authentication.rememberMe')}
                />
                <CustomLink
                  to={APP_ROUTES.REGISTER_PERSONAL_INFORMATION}
                  title={t('Authentication.dontHaveAnAccount')}
                  className="is-login__form__register"
                />
              </div>

              <div className="is-login__form__buttons">
                <div className="is-login__form__buttons__login">
                  <LoadingButton
                    size="large"
                    isLoading={isLoading}
                    className="is-button--on-click-translate"
                  >
                    {t('Authentication.login')}
                  </LoadingButton>
                </div>
              </div>
            </form>
          );
        }}
      />
    </AuthWrapper>
  );
};
export default Login;
