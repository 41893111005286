import classNames from 'classnames';
import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import Button from 'components/Button';
import LoadingButton from 'components/LoadingButton';
import Modal from 'components/Modal';
import { ModalProps } from 'components/Modal/Modal.component';
import useTanstackMutation from 'hooks/useTanstackMutation';
import { Payment } from 'models/Payment';

import './DeleteModal.styles.responsive.scss';
import './DeleteModal.styles.scss';

export type DeleteModalProps = {
  className?: string;
  deletingObjectName: string;
  middleIcon?: ReactNode;
  path: string;
  onSuccess?: (data?: Payment) => void;
  onError?: () => void;
} & ModalProps;

const DeleteModal: React.FC<DeleteModalProps> = (props) => {
  const {
    className,
    deletingObjectName,
    middleIcon,
    path,
    onSuccess,
    onError,
    ...rest
  } = props;

  const classes = classNames('delete-modal', className);

  const { t } = useTranslation();

  const { mutate, isPending } = useTanstackMutation({
    onSuccess,
    onError,
  });

  const handleDelete = () =>
    mutate({
      body: {},
      method: 'DELETE',
      path,
    });

  return (
    <Modal className={classes} {...rest} disableCloseButton>
      <p className="delete-modal__description">
        {t('Messages.areYouSureYouWantToDelete')}
        <strong className="delete-modal__description__purpose">
          {deletingObjectName}?
        </strong>
      </p>
      <p className="delete-modal__description">
        {t('Messages.youCantUndoThisAction')}
      </p>
      <div className="delete-modal__wrapper">{middleIcon}</div>
      <div className="delete-modal__buttons">
        <Button styleType="solid-bright" onClick={rest.closeModal} size="large">
          {t('Buttons.no')}
        </Button>
        <LoadingButton
          autoFocus
          size="large"
          styleType="solid-black"
          isLoading={isPending}
          onClick={handleDelete}
        >
          {t('Buttons.yes')}
        </LoadingButton>
      </div>
    </Modal>
  );
};

export default DeleteModal;
