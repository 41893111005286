type EventName = 'logout';

export default {
  listeners: {} as Record<EventName, ((data: any) => void)[]>,
  addEventListener<T = any>(event: EventName, callback: (data: T) => void) {
    if (!this.listeners[event]) this.listeners[event] = [];
    this.listeners[event].push(callback);

    return () => {
      this.removeEventListener(event, callback);
    };
  },
  removeEventListener<T = any>(
    event: EventName,
    callback: (data: T) => void,
  ): void {
    this.listeners[event] = (this.listeners[event] || []).filter(
      (cb) => cb !== callback,
    );
  },
  broadcastEvent<T = any>(event: EventName, payload?: T): void {
    this.listeners[event]?.forEach((cb) => cb(payload));
  },
};
