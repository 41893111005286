import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { API_ENDPOINTS } from 'config/endpoints';
import { PaginationResponse } from 'models/Response';
import { Creditor } from 'models/User';
import { useMemo } from 'react';

const useCreditorsForClient = (
  clientId: string,
  queryParams: Record<string, any>,
  options?: Partial<UseQueryOptions<PaginationResponse<Creditor>>>,
) => {
  const defaultQueryParams = useMemo(
    () => ({
      size: 9,
      ...queryParams,
    }),
    [queryParams],
  );

  return useQuery<PaginationResponse<Creditor>>({
    queryKey: [
      `${API_ENDPOINTS.GET_CREDITORS_FOR_CLIENT(clientId)}`,
      defaultQueryParams,
    ],
    ...options,
  });
};

export default useCreditorsForClient;
