import AccountForm from 'components/AccountForm';
import AccountsList from 'components/AccountsList';
import Modal from 'components/Modal';
import useDisclosure from 'hooks/useDisclosure';
import CreateClientIcon from 'icons/CreateClient.icon';
import useMembersForClients from 'queries/MembersForClient';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

type ClientDetailsAccountsCardProps = {
  clientId: string;
};

const ClientDetailsAccountsCard: FC<ClientDetailsAccountsCardProps> = (
  props,
) => {
  const { clientId } = props;

  const { t } = useTranslation();

  const { isOpen, open, close } = useDisclosure();

  const {
    data: clientMembers,
    isLoading,
    isRefetching,
  } = useMembersForClients(clientId);

  return (
    <>
      <AccountsList
        accounts={clientMembers?.items ?? []}
        isLoading={isLoading || isRefetching}
        onClick={open}
      />
      {isOpen && (
        <Modal
          closeModal={close}
          heading={t('Dashboard.createClient')}
          subtitle={t('Messages.hereYouCanEnterDetails')}
          className="client-details__accounts-card__modal"
          iconLeft={<CreateClientIcon />}
        >
          <AccountForm accountType="client" id={clientId} onSuccess={close} />
        </Modal>
      )}
    </>
  );
};

export default ClientDetailsAccountsCard;
