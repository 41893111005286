import classNames from 'classnames';
import Button from 'components/Button';
import InputField from 'components/InputField';
import { APP_ROUTES } from 'config/routes';
import ArrowIcon from 'icons/Arrow.icon';
import ArrowBackIcon from 'icons/ArrowBack.icon';
import BillIcon from 'icons/Bill.icon';
import EmailIcon from 'icons/Email.icon';
import PhoneIcon from 'icons/Phone.icon';
import React from 'react';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import storageService from 'services/storageService';
import {
  PhoneRegExp,
  VatNumberRegExp,
  ZipCodeRegExp,
} from 'validations/regularExpression';
import {
  composeValidators,
  validateRegex,
  validateRequired,
} from 'validations/validations';
import RegisterStep from '../RegisterStep';

type RegisterAccountInformationProps = {
  className: string;
};

type FormInputs = {
  vatNumber: string;
  phone: string;
  address: string;
  zipCode: string;
};

const RegisterAccountInformation: React.FC<RegisterAccountInformationProps> = (
  props,
) => {
  const { className } = props;
  const classes = classNames('is-register-form', className);
  const navigate = useNavigate();

  const { t } = useTranslation();

  const handleSubmit = async (formData: FormInputs) => {
    storageService.setItem('user', formData);

    navigate('../security-information');
  };

  return (
    <RegisterStep stepperIndex={1} title={t('Register.accountInformation')}>
      <Form<FormInputs>
        onSubmit={handleSubmit}
        initialValues={storageService.getItem('user')}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit} className={classes}>
            <div className="is-register-form__inputs">
              <InputField
                titleicon={<EmailIcon />}
                title={t('Register.address')}
                name="address"
                validate={validateRequired('Form.requiredAddress')}
              />
              <InputField
                titleicon={<EmailIcon />}
                title={t('Register.zipCode')}
                name="zipCode"
                validate={composeValidators(
                  validateRequired('Form.requiredZipCode'),
                  validateRegex('Form.invalidZipCode', ZipCodeRegExp),
                )}
              />
              <InputField
                title={t('Register.vatNumber')}
                titleicon={<BillIcon />}
                name="vatNumber"
                validate={composeValidators(
                  validateRegex('Form.invalidVatNumber', VatNumberRegExp),
                  validateRequired('Form.vatNumberRequired'),
                )}
              />
              <InputField
                titleicon={<PhoneIcon />}
                title={t('Register.phone')}
                name="phone"
                validate={composeValidators(
                  validateRegex('Form.phoneNumberInvalid', PhoneRegExp),
                  validateRequired('Form.phoneNumberRequired'),
                )}
              />
            </div>
            <div className="is-register-form__buttons">
              <Button
                className="is-button--on-click-translate"
                styleType="transparent"
                type="button"
                icon={<ArrowBackIcon />}
                onClick={() => {
                  navigate(APP_ROUTES.REGISTER_PERSONAL_INFORMATION);
                }}
              >
                {t('Buttons.back')}
              </Button>
              <Button
                className="register__submit"
                styleType="solid-bright"
                iconPosition="right"
                icon={<ArrowIcon />}
              >
                {t('Buttons.next')}
              </Button>
            </div>
          </form>
        )}
      />
    </RegisterStep>
  );
};

export default RegisterAccountInformation;
