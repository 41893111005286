// eslint-disable-next-line

import { ComplexRoute } from 'router/types';
import CreatePayment from '../CreatePayment';
import EditPayment from '../EditPayment';
import PaymentsList from '../PaymentsList';

export default [
  {
    path: '',
    element: PaymentsList,
  },
  {
    path: 'create-payment',
    element: CreatePayment,
    authorizedRoles: ['book_keeper'],
  },
  {
    path: 'edit-payment',
    element: EditPayment,
    authorizedRoles: ['book_keeper'],
  },
] as Array<ComplexRoute>;
