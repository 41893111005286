import { useQueryClient } from '@tanstack/react-query';
import React, { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import bus from 'bus';
import Sidebar from 'components/Sidebar';
import { API_ENDPOINTS } from 'config/endpoints';
import { APP_ROUTES } from 'config/routes';
import useTanstackMutation from 'hooks/useTanstackMutation';
import credentialsService from 'services/credentialsService';

import './Home.styles.scss';

const Home: React.FC = () => {
  const navigate = useNavigate();

  const queryClient = useQueryClient();

  const { mutate } = useTanstackMutation({
    onSuccess: () => {
      credentialsService.removeAuthBody();
      queryClient.clear();
      navigate(APP_ROUTES.LOGIN);
    },
  });

  useEffect(() => {
    const logout = () => {
      mutate({
        path: API_ENDPOINTS.LOGOUT,
        body: {},
        method: 'POST',
      });
    };

    bus.addEventListener('logout', logout);

    return () => {
      bus.removeEventListener('logout', logout);
    };
    // eslint-disable-next-line
  }, []);

  return (
    <div className="home">
      <Sidebar />
      <main className="home__main">
        <Outlet />
      </main>
    </div>
  );
};

export default Home;
