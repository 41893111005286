import { Navigate } from 'react-router-dom';

import { APP_ROUTES } from 'config/routes';
import { ComplexRoute } from 'router/types';
import Client from '../Client';
import Clients from '../Clients';
import Creditors from '../Creditors';
import Dashboard from '../Dashboard';
import Logout from '../Logout';
import Payments from '../Payments';
import paymentRoutes from '../Payments/routes';
import Profile from '../Profile';
import Project from '../Project';
import Projects from '../Projects';

export default [
  {
    path: '',
    element: () => <Navigate to={APP_ROUTES.DASHBOARD} />,
  },
  {
    path: 'dashboard',
    element: Dashboard,
    authorizedRoles: ['book_keeper'],
  },
  {
    path: 'creditors',
    element: Creditors,
    authorizedRoles: ['client'],
  },

  {
    path: 'client',
    element: Client,
    authorizedRoles: ['client'],
  },
  {
    path: 'clients',
    element: Clients,
    authorizedRoles: ['book_keeper'],
  },
  {
    path: 'profile',
    element: Profile,
  },
  {
    path: 'projects/:id',
    authorized: true,
    onlyPublic: false,
    element: Project,
  },
  {
    path: 'projects',
    authorized: true,
    onlyPublic: false,
    element: Projects,
    authorizedRoles: ['client'],
  },
  {
    path: 'payments',
    element: Payments,
    authorized: true,
    routes: paymentRoutes,
    authorizedRoles: ['book_keeper'],
  },
  {
    path: 'logout',
    authorized: true,
    onlyPublic: false,
    element: Logout,
  },
] as Array<ComplexRoute>;
