import classNames from 'classnames';
import React from 'react';

import Button from 'components/Button';
import Input from 'components/Input';
import { InputProps } from 'components/Input/Input.component';
import CrossIcon from 'icons/Cross.icon';
import SearchIcon from 'icons/Search.icon';

import './SearchInput.styles.responsive.scss';
import './SearchInput.styles.scss';

type SearchInputProps = {
  className?: string;
  onChange: (value: string) => void;
} & Omit<InputProps, 'onChange'>;

const SearchInput: React.FC<SearchInputProps> = (props) => {
  const { className, onChange, ...inputProps } = props;

  const classes = classNames('search-input', className);

  return (
    <div className={classes}>
      <SearchIcon className="search-input__icon search-input__icon--search" />
      <Input
        className="search-input__input"
        onChange={(e) => onChange(e.target.value)}
        {...inputProps}
      />
      {inputProps.value && (
        <Button
          className="search-input__icon search-input__icon--cross"
          icon={<CrossIcon />}
          styleType="icon"
          onClick={() => onChange('')}
        />
      )}
    </div>
  );
};

export default SearchInput;
