import React, { ReactNode } from 'react';
import LogoMax from 'icons/LogoMax.icon';
import Stepper from 'components/Stepper';
import classNames from 'classnames';

import './RegisterStep.styles.scss';

type RegisterStepProps = {
  title?: string;
  className?: string;
  stepperIndex?: number;
  children?: ReactNode;
  stepper?: boolean;
};

const RegisterStep: React.FC<RegisterStepProps> = (props) => {
  const { title, className, children, stepperIndex, stepper = true } = props;
  const classes = classNames('is-register', className);

  return (
    <div className={classes}>
      <header className="is-register__header">
        <LogoMax fillColor="#252D56" />
      </header>

      <main className="is-register__content">
        <div className="is-register__info">
          {title}
          {stepper && <Stepper numberOfNodes={3} nodeIndex={stepperIndex} />}
        </div>
        {children && children}
      </main>

      <footer className="is-register__footer">
        <p className="is-copyright">@Copyright instructpay</p>
      </footer>
    </div>
  );
};

export default RegisterStep;
