import { QueryFunction, QueryKey } from '@tanstack/react-query';
import bus from 'bus';
import { API_ENDPOINTS } from 'config/endpoints';

import env from 'env';
import { Token } from 'models/AuthBody';
import credentialsService from 'services/credentialsService';

export const BASE_PATH = env.SERVER_ENDPOINT.endsWith('/')
  ? env.SERVER_ENDPOINT.slice(0, -1)
  : env.SERVER_ENDPOINT;

export const handleInvalidAccessToken = async () => {
  const headers: Record<string, string> = {};

  try {
    const responseTokens = await fetch(
      `${BASE_PATH}${API_ENDPOINTS.REFRESH_TOKEN}`,
      {
        headers,
        method: 'POST',
        credentials: 'include',
      },
    );

    const json: Token = await responseTokens.json();

    if (json.accessToken) credentialsService.saveToken(json);
    else throw new Error();
  } catch (err) {
    bus.broadcastEvent('logout');
  }
};

const parseParamsToURL = (queryKey: QueryKey, pageParam: unknown) => {
  const [path, queryKeyParams] = queryKey as [string, Record<string, string>];

  const queryParams = { ...queryKeyParams };

  if (pageParam) queryParams.page = pageParam as string;

  const queryString = new URLSearchParams(queryParams).toString();

  const basePath = `${BASE_PATH}${path}`;

  const url = queryString ? `${basePath}?${queryString}` : basePath;

  return url;
};

const invalidateAccessToken = async () => {
  const { expiringMoment } = credentialsService;

  if (expiringMoment && expiringMoment < Date.now())
    await handleInvalidAccessToken();
};

const buildRequestHeader = () => {
  const { token } = credentialsService;

  const headers: Record<string, string> = {};

  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }

  return headers;
};

const handleError = async (err: unknown) => {
  if (err && typeof err === 'object' && 'status' in err) {
    const errorWithStatus = err as { status: number };
    if (errorWithStatus.status === 401) handleInvalidAccessToken();
    if (errorWithStatus.status === 403) bus.broadcastEvent('logout');
  }

  throw new Error(err instanceof Response ? await err.text() : String(err));
};

export const defaultQueryFn: QueryFunction = async ({
  queryKey,
  pageParam,
}) => {
  const url = parseParamsToURL(queryKey, pageParam);

  try {
    await invalidateAccessToken();

    const headers = buildRequestHeader();

    const res = await fetch(url, {
      headers,
      credentials: 'include',
    });

    if (!res.ok) throw res;

    const json = await res.json();
    return json;
  } catch (err: unknown) {
    await handleError(err);
  }
};

export const xmlQueryFn: QueryFunction = async ({ queryKey, pageParam }) => {
  const url = parseParamsToURL(queryKey, pageParam);

  try {
    await invalidateAccessToken();

    const headers = buildRequestHeader();

    const res = await fetch(url, {
      headers,
      credentials: 'include',
    });

    if (!res.ok) throw res;

    const blob = await res.blob();

    const downloadUrl = window.URL.createObjectURL(new Blob([blob]));

    const link = document.createElement('a');
    link.href = downloadUrl;
    link.setAttribute('download', `File.xml`);

    document.body.appendChild(link);

    link.click();

    link.parentNode.removeChild(link);

    return blob;
  } catch (err: unknown) {
    await handleError(err);
  }
};
