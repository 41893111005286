import { useQuery } from '@tanstack/react-query';
import classNames from 'classnames';
import Button from 'components/Button';
import ClientDetails from 'components/ClientDetails';
import ClientOverviewEdit from 'components/ClientOverviewEdit';
import Header from 'components/Header';
import LoadingSpinner from 'components/LoadingSpinner';
import PreviewCard from 'components/PreviewCard';
import { API_ENDPOINTS } from 'config/endpoints';
import useDisclosure from 'hooks/useDisclosure';
import ClientsIcon from 'icons/Clients.icon';
import CrossIcon from 'icons/Cross.icon';
import EditIcon from 'icons/Edit.icon';
import { ClientWithProjectStatus } from 'models/User';
import React from 'react';
import { useTranslation } from 'react-i18next';
import credentialsService from 'services/credentialsService';
import './Client.styles.responsive.scss';
import './Client.styles.scss';

type ClientProps = {
  className?: string;
};

const Client: React.FC<ClientProps> = (props) => {
  const { className } = props;

  const classes = classNames('client', className);

  const { t } = useTranslation();

  const { isOpen: isEditing, toggle } = useDisclosure();

  const { clientId } = credentialsService.user;
  const { data, isFetching, refetch } = useQuery<ClientWithProjectStatus>({
    queryKey: [
      `${API_ENDPOINTS.CLIENTS}/${clientId}`,
      { includeProjectStatuses: true },
    ],
  });

  const name = data?.companyName ?? `${data?.firstName} ${data?.lastName}`;

  return (
    <div className={classes}>
      <Header
        title={!data && isFetching ? t('Sidebar.client') : name}
        className="client__header"
      />
      <div className="client__body">
        {!data && isFetching ? (
          <LoadingSpinner className="client__body__loading" />
        ) : (
          <PreviewCard
            title={t('Index.information')}
            titleIconLeft={<ClientsIcon />}
            button={
              <Button
                styleType="icon"
                icon={!isEditing ? <EditIcon /> : <CrossIcon />}
                onClick={toggle}
              />
            }
          >
            {!isEditing ? (
              <ClientDetails client={data} />
            ) : (
              <ClientOverviewEdit
                data={data}
                formConfig={{
                  buttonsPosition: 'right',
                  onSuccess: async () => {
                    await refetch();
                    toggle();
                  },
                }}
                className="client__body__edit"
              />
            )}
          </PreviewCard>
        )}
      </div>
    </div>
  );
};

export default Client;
