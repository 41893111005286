export const calculateToolTipPosition = (
  containerRect: DOMRect,
  contentRect: DOMRect,
  distance: number,
  position: 'bottom' | 'top' = 'top',
) => {
  let topInPx = containerRect?.top - contentRect?.height - distance;
  let leftInPx =
    containerRect?.left + containerRect?.width / 2 - contentRect?.width / 2;

  const possibleBottomPosition = containerRect?.bottom + distance;

  topInPx =
    topInPx > 0 && position === 'top'
      ? topInPx
      : possibleBottomPosition + contentRect?.height > window.innerHeight
      ? topInPx
      : possibleBottomPosition;

  leftInPx = Math.max(0, leftInPx);
  leftInPx =
    leftInPx + contentRect?.width > window.innerWidth
      ? window.innerWidth - contentRect?.width
      : leftInPx;

  return {
    top: `${topInPx}px`,
    left: `${leftInPx}px`,
  };
};
