import { useQuery } from '@tanstack/react-query';
import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Button from 'components/Button';
import ClientPreview from 'components/ClientPreview';
import ClientPreviewCard from 'components/ClientPreviewCard';
import CreateClientModal from 'components/CreateClientModal';
import EmptyDashboardBody from 'components/EmptyDashboardBody';
import Header from 'components/Header';
import PreviewCardWithSearch from 'components/PreviewCardWithSearch';
import { API_ENDPOINTS } from 'config/endpoints';
import { SEARCH_PARAMS } from 'config/searchParams';
import { DEFAULT_PAGE } from 'constant';
import useDisclosure from 'hooks/useDisclosure';
import useSearchParams from 'hooks/useSearchParams';
import FolderIcon from 'icons/Folder.icon';
import PaymentsIcon from 'icons/Payments.icon';
import PlusIcon from 'icons/Plus.icon';
import ThreeClientsIcon from 'icons/ThreeClients.icon';
import { PaginationResponse } from 'models/Response';
import { Client } from 'models/User';

import './Clients.styles.responsive.scss';
import './Clients.styles.scss';

type ClientsProps = {
  className?: string;
};

const Clients: React.FC<ClientsProps> = (props) => {
  const { className } = props;

  const classes = classNames('clients', className);

  const { t } = useTranslation();

  const { getSearchParam, setSearchParam, setSearchParams } = useSearchParams();

  const clientId = getSearchParam(SEARCH_PARAMS.CLIENT_ID);

  const { isOpen, open, close } = useDisclosure();

  const { data: initialClientData, isLoading } = useQuery<
    PaginationResponse<Client>
  >({
    queryKey: [API_ENDPOINTS.CLIENTS, { size: 20, sortBy: 'lastPayment:-1' }],
  });

  return (
    <>
      <Header title={t('Clients.clients')} className="clients__header" />
      <div className={classes}>
        <PreviewCardWithSearch<Client>
          filterInstantly={false}
          queryKey={[API_ENDPOINTS.CLIENTS, { size: 20 }]}
          searchKey="companyName"
          title={t('Clients.clients')}
          titleIconLeft={<ThreeClientsIcon />}
          button={
            <Button
              styleType="icon"
              size="icon"
              icon={<PlusIcon />}
              onClick={open}
            />
          }
          onFetch={(data) => {
            if (!data || data.length === 0) return;

            if (!clientId)
              setSearchParam(SEARCH_PARAMS.CLIENT_ID, data?.[0]._id, {
                replace: true,
              });
          }}
          render={(data) =>
            data?.map((client) => (
              <ClientPreview
                key={client._id}
                name={
                  client.companyName || `${client.firstName} ${client.lastName}`
                }
                itemsIcon={<FolderIcon />}
                subtextIcon={<PaymentsIcon />}
                itemsText={t('Dashboard.project', {
                  count: client.numberProjects,
                })}
                numOfItems={client.numberProjects}
                subtext={client.bankAccount}
                onClick={() => {
                  const searchParams = new URLSearchParams(
                    window.location.search,
                  );
                  searchParams.set(SEARCH_PARAMS.CLIENT_ID, client._id);
                  searchParams.set(SEARCH_PARAMS.PAGE, `${DEFAULT_PAGE}`);
                  searchParams.delete(SEARCH_PARAMS.CREDITOR_ID);
                  setSearchParams(searchParams, { replace: true });
                }}
                selected={client._id === clientId}
              />
            ))
          }
        />

        {isOpen && <CreateClientModal closeModal={close} />}

        {clientId ? (
          <ClientPreviewCard clientId={clientId} />
        ) : (
          !isLoading &&
          (!initialClientData || initialClientData.items.length === 0) && (
            <EmptyDashboardBody onClick={open} />
          )
        )}
      </div>
    </>
  );
};

export default Clients;
