import { useQuery } from '@tanstack/react-query';
import classNames from 'classnames';
import Lottie from 'lottie-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import animationData from 'assets/projects.json';
import ClientPreview, {
  ClientPreviewProps,
} from 'components/ClientPreview/ClientPreview.component';
import CreateClientModal from 'components/CreateClientModal';
import EmptyDashboardBody from 'components/EmptyDashboardBody';
import Link from 'components/Link';
import PaymentTable, {
  HeaderTable,
} from 'components/PaymentTable/PaymentTable.component';
import PreviewCardWithSearch from 'components/PreviewCardWithSearch';
import ProjectsPreviewCard, {
  ProjectsPreviewCardProps,
} from 'components/ProjectsPreviewCard/ProjectsPreviewCard.component';
import { API_ENDPOINTS } from 'config/endpoints';
import { APP_ROUTES } from 'config/routes';
import { SEARCH_PARAMS } from 'config/searchParams';
import useDisclosure from 'hooks/useDisclosure';
import useSearchParams from 'hooks/useSearchParams';
import FolderIcon from 'icons/Folder.icon';
import PaymentsIcon from 'icons/Payments.icon';
import ThreeClientsIcon from 'icons/ThreeClients.icon';
import { Payment } from 'models/Payment';
import { Project } from 'models/Project';
import { PaginationResponse } from 'models/Response';
import { Client } from 'models/User';
import DashboardBodyLoading from './DashboardBodyLoading.component';

import './DashboardBody.styles.responsive.scss';
import './DashboardBody.styles.scss';

export type ClientInfo = Omit<ClientPreviewProps, 'className'>;
export type ProjectInfo = Omit<ProjectsPreviewCardProps, 'className'>;

type DashboardBodyProps = {
  className?: string;
};

const headers = (
  t: (message: string, options?: { count: number }) => string,
): Array<HeaderTable> => [
  { heading: t('Dashboard.payer'), value: 'payer' },
  { heading: t('Dashboard.payee'), value: 'payee' },
  { heading: t('Dashboard.project', { count: 1 }), value: 'project' },
  { heading: t('Dashboard.amount'), value: 'amount' },
  { heading: t('Fields.purpose'), value: 'purpose' },
  { value: 'button' },
];

const DashboardBody: React.FC<DashboardBodyProps> = (props) => {
  const { className } = props;

  const classes = classNames('dashboard-body', className);

  const { isOpen, open, close } = useDisclosure();

  const { t } = useTranslation();

  const navigate = useNavigate();

  const { getSearchParam, setSearchParam, removeSearchParam } =
    useSearchParams();

  const selectedClientId = getSearchParam(SEARCH_PARAMS.CLIENT_ID);
  const selectedProjectId = getSearchParam(SEARCH_PARAMS.PROJECT_ID);

  const { data: initialClientData, isLoading } = useQuery<
    PaginationResponse<Client>
  >({
    queryKey: [API_ENDPOINTS.CLIENTS, { size: 20, sortBy: 'lastPayment:-1' }],
  });

  return (
    <div className={classes}>
      {isLoading ? (
        <DashboardBodyLoading />
      ) : initialClientData?.items.length === 0 ? (
        <EmptyDashboardBody onClick={open} />
      ) : (
        <>
          <h2 className="dashboard-body__subtitle">
            {t('Dashboard.viewTheLatestPaymentsForYourClients')}
          </h2>
          <div className="dashboard-body__min-cards">
            <PreviewCardWithSearch<Client>
              isContentInline
              filterInstantly={false}
              className="dashboard-body__preview-card"
              queryKey={[
                API_ENDPOINTS.CLIENTS,
                { size: 20, sortBy: 'lastPayment:-1' },
              ]}
              searchKey="name"
              title={t('Clients.clients')}
              titleIconLeft={<ThreeClientsIcon />}
              onFetch={(data) => {
                if (selectedClientId || data.length === 0) return;
                removeSearchParam(SEARCH_PARAMS.PROJECT_ID);
                setSearchParam(SEARCH_PARAMS.CLIENT_ID, data?.[0]?._id);
              }}
              render={(data) =>
                data?.map((client) => (
                  <ClientPreview
                    key={client._id}
                    name={
                      client.companyName ||
                      `${client.firstName} ${client.lastName}`
                    }
                    linkTo={`${APP_ROUTES.CLIENTS}?${SEARCH_PARAMS.CLIENT_ID}=${client._id}`}
                    selected={selectedClientId === client._id}
                    itemsIcon={<FolderIcon />}
                    subtextIcon={<PaymentsIcon />}
                    itemsText={t('Dashboard.project', {
                      count: client.numberProjects,
                    })}
                    numOfItems={client.numberProjects}
                    subtext={client.bankAccount}
                    onClick={() => {
                      removeSearchParam(SEARCH_PARAMS.PROJECT_ID);
                      setSearchParam(SEARCH_PARAMS.CLIENT_ID, client._id);
                    }}
                  />
                ))
              }
            />
            <PreviewCardWithSearch<Project>
              isContentInline
              keepPreviousData={false}
              filterInstantly={false}
              EmptyStateComponent={
                <div className="dashboard-body__preview-card__projects__empty-state">
                  <span className="dashboard-body__preview-card__projects__empty-state__span">
                    {t('Dashboard.EmptyProject.toCreatePaymentOrders')}&nbsp;
                    <Link
                      to={`${APP_ROUTES.CLIENTS}?${SEARCH_PARAMS.CLIENT_ID}=${
                        selectedClientId || ''
                      }`}
                    >
                      {t('Dashboard.EmptyProject.clientsPage')}
                    </Link>
                    &nbsp;{t('Dashboard.EmptyProject.toCreateYourFirstProject')}
                  </span>
                  <Lottie
                    loop={false}
                    animationData={animationData}
                    className="dashboard-body__preview-card__projects__empty-state__animation"
                  />
                </div>
              }
              className="dashboard-body__preview-card"
              queryKey={[
                API_ENDPOINTS.PROJECTS,
                {
                  size: 20,
                  clientId: selectedClientId,
                  sortBy: 'lastPayment:-1',
                },
              ]}
              enabled={!!selectedClientId}
              onFetch={(data) => {
                if (selectedProjectId || data.length === 0) return;
                setSearchParam(SEARCH_PARAMS.PROJECT_ID, data?.[0]?._id);
              }}
              searchKey="name"
              title={t('Index.projects')}
              titleIconLeft={<ThreeClientsIcon />}
              render={(data) =>
                data?.map((project) => (
                  <ProjectsPreviewCard
                    key={project._id}
                    project={project}
                    selected={project._id === selectedProjectId}
                    onClick={() =>
                      setSearchParam(SEARCH_PARAMS.PROJECT_ID, project._id)
                    }
                    linkTo={`${APP_ROUTES.PROJECTS}/${project._id}`}
                  />
                ))
              }
            />
          </div>
          <div className="dashboard-body__max-card">
            <PreviewCardWithSearch<Payment>
              isContentInline
              keepPreviousData={false}
              filterInstantly={false}
              className="dashboard-body__preview-card"
              queryKey={[
                API_ENDPOINTS.PAYMENT_SLIPS,
                {
                  size: 20,
                  projectId: selectedProjectId,
                  sortBy: 'processmentDate:-1',
                },
              ]}
              enabled={!!selectedProjectId}
              searchKey="payee.name"
              title={t('Payments.payments')}
              titleIconLeft={<ThreeClientsIcon />}
              render={(data) => (
                <PaymentTable
                  linkTo
                  hideButtons
                  paymentsList={data || []}
                  headers={headers(t)}
                  classNameTable="table-payments"
                />
              )}
            />
          </div>
        </>
      )}
      {isOpen && (
        <CreateClientModal
          closeModal={(currentStep) => {
            if (currentStep) navigate(APP_ROUTES.CLIENTS);
            close();
          }}
        />
      )}
    </div>
  );
};

export default DashboardBody;
