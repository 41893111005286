import LanguagePicker from 'components/LanguagePicker';
import { FC } from 'react';
import { Outlet } from 'react-router-dom';
import './Register.styles.scss';

const Register: FC = () => {
  return (
    <>
      <LanguagePicker className="is-language-picker--absolute is-language-picker--top-right" />
      <Outlet />
    </>
  );
};

export default Register;
