import classNames from 'classnames';
import Button from 'components/Button';
import {
  InputFieldComponent,
  InputFieldComponentProps,
} from 'components/InputField/InputField.component';
import EyeNotVisibleIcon from 'icons/EyeNotVisible.icon';
import EyeVisibleIcon from 'icons/EyeVisible.icon';
import React, { useEffect, useMemo, useRef, useState } from 'react';

import './PasswordInputField.styles.scss';

export type PasswordInputFieldProps = {
  className?: string;
  classNameInput?: string;
  passwordRecoveryLink?: string;
} & InputFieldComponentProps;

const PasswordInputField: React.FC<PasswordInputFieldProps> = (props) => {
  const { className, classNameInput, passwordRecoveryLink, ref, ...rest } =
    props;

  const passwordRef = useRef<HTMLInputElement>(null);

  const [passwordVisible, setPasswordVisible] = useState(false);

  useEffect(() => {
    if (passwordRef?.current) {
      const password = passwordRef.current;

      const prevent = (e: Event) => e.preventDefault();
      const restrictedActions = ['copy', 'paste', 'cut', 'drag', 'crop'];

      restrictedActions.forEach((restrictedAction) =>
        password.addEventListener(restrictedAction, prevent),
      );

      return () => {
        restrictedActions.forEach((restrictedAction) =>
          password.removeEventListener(restrictedAction, prevent),
        );
      };
    }
  }, []);

  const classes = classNames('is-input__content__eye-button', className);

  const EyeButton = useMemo(
    () => (
      <Button
        styleType="transparent"
        type="button"
        className={classes}
        onClick={() => {
          setPasswordVisible(!passwordVisible);
        }}
        icon={passwordVisible ? <EyeVisibleIcon /> : <EyeNotVisibleIcon />}
      />
    ),
    [passwordVisible, classes],
  );

  return (
    <InputFieldComponent
      ref={passwordRef}
      suffixButton={EyeButton}
      type={passwordVisible ? 'input' : 'password'}
      additionallink={
        passwordRecoveryLink
          ? {
              name: 'forgot password?',
              path: passwordRecoveryLink,
            }
          : undefined
      }
      className={classNameInput}
      {...rest}
    />
  );
};

export default PasswordInputField;
