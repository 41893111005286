import Decimal from 'decimal.js';
import { PAYMENT_STATUS, PaymentSelectingMode } from 'models/Payment';
import { FormRenderProps } from 'react-final-form';

function isTouchDevice(): boolean {
  const prefixes = ' -webkit- -moz- -o- -ms- '.split(' ');
  const mq = function (query: string) {
    return window.matchMedia(query).matches;
  };

  if ('ontouchstart' in window || 'DocumentTouch' in window) {
    return true;
  }

  const query = ['(', prefixes.join('touch-enabled),('), 'ncoded', ')'].join(
    '',
  );
  return mq(query);
}

export const shouldDisableFormSubmit = <T,>(
  formState: Omit<FormRenderProps<T>, 'handleSubmit'>,
) => {
  const { dirty, submitSucceeded, dirtySinceLastSubmit, hasSubmitErrors } =
    formState;

  const isFormNotDirty =
    !(dirty && !submitSucceeded) && !(dirtySinceLastSubmit && submitSucceeded);

  return (isFormNotDirty || formState.invalid) && !hasSubmitErrors;
};

const calculateRefNumCharVal = (character: string) => {
  if (character >= '0' && character <= '9') return character;

  if (character >= 'a') return (character.charCodeAt(0) - 87).toString();

  return (character.charCodeAt(0) - 55).toString();
};

export const calculateReferenceNumberValue = (referenceNumber: string) =>
  referenceNumber
    .split(/[\s-/]+/)
    .join('')
    .split('')
    .map((ch) => calculateRefNumCharVal(ch))
    .join('');

export const calculateChecksum = (value: string) => {
  const val = new Decimal(calculateReferenceNumberValue(value)).toNumber();

  const decimal = new Decimal(val * 100).mod(97).toNumber();
  const calculatedChecksum = 98 - decimal;

  return calculatedChecksum.toString();
};

export const parseStringValue = (value: any) =>
  /^\d+$/.test(value)
    ? +value
    : /^(true|false)$/.test(value)
    ? JSON.parse(value)
    : value;

export function convertFormDataToJSONObject(formData: FormData) {
  const obj: any = {};
  formData.forEach((val, key) => {
    const isArray = key.includes('[]') || key.includes('files');

    if (isArray) {
      const newKey = key.split('[]')[0];
      if (!obj[newKey]) obj[newKey] = [];
      obj[newKey].push(parseStringValue(val));
    } else {
      obj[key] = parseStringValue(val);
    }
  });
  return obj;
}

export const convertObjToFormData = (
  obj: Record<string, any>,
  formData = new FormData(),
  path = '',
) => {
  if (obj === undefined) return;

  for (const prop in obj) {
    const newPath = path ? `${path}[${prop}]` : prop;
    if (typeof obj[prop] !== 'object') {
      if (obj[prop] instanceof File) {
        const file: File = obj[prop];
        formData.append(newPath, file, file.name);
      } else {
        formData.append(newPath, obj[prop]);
      }
    } else if (obj[prop] === null) {
      formData.append(newPath, obj[prop]);
    } else {
      convertObjToFormData(obj[prop], formData, newPath);
    }
  }

  return formData;
};

export function debounce(
  func: (...args: any[]) => void,
  wait: number,
  isImmediate = false,
) {
  let timeout: NodeJS.Timeout | null;
  return function (...args: any[]) {
    const later = () => {
      timeout = null;
      if (!isImmediate) {
        func(...args);
      }
    };
    const callNow = isImmediate && !timeout;
    if (timeout) clearTimeout(timeout);
    timeout = global.setTimeout(later, wait);
    if (callNow) {
      func(...args);
    }
  };
}

/**
 * Calculates the scale and scale percentage based on the number of processed items and total items.
 *
 * @param processedItems - The number of items that have been processed.
 * @param totalItems - The total number of items.
 * @returns An object containing the scale and scale percentage.
 */
function calculateScale(processedItems: number, totalItems: number) {
  if (!processedItems) return { scale: 1, scalePercentage: 100 };

  const scale = Math.min(processedItems / totalItems, 1);
  const scalePercentage = scale * 100;

  return { scale, scalePercentage };
}

function capitalizeFirstLetter(text: string) {
  return text.charAt(0).toUpperCase() + text.slice(1);
}

const createRange = (start: number, end: number) =>
  Array.from({ length: end - start + 1 }, (_, i) => i + start);

const createPagination = (
  totalPages: number,
  currentPage: number,
  boundaryCount: number,
  siblingCount: number,
) => {
  const maxValueToConnectLeft = boundaryCount + 2 * (siblingCount + 1);
  const minValueToConnectRight = totalPages - maxValueToConnectLeft + 1;

  const isConnectingLeft = currentPage <= maxValueToConnectLeft - 1;
  const isConnectingRight = currentPage >= minValueToConnectRight + 1;

  const finalArray = [];

  if (totalPages <= maxValueToConnectLeft) {
    return createRange(1, totalPages);
  }

  if (totalPages <= 2 * boundaryCount + 2 * siblingCount + 3) {
    finalArray.push(...createRange(1, totalPages));
  } else if (isConnectingLeft) {
    finalArray.push(...createRange(1, maxValueToConnectLeft));
    finalArray.push('...');
    finalArray.push(...createRange(totalPages - boundaryCount + 1, totalPages));
  } else if (isConnectingRight) {
    finalArray.push(...createRange(1, boundaryCount));
    finalArray.push('...');
    finalArray.push(...createRange(minValueToConnectRight, totalPages));
  } else {
    finalArray.push(...createRange(1, boundaryCount));
    finalArray.push('...');
    finalArray.push(
      ...createRange(currentPage - siblingCount, currentPage + siblingCount),
    );
    finalArray.push('...');
    finalArray.push(...createRange(totalPages - boundaryCount + 1, totalPages));
  }

  return finalArray;
};

function calculatePercentage(part: number, total: number) {
  return (part / total) * 100;
}

const shouldPaymentBeSelectable = (
  paymentSelectingMode: PaymentSelectingMode,
  paymentStatus: PAYMENT_STATUS,
) => {
  if (paymentSelectingMode === PaymentSelectingMode.None) return false;

  if (paymentSelectingMode === PaymentSelectingMode.Processing)
    return paymentStatus !== PAYMENT_STATUS.PROCESSED;

  let filterArray = [PAYMENT_STATUS.UNCHECKED];

  filterArray = [
    PaymentSelectingMode.Editing,
    PaymentSelectingMode.Verifying,
  ].includes(paymentSelectingMode)
    ? [...filterArray, PAYMENT_STATUS.ERROR]
    : filterArray;

  filterArray =
    PaymentSelectingMode.Verifying === paymentSelectingMode
      ? [...filterArray, PAYMENT_STATUS.CHECKED]
      : filterArray;

  return filterArray.includes(paymentStatus);
};

// eslint-disable-next-line @typescript-eslint/no-empty-function
function noop(): any {}

export default {
  capitalizeFirstLetter,
  calculatePercentage,
  shouldPaymentBeSelectable,
  noop,
  isTouchDevice,
  parseStringValue,
  convertObjToFormData,
  convertFormDataToJSONObject,
  debounce,
  calculateScale,
  createRange,
  createPagination,
};
