import React from 'react';
import classNames from 'classnames';
import './LoadingSpinner.styles.scss';
import './LoadingSpinner.styles.responsive.scss';

type LoadingSpinnerProps = {
  className?: string;
};

const LoadingSpinner: React.FC<LoadingSpinnerProps> = (props) => {
  const { className } = props;

  const classes = classNames('loading-spinner', className);

  return <div className={classes}></div>;
};

export default LoadingSpinner;
