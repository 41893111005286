import { useCallback } from 'react';
import { NavigateOptions } from 'react-router-dom';

import { SEARCH_PARAMS } from 'config/searchParams';
import { DEFAULT_PAGE } from 'constant';
import useSearchParams from './useSearchParams';

export default function () {
  const { getSearchParam, setSearchParam } = useSearchParams();

  const page = Number(getSearchParam(SEARCH_PARAMS.PAGE) || DEFAULT_PAGE);

  const setPage = useCallback(
    (page: number | string, options?: NavigateOptions) =>
      setSearchParam(SEARCH_PARAMS.PAGE, `${page}`, options),
    [setSearchParam],
  );

  return { page, setPage };
}
