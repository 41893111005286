import { useCallback } from 'react';
import { NavigateOptions, useSearchParams } from 'react-router-dom';

export default function () {
  const [searchParams, setSearchParams] = useSearchParams();

  const setSearchParam = useCallback(
    (
      searchParam: string,
      value: string,
      options: NavigateOptions = { replace: true },
    ) => {
      const searchParams = new URLSearchParams(window.location.search);
      searchParams.set(searchParam, value);
      setSearchParams(searchParams, options);
    },
    [setSearchParams],
  );

  const removeSearchParam = useCallback(
    (searchParam: string, options: NavigateOptions = { replace: true }) => {
      const searchParams = new URLSearchParams(window.location.search);
      searchParams.delete(searchParam);
      setSearchParams(searchParams, options);
    },
    [setSearchParams],
  );

  const getSearchParam = useCallback(
    (searchParam: string) => {
      return searchParams.get(searchParam);
    },
    [searchParams],
  );

  return {
    searchParams,
    setSearchParams,
    setSearchParam,
    removeSearchParam,
    getSearchParam,
  };
}
