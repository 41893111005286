import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Button from 'components/Button';
import VectorArrowIcon from 'icons/VectorArrow.icon';
import utils from 'utils';

import './PaginationBar.style.scss';

export type PaginationBarProps = {
  className?: string;
  currentPage?: number;
  totalPages: number;
  siblingCount?: number;
  boundaryCount?: number;
  onChange: (val: number) => void;
};

const PaginationBar: React.FC<PaginationBarProps> = (props) => {
  const {
    className,
    onChange,
    currentPage,
    totalPages,
    boundaryCount = 1,
    siblingCount = 1,
  } = props;

  const paginationBarClasses = classNames('pagination-bar', className);

  const { t } = useTranslation();

  const finalArray = utils.createPagination(
    totalPages,
    currentPage,
    boundaryCount,
    siblingCount,
  );
  if (currentPage === 0 || !totalPages) return null;

  return (
    <div className={paginationBarClasses}>
      <Button
        styleType="icon"
        size="icon"
        icon={<VectorArrowIcon />}
        rotateIcon="left"
        onClick={() => onChange(currentPage - 1)}
        disabled={currentPage === 1}
        className="pagination-bar__change-page-button"
      >
        {t('Buttons.prev')}
      </Button>
      <ul className="pagination-bar__numbered-list">
        {finalArray.map((val, index) => (
          <li key={index}>
            {val !== '...' ? (
              <Button
                size="icon"
                styleType="pagination"
                className={`${val === currentPage ? 'active' : ''}`}
                onClick={() => onChange(Number(val))}
              >
                {val}
              </Button>
            ) : (
              <p className="pagination-bar__three-dots">{val}</p>
            )}
          </li>
        ))}
      </ul>
      <Button
        size="icon"
        styleType="icon"
        icon={<VectorArrowIcon />}
        iconPosition="right"
        rotateIcon="right"
        onClick={() => onChange(currentPage + 1)}
        disabled={currentPage === totalPages}
        className="pagination-bar__change-page-button"
      >
        {t('Buttons.next')}
      </Button>
    </div>
  );
};
export default PaginationBar;
