import {
  QueryClient,
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';

import { MutationVariables } from 'providers/TanstackQueryProvider/utils/defaultMutationFn';

export default function <TData, TError = Response, TVars = MutationVariables>(
  options?: UseMutationOptions<TData, TError, TVars>,
  queryClient?: QueryClient,
): UseMutationResult<TData, TError, TVars, unknown> {
  const response = useMutation<TData, TError, TVars, unknown>(
    options,
    queryClient,
  );

  return response;
}
