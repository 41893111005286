import classNames from 'classnames';
import { FormApi } from 'final-form';
import React, { useState } from 'react';
import { Form } from 'react-final-form';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

import Button from 'components/Button';
import InputField from 'components/InputField';
import LoadingButton from 'components/LoadingButton';
import { API_ENDPOINTS } from 'config/endpoints';
import useTanstackMutation from 'hooks/useTanstackMutation';
import EditIcon from 'icons/Edit.icon';
import EmailIcon from 'icons/Email.icon';
import UserIcon from 'icons/User.icon';
import UserAvatarIcon from 'icons/UserAvatar.icon';
import authService from 'services/authService';
import credentialsService from 'services/credentialsService';
import { shouldDisableFormSubmit } from 'utils';
import CardLayout from '../CardLayout';

import { useQueryClient } from '@tanstack/react-query';
import './PersonalInformationCard.styles.responsive.scss';
import './PersonalInformationCard.styles.scss';

type EditFormInput = {
  firstName: string;
  lastName: string;
};

type PersonalInformationCardProps = {
  className?: string;
  initialData: EditFormInput & { email: string };
  onSuccess?: () => Promise<void>;
};

const PersonalInformationCard: React.FC<PersonalInformationCardProps> = (
  props,
) => {
  const { className, initialData, onSuccess } = props;

  const classes = classNames('personal-information-card', className);

  const { t } = useTranslation();

  const [isEditing, setIsEditing] = useState(false);

  const queryClient = useQueryClient();

  const toggleEditing = () => {
    setIsEditing((old) => !old);
  };

  const { mutate, isPending } = useTanstackMutation({
    onSuccess: async () => {
      const { user } = credentialsService;
      toast.success(t('Messages.successfullyUpdatedPersonalInformation'));
      queryClient.refetchQueries({
        queryKey: [`${API_ENDPOINTS.BOOKKEEPERS}/${user._id}`],
      });
      queryClient.invalidateQueries({
        queryKey: [`${API_ENDPOINTS.CLIENT_MEMBERS}/${user._id}`],
      });

      await onSuccess?.();
    },
    onError: () => {
      toast.error(t('Messages.failedUpdatingPersonalInformation'));
    },
    onSettled: () => {
      setIsEditing(false);
    },
  });

  const handleSubmit = async (
    data: EditFormInput,
    methods: FormApi<EditFormInput, Partial<EditFormInput>>,
  ) => {
    const payload: EditFormInput = {
      firstName: data.firstName,
      lastName: data.lastName,
    };

    const { user } = credentialsService;
    const memberId =
      authService.checkRolesForUser(user, ['client']) && user._id;

    const body = payload;
    const method = 'PATCH';
    const path = !memberId
      ? API_ENDPOINTS.BOOKKEEPERS
      : `${API_ENDPOINTS.CLIENT_MEMBERS}/${memberId}`;

    mutate(
      {
        body,
        method,
        path,
      },
      {
        onError: () => methods.reset(),
      },
    );
  };

  return (
    <CardLayout
      className={classes}
      title={t('Register.personalInformation')}
      titleIcon={<UserAvatarIcon />}
      subtitle={t('Profile.updateYourPersonalInformation')}
    >
      <Form<EditFormInput>
        onSubmit={handleSubmit}
        initialValues={initialData}
        render={({ handleSubmit, ...methods }) => {
          const isDisabled =
            !methods.submitFailed && shouldDisableFormSubmit(methods);

          return (
            <form
              onSubmit={handleSubmit}
              className="personal-information-card__form"
            >
              {!isEditing && (
                <Button
                  styleType="transparent"
                  icon={<EditIcon />}
                  iconPosition="left"
                  className="personal-information-card__form__edit-trigger"
                  type="button"
                  onClick={toggleEditing}
                />
              )}
              <div className="personal-information-card__form__row">
                <InputField
                  name="firstName"
                  title={t('Register.firstName')}
                  titleicon={<UserIcon />}
                  className={classNames(
                    'personal-information-card__form__row__field',
                    !isEditing &&
                      'personal-information-card__form__row__field--disabled',
                  )}
                  disabled={!isEditing}
                />
                <InputField
                  name="lastName"
                  title={t('Register.lastName')}
                  titleicon={<UserIcon />}
                  className={classNames(
                    'personal-information-card__form__row__field',
                    !isEditing &&
                      'personal-information-card__form__row__field--disabled',
                  )}
                  disabled={!isEditing}
                />
              </div>

              <div className="personal-information-card__form__row">
                <InputField
                  name="email"
                  title={t('Register.email')}
                  titleicon={<EmailIcon />}
                  className="personal-information-card__form__row__field personal-information-card__form__row__field--disabled"
                  disabled
                />
              </div>
              <div className="personal-information-card__form__row">
                {isEditing && (
                  <div className="personal-information-card__form__buttons">
                    <LoadingButton
                      type="submit"
                      styleType="solid"
                      className="personal-information-card__form__buttons__button"
                      isLoading={isPending}
                      disabled={isDisabled}
                    >
                      {t('Buttons.saveChanges')}
                    </LoadingButton>
                    <Button
                      type="button"
                      styleType="solid-bright"
                      className="personal-information-card__form__buttons__button"
                      onClick={toggleEditing}
                    >
                      {t('Buttons.cancel')}
                    </Button>
                  </div>
                )}
              </div>
            </form>
          );
        }}
      />
    </CardLayout>
  );
};

export default PersonalInformationCard;
