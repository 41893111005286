import classNames from 'classnames';
import { LottieOptions, useLottie } from 'lottie-react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import clientHandshake from 'assets/client-handshake.json';

import Button from 'components/Button';
import EmptyStateLayout from 'components/EmptyStateLayout';
import './EmptyDashboardBody.styles.responsive.scss';
import './EmptyDashboardBody.styles.scss';

type EmptyDashboardBodyProps = {
  className?: string;
  onClick?: () => void;
};

const EmptyDashboardBody: React.FC<EmptyDashboardBodyProps> = (props) => {
  const { className, onClick } = props;

  const classes = classNames('empty-dashboard-body', className);

  const { t } = useTranslation();

  const options: LottieOptions = {
    animationData: clientHandshake,
    loop: false,
    className: 'empty-dashboard-body__icon',
  };

  const { View } = useLottie(options);

  return (
    <EmptyStateLayout
      className={classes}
      title={t('Dashboard.toCreateAndTrackPaymentsAddAClientFirst')}
      description={t('Dashboard.createAClientFirst')}
    >
      <EmptyStateLayout.Title />
      <EmptyStateLayout.Description />
      <EmptyStateLayout.Content>{View}</EmptyStateLayout.Content>
      <EmptyStateLayout.Footer>
        <Button
          className="empty-dashboard-body__button"
          size="cta"
          onClick={onClick}
        >
          {t('Buttons.createNewClient')}
        </Button>
      </EmptyStateLayout.Footer>
    </EmptyStateLayout>
  );
};

export default EmptyDashboardBody;
