import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { API_ENDPOINTS } from 'config/endpoints';
import {
  ClientWithProjectsInformation,
  ClientWithProjectStatus,
} from 'models/User';

type Client = ClientWithProjectsInformation & ClientWithProjectStatus;

const useCurrentClient = (options?: UseQueryOptions<Client>) => {
  return useQuery<Client>({
    queryKey: [`${API_ENDPOINTS.CURRENT_CLIENT}`],
    ...options,
  });
};

export default useCurrentClient;
