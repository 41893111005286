import React from 'react';

type RecoveryKeyProps = {
  className?: string;
};

const RecoveryKey: React.FC<RecoveryKeyProps> = (props) => {
  const { className } = props;
  return (
    <svg
      width="261"
      height="116"
      viewBox="0 0 261 116"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M177 56.4805H211.887V61.7426H253.633"
        stroke="#E7E8FF"
        strokeWidth="4"
      />
      <path
        d="M5.61328 57.5332H92.5004M54.3754 108.751V74.3719H78.5004"
        stroke="#E7E8FF"
        strokeWidth="4"
      />
      <path
        d="M43.8516 6.66602V38.2386H103.501"
        stroke="#E7E8FF"
        strokeWidth="4"
      />
      <path
        d="M188 37.537H211.887V6.66602C211.887 6.66602 226.621 6.66602 234.689 6.66602"
        stroke="#E7E8FF"
        strokeWidth="4"
      />
      <path d="M162 74.0195H212.94V94.7171" stroke="#E7E8FF" strokeWidth="4" />
      <circle cx="44.2008" cy="5.6129" r="5.6129" fill="#E7E8FF" />
      <circle cx="5.6129" cy="56.8297" r="5.6129" fill="#E7E8FF" />
      <circle cx="53.3219" cy="110.154" r="5.6129" fill="#E7E8FF" />
      <circle cx="212.589" cy="95.4195" r="5.6129" fill="#E7E8FF" />
      <circle cx="255.386" cy="61.7418" r="5.6129" fill="#E7E8FF" />
      <circle cx="237.144" cy="7.01525" r="5.6129" fill="#E7E8FF" />
      <g filter="url(#filter0_d_1_6085)">
        <rect
          x="140.324"
          y="36.2285"
          width="13"
          height="15"
          transform="rotate(41.5578 140.324 36.2285)"
          fill="black"
        />
      </g>
      <g filter="url(#filter1_d_1_6085)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M160.216 29.1859C160.05 26.4294 157.681 24.3293 154.924 24.4951L139.951 25.3957C137.195 25.5615 135.095 27.9305 135.261 30.6869L136.161 45.6598C136.327 48.4163 138.696 50.5164 141.453 50.3506L156.425 49.45C159.182 49.2842 161.282 46.9152 161.116 44.1588L160.216 29.1859ZM147.178 38.2955C149.038 39.9442 151.882 39.7731 153.531 37.9134C155.179 36.0537 155.008 33.2096 153.149 31.5609C151.289 29.9123 148.445 30.0833 146.796 31.943C145.147 33.8027 145.319 36.6468 147.178 38.2955Z"
          fill="black"
        />
      </g>
      <g filter="url(#filter2_d_1_6085)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M137.509 42.42C139.158 40.5603 142.002 40.3892 143.861 42.0379C145.721 43.6866 145.892 46.5307 144.244 48.3904L133.299 60.7363L131.053 58.7456L129.726 60.2422L131.972 62.2329L130.646 63.7289L128.4 61.7378L127.073 63.2344L129.319 65.2255L127.992 66.7226L125.746 64.7319L124.419 66.2285L126.665 68.2192L125.339 69.7152L123.093 67.7241L121.766 69.2207L124.012 71.2118L118.04 77.9477L112.683 77.2074L111.306 71.9774L137.509 42.42Z"
          fill="black"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_1_6085"
          x="123.373"
          y="29.2285"
          width="33.6797"
          height="33.8486"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="3.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.29 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1_6085"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1_6085"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d_1_6085"
          x="128.252"
          y="17.4863"
          width="39.873"
          height="39.873"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="3.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.29 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1_6085"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1_6085"
            result="shape"
          />
        </filter>
        <filter
          id="filter2_d_1_6085"
          x="104.305"
          y="33.9053"
          width="48.0723"
          height="51.042"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="3.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.29 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1_6085"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1_6085"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default RecoveryKey;
