import classNames from 'classnames';
import React from 'react';

import './DataDisplayRow.styles.responsive.scss';
import './DataDisplayRow.styles.scss';

export type DataDisplayRowProps = {
  className?: string;
  title: string;
  data?: string | number;
};

const DataDisplayRow: React.FC<DataDisplayRowProps> = (props) => {
  const { className, data, title } = props;

  const classes = classNames('data-display-row', className);

  if (!data) return null;

  return (
    <p className={classes}>
      <span className="data-display-row__title">{title}: </span>
      <span className="data-display-row__data">{data}</span>
    </p>
  );
};

export default DataDisplayRow;
