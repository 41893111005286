import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import LoadingSpinner from 'components/LoadingSpinner';
import { useLogout } from './Logout.hooks';

import './Logout.styles.responsive.scss';
import './Logout.styles.scss';

type LogoutProps = {
  className?: string;
};

const Logout: React.FC<LogoutProps> = (props) => {
  const { className } = props;

  const classes = classNames('logout', className);

  const { t } = useTranslation();

  const { time, isPending } = useLogout();

  return (
    <div className={classes}>
      <div className="logout__card">
        <p>{t('Index.youAreTryingToAccessForbiddenResource')}</p>
        <p>{t('Index.youWillBeLoggedOutIn', { time })}</p>
        <div className="logout__card__spinner">
          {isPending && <LoadingSpinner />}
        </div>
      </div>
    </div>
  );
};

export default Logout;
