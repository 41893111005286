import React from 'react';

const BankIcon: React.FC = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      href="http://www.w3.org/1999/xlink"
    >
      <rect width="18" height="18" fill="url(#pattern0_69_6791)" />
      <defs>
        <pattern
          id="pattern0_69_6791"
          patternContentUnits="objectBoundingBox"
          width="1"
          height="1"
        >
          <use href="#image0_69_6791" transform="scale(0.02)" />
        </pattern>
        <image
          id="image0_69_6791"
          width="50"
          height="50"
          href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAAB1klEQVR4nO2ZPUoDQRhAnyJGEVGs/K9sLNTKxp9GvIKQA4hHMB5BQaNFGq/gFWzEREQhnXiABJJGsJAUEnVkwhTLslkmu7O7s2EefMUuszPzZr8vmUzA4cgdo0AReFdRVPdyxSFQB4Qv3oAjYATL2QEeAgT88QwcYCEbwJ2GgD/ugW0sYF0J/EWQ8AttZSGwCtwCPzEFvPGrFmUtDYEFoAJ8a0xMtjkFFlWUBniuosYyzhxwDnQGWGE5cT+lAZ7vqDHl2LGZUoN/RkiVoBWdj9DPlxKaiSIwDpwA7Rg5L9PJz1KM/j7Uok7qCIwBx0DDQPEGpdaZgX4bao5yrn2pGRjIW7SlCMUuNKMaJiJyFn0ZGpGqBZMTmvEYJuKwnSfNTwvddq8B7V4C2tUD2sl7kdEtsqzaaeNEcG+kh0utfrgawdVID1cjadWIPIGcJgNEVt/ENosUgAugaWgb31SHEoW0RcqGBIQvLtMWiXMiI0KiRcp0ExLp5klk2XfyryUS9CMpTpgQ8TKrK2L61ZsQWfH0s5lnERES3aRTKjMRkbKI/L9RshvxWtgisqfu70e8FraIJJZatWERSZqktihtUuYmIZFy2iITwLXa5JkQaAFXql8HtvEP0xmXR9BDggUAAAAASUVORK5CYII="
        />
      </defs>
    </svg>
  );
};

export default BankIcon;
