import classNames from 'classnames';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Button from 'components/Button';
import LoadingButton from 'components/LoadingButton';
import { LoadingButtonProps } from 'components/LoadingButton/LoadingButton.component';
import Modal from 'components/Modal';
import { ModalProps } from 'components/Modal/Modal.component';
import TextArea from 'components/TextArea';

import './CommentModal.styles.responsive.scss';
import './CommentModal.styles.scss';

type CommentModalProps = {
  className?: string;
  buttonConfig: LoadingButtonProps;
  onClick: (message: string) => void;
} & ModalProps;

const CommentModal: React.FC<CommentModalProps> = (props) => {
  const { className, buttonConfig, onClick, ...modalConfig } = props;

  const [message, setMessage] = useState('');

  const { t } = useTranslation();

  const classes = classNames('comment-modal', className);

  return (
    <Modal disableCloseButton className={classes} {...modalConfig}>
      <TextArea
        className="comment-modal__text-area"
        maxLength={300}
        value={message}
        onChange={(e) => setMessage(e.target.value)}
      />
      <div className="comment-modal__buttons">
        <Button onClick={() => modalConfig.closeModal()} styleType="solid-gray">
          {t('Buttons.cancel')}
        </Button>
        <LoadingButton
          className="comment-modal__buttons__submit"
          onClick={() => onClick(message)}
          disabled={!message || message.trim() === ''}
          {...buttonConfig}
        />
      </div>
    </Modal>
  );
};

export default CommentModal;
