import React from 'react';
import { Field, FieldRenderProps, UseFieldConfig } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import RadioGroup, {
  RadioButtonOptionType,
  RadioGroupProps,
} from 'components/RadioGroup/RadioGroup.component';

import './RadioGroupField.styles.responsive.scss';
import './RadioGroupField.styles.scss';

type RadioGroupFieldProps = Omit<
  RadioGroupProps,
  'groupName' | 'onChange' | 'value'
> &
  UseFieldConfig<RadioButtonOptionType, RadioButtonOptionType>;

type RadioGroupFieldComponentProps = Omit<RadioGroupProps, 'groupName'> &
  FieldRenderProps<RadioButtonOptionType, HTMLElement>;

const RadioGroupFieldComponent: React.FC<RadioGroupFieldComponentProps> = (
  props,
) => {
  const {
    input: { onChange, value, name },
    meta,
    ...rest
  } = props;

  const { t } = useTranslation();

  const error = (!meta.dirtySinceLastSubmit && meta.submitError) || meta.error;

  const translatedError = meta.touched && t(error);

  return (
    <RadioGroup
      name={name}
      value={value}
      onChange={onChange}
      error={translatedError}
      {...rest}
    />
  );
};

const RadioGroupField: React.FC<RadioGroupFieldProps> = (props) => (
  <Field component={RadioGroupFieldComponent} {...props} />
);

export default RadioGroupField;
