import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';

import { API_ENDPOINTS } from 'config/endpoints';
import { SEARCH_PARAMS } from 'config/searchParams';
import useSearchParams from 'hooks/useSearchParams';
import { Project } from 'models/Project';
import { Client } from 'models/User';

const useFilteredData = () => {
  const [selectedClient, setSelectedClient] = useState<Client>(undefined);
  const [selectedProject, setSelectedProject] = useState<Project>(undefined);

  const { getSearchParam, setSearchParam, removeSearchParam } =
    useSearchParams();

  const clientId = getSearchParam(SEARCH_PARAMS.CLIENT_ID);
  const projectId = getSearchParam(SEARCH_PARAMS.PROJECT_ID);

  useEffect(() => {
    if (!clientId) setSelectedClient(undefined);
    if (!projectId) setSelectedProject(undefined);
  }, [clientId, projectId]);

  const enableClientQuery =
    !projectId &&
    !!clientId &&
    (!selectedClient || clientId !== selectedClient._id);
  const { data: clientData } = useQuery<Client>({
    queryKey: [`${API_ENDPOINTS.CLIENTS}/${clientId}`],
    enabled: enableClientQuery,
  });

  const enableProjectQuery =
    !!projectId && (!selectedProject || projectId !== selectedProject._id);
  const { data: projectData } = useQuery<Project>({
    queryKey: [
      `${API_ENDPOINTS.PROJECTS}/${projectId}`,
      { includeClient: true },
    ],
    enabled: enableProjectQuery,
  });

  useEffect(() => {
    if (clientData) setSelectedClient(clientData);
  }, [clientData]);

  useEffect(() => {
    if (projectData) {
      setSelectedClient(projectData.clients[0]);
      setSelectedProject(projectData);
    }
  }, [projectData]);

  const clearFilters = () => {
    removeSearchParam(SEARCH_PARAMS.CLIENT_ID);
    removeSearchParam(SEARCH_PARAMS.PROJECT_ID);
    setSelectedProject(undefined);
    setSelectedClient(undefined);
  };

  const onClientSelect = (data?: Client) => {
    if (data) {
      setSelectedClient(data);
      setSearchParam(SEARCH_PARAMS.CLIENT_ID, data._id);
    } else {
      clearFilters();
    }
  };
  const onProjectSelect = (data?: Project) => {
    data
      ? setSearchParam(SEARCH_PARAMS.PROJECT_ID, data._id)
      : removeSearchParam(SEARCH_PARAMS.PROJECT_ID);
    setSelectedProject(data);
  };

  return {
    projectId,
    clientId,
    onClientSelect,
    onProjectSelect,
    selectedClient,
    selectedProject,
    clearFilters,
  };
};

export default useFilteredData;
