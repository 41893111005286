import React from 'react';
import classNames from 'classnames';
import utils from 'utils';

import './ProgressCard.styles.scss';
import './ProgressCard.styles.responsive.scss';

type ProgressCardProps = {
  className?: string;
  totalItems: number;
  processedItems: number;
  status: string;
  itemsTitle: string;
};

const ProgressCard: React.FC<ProgressCardProps> = (props) => {
  const { className, processedItems, status, totalItems, itemsTitle } = props;

  const classes = classNames('progress-card', className);

  const { scale, scalePercentage } = utils.calculateScale(
    processedItems,
    totalItems,
  );

  return (
    <div className={classes}>
      <div className="progress-card__left">
        <div
          className="progress-card__left__scale"
          style={{ height: `${scalePercentage}%`, opacity: scale }}
        />
      </div>
      <div className="progress-card__right">
        <p className="progress-card__right__processed-items">
          {processedItems} {itemsTitle}
        </p>
        <small className="progress-card__right__status">{status}</small>
      </div>
    </div>
  );
};

export default ProgressCard;
