import { keepPreviousData, useQuery } from '@tanstack/react-query';
import classNames from 'classnames';
import Lottie from 'lottie-react';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import animationData from 'assets/projects.json';
import Header from 'components/Header';
import LoadingSpinner from 'components/LoadingSpinner';
import PreviewCard from 'components/PreviewCard';
import ProjectsCard from 'components/ProjectsCard';
import ProjectsInfoList from 'components/ProjectsInfoList';
import SearchInput from 'components/SearchInput';
import SortProjects from 'components/Sort/components/SortProjects.component';
import { SortOrder } from 'components/Sort/Sort.component';
import { API_ENDPOINTS } from 'config/endpoints';
import { SEARCH_PARAMS } from 'config/searchParams';
import { DEFAULT_PAGE } from 'constant';
import useDebounce from 'hooks/useDebounce';
import useSearchParams from 'hooks/useSearchParams';
import ArrowIcon from 'icons/Arrow.icon';
import OpenFolderIcon from 'icons/OpenFolder.icon';
import { Project } from 'models/Project';
import { PaginationResponse } from 'models/Response';
import useCurrentClient from 'queries/CurrentClient';
import credentialsService from 'services/credentialsService';

import EmptyStateLayout from 'components/EmptyStateLayout';
import usePageParam from 'hooks/usePageParam';
import './Projects.styles.responsive.scss';
import './Projects.styles.scss';

type ProjectsProps = {
  className?: string;
};

const Projects: React.FC<ProjectsProps> = (props) => {
  const { className } = props;

  const classes = classNames('projects', className);

  const { t } = useTranslation();

  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search, 300);

  const { getSearchParam } = useSearchParams();
  const { page, setPage } = usePageParam();
  const order =
    getSearchParam(SEARCH_PARAMS.ORDER) === SortOrder.Ascending ? '1' : '-1';
  const sortBy = getSearchParam(SEARCH_PARAMS.SORT_BY) || 'lastPayment';
  const clientId = credentialsService.user?.clientId;

  const {
    data: projects,
    isFetching,
    isFetched,
    isLoading,
  } = useQuery<PaginationResponse<Project>>({
    queryKey: [
      `${API_ENDPOINTS.PROJECTS}`,
      {
        page,
        clientId,
        size: 8,
        ...(debouncedSearch ? { searchBy: `name:${debouncedSearch}` } : {}),
        sortBy: `${sortBy}:${order}`,
      },
    ],
    enabled: !!page && !!clientId,
    placeholderData: keepPreviousData,
  });

  const { data: clientInfo, isFetching: isFetchingInfo } = useCurrentClient();

  const newTotalPages = useRef(0);
  if (projects && newTotalPages.current !== projects?.totalPages) {
    newTotalPages.current = projects?.totalPages;
  }

  const arrowClasses = useMemo(
    () =>
      classNames('projects__arrow', {
        'projects__arrow--up': clientInfo?.percentageDifference[0] !== '-',
        'projects__arrow--down': clientInfo?.percentageDifference[0] === '-',
      }),
    [clientInfo],
  );

  const projectsInfoConfig = useMemo(
    () => [
      {
        data: clientInfo?.numberProjects,
        text: t('Projects.projectsInTotal', {
          count: clientInfo?.numberProjects,
        }).toLowerCase(),
      },
      {
        data: clientInfo?.numberProjectsInCurrentYear,
        text: t('Projects.projectsInYear', {
          count: clientInfo?.numberProjectsInCurrentYear,
          year: 2024,
        }).toLowerCase(),
      },
      {
        data: clientInfo?.numberCreditors,
        text: t('Projects.creditor', {
          count: clientInfo?.numberCreditors,
        }).toLowerCase(),
      },
      {
        data: `${clientInfo?.amountPreviousMonth.toLocaleString()} RSD`,
        text: t('Projects.paidInPreviousMonth').toLowerCase(),
      },
      {
        data: `${clientInfo?.amountCurrentMonth.toLocaleString()} RSD`,
        text: t('Projects.paidInCurrentMonth').toLowerCase(),
      },
      {
        data: clientInfo?.percentageDifference,
        text: t(
          `Projects.${
            clientInfo?.percentageDifference[0] === '-'
              ? 'decrease'
              : 'increase'
          }`,
        ).toLowerCase(),
        icon: <ArrowIcon className={arrowClasses} />,
        helperText: `(${t('Projects.comparedToThePreviousMonth')})`,
      },
    ],
    [clientInfo, t, arrowClasses],
  );

  useEffect(() => {
    setPage(DEFAULT_PAGE);
    // eslint-disable-next-line
  }, [debouncedSearch]);

  return (
    <div className={classes}>
      <Header
        title={t('Index.projects')}
        browser={
          <SearchInput
            value={search}
            onChange={setSearch}
            placeholder={t('Fields.search')}
            disabled={
              isFetched &&
              !debouncedSearch &&
              (!projects || projects.totalItems === 0)
            }
          />
        }
      />
      <div className="projects__body">
        {(projects && projects.totalItems) ||
        (isFetching && !isLoading) ||
        debouncedSearch ? (
          <>
            <ProjectsInfoList
              isLoading={isFetchingInfo}
              className="projects__body__info"
              projectsInfo={projectsInfoConfig}
            />
            <PreviewCard
              className="projects__body__card"
              title={t('Index.projects')}
              titleIconRight={<OpenFolderIcon />}
              button={<SortProjects className="projects__body__sort" />}
            >
              {isFetching || projects?.items ? (
                <ProjectsCard
                  projectsLength={8}
                  showSort={false}
                  projectsList={projects?.items || []}
                  paginationConfig={{
                    totalPages: projects?.totalPages || newTotalPages.current,
                    currentPage: page,
                    onChange: (val) =>
                      setPage(val, {
                        replace: true,
                      }),
                  }}
                  isLoading={isFetching}
                  emptyState={t('Messages.thereAreNoProjectsUnderThatName')}
                />
              ) : (
                <></>
              )}
            </PreviewCard>
          </>
        ) : (
          <div className="projects__body__empty-state">
            {!newTotalPages.current && !isFetched ? (
              <LoadingSpinner className="projects__body__empty-state__loading" />
            ) : (
              <EmptyStateLayout
                title={t('Messages.yourBookkeeperDidntAddAnyProjectsYet')}
              >
                <EmptyStateLayout.Title className="projects__body__empty-state__paragraph" />
                <EmptyStateLayout.Content>
                  <Lottie
                    className="projects__body__empty-state__animation"
                    animationData={animationData}
                    loop={false}
                  />
                </EmptyStateLayout.Content>
              </EmptyStateLayout>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Projects;
