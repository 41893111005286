import { useQueryClient } from '@tanstack/react-query';
import classNames from 'classnames';
import { FC } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

import Button from 'components/Button';
import Dropdown from 'components/Dropdown';
import LoadingButton from 'components/LoadingButton';
import { API_ENDPOINTS } from 'config/endpoints';
import useDisclosure from 'hooks/useDisclosure';
import useTanstackMutation from 'hooks/useTanstackMutation';
import ThreeDotsIcon from 'icons/ThreeDots.icon';
import { Account } from 'models/User';
import authService from 'services/authService';
import credentialsService from 'services/credentialsService';
import { INVITATION_STATUS } from 'types';
import AccountPreviewRemoveClientModal from './AccountPreviewRemoveClientModal.component';

type AccountPreviewDropdownProps = {
  account: Account;
};

const AccountPreviewDropdown: FC<AccountPreviewDropdownProps> = (props) => {
  const { account } = props;

  const { t } = useTranslation();

  const { isOpen, toggle } = useDisclosure();
  const queryClient = useQueryClient();

  const { isPending, mutate } = useTanstackMutation({
    onSuccess: () => {
      toast.success(t('Messages.userInvitedSuccesfully'));
      queryClient.invalidateQueries({
        queryKey: [API_ENDPOINTS.GET_MEMBERS_FOR_CLIENT(account.clientId)],
      });
    },
    onError: () => toast.error(t('Messages.errorInvitingUser')),
  });

  const inviteUser = () =>
    mutate({
      method: 'POST',
      path: API_ENDPOINTS.INVITE_CLIENT_MEMBER(account._id),
    });

  const { user } = credentialsService;
  if (authService.checkRolesForUser(user, ['client'])) {
    return null;
  }

  return (
    <Dropdown
      isOpen={isOpen}
      toggleOpenDropdown={toggle}
      matchParrentSize={false}
      trigger={
        <Button
          styleType="icon"
          icon={<ThreeDotsIcon className="account-preview__options__icon" />}
          className="account-preview__options"
        />
      }
    >
      {account.inviteStatus !== INVITATION_STATUS.ACCEPTED && (
        <LoadingButton
          onClick={inviteUser}
          isLoading={isPending}
          styleType="dropdown"
          className={classNames(
            'account-preview__options__button',
            'account-preview__options__button--send-again',
          )}
        >
          {t('Buttons.sendAgain')}
        </LoadingButton>
      )}
      <AccountPreviewRemoveClientModal account={account} />
    </Dropdown>
  );
};

export default AccountPreviewDropdown;
