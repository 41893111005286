import classNames from 'classnames';
import React from 'react';

import { SEARCH_PARAMS } from 'config/searchParams';
import useSearchParams from 'hooks/useSearchParams';
import { Creditor } from 'models/User';

import Button from 'components/Button';
import { useTranslation } from 'react-i18next';
import './CreditorsTable.styles.responsive.scss';
import './CreditorsTable.styles.scss';

export type CreditorsTableProps = {
  className?: string;
  creditorsList?: Creditor[];
  headers: Array<CreditorTableHeader>;
};

export type CreditorTableHeader = {
  heading?: string;
  value: keyof CreditorInfo;
};

export type CreditorInfo = {
  name: string;
  address: string;
  zipCode?: string;
  bankAccount: string;
  numberOfPaymentSlips: number;
  button?: React.ReactNode;
};

type TableRowProps = {
  className?: string;
  creditor: CreditorInfo;
  headers: Array<CreditorTableHeader>;
  onClick?: () => void;
};

const TableHead = ({ header }: { header: Record<string, string> }) => {
  return <th>{header.heading}</th>;
};

const TableRow = (props: TableRowProps) => {
  const { creditor, headers, className, onClick } = props;

  return (
    <tr className={className} onClick={onClick}>
      {headers.map((header: CreditorTableHeader, index: number) => {
        return <td key={index}>{creditor[`${header.value}`]}</td>;
      })}
    </tr>
  );
};

const CreditorsTable: React.FC<CreditorsTableProps> = (props) => {
  const { className, headers, creditorsList } = props;

  const classes = classNames('creditors-table', className);

  const { setSearchParam } = useSearchParams();

  const { t } = useTranslation();

  return (
    <table className={classes}>
      <thead>
        <tr>
          {headers.map((header: Record<string, string>, index: number) => (
            <TableHead header={header} key={index} />
          ))}
        </tr>
      </thead>
      <tbody>
        {creditorsList.map((creditor: Creditor, index: number) => {
          const {
            name,
            address: { zipCode, name: address },
            bankAccount,
            numberOfPaymentSlips,
          } = creditor;

          return (
            <TableRow
              className="creditors-table__row"
              creditor={{
                name,
                address,
                zipCode,
                bankAccount,
                numberOfPaymentSlips,
                button: (
                  <Button
                    styleType="solid-gray"
                    size="table-small"
                    className="creditors-table__row__button"
                    onClick={() =>
                      setSearchParam(SEARCH_PARAMS.CREDITOR_ID, creditor._id)
                    }
                  >
                    {t('Index.details')}
                  </Button>
                ),
              }}
              key={index}
              headers={headers}
            />
          );
        })}
      </tbody>
    </table>
  );
};

export default CreditorsTable;
