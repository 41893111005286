const auth = '/auth';
const bookkeepers = '/book-keepers';
const clients = '/clients';
const creditors = '/creditors';
const current = '/current';
const copy = '/copy';
const exists = '/exists';
const logout = '/logout';
const projects = '/projects';
const refresh = '/refresh';
const paymentSlips = '/payment-slips';
const passwordReset = '/password-reset';
const purposes = '/purposes';
const forgotPassword = `/forgot-password`;
const clientMembers = '/client-members';
const invitation = '/invitation';
const notify = '/notify';
const status = '/status';
const bulk = '/bulk';
const xml = '/xml';
const register = '/register';

const bookkeepersClients = `${bookkeepers}${clients}`;

export const API_ENDPOINTS = {
  AUTH: auth,
  BOOKKEEPERS: bookkeepers,
  BOOKKEEPERS_CLIENTS: bookkeepersClients,
  BOOKKEEPER_EXISTS: `${bookkeepers}${exists}`,
  CLIENTS: clients,
  CURRENT_CLIENT: `${clients}${current}`,
  CLIENT_INVITATION: (clientId: string) => `${clients}/${clientId}/invitation`,
  CLIENT_MEMBERS: clientMembers,
  CREDITORS: creditors,

  INVITE_CLIENT_MEMBER: (memberId: string) =>
    `${clientMembers}/${memberId}${invitation}`,

  GET_CREDITORS_FOR_CLIENT: (clientId: string) =>
    `${clients}/${clientId}${creditors}`,
  GET_MEMBERS_FOR_CLIENT: (clientId: string) =>
    `${clients}/${clientId}${clientMembers}`,
  GET_PAYMENT_PURPOSE_SUGGESTIONS: (clientId: string) =>
    `${clients}/${clientId}${paymentSlips}${purposes}`,
  GET_XML_PAYMENT_SLIPS: (projectId: string) =>
    `${projects}/${projectId}${paymentSlips}${xml}`,

  PAYMENT_SLIPS: paymentSlips,
  PASSWORD_RESET: `${auth}${passwordReset}`,

  NOTIFY_CLIENT: (clientId: string) => `${clients}/${clientId}${notify}`,
  NOTIFY_BOOKKEEPER: (bookkeeperId: string) =>
    `${bookkeepers}/${bookkeeperId}${notify}`,

  FORGOT_PASSWORD: `${auth}${forgotPassword}`,

  UPDATE_PAYMENT_SLIPS_STATUSES: (projectId: string) =>
    `${projects}/${projectId}${paymentSlips}${status}`,
  UPDATE_PAYMENT_SLIPS_REF_MODEL: (projectId: string) =>
    `${projects}/${projectId}${paymentSlips}${bulk}`,

  LOGOUT: `${auth}${logout}`,
  PROJECTS: projects,
  PROJECT_COPY: `${projects}${copy}`,
  REFRESH_TOKEN: `${auth}${refresh}`,
  REGISTER_CLIENT_MEMBER: (token: string) =>
    `${clientMembers}${register}/${token}`,
};
