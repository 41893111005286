import { useQueryClient } from '@tanstack/react-query';
import classNames from 'classnames';
import React from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

import { DeleteModal } from 'components/DeleteModal';
import { API_ENDPOINTS } from 'config/endpoints';
import BackgroundSquares from 'icons/BackgroundSquares.icon';
import PaymentsIcon from 'icons/Payments.icon';
import { Payment } from 'models/Payment';

import './DeletePaymentModal.styles.responsive.scss';
import './DeletePaymentModal.styles.scss';

type DeletePaymentModalProps = {
  className?: string;
  payment: Payment;
  onSuccess?: () => void;
  close: () => void;
};

const DeletePaymentModal: React.FC<DeletePaymentModalProps> = (props) => {
  const { className, payment, onSuccess, close } = props;

  const classes = classNames('delete-payment-modal', className);

  const { t } = useTranslation();

  const queryClient = useQueryClient();

  return (
    <DeleteModal
      className={classes}
      closeModal={close}
      heading={t('Index.deletePayment')}
      deletingObjectName={payment.purpose}
      path={`${API_ENDPOINTS.PAYMENT_SLIPS}/${payment.id}`}
      middleIcon={
        <div className="paymentcard__modal--delete__wrapper">
          <BackgroundSquares className="paymentcard__modal--delete__wrapper__background" />
          <PaymentsIcon className="paymentcard__modal--delete__wrapper__icon" />
        </div>
      }
      onSuccess={() => {
        toast.success(t('Messages.successfullyDeletedPaymentSlip'));
        queryClient.invalidateQueries({
          queryKey: [API_ENDPOINTS.PAYMENT_SLIPS],
        });
        queryClient.invalidateQueries({
          queryKey: [`${API_ENDPOINTS.PROJECTS}/${payment.projectId}`],
        });
        onSuccess?.();
        close();
      }}
      onError={() => {
        toast.error(t('Messages.errorDeletingPaymentSlip'));
      }}
    />
  );
};

export default DeletePaymentModal;
