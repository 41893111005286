import { ChartData, ChartOptions } from 'chart.js';

export const getGradients = () => {
  const ctx = document.createElement('canvas').getContext('2d');

  const gradientSpeeding = ctx?.createLinearGradient(0, 0, 0, 400);
  gradientSpeeding?.addColorStop(0, 'rgba(0, 9, 194, 0.5)');
  gradientSpeeding?.addColorStop(0.5, 'rgba(0, 9, 194, 0.025)');
  gradientSpeeding?.addColorStop(1, 'rgba(0, 9, 194, 0)');

  return { gradientSpeeding };
};

export const options: ChartOptions<'line'> = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
  },
  scales: {
    y: {
      min: 0,
      max: 1200,
      ticks: {
        stepSize: 300,
      },
    },
  },
  maintainAspectRatio: true,
  layout: {
    autoPadding: true,
  },
};

const labels = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
];

export const data: ChartData<'line'> = {
  labels,
  datasets: [
    {
      label: 'Neto plate 2022',
      data: [0, 400, 500, 400, 720, 900, 900, 800, 800, 900],
      borderColor: '#0009c2',
      fill: 'origin',
      backgroundColor: getGradients().gradientSpeeding,
      tension: 0.3,
      pointRadius: 6,
      pointBackgroundColor: 'white',
      borderWidth: 2,
      pointBorderWidth: 2,
      pointHoverBackgroundColor: '#0009c2',
      pointHoverRadius: 6,
      clip: 10,
    },
  ],
};
