import classNames from 'classnames';
import React, { useEffect, useState } from 'react';

import './CheckBox.styles.scss';

export type CheckBoxProps = {
  className?: string;
  label?: string;
  error?: boolean;
  value?: boolean;
  onChange?: (value: boolean) => void;
} & Omit<
  React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >,
  'onChange' | 'value'
>;

const CheckBox: React.FC<CheckBoxProps> = (props) => {
  const { className, label, error, onChange, value = false, ...rest } = props;

  const [checked, setChecked] = useState(value);

  useEffect(() => {
    setChecked(value);
  }, [value]);

  const classes = classNames(
    'is-checkbox',
    { 'is-checkbox--checked': checked },
    { 'is-checkbox--error': error },
    className,
  );

  return (
    <label className={classes}>
      <span className="is-checkbox__button" />
      <input
        hidden
        checked={checked}
        type="checkbox"
        {...rest}
        onChange={(ev) => {
          setChecked(ev.target.checked);
          onChange?.(ev.target.checked);
        }}
      />
      {label && <p className="is-checkbox__label">{label}</p>}
    </label>
  );
};

export default CheckBox;
