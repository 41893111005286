import { SelectOptionType } from 'components/Select/Select.component';
import { DateTime } from 'luxon';
import { calculateChecksum, calculateReferenceNumberValue } from 'utils';
import { ModelRegExp } from './regularExpression';

export const VALID: unknown = undefined;

export const validateRegex =
  (errorMessage: string, regexp: RegExp) => (value: string) =>
    regexp.test(value) ? undefined : errorMessage;

export const validateRequired = (errorMessage: string) => (value: string) => {
  const trimmedValue = typeof value === 'string' ? value?.trim() : value;

  if (trimmedValue) return VALID;

  return errorMessage ?? '';
};

export const validateModel = (value: string) => {
  if (!value || value.length === 0) return VALID;

  if (ModelRegExp.test(value)) return VALID;

  if (value.length !== 2) return 'Form.modelInvalidLength';

  return 'Form.modelInvalidFormat';
};

export const validateBankAccountChecksum =
  (errorMessage: string) => (value: string) => {
    const bankAccount = value.split('-').join('');

    const bankNumber = bankAccount.slice(0, 3);
    const bankAccountNumber = bankAccount.slice(3, -2);
    const checksum = bankAccount.slice(-2);

    const paddedBankAccountNumber = bankAccountNumber.padStart(13, '0');

    const calculatedChecksum = calculateChecksum(
      bankNumber + paddedBankAccountNumber,
    );

    if (Number(checksum) !== Number(calculatedChecksum))
      return errorMessage ?? '';

    return VALID;
  };

export const validateBankAccountLength =
  (errorMessage: string) => (value: string) => {
    const bankAccount = value.split('-').join('');

    if (bankAccount.length <= 18) return VALID;

    return errorMessage ?? '';
  };

export const validateRequiredObject =
  (errorMessage: string) => (value: any) => {
    if (value) return VALID;

    return errorMessage ?? '';
  };

export const validateRequiredSelect =
  (errorMessage: string) => (value: SelectOptionType) => {
    if (value?.value) return VALID;

    return errorMessage ?? '';
  };

export const validateSearchRequired =
  (errorMessage: string) =>
  (value: { value: string; key: string } | string) => {
    if (value) return VALID;

    return errorMessage ?? '';
  };

export const validateRequiredDateTime =
  (errorMessage: string, isChecked = false) =>
  (value: DateTime) => {
    if (value?.isValid) return VALID;

    if (isChecked) return VALID;

    return errorMessage ?? '';
  };
export const validateMinDateTime =
  (errorMessage: string, minDate: DateTime) => (value: DateTime) => {
    if (!value) return VALID;

    if (value.toISODate() >= minDate.toISODate()) return VALID;
    return errorMessage ?? '';
  };

export const validatePasswordRepeat =
  (errorMessage: string) => (value: string, allValues: any) => {
    return value !== allValues.password ? errorMessage : undefined;
  };

export const composeValidators =
  (...validators: Array<any>) =>
  (value: any, allValues: Record<string, any>) => {
    return validators.reduce((errorMessage, validator) => {
      return errorMessage || validator(value, allValues);
    }, undefined);
  };

export const validateDirtyNoBlankSpace =
  (initialValue: string, errorMessage: string) => (value: string) => {
    if (!initialValue) return VALID;

    const trimmedValue = typeof value === 'string' ? value?.trim() : value;

    if (initialValue === trimmedValue) return errorMessage ?? '';

    return VALID;
  };

export const validateReferenceNumber = (model?: string) => (value: string) => {
  if (!value && model !== '97') return VALID;

  if (!value) return 'Form.required';

  if (!RegExp(/^[\sA-Za-z0-9-]+$/).test(value))
    return 'Form.referenceNumberInvalid';

  const referenceNumber = calculateReferenceNumberValue(value);

  if (model === '97' && referenceNumber.length < 3)
    return 'Form.referenceNumberTooShort';
  if (referenceNumber.length > 20) return 'Form.referenceNumberTooLong';

  if (model !== '97') return VALID;

  const checksum = calculateChecksum(referenceNumber.slice(2));
  if (checksum !== value.slice(0, 2))
    return 'Form.referenceNumberInvalidFormat';

  return VALID;
};
