import { useQuery } from '@tanstack/react-query';
import classNames from 'classnames';
import Lottie from 'lottie-react';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import animationData from 'assets/projects.json';
import BackLink from 'components/BackLink';
import CreditorInfoCard from 'components/CreditorInfoCard';
import DataDisplayRow from 'components/DataDisplayRow';
import LoadingSpinner from 'components/LoadingSpinner';
import PaymentTable from 'components/PaymentTable';
import SkeletonLoader from 'components/SkeletonLoader';
import { API_ENDPOINTS } from 'config/endpoints';
import { SEARCH_PARAMS } from 'config/searchParams';
import { defaultPaymentsHeader } from 'config/tables';
import useSearchParams from 'hooks/useSearchParams';
import { Payment, PAYMENT_STATUS } from 'models/Payment';
import { PaginationResponse } from 'models/Response';
import { Creditor } from 'models/User';
import PaymentsListLoading from 'router/subrouters/PaymentsList/PaymentsListLoading.component';

import authService from 'services/authService';
import credentialsService from 'services/credentialsService';
import './CreditorDetails.styles.responsive.scss';
import './CreditorDetails.styles.scss';

type CreditorDetailsProps = {
  className?: string;
  creditorId: string;
  clientId: string;
};

const CreditorDetails: React.FC<CreditorDetailsProps> = (props) => {
  const { className, creditorId, clientId } = props;

  const classes = classNames('creditor-details', className);

  const { t } = useTranslation();

  const { data, isFetching } = useQuery<Creditor>({
    queryKey: [`${API_ENDPOINTS.CREDITORS}/${creditorId}`],
  });

  const { data: payments, isFetching: isPaymentsFetching } = useQuery<
    PaginationResponse<Payment>
  >({
    queryKey: [API_ENDPOINTS.PAYMENT_SLIPS, { creditorId }],
  });

  const { removeSearchParam } = useSearchParams();

  const amountToBePaid = useMemo(
    () =>
      payments?.items.reduce((acc, curr) => acc + curr.price.amount, 0) ||
      undefined,
    [payments],
  );
  const totalPaidAmount = useMemo(
    () =>
      payments?.items
        .filter((p) => p.status === PAYMENT_STATUS.PROCESSED)
        .reduce((acc, curr) => acc + curr.price.amount, 0) || 0,
    [payments],
  );

  const isClient = authService.checkRolesForUser(credentialsService.user, [
    'client',
  ]);

  if (isFetching)
    return (
      <div className="creditor-details--fetching">
        <LoadingSpinner />
      </div>
    );

  return (
    <div className={classes}>
      <BackLink
        onClick={() =>
          removeSearchParam(SEARCH_PARAMS.CREDITOR_ID, { replace: true })
        }
      />
      <CreditorInfoCard
        creditor={data}
        clientId={clientId}
        isFetching={isFetching}
      />
      <div className="creditor-details__table-wrapper">
        {isPaymentsFetching ? (
          <>
            <SkeletonLoader className="creditor-details__table-wrapper__loading__title" />
            <SkeletonLoader className="creditor-details__table-wrapper__loading__info" />
            <PaymentsListLoading />
          </>
        ) : payments?.items.length ? (
          <>
            <h3 className="creditor-details__table-wrapper__history">
              {t('Index.historyOfPaymentSlips')}
            </h3>
            <section className="creditor-details__table-wrapper__payments-info">
              <DataDisplayRow
                title={t('Index.numberOfPayments')}
                data={payments?.totalItems}
              />
              <DataDisplayRow
                title={t('Index.totalPaid')}
                data={totalPaidAmount?.toLocaleString()}
              />
              <DataDisplayRow
                title={t('Index.totalToPay')}
                data={amountToBePaid?.toLocaleString()}
              />
            </section>
            <div className="creditor-details__table-wrapper__table">
              <PaymentTable
                linkTo
                headers={defaultPaymentsHeader(t, isClient)}
                paymentsList={payments?.items || []}
              />
            </div>
          </>
        ) : (
          <div className="creditor-details__empty-payments">
            <h3 className="creditor-details__empty-payments__title">
              {t('Index.historyOfPaymentSlipsEmpty')}
            </h3>
            <Lottie
              className="creditor-details__empty-payments__animation"
              animationData={animationData}
              loop={false}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default CreditorDetails;
