import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import EmailIcon from 'icons/Email.icon';
import PhoneIcon from 'icons/Phone.icon';
import { Account } from 'models/User';
import { INVITATION_STATUS } from 'types';
import AccountPreviewDropdown from './components/AccountPreviewDropdown.component';

import './AccountPreview.styles.responsive.scss';
import './AccountPreview.styles.scss';

type AccountPreviewProps = {
  className?: string;
  account: Account;
};

const AccountPreview: React.FC<AccountPreviewProps> = (props) => {
  const { className, account } = props;

  const classes = classNames(
    'account-preview',
    {
      'account-preview--pending': [
        INVITATION_STATUS.PENDING,
        INVITATION_STATUS.NOT_INVITED,
      ].includes(account.inviteStatus),
    },
    className,
  );
  const { t } = useTranslation();

  return (
    <div className={classes}>
      <div className="account-preview__name">
        {account.firstName} {account.lastName}
        <AccountPreviewDropdown account={account} />
      </div>
      <div className="account-preview__row">
        <PhoneIcon />
        {account.phone}
      </div>
      <div className="account-preview__row">
        <EmailIcon />
        {account.email}
      </div>
      <span className="account-preview__row">
        {account.inviteStatus === INVITATION_STATUS.PENDING &&
          `${t('Index.pendingInvite')}...`}
        {account.inviteStatus === INVITATION_STATUS.NOT_INVITED &&
          `${t('Index.notInvited')}`}
      </span>
    </div>
  );
};

export default AccountPreview;
