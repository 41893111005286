import React from 'react';
import { Field, FieldRenderProps, UseFieldConfig } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import Select, {
  SelectOptionType,
  SelectProps,
} from 'components/Select/Select.component';

type SelectFieldProps = { name: string } & SelectProps &
  UseFieldConfig<SelectOptionType, SelectOptionType>;

type SelectFieldComponentProps = SelectProps &
  FieldRenderProps<SelectOptionType, HTMLElement>;

const SelectFieldComponent: React.FC<SelectFieldComponentProps> = (props) => {
  const {
    input: { onChange, value },
    meta,
    ...rest
  } = props;
  const { t } = useTranslation();

  const error = (!meta.dirtySinceLastSubmit && meta.submitError) || meta.error;

  const translatedError = meta.touched && t(error);

  return (
    <Select
      {...rest}
      onChange={onChange}
      value={value}
      error={translatedError}
    />
  );
};

const SelectField: React.FC<SelectFieldProps> = (props) => (
  <Field component={SelectFieldComponent} {...props} />
);

export default SelectField;
