import classNames from 'classnames';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import EmptyProjectCard from 'components/EmptyProjectCard';
import { APP_ROUTES } from 'config/routes';
import { SEARCH_PARAMS } from 'config/searchParams';

import Button from 'components/Button';
import PlusIcon from 'icons/Plus.icon';
import { useTranslation } from 'react-i18next';
import './EmptyProjectState.styles.responsive.scss';
import './EmptyProjectState.styles.scss';

type EmptyProjectStateProps = {
  className?: string;
  projectId?: string;
  isList?: boolean;
};

const EmptyProjectState: React.FC<EmptyProjectStateProps> = (props) => {
  const { className, projectId, isList = false } = props;

  const classes = classNames(
    'empty-project-state',
    { 'empty-project-state--list-view': isList },
    className,
  );

  const { t } = useTranslation();

  const navigate = useNavigate();

  const createPaymentParams = projectId
    ? `?${SEARCH_PARAMS.PROJECT_ID}=${projectId}`
    : '';

  const URL = `${APP_ROUTES.CREATE_PAYMENT}${createPaymentParams}`;

  return (
    <div className={classes}>
      <EmptyProjectCard
        onClick={isList ? undefined : () => navigate(URL)}
        isList={isList}
      />
      {isList && (
        <Button
          className="empty-project-state__button"
          icon={<PlusIcon />}
          size="cta"
          onClick={() => navigate(URL)}
        >
          {t('Buttons.addNewPaymentSlip')}
        </Button>
      )}
      {Array.from({ length: 11 }, (_, i) => i).map((val) => (
        <EmptyProjectCard key={val} isList={isList} />
      ))}
    </div>
  );
};

export default EmptyProjectState;
