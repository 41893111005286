import classNames from 'classnames';
import React from 'react';
import { Outlet } from 'react-router-dom';

import './Payments.styles.responsive.scss';
import './Payments.styles.scss';

type PaymentsProps = {
  className?: string;
};

const Payments: React.FC<PaymentsProps> = (props) => {
  const { className } = props;

  const classes = classNames('payments', className);

  return (
    <div className={classes}>
      <Outlet />
    </div>
  );
};

export default Payments;
