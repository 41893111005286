import classNames from 'classnames';
import React, { PropsWithChildren } from 'react';

import './Toggle.styles.responsive.scss';
import './Toggle.styles.scss';

export type ToggleProps = {
  className?: string;
  icon?: React.ReactNode;
  isToggled: boolean;
  onToggle: (toggleState: boolean) => void;
  disabled?: boolean;
} & PropsWithChildren;

const Toggle: React.FC<ToggleProps> = (props) => {
  const {
    className,
    icon,
    onToggle,
    isToggled,
    disabled = false,
    children,
  } = props;

  const classes = classNames(
    'toggle',
    { 'toggle--toggled': isToggled },
    { 'toggle--disabled': disabled },
    className,
  );

  return (
    <div className={classes} onClick={() => !disabled && onToggle(!isToggled)}>
      {icon}
      {children}
    </div>
  );
};

export default Toggle;
