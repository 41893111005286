import classNames from 'classnames';
import React, { PropsWithChildren } from 'react';

import LanguagePicker from 'components/LanguagePicker';
import SidebarLogoMax from 'icons/LogoMax.icon';

import './AuthWrapper.styles.responsive.scss';
import './AuthWrapper.styles.scss';

type AuthWrapperProps = {
  className?: string;
  title: string;
} & PropsWithChildren;

const AuthWrapper: React.FC<AuthWrapperProps> = (props) => {
  const { className, title, children } = props;

  const classes = classNames('auth-wrapper', className);

  return (
    <div className={classes}>
      <LanguagePicker className="is-language-picker--absolute is-language-picker--top-right" />
      <div className="auth-wrapper__header">
        <div className="is-logo-extended">
          <SidebarLogoMax fillColor="#252D56" />
        </div>
      </div>
      <div className="auth-wrapper__content">
        <div className="auth-wrapper__content__description">
          <div className="auth-wrapper__illustration" />
          <p>{title}</p>
        </div>
        {children}
      </div>
      <div className="auth-wrapper__footer">
        <p className="is-copyright">@Copyright instructpay</p>
      </div>
    </div>
  );
};

export default AuthWrapper;
