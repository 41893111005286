import React from 'react';

type DoubleChevronIconProps = {
  right?: boolean;
  className?: string;
};

const DoubleChevronIcon: React.FC<DoubleChevronIconProps> = ({
  right = false,
  className,
}) => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="5 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ ...(right ? { transform: 'rotateZ(180deg)' } : {}) }}
      className={className}
    >
      <path
        d="M26.2812 7.5L27.625 8.84375L21.4375 15.0312L27.625 21.2187L26.2813 22.5625L18.75 15.0312L26.2812 7.5Z"
        fill="black"
      />
      <path
        d="M18.2812 7.5L19.625 8.84375L13.4375 15.0312L19.625 21.2187L18.2813 22.5625L10.75 15.0312L18.2812 7.5Z"
        fill="black"
      />
    </svg>
  );
};

export default DoubleChevronIcon;
