import { Navigate } from 'react-router-dom';

import { EnhancedRouteProps } from 'router/types';
import RegisterAccountInformation from './RegisterAccountInformation';
import RegisterPersonalInformation from './RegisterPersonalInformation';
import RegisterSecurityInformation from './RegisterSecurityInformation';
import { RegisterSuccesfully } from './RegisterSuccessfully';
export default [
  {
    path: 'personal-information',
    element: RegisterPersonalInformation,
    onlyPublic: true,
  },
  {
    path: 'account-information',
    element: RegisterAccountInformation,
    onlyPublic: true,
    authorized: false,
  },
  {
    path: 'security-information',
    element: RegisterSecurityInformation,
    onlyPublic: true,
  },
  {
    path: 'register-success',
    element: RegisterSuccesfully,
    onlyPublic: true,
  },
  {
    path: '',
    element: () => <Navigate to="/" replace />,
  },
] as Array<EnhancedRouteProps>;
