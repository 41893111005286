import classNames from 'classnames';
import CheckBox from 'components/CheckBox';
import EmptyProjectState from 'components/EmptyProjectState';
import PaymentTable from 'components/PaymentTable';
import { HeaderTable } from 'components/PaymentTable/PaymentTable.component';
import { Payment, PaymentSelectingMode, SelectedPayment } from 'models/Payment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import authService from 'services/authService';
import credentialsService from 'services/credentialsService';
import PaymentsListLoading from './PaymentsListLoading.component';
import PaymentsNoData from './PaymentsNoData.component';

export type PaymentsListViewProps = {
  className?: string;
  data?: Payment[];
  isEmpty: boolean;
  isLoading: boolean;
  projectId: string;
  paymentSelectingMode?: PaymentSelectingMode;
  selected?: SelectedPayment[];
  onSelect?: (selectedPayment: SelectedPayment, newValue: boolean) => void;
  selectAll?: (select: boolean) => void;
  areAllSelected?: boolean;
};

const headers = (
  t: (message: string, options?: { count: number }) => string,
  isProcessing: boolean,
  selectAll: (select: boolean) => void,
  areAllSelected: boolean,
  isClient: boolean,
): Array<HeaderTable> => [
  ...(isProcessing
    ? ([
        {
          heading: (
            <CheckBox
              value={areAllSelected}
              onChange={selectAll}
              className="project__body__controls__checkbox"
            />
          ),
          value: 'checkbox',
        },
      ] as const)
    : []),
  { heading: t('Dashboard.payer'), value: 'payer' },
  { heading: t('Dashboard.payee'), value: 'payee' },
  { heading: t('Fields.bankAccount'), value: 'payeesAccount' },
  ...(isClient
    ? ([{ heading: t('Fields.purpose'), value: 'purpose' }] as const)
    : []),
  ...(isClient
    ? ([{ heading: t('Fields.paymentCode'), value: 'paymentCode' }] as const)
    : []),
  { heading: t('Dashboard.amount'), value: 'amount' },
  { heading: t('Fields.model'), value: 'model' },
  { heading: t('Fields.referenceNumber'), value: 'referenceNumber' },
  { value: 'button' },
];

const PaymentsListView: React.FC<PaymentsListViewProps> = (props) => {
  const {
    className,
    isEmpty,
    isLoading,
    data,
    projectId,
    paymentSelectingMode,
    selected,
    areAllSelected,
    selectAll,
    onSelect,
  } = props;

  const classes = classNames(className, 'payments-list__body--list__table');

  const { t } = useTranslation();

  const isProcessing = paymentSelectingMode !== PaymentSelectingMode.None;
  const isClient = authService.checkRolesForUser(credentialsService.user, [
    'client',
  ]);

  return isLoading ? (
    <PaymentsListLoading />
  ) : isEmpty ? (
    <EmptyProjectState projectId={projectId} isList />
  ) : data.length ? (
    <div className="payments-list__body--list">
      <PaymentTable
        headers={headers(t, isProcessing, selectAll, areAllSelected, isClient)}
        paymentsList={data}
        classNameTable={classes}
        paymentSelectingMode={paymentSelectingMode}
        checked={selected}
        onCheck={onSelect}
        hideButtons={paymentSelectingMode !== PaymentSelectingMode.None}
      />
    </div>
  ) : (
    <PaymentsNoData />
  );
};

export default PaymentsListView;
