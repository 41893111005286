import { ConfigObject } from 'mapping';
import { Client } from 'models/User';

export const generateDisplayConfigObject = (
  t: (message: string) => string,
): ConfigObject<Partial<Client>> => ({
  vatNumber: {
    index: 0,
    property: 'vatNumber',
    mapper: (val) => [{ title: t('Register.vatNumber'), data: val }],
  },
  bankAccount: {
    index: 1,
    property: 'bankAccount',
    mapper: (val) => [{ title: t('Register.bankAccount'), data: val }],
  },
  address: {
    index: 2,
    property: 'address',
    mapper: (val) => [
      { title: t('Register.address'), data: `${val.name}, ${val.zipCode}` },
    ],
  },
});
