import BulkPaymentFormModal from 'components/BulkPaymentFormModal';
import { PaymentSelectingMode } from 'models/Payment';
import React, { useContext } from 'react';
import ProjectContext from '../provider/Project.context';

const ProjectBulkPayment: React.FC = () => {
  const {
    refetchPayments,
    setPaymentSelectingMode,
    isOpen,
    close,
    projectData,
    selected,
  } = useContext(ProjectContext);

  return (
    isOpen && (
      <BulkPaymentFormModal
        modalConfig={{ closeModal: close }}
        onSuccess={() => {
          close();
          refetchPayments();
          setPaymentSelectingMode(PaymentSelectingMode.None);
        }}
        onCancel={close}
        projectId={projectData?._id}
        paymentSlipsIds={selected?.map((p) => p.id)}
      />
    )
  );
};

export default ProjectBulkPayment;
