import React from 'react';

const PasswordIcon: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20">
      <path d="M5.833 11.792q-.729 0-1.26-.532-.531-.531-.531-1.26t.531-1.26q.531-.532 1.26-.532t1.261.532q.531.531.531 1.26t-.531 1.26q-.532.532-1.261.532Zm0 3.354q-2.145 0-3.645-1.5T.688 10q0-2.146 1.5-3.646t3.645-1.5q1.459 0 2.636.698t1.843 1.802h7.23L20.188 10l-3.855 3.854-1.666-1.25L13 13.833l-1.729-1.187h-.959q-.687 1.125-1.854 1.812-1.166.688-2.625.688Zm0-1.896q1.188 0 2.052-.688.865-.687 1.157-1.833h2.875l1.041.792 1.73-1.292 1.458 1.125 1.437-1.375-.729-.708H9.042q-.271-1.125-1.146-1.823-.875-.698-2.063-.698-1.333 0-2.291.958-.959.959-.959 2.292 0 1.354.948 2.302t2.302.948Z" />
    </svg>
  );
};

export default PasswordIcon;
