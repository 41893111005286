import classNames from 'classnames';
import React, { useEffect, useState } from 'react';

import RadioButton from 'components/RadioButton';
import { RadioButtonProps } from 'components/RadioButton/RadioButton.component';

import './RadioGroup.styles.responsive.scss';
import './RadioGroup.styles.scss';

export type RadioButtonOptionType = {
  id: string;
  label: string;
};

export type RadioGroupProps = {
  className?: string;
  direction?: 'vertical' | 'horizontal';
  name: string;
  options: RadioButtonOptionType[];
  onChange: (value: string) => void;
  value: RadioButtonProps['value'];
  preselectFirst?: boolean;
  error?: string;
} & Omit<RadioButtonProps, 'id' | 'name' | 'label'>;

const RadioGroup: React.FC<RadioGroupProps> = (props) => {
  const {
    className,
    direction = 'horizontal',
    name,
    options,
    onChange,
    value,
    preselectFirst = true,
    error,
    ...rest
  } = props;

  const classes = classNames(
    'radio-group',
    {
      'radio-group--vertical': direction === 'vertical',
      'radio-group--horizontal': direction === 'horizontal',
      'radio-group--error': !!error,
    },
    className,
  );

  const [internalValue, setInternalValue] = useState(value || '');

  const handleChange = (targetValue: string) => {
    setInternalValue(targetValue);
    onChange(targetValue);
  };

  useEffect(() => {
    if (!options.find((opt) => opt.id === value)) {
      preselectFirst && handleChange(options[0].id);
      return;
    }

    if (value !== internalValue) {
      handleChange(value);
    }
    // eslint-disable-next-line
  }, [value]);

  useEffect(() => {
    if (!value && preselectFirst) handleChange(options[0].id);
    // eslint-disable-next-line
  }, []);

  return (
    <div className={classes}>
      {options.map((option) => (
        <RadioButton
          id={option.id}
          key={option.id}
          label={option.label}
          name={name}
          value={internalValue}
          onChange={handleChange}
          {...rest}
        />
      ))}
    </div>
  );
};

export default RadioGroup;
