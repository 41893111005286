import classNames from 'classnames';
import React, { ReactNode, useCallback, useEffect, useState } from 'react';

import Link from 'components/Link';
import useCallbackRef from 'hooks/useCallbackRef';
import ArrowIcon from 'icons/Arrow.icon';

import { useTranslation } from 'react-i18next';
import './ClientPreview.style.scss';

export type ClientPreviewProps = {
  className?: string;
  name: string;
  subtext: string;
  subtextIcon?: ReactNode;
  numOfItems: number;
  itemsText: string;
  itemsIcon: ReactNode;
  onClick?: () => void;
  linkTo?: string;
  selected?: boolean;
};

const ClientPreview: React.FC<ClientPreviewProps> = (props) => {
  const {
    className,
    name,
    subtext,
    numOfItems,
    itemsText,
    itemsIcon,
    subtextIcon,
    onClick,
    linkTo,
    selected = false,
  } = props;

  const { t } = useTranslation();

  const [showFullName, setShowFullName] = useState(false);
  const [nameRef, triggerRef] = useCallbackRef();

  const isEllipsisActive = useCallback((e: Record<string, any>) => {
    return e.offsetWidth < e.scrollWidth;
  }, []);

  useEffect(() => {
    if (!nameRef) return;
    setShowFullName(isEllipsisActive(nameRef));
  }, [isEllipsisActive, nameRef, showFullName]);

  const clientPreviewClasses = classNames(
    'client-preview',
    {
      'client-preview--selected': selected,
      'client-preview--clickable': !!onClick,
    },
    className,
  );
  const nameClasses = classNames('client-preview__info__basic__name', {
    'client-preview__info__basic__name--show': showFullName,
  });

  return (
    <section className={clientPreviewClasses} onClick={onClick}>
      <div className="client-preview__info">
        <div className="client-preview__info__basic">
          <p ref={triggerRef} className={nameClasses}>
            {name}
          </p>
          <span className="client-preview__info__basic__border"></span>
          <p className="client-preview__info__basic__projects">
            {itemsIcon}
            {numOfItems} <span>{itemsText}</span>
          </p>
        </div>
        {linkTo && (
          <Link
            to={linkTo}
            className="client-preview__link"
            onClick={(e) => e.stopPropagation()}
            variant="button"
          >
            {t('Buttons.goToClient')}
            <ArrowIcon className="client-preview__link__icon" />
          </Link>
        )}
      </div>
      <p className="client-preview__subtext">
        {subtextIcon} {subtext}
      </p>
    </section>
  );
};

export default ClientPreview;
