import classNames from 'classnames';
import { DateTime } from 'luxon';
import React, { useState } from 'react';
import { Form } from 'react-final-form';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

import Button from 'components/Button';
import CheckBox from 'components/CheckBox';
import DataDisplayRow from 'components/DataDisplayRow';
import InputField from 'components/InputField';
import LoadingButton from 'components/LoadingButton';
import Modal from 'components/Modal';
import ProcessDateField from 'components/ProcessDateField';
import ToolTip from 'components/ToolTip';
import { API_ENDPOINTS } from 'config/endpoints';
import useDisclosure from 'hooks/useDisclosure';
import useTanstackMutation from 'hooks/useTanstackMutation';
import HelpIcon from 'icons/Help.icon';
import {
  validateMinDateTime,
  validateModel,
  validateReferenceNumber,
} from 'validations/validations';

import './BulkPaymentForm.styles.responsive.scss';
import './BulkPaymentForm.styles.scss';

type FormInputs = {
  model?: string;
  referenceNumber?: string;
  processmentDate?: DateTime;
  instant?: boolean;
};

export type BulkPaymentFormProps = {
  className?: string;
  paymentSlipsIds: string[];
  projectId: string;
  onSuccess?: () => void;
  onCancel?: () => void;
};

const BulkPaymentForm: React.FC<BulkPaymentFormProps> = (props) => {
  const { className, paymentSlipsIds, projectId, onSuccess, onCancel } = props;

  const classes = classNames('bulk-payment-form', className);

  const { t } = useTranslation();

  const { isOpen, open, close } = useDisclosure();

  const [editModelAndRef, setEditModelAndRef] = useState(false);

  const { mutate, isPending } = useTanstackMutation({
    onSuccess: () => {
      toast.success(t('Messages.paymentSlipsUpdatedSuccessfully'));
      onSuccess?.();
    },
    onError: () => {
      toast.error(t('Messages.errorUpdatingPaymentSlips'));
    },
  });

  const handleSubmit = (data: FormInputs) => {
    if (!isOpen) {
      open();
    }
  };

  const handleMutate = (data: FormInputs) => {
    const { instant, model, processmentDate, referenceNumber } = data;

    const body = {
      paymentSlipsIds,
      ...(editModelAndRef ? { referenceNumber: referenceNumber ?? '' } : {}),
      ...(editModelAndRef ? { model: model ?? '' } : {}),
      ...(instant ? { instant } : {}),
      ...(processmentDate
        ? { processmentDate: processmentDate.toFormat('yyyy.MM.dd') }
        : {}),
    };

    mutate({
      method: 'PATCH',
      path: API_ENDPOINTS.UPDATE_PAYMENT_SLIPS_REF_MODEL(projectId),
      body,
    });
  };

  return (
    <Form<FormInputs>
      onSubmit={handleSubmit}
      render={({ handleSubmit, values, form }) => {
        const disableNextButton =
          !editModelAndRef && !form.getFieldState('processmentDate')?.dirty;

        return (
          <form onSubmit={handleSubmit} className={classes}>
            <div className="bulk-payment-form__field-help">
              <CheckBox
                label={t('Index.editModelAndReferenceNumber')}
                value={editModelAndRef}
                onChange={setEditModelAndRef}
                className={classNames('bulk-payment-form__checkbox', {
                  'bulk-payment-form__checkbox--checked': editModelAndRef,
                })}
              />
              <ToolTip content={t('Messages.ifYouDontCheck')}>
                <HelpIcon />
              </ToolTip>
            </div>
            <div className="bulk-payment-form__fields">
              <InputField
                className="bulk-payment-form__fields__model"
                name="model"
                title={t('Fields.model')}
                variant="squared"
                tabIndex={isOpen ? -1 : 0}
                validate={validateModel}
                disabled={!editModelAndRef}
              />
              <InputField
                key={values.model}
                className="bulk-payment-form__fields__reference-number"
                name="referenceNumber"
                title={t('Fields.referenceNumber')}
                variant="squared"
                validate={validateReferenceNumber(values.model)}
                autoComplete="off"
                tabIndex={isOpen ? -1 : 0}
                disabled={!editModelAndRef}
              />
            </div>
            <div className="bulk-payment-form__separator" />
            <div className="bulk-payment-form__field-help">
              <ToolTip content={t('Messages.ifYouDontEnterChanges')}>
                <HelpIcon />
              </ToolTip>
            </div>
            <ProcessDateField
              isChecked={values.instant}
              validate={validateMinDateTime('Form.dateInvalid', DateTime.now())}
              onDateChange={(value) =>
                form.change('processmentDate', value as DateTime)
              }
            />
            <footer className="bulk-payment-form__buttons">
              <Button
                styleType="solid-gray"
                type="button"
                onClick={onCancel}
                className="bulk-payment-form__buttons__button"
                tabIndex={isOpen ? -1 : 0}
              >
                {t('Buttons.cancel')}
              </Button>
              <ToolTip
                content={t(
                  'Messages.youMustSelectModelAndReferenceNumberOrChangeProcessingDate',
                )}
                disabled={!disableNextButton}
              >
                <Button
                  className="bulk-payment-form__buttons__button"
                  type="submit"
                  styleType="solid"
                  iconPosition="right"
                  tabIndex={isOpen ? -1 : 0}
                  disabled={disableNextButton}
                >
                  {t('Buttons.next')}
                </Button>
              </ToolTip>
            </footer>
            {isOpen && (
              <Modal
                disableCloseButton
                heading={t('Index.confirmChanges')}
                subtitle={t(
                  'Messages.areYouSureYouWantToSavePaymentSlipsWithTheseChanges',
                )}
                closeModal={close}
                className="bulk-payment-form__confirmation-modal"
              >
                <DataDisplayRow
                  title={t('Fields.model')}
                  data={values.model ?? (editModelAndRef && t('Clients.empty'))}
                />
                <DataDisplayRow
                  title={t('Fields.referenceNumber')}
                  data={
                    values.referenceNumber ??
                    (editModelAndRef && t('Clients.empty'))
                  }
                />
                <DataDisplayRow
                  title={t('Fields.processDate')}
                  data={
                    !values.instant &&
                    values.processmentDate?.toFormat('dd.MM.yyyy')
                  }
                />
                <DataDisplayRow
                  title={t('Fields.instantPayment')}
                  data={values.instant && t('Buttons.yes')}
                />
                <div className="bulk-payment-form__buttons">
                  <Button
                    styleType="solid-gray"
                    type="button"
                    onClick={close}
                    className="bulk-payment-form__buttons__button"
                  >
                    {t('Buttons.cancel')}
                  </Button>
                  <LoadingButton
                    autoFocus
                    isLoading={isPending}
                    className="bulk-payment-form__buttons__button"
                    type="button"
                    styleType="solid"
                    iconPosition="right"
                    onClick={() => handleMutate(values)}
                  >
                    {t('Buttons.submit')}
                  </LoadingButton>
                </div>
              </Modal>
            )}
          </form>
        );
      }}
    />
  );
};

export default BulkPaymentForm;
