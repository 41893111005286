import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import BulkPaymentForm, {
  BulkPaymentFormProps,
} from 'components/BulkPaymentForm/BulkPaymentForm.component';
import Modal from 'components/Modal';
import { ModalProps } from 'components/Modal/Modal.component';

import './BulkPaymentFormModal.styles.responsive.scss';
import './BulkPaymentFormModal.styles.scss';

type BulkPaymentFormModalProps = {
  className?: string;
  modalConfig: ModalProps;
} & BulkPaymentFormProps;

const BulkPaymentFormModal: React.FC<BulkPaymentFormModalProps> = (props) => {
  const { className, modalConfig, ...bulkFormProps } = props;

  const classes = classNames('bulk-payment-form-modal', className);

  const { t } = useTranslation();

  return (
    <Modal
      disableCloseButton
      heading={t('Index.editSelectedPaymentOrders')}
      subtitle={t(
        'Index.doYouWantToChangeTheDetailsOfTheSelectedPOSWithinTheProject?',
      )}
      className={classes}
      {...modalConfig}
    >
      <BulkPaymentForm {...bulkFormProps} />
    </Modal>
  );
};

export default BulkPaymentFormModal;
