import { useQuery } from '@tanstack/react-query';
import classNames from 'classnames';
import React from 'react';

import BackLink from 'components/BackLink';
import PaymentForm from 'components/PaymentForm';
import PaymentFormLoader from 'components/PaymentForm/PaymentFormLoader.component';
import { API_ENDPOINTS } from 'config/endpoints';
import { SEARCH_PARAMS } from 'config/searchParams';
import useSearchParams from 'hooks/useSearchParams';
import { ProjectWithClient } from 'models/Project';

import './CreatePayment.styles.responsive.scss';
import './CreatePayment.styles.scss';

type CreatePaymentProps = {
  className?: string;
};

const CreatePayment: React.FC<CreatePaymentProps> = (props) => {
  const { className } = props;

  const classes = classNames('create-payment', className);

  const { getSearchParam } = useSearchParams();

  const projectId = getSearchParam(SEARCH_PARAMS.PROJECT_ID);

  const { data: singleProjectData, isLoading } = useQuery<ProjectWithClient>({
    queryKey: [
      `${API_ENDPOINTS.PROJECTS}/${projectId}`,
      { includeClient: true },
    ],
    enabled: !!projectId,
  });

  return (
    <div className={classes}>
      <BackLink className="create-payment__back" />
      {!isLoading ? (
        <PaymentForm initialProject={singleProjectData} />
      ) : (
        <PaymentFormLoader />
      )}
    </div>
  );
};

export default CreatePayment;
