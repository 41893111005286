import { useQuery } from '@tanstack/react-query';
import { API_ENDPOINTS } from 'config/endpoints';
import { SEARCH_PARAMS } from 'config/searchParams';
import useDisclosure from 'hooks/useDisclosure';
import useSearchParams from 'hooks/useSearchParams';
import {
  PAYMENT_STATUS,
  PaymentSelectingMode,
  PaymentView,
} from 'models/Payment';
import { ProjectWithClient } from 'models/Project';
import useMembersForClients from 'queries/MembersForClient';
import React, { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import authService from 'services/authService';
import credentialsService from 'services/credentialsService';
import usePaymentSlips, { usePaymentsQueryParams } from '../Project.hooks';
import ProjectContext from './Project.context';

type ProjectProps = {
  children: React.ReactNode;
};

const ProjectDataProvider: React.FC<ProjectProps> = (props) => {
  const { children } = props;
  const [paymentSelectingMode, setPaymentSelectingMode] =
    useState<PaymentSelectingMode>(PaymentSelectingMode.None);

  const { id } = useParams();

  const { getSearchParam } = useSearchParams();

  const { isOpen, open, close } = useDisclosure();

  const selectedView: PaymentView =
    (getSearchParam(SEARCH_PARAMS.PAYMENT_VIEW) as PaymentView) || 'list';

  const {
    data: projectData,
    isFetching: isProjectFetching,
    isFetched,
    refetch,
  } = useQuery<ProjectWithClient>({
    queryKey: [`${API_ENDPOINTS.PROJECTS}/${id}`, { includeClient: true }],
  });

  const { data: members, isLoading: isMembersLoading } = useMembersForClients(
    projectData?.clientId,
    { enabled: !!projectData },
  );

  const [search, setSearch, queryParams] = usePaymentsQueryParams({ id });
  const {
    data: paymentSlipsData,
    isFetching: isPaymentSlipsFetching,
    isLoading: isPaymentSlipsLoading,
    refetchPayments,
    selected,
    unselected,
    newlySelected,
    areAllSelected,
    areNoneSelected,
    onSelect,
    selectAll,
    resetSelected,
  } = usePaymentSlips({
    queryParams,
    paymentSelectingMode,
  });

  const isEmptyClientStatePresent = !(
    (projectData && projectData.payments > 0) ||
    authService.checkRolesForUser(credentialsService.user, ['book_keeper'])
  );

  const hasErrors = useMemo(
    () =>
      paymentSlipsData?.items.some((p) => p.status === PAYMENT_STATUS.ERROR),
    [paymentSlipsData],
  );

  return (
    <ProjectContext.Provider
      value={{
        open,
        close,
        isOpen,
        search,
        isFetched,
        setSearch,
        queryParams,
        refetch,
        projectId: id,
        projectData,
        hasErrors,
        isDownloadPending: false,
        paymentSlipsData,
        isPaymentSlipsFetching,
        isPaymentSlipsLoading,
        isEmptyClientStatePresent,
        selected,
        unselected,
        newlySelected,
        areNoneSelected,
        areAllSelected,
        onSelect,
        selectAll,
        resetSelected,
        members,
        isMembersLoading,
        isProjectFetching,
        paymentSelectingMode,
        setPaymentSelectingMode,
        selectedView,
        refetchPayments,
      }}
    >
      {children}
    </ProjectContext.Provider>
  );
};

export default ProjectDataProvider;
