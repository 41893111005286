import classNames from 'classnames';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import Button from 'components/Button';
import LoadingButton from 'components/LoadingButton';
import Modal from 'components/Modal';
import useDisclosure from 'hooks/useDisclosure';
import DownloadIcon from 'icons/Download.icon';
import useDownloadPayments from 'queries/DownloadPayments';

import './DownloadPayments.styles.responsive.scss';
import './DownloadPayments.styles.scss';

type DownloadPaymentsProps = {
  className?: string;
  openModalCondition?: boolean;
  projectId: string;
};

const DownloadPayments: React.FC<DownloadPaymentsProps> = (props) => {
  const { className, openModalCondition, projectId } = props;

  const classes = classNames('download-payments', className);

  const { t } = useTranslation();
  const { isOpen, open, close } = useDisclosure();

  const { download, isFetching, data } = useDownloadPayments(projectId);

  const handleClick = () => {
    if (openModalCondition) {
      open();
    } else {
      download();
    }
  };

  useEffect(() => {
    if (data) close();
    // eslint-disable-next-line
  }, [data]);

  return (
    <div className={classes}>
      <LoadingButton
        styleType="solid-white"
        className="download-payments__button"
        icon={<DownloadIcon />}
        isLoading={isFetching}
        onClick={handleClick}
      />
      {isOpen && (
        <Modal
          disableCloseButton
          closeModal={close}
          heading={t('Index.downloadingWithError')}
          className="download-payments__modal"
        >
          <p className="download-payments__modal__text">
            {t('Messages.youHaveSomePaymentsThatHaveAnError')}
          </p>
          <div className="download-payments__modal__buttons">
            <Button styleType="solid-gray" onClick={close}>
              {t('Buttons.cancel')}
            </Button>
            <LoadingButton
              isLoading={isFetching}
              autoFocus
              styleType="solid"
              onClick={download}
            >
              {t('Buttons.proceed')}
            </LoadingButton>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default DownloadPayments;
