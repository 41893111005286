import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import ClientForm from 'components/Forms/ClientForm';
import { ClientFormProps } from 'components/Forms/ClientForm/ClientForm.component';
import EditIcon from 'icons/Edit.icon';
import { Client } from 'models/User';

import './ClientOverviewEdit.styles.responsive.scss';
import './ClientOverviewEdit.styles.scss';

type ClientOverviewEditProps = {
  className?: string;
  data: Client;
  formConfig: Omit<ClientFormProps, 'initialValues'>;
  hideTitle?: boolean;
};

const ClientOverviewEdit: React.FC<ClientOverviewEditProps> = (props) => {
  const { className, data, formConfig, hideTitle = false } = props;

  const classes = classNames('client-overview-edit', className);

  const { t } = useTranslation();

  return (
    <div className={classes}>
      {!hideTitle && (
        <h2 className="client-overview-edit__information">
          <EditIcon className="client-overview-edit__information__icon" />
          {t('Clients.editClientsInformation')}
        </h2>
      )}
      <ClientForm
        initialValues={data}
        className="client-overview-edit__form"
        {...formConfig}
      />
    </div>
  );
};

export default ClientOverviewEdit;
