import React from 'react';
import classNames from 'classnames';
import './CardLayout.styles.scss';
import './CardLayout.styles.responsive.scss';

type CardLayoutProps = {
  className?: string;
  children: React.ReactNode;
  title: string;
  titleIcon?: React.ReactNode;
  subtitle: string;
};

const CardLayout: React.FC<CardLayoutProps> = (props) => {
  const { className, children, subtitle, title, titleIcon } = props;

  const classes = classNames('card-layout', className);

  return (
    <section className={classes}>
      <div className="card-layout__heading">
        <p className="card-layout__heading__title">
          {title} {titleIcon}
        </p>
        <div className="card-layout__heading__subtitle">{subtitle}</div>
      </div>

      <div className="card-layout__content">
        <div className="card-layout__content__wrap">
          <div className="card-layout__content__wrap__sub-card">{children}</div>
        </div>
      </div>
    </section>
  );
};

export default CardLayout;
