import { useEffect, useState } from 'react';

/**
 * Custom hook to debounce a value.
 *
 * @param value - The input value to debounce.
 * @param delay - The debounce delay in milliseconds. Default is 100ms.
 * @returns The debounced value.
 */

export default function (value: string, delay = 100) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}
