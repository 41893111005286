import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import React from 'react';

import client from './config';

const isDevelopment = process.env.NODE_ENV === 'development';

export type KFQueryClientProviderProps = { children: React.ReactElement };

const { fetch: originalFetch } = window;

window.fetch = async (...args) => {
  const [resource, config] = args;

  const response = await originalFetch(resource, config);
  if (!response.ok && [401, 403].includes(response.status)) {
    const { message } = await response.json();
    console.error(message);

    return Promise.reject(response);
  }
  return Promise.resolve(response);
};

const KFQueryClientProvider: React.FC<KFQueryClientProviderProps> = ({
  children,
}) => (
  <QueryClientProvider client={client}>
    {children}
    {isDevelopment && <ReactQueryDevtools />}
  </QueryClientProvider>
);

export { KFQueryClientProvider };
