import React from 'react';
import { Field, FieldRenderProps, UseFieldConfig } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import Combobox, {
  ComboboxProps,
} from 'components/Combobox/Combobox.component';

import './ComboboxField.styles.responsive.scss';
import './ComboboxField.styles.scss';

type ComboboxFieldProps<T> = {
  name: string;
  onChange?: (data: T) => void;
} & Omit<ComboboxProps<T>, 'value' | 'onChange'> &
  UseFieldConfig<T, T>;

type ComboboxFieldComponentProps<T> = ComboboxFieldProps<T> &
  FieldRenderProps<T, HTMLElement>;

const ComboboxFieldComponent = <T extends Record<string, any>>(
  props: ComboboxFieldComponentProps<T>,
) => {
  const {
    input: { onChange: onFormChange, ...input },
    meta,
    onChange,
    ...restOfProps
  } = props;
  const { t } = useTranslation();

  const error = (!meta.dirtySinceLastSubmit && meta.submitError) || meta.error;

  const translatedError = meta.touched && t(error);

  const handleSelect = (values: T) => {
    onChange?.(values);
    onFormChange?.(values);
  };

  return (
    <Combobox
      onChange={handleSelect}
      error={translatedError}
      {...input}
      {...restOfProps}
    />
  );
};

const ComboboxField = <T,>(props: ComboboxFieldProps<T>) => (
  <Field component={ComboboxFieldComponent<T>} {...props} />
);

export default ComboboxField;
