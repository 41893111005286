import React from 'react';

type GridViewIconProps = {
  className?: string;
};

const GridViewIcon: React.FC<GridViewIconProps> = (props) => {
  const { className } = props;
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M12.5 0.5H1.5C0.947715 0.5 0.5 0.947715 0.5 1.5V12.5C0.5 13.0523 0.947715 13.5 1.5 13.5H12.5C13.0523 13.5 13.5 13.0523 13.5 12.5V1.5C13.5 0.947715 13.0523 0.5 12.5 0.5Z"
        stroke="#4D4D4D"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M0.5 3.5H13.5"
        stroke="#4D4D4D"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 3.5V13.5"
        stroke="#4D4D4D"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M0.5 8.5H13.5"
        stroke="#4D4D4D"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default GridViewIcon;
