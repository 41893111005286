import { useQueryClient } from '@tanstack/react-query';
import classNames from 'classnames';
import React from 'react';
import { Form } from 'react-final-form';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

import Button from 'components/Button';
import InputField from 'components/InputField';
import LoadingButton from 'components/LoadingButton';
import { API_ENDPOINTS } from 'config/endpoints';
import useTanstackMutation from 'hooks/useTanstackMutation';
import { Account } from 'models/User';
import utils from 'utils';
import {
  EmailRegExp,
  FirstNameRegExp,
  LastNameRegExp,
  PhoneRegExp,
} from 'validations/regularExpression';
import {
  composeValidators,
  validateRegex,
  validateRequired,
} from 'validations/validations';

import './AccountForm.styles.responsive.scss';
import './AccountForm.styles.scss';

type FormInputs = {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
};

type AccountFormProps = {
  className?: string;
  id: string;
  accountType: 'client' | 'bookkeeper';
  isSkippable?: boolean;
  onCancel?: () => void;
  onSuccess?: (account?: Account) => void;
};

const AccountForm: React.FC<AccountFormProps> = (props) => {
  const {
    className,
    onCancel,
    id,
    accountType,
    isSkippable = false,
    onSuccess,
  } = props;

  const classes = classNames('account-form', className);

  const { t } = useTranslation();

  const queryClient = useQueryClient();

  const { mutate, isPending } = useTanstackMutation({
    onSuccess: (data: Account) => {
      toast.success(t(`Messages.${accountType}AccountCreatedSuccessfully`));

      queryClient.invalidateQueries({
        queryKey: [`${API_ENDPOINTS.GET_MEMBERS_FOR_CLIENT(id)}`],
      });

      onSuccess?.(data);
    },
    onError: () => {
      toast.error(
        t(
          `Messages.errorCreating${utils.capitalizeFirstLetter(
            accountType,
          )}Account`,
        ),
      );
    },
  });

  const onSubmit = (data: FormInputs) => {
    const body = {
      ...data,
      ...(accountType === 'client' ? { clientId: id } : { bookkeeperId: id }),
    };
    const method = 'POST';
    const path = API_ENDPOINTS.CLIENT_MEMBERS;

    mutate({
      body,
      method,
      path,
    });
  };

  return (
    <Form<FormInputs>
      onSubmit={onSubmit}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit} className={classes}>
          <div className="account-form__row">
            <InputField
              name="firstName"
              title={t('Register.firstName')}
              validate={composeValidators(
                validateRequired('Form.required'),
                validateRegex('Form.firstNameNotValid', FirstNameRegExp),
              )}
            />
            <InputField
              name="lastName"
              title={t('Register.lastName')}
              validate={composeValidators(
                validateRequired('Form.required'),
                validateRegex('Form.lastNameNotValid', LastNameRegExp),
              )}
            />
          </div>
          <InputField
            name="email"
            title={t('Register.email')}
            validate={composeValidators(
              validateRequired('Form.required'),
              validateRegex('Form.invalidEmail', EmailRegExp),
            )}
          />
          <InputField
            name="phone"
            title={t('Register.phone')}
            validate={composeValidators(
              validateRequired('Form.required'),
              validateRegex('Form.phoneNumberInvalid', PhoneRegExp),
            )}
          />
          <div className="account-form__footer">
            {isSkippable && (
              <Button
                className="account-form__footer__button"
                styleType="solid-gray"
                type="button"
                onClick={onCancel}
                size="large"
              >
                {t('Buttons.skip')}
              </Button>
            )}
            <LoadingButton
              styleType="solid-black"
              className="account-form__footer__button"
              isLoading={isPending}
              size="large"
            >
              {t('Buttons.addUser')}
            </LoadingButton>
          </div>
        </form>
      )}
    />
  );
};

export default AccountForm;
