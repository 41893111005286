import classNames from 'classnames';
import Button from 'components/Button';
import DataDisplayRow from 'components/DataDisplayRow';
import { APP_ROUTES } from 'config/routes';
import { SEARCH_PARAMS } from 'config/searchParams';
import PlusIcon from 'icons/Plus.icon';
import { PaymentSelectingMode } from 'models/Payment';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import authService from 'services/authService';
import credentialsService from 'services/credentialsService';
import ProjectContext from '../provider/Project.context';

const ProjectFooter: React.FC = () => {
  const {
    projectId: id,
    paymentSlipsData,
    paymentSelectingMode,
    selected,
    projectData,
    isProjectFetching,
    isPaymentSlipsFetching,
  } = useContext(ProjectContext);

  const { t } = useTranslation();

  const navigate = useNavigate();

  const totalAmount = useMemo(
    () => selected.reduce((acc, ch) => acc + ch.amount, 0),
    [selected],
  );
  const totalSelected = selected.length;
  const totalToPay = projectData?.amount;
  const totalPaid = projectData?.processedAmount;
  const numberOfPayments = projectData?.payments;

  const node = document.getElementsByClassName('home__main')[0];

  const [isBottom, setIsBottom] = useState(undefined);
  const handleScroll = useCallback(() => {
    const { scrollTop, scrollHeight, clientHeight } = node;
    if (scrollTop + clientHeight >= scrollHeight - 20) {
      setIsBottom(true);
    } else {
      setIsBottom(false);
    }
  }, [node]);

  useEffect(() => {
    if (node) {
      const { scrollTop, scrollHeight, clientHeight } = node;
      setIsBottom(scrollTop + clientHeight >= scrollHeight - 20);
      node.addEventListener('scroll', handleScroll);
      return () => node.removeEventListener('scroll', handleScroll);
    }
  }, [node, handleScroll, paymentSlipsData]);

  return (
    <div
      className={classNames('project__footer', {
        'project__footer--bottom-reached': isBottom,
      })}
    >
      {paymentSlipsData?.totalItems > 0 &&
        !isProjectFetching &&
        !isPaymentSlipsFetching && (
          <>
            {authService.checkRolesForUser(credentialsService.user, [
              'book_keeper',
            ]) && (
              <Button
                disabled={paymentSelectingMode !== PaymentSelectingMode.None}
                className="project__footer__button"
                icon={<PlusIcon />}
                styleType="solid"
                size="cta"
                onClick={() =>
                  navigate(
                    `${APP_ROUTES.CREATE_PAYMENT}?${SEARCH_PARAMS.PROJECT_ID}=${id}`,
                  )
                }
              >
                {t('Buttons.addNewPayment')}
              </Button>
            )}
            {paymentSlipsData.totalItems > 0 && !isPaymentSlipsFetching && (
              <div className="project__footer__info">
                {paymentSelectingMode !== PaymentSelectingMode.None && (
                  <div className="project__footer__info__part">
                    <DataDisplayRow
                      title={t('Index.totalAmount')}
                      data={new Intl.NumberFormat('sr-RS', {
                        style: 'currency',
                        currency: 'RSD',
                      }).format(totalAmount)}
                    />
                    <DataDisplayRow
                      title={t('Index.totalSelected')}
                      data={totalSelected}
                    />
                  </div>
                )}
                <div className="project__footer__info__part">
                  <DataDisplayRow
                    title={t('Index.totalToPay')}
                    data={new Intl.NumberFormat('sr-RS', {
                      style: 'currency',
                      currency: 'RSD',
                    }).format(totalToPay)}
                  />
                  <DataDisplayRow
                    title={t('Index.totalPaid')}
                    data={new Intl.NumberFormat('sr-RS', {
                      style: 'currency',
                      currency: 'RSD',
                    }).format(totalPaid)}
                  />
                  <DataDisplayRow
                    title={t('Index.numberOfPayments')}
                    data={numberOfPayments}
                  />
                </div>
              </div>
            )}
          </>
        )}
    </div>
  );
};

export default ProjectFooter;
