export const FirstNameRegExp = RegExp('([a-z])(?=.{2,})');
export const LastNameRegExp = RegExp('([a-z])(?=.{2,})');
export const EmailRegExp = RegExp(
  '^[a-zA-Z0-9_:$!%-.]+@[a-zA-Z0-9.-]+.[a-zA-Z]$',
);
// eslint-disable-next-line
export const PasswordRegExp =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&\-#$.%&*~!@^(){}[\]:;<>,?/_+=|\\])[a-zA-Z\d@$!%*#?&\-#$.%&*~!@^(){}[\]:;<>,?/_+=|\\]{8,64}$/;

export const LetterUppercaseRegExp = /(?=.*[A-Z])/;
export const NumberRegExp = /(?=.*[0-9])/;
export const SpecialCharacterRegExp = /(?=.*[!@#$%^&*(),.?":{}|<>])/;
export const AtLeastEightRegExp = /^.{8,}$/;

export const PhoneRegExp = /^(\+381|0)(60|61|62|63|64|65|66|677|69)(\d{6,7})$/;
export const IdNumberRegExp = RegExp('^[0-9].{10,}$');

export const ZipCodeRegExp = RegExp(/^\d{5}$/);

export const VatNumberRegExp = RegExp(/^[1-9][0-9]{8}$/);

export const BankAccountRegExp = /^(\d{3})-?(\d{8,14})-?(\d{2})$/;

export const ModelRegExp = /^(\d{2})?$|^$/;
