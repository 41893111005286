import { ComplexRoute } from 'router/types';
import RegisterClient from './RegisterClient';

export default [
  {
    path: 'register',
    element: RegisterClient,
    onlyPublic: true,
  },
] as Array<ComplexRoute>;
