import React from 'react';
import { Icon } from 'models/Icon';

const LogoutIcon: React.FC<Icon> = (props) => {
  const { className } = props;

  return (
    <svg
      className={className}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="black"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M2.20508 15.1094C1.87174 15.1094 1.58008 14.9844 1.33008 14.7344C1.08008 14.4844 0.955078 14.1927 0.955078 13.8594V1.35938C0.955078 1.02604 1.08008 0.734375 1.33008 0.484375C1.58008 0.234375 1.87174 0.109375 2.20508 0.109375H8.26758V1.35938H2.20508V13.8594H8.26758V15.1094H2.20508ZM12.3301 11.2552L11.4342 10.3594L13.5592 8.23438H6.26758V6.98438H13.5176L11.3926 4.85938L12.2884 3.96354L15.9551 7.63021L12.3301 11.2552Z" />
    </svg>
  );
};

export default LogoutIcon;
