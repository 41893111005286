import { useQueryClient } from '@tanstack/react-query';
import React, { useCallback, useContext, useMemo } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

import Button from 'components/Button';
import CommentModal from 'components/CommentModal';
import DownloadPayments from 'components/DownloadPayments';
import LoadingButton from 'components/LoadingButton';
import Modal from 'components/Modal';
import SearchInput from 'components/SearchInput';
import ToggleGroup from 'components/ToggleGroup';
import ToolTip from 'components/ToolTip';
import { API_ENDPOINTS } from 'config/endpoints';
import { SEARCH_PARAMS } from 'config/searchParams';
import useDisclosure from 'hooks/useDisclosure';
import useSearchParams from 'hooks/useSearchParams';
import useTanstackMutation from 'hooks/useTanstackMutation';
import BellIcon from 'icons/Bell.icon';
import GridViewIcon from 'icons/GridView.icon';
import ListViewIcon from 'icons/ListView.icon';
import {
  PAYMENT_STATUS,
  PaymentSelectingMode,
  SelectedPayment,
} from 'models/Payment';
import authService from 'services/authService';
import credentialsService from 'services/credentialsService';
import { INVITATION_STATUS } from 'types';
import ProjectContext from '../provider/Project.context';
import ProjectSelectedPaymentsActionsButton from './ProjectSelectedPaymentsActionsButton';

const ProjectBrowser: React.FC = () => {
  const {
    search,
    setSearch,
    paymentSelectingMode,
    setPaymentSelectingMode,
    projectData,
    refetch,
    newlySelected,
    unselected,
    resetSelected,
    selectedView,
    members,
    isMembersLoading,
    isProjectFetching,
    open,
    selected,
    paymentSlipsData,
    projectId,
    hasErrors,
  } = useContext(ProjectContext);

  const {
    isOpen: isOpenWarningModal,
    open: openWarningModal,
    close: closeWarningModal,
  } = useDisclosure();

  const {
    isOpen: isOpenErrorModal,
    open: openErrorModal,
    close: closeErrorModal,
  } = useDisclosure();

  const {
    isOpen: isOpenNotifyModal,
    open: openNotifyModal,
    close: closeNotifyModal,
  } = useDisclosure();

  const queryClient = useQueryClient();

  const { isPending, mutate: mutateProcessed } = useTanstackMutation({
    onSuccess: () => {
      toast.success(t('Messages.paymentSlipsSelectedSuccessfully'));
      queryClient.invalidateQueries({
        queryKey: [API_ENDPOINTS.PAYMENT_SLIPS, { projectId: projectData._id }],
      });
      queryClient.invalidateQueries({
        queryKey: [API_ENDPOINTS.PROJECTS, { clientId: projectData?.clientId }],
      });
      queryClient.invalidateQueries({
        queryKey: [API_ENDPOINTS.CURRENT_CLIENT],
      });
      refetch();
      setPaymentSelectingMode(PaymentSelectingMode.None);
    },
    onError: () => {
      toast.error(t('Messages.errorSelectingPaymentSlips'));
    },
  });

  const { mutate, isPending: isNotificationPending } = useTanstackMutation({
    onSuccess: () => {
      toast.success(
        t(
          `Messages.${!isClient ? 'client' : 'bookkeeper'}NotifiedSuccessfully`,
        ),
      );
      closeNotifyModal();
    },
    onError: () => {
      toast.error(
        t(`Messages.errorNotifying${!isClient ? 'Client' : 'Bookkeeper'}`),
      );
    },
  });

  const updateSelectedStatus = useCallback(
    (status: PAYMENT_STATUS, aditionalBodyOptions?: Record<string, any>) => {
      const body = [
        ...(newlySelected as SelectedPayment[]).map(({ id }) => ({
          id,
          status,
          ...aditionalBodyOptions,
        })),
        ...(unselected as SelectedPayment[]).map(({ id }) => ({
          id,
          status: PAYMENT_STATUS.UNCHECKED,
        })),
      ];

      if (!body.length) return;

      mutateProcessed({
        body,
        method: 'PATCH',
        path: API_ENDPOINTS.UPDATE_PAYMENT_SLIPS_STATUSES(projectData?._id),
      });
    },
    [newlySelected, unselected, projectData, mutateProcessed],
  );

  const { t } = useTranslation();

  const { setSearchParam } = useSearchParams();

  const isClient = authService.checkRolesForUser(credentialsService.user, [
    'client',
  ]);

  const notifyUser = (message: string) => {
    const userId = !isClient ? projectData.clientId : projectData.bookkeeperId;
    const path =
      API_ENDPOINTS[!isClient ? 'NOTIFY_CLIENT' : 'NOTIFY_BOOKKEEPER'](userId);

    mutate({
      body: {
        projectId: projectData._id,
        projectName: projectData.name,
        message,
      },
      method: 'POST',
      path,
    });
  };

  const buttonsConfig = useMemo(() => {
    const isProcessing = {
      label: t('Buttons.processing'),
      onToggle: () => {
        openWarningModal();
        setPaymentSelectingMode(PaymentSelectingMode.Processing);
      },
    };
    if (isClient) {
      return [
        {
          label: t('Buttons.verifying'),
          onToggle: () =>
            setPaymentSelectingMode(PaymentSelectingMode.Verifying),
        },
        {
          label: t('Buttons.errorMarking'),
          onToggle: () => setPaymentSelectingMode(PaymentSelectingMode.Error),
        },
        isProcessing,
      ];
    }

    return [
      {
        label: t('Buttons.editing'),
        onToggle: () => setPaymentSelectingMode(PaymentSelectingMode.Editing),
      },
      isProcessing,
    ];
  }, [isClient, t, setPaymentSelectingMode, openWarningModal]);

  return (
    <div className="project__header__browser">
      <SearchInput
        className="project__header__browser__search"
        placeholder={`${t('Fields.search')}...`}
        value={search}
        onChange={setSearch}
      />
      <div className="project__header__browser__buttons">
        {paymentSelectingMode === PaymentSelectingMode.None && (
          <ToolTip
            content={t('Messages.inviteTheClientFirstFromTheClientPage')}
            disabled={members?.items.some(
              (p) => p.inviteStatus !== INVITATION_STATUS.NOT_INVITED,
            )}
          >
            <LoadingButton
              icon={<BellIcon />}
              styleType="solid-white"
              className="project__header__browser__buttons__notify"
              disabled={
                members?.items.length === 0 ||
                members?.items.every(
                  (p) => p.inviteStatus === INVITATION_STATUS.NOT_INVITED,
                )
              }
              isLoading={isMembersLoading || isProjectFetching}
              onClick={openNotifyModal}
            >
              {t(`Buttons.notify${!isClient ? 'Client' : 'Bookkeeper'}`)}
            </LoadingButton>
          </ToolTip>
        )}
        <ProjectSelectedPaymentsActionsButton
          disabled={paymentSlipsData?.totalItems === 0}
          disabledAction={
            paymentSelectingMode === PaymentSelectingMode.Editing &&
            selected.length === 0
          }
          selectingMode={paymentSelectingMode}
          isPending={isPending}
          onToggle={(mod) => setPaymentSelectingMode(mod)}
          buttonsConfig={buttonsConfig}
          onClickActions={{
            processing: () => updateSelectedStatus(PAYMENT_STATUS.PROCESSED),
            editing: open,
            error: openErrorModal,
            verifying: () => updateSelectedStatus(PAYMENT_STATUS.CHECKED),
          }}
          reset={resetSelected}
        />
      </div>
      {paymentSelectingMode === PaymentSelectingMode.None && (
        <DownloadPayments
          projectId={projectId}
          openModalCondition={hasErrors}
        />
      )}
      <ToggleGroup
        exclusive
        options={[
          {
            icon: <ListViewIcon />,
            isToggled: selectedView === 'list',
            onToggle: () =>
              setSearchParam(SEARCH_PARAMS.PAYMENT_VIEW, 'list', {
                replace: true,
              }),
          },
          {
            icon: <GridViewIcon />,
            isToggled: selectedView === 'grid',
            onToggle: () =>
              setSearchParam(SEARCH_PARAMS.PAYMENT_VIEW, 'grid', {
                replace: true,
              }),
          },
        ]}
      />
      {isOpenWarningModal && (
        <Modal
          disableCloseButton
          closeModal={closeWarningModal}
          heading={t('Index.warning')}
          subtitle={t(
            'Messages.onceYouSaveThePaymentsAsProcessedYouWontBeAbleToUndoYourActions',
          )}
          className="project__warning-modal"
        >
          <Button
            size="large"
            onClick={closeWarningModal}
            className="project__header__browser__modal--warning__button"
          >
            {t('Buttons.confirm')}
          </Button>
        </Modal>
      )}
      {isOpenErrorModal && (
        <CommentModal
          className="project__header__browser__modal--error"
          closeModal={closeErrorModal}
          heading={t('Index.errorComments')}
          onClick={(message) =>
            updateSelectedStatus(PAYMENT_STATUS.ERROR, { message })
          }
          subtitle={t(
            'Messages.leaveTheCommentsAboutTheSelectedPaymentSlipsError',
          )}
          buttonConfig={{
            isLoading: isPending,
            children: t('Buttons.submitComments'),
            className: 'project__header__browser__modal--error__button',
          }}
        />
      )}
      {isOpenNotifyModal && (
        <CommentModal
          closeModal={closeNotifyModal}
          heading={t('Index.notificationComments')}
          subtitle={t(
            `Messages.leaveTheCommentsForThe${
              !isClient ? 'Client' : 'Bookkeeper'
            }YouWishToNotify`,
          )}
          onClick={notifyUser}
          buttonConfig={{
            isLoading: isNotificationPending,
            children: t('Buttons.send'),
            className: 'project__header__browser__modal--notify__button',
          }}
        />
      )}
    </div>
  );
};

export default ProjectBrowser;
