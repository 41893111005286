import { useQueryClient } from '@tanstack/react-query';
import classNames from 'classnames';
import { DateTime } from 'luxon';
import React, { useState } from 'react';
import { Form } from 'react-final-form';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import CheckBox from 'components/CheckBox';
import InputField from 'components/InputField';
import Link from 'components/Link';
import LoadingButton from 'components/LoadingButton';
import ProcessDateField from 'components/ProcessDateField';
import { API_ENDPOINTS } from 'config/endpoints';
import { APP_ROUTES } from 'config/routes';
import useTanstackMutation from 'hooks/useTanstackMutation';
import { Project } from 'models/Project';
import { Client } from 'models/User';
import { shouldDisableFormSubmit } from 'utils';
import {
  composeValidators,
  validateDirtyNoBlankSpace,
  validateRequired,
} from 'validations/validations';

import './ProjectForm.styles.responsive.scss';
import './ProjectForm.styles.scss';

type ProjectFormInputs = {
  name: string;
  processmentDate?: DateTime;
  instant?: boolean;
};

export type ProjectFormProps = {
  className?: string;
  initialValues?: Partial<ProjectFormInputs>;
  onSuccess?: () => void;
  onError?: () => void;
  client?: Client;
  copyFromProject?: Project;
};

const ProjectForm: React.FC<ProjectFormProps> = (props) => {
  const {
    className,
    initialValues,
    onSuccess,
    onError,
    client,
    copyFromProject,
  } = props;

  const classes = classNames('project-form', className);

  const [enableButton, setEnableButton] = useState(
    !copyFromProject ||
      copyFromProject.payments - copyFromProject.processed === 0,
  );

  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const navigate = useNavigate();

  const { mutate, isPending } = useTanstackMutation<Project>({
    onSuccess: (data) => {
      const notificationType = copyFromProject
        ? 'Copied'
        : !initialValues?.name
        ? 'Created'
        : 'Updated';

      toast.success(t(`Messages.project${notificationType}Successfully`));

      queryClient.invalidateQueries({
        queryKey: [`${API_ENDPOINTS.PROJECTS}`, { clientId: data.clientId }],
      });
      queryClient.invalidateQueries({
        queryKey: [`${API_ENDPOINTS.CLIENTS}`],
      });
      queryClient.invalidateQueries({
        queryKey: [API_ENDPOINTS.CURRENT_CLIENT],
      });

      onSuccess?.();

      const URL = `${APP_ROUTES.PROJECTS}/${data._id}`;

      navigate(URL);
    },
    onError: () => {
      const notificationType = copyFromProject
        ? 'Copying'
        : !initialValues?.name
        ? 'Creating'
        : 'Updating';

      toast.error(t(`Messages.error${notificationType}Project`));

      onError?.();
    },
  });

  const handleSubmit = (data: ProjectFormInputs) => {
    if (!client._id) return;

    const path = copyFromProject
      ? API_ENDPOINTS.PROJECT_COPY
      : API_ENDPOINTS.PROJECTS;

    const method = !initialValues || copyFromProject ? 'POST' : 'PATCH';

    const body = copyFromProject
      ? {
          ...data,
          processmentDate: data.processmentDate.toFormat('yyyy.MM.dd'),
          oldProjectId: copyFromProject._id,
          nameNewProject: data.name,
        }
      : {
          clientId: client._id,
          name: data.name,
        };

    mutate({
      path,
      method,
      body,
    });
  };

  return (
    <Form<ProjectFormInputs>
      onSubmit={handleSubmit}
      initialValues={initialValues}
      render={({ handleSubmit, ...rest }) => (
        <form onSubmit={handleSubmit} className={classes}>
          <InputField
            className={classNames(
              'project-form__input',
              'project-form__input--square',
            )}
            name="name"
            title={t('Fields.nameOfTheProject')}
            placeholder={t('Fields.enterTheName')}
            validate={composeValidators(
              validateRequired('Form.nameRequired'),
              validateDirtyNoBlankSpace(
                rest.initialValues?.name,
                'Form.newProjectNameMustNotBeTheSameAsTheCopyProjectName',
              ),
            )}
          />
          {!!copyFromProject && (
            <>
              <ProcessDateField
                className="project-form__date"
                subtitle={t(
                  'Messages.selectTheDateWhenYouWantThePaymentsToBeProcessed',
                )}
                isChecked={rest.values.instant}
                onDateChange={(value) =>
                  rest.form.change('processmentDate', value as DateTime)
                }
              />
              {copyFromProject.payments - copyFromProject.processed !== 0 && (
                <>
                  <p className="project-form__warning">
                    {t('Index.unprocessedPaymentsDetectedInAProject')}
                  </p>
                  <span className="project-form__old-project">
                    {t('Index.youAreAboutToCopyA')}{' '}
                    <Link
                      to={`${APP_ROUTES.PROJECTS}/${copyFromProject._id}`}
                      target="_blank"
                    >
                      {t('Fields.project').toLowerCase()}
                    </Link>{' '}
                    {t('Index.thatHasUnprocessedPayments')}
                  </span>

                  <CheckBox
                    className="project-form__old-project__checkbox"
                    label={t('Index.saveAllAsProcessedAndProcceed')}
                    value={enableButton}
                    onChange={setEnableButton}
                  />
                </>
              )}
            </>
          )}
          <LoadingButton
            isLoading={isPending}
            disabled={
              (initialValues?.name && shouldDisableFormSubmit(rest)) ||
              !rest.form.getFieldState('name')?.dirty ||
              rest.values['name']?.trim() === '' ||
              !enableButton
            }
            className="project-form__button"
            styleType="solid"
          >
            {t('Buttons.save')}
          </LoadingButton>
        </form>
      )}
    />
  );
};

export default ProjectForm;
