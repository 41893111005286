import {
  CategoryScale,
  Chart as ChartJS,
  Filler,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js';
import classNames from 'classnames';
import { FC, useMemo } from 'react';
import { Line } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';

import DataDisplayRow from 'components/DataDisplayRow';
import ProgressCard from 'components/ProgressCard';
import { createDisplayConfig } from 'mapping';
import { Client, ClientWithProjectStatus } from 'models/User';
import { data, options } from './chartConfig';
import ClientDetailsAccountsCard from './components/ClientDetailsAccountsCard.component';
import { generateDisplayConfigObject } from './displayConfig';

import './ClientDetails.styles.responsive.scss';
import './ClientDetails.styles.scss';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
);

type ClientDetailsProps = {
  className?: string;
  client: ClientWithProjectStatus;
};

const ClientDetails: FC<ClientDetailsProps> = (props) => {
  const { className, client } = props;

  const classes = classNames('client-details', className);

  const { t } = useTranslation();

  const InfoCard = useMemo(() => {
    const clientName =
      client.companyName ?? `${client.firstName} ${client.lastName}`;

    return (
      <div className="client-details__info">
        <h2 className="client-details__info__name">{clientName}</h2>
        {createDisplayConfig<Partial<Client>>(
          client,
          generateDisplayConfigObject(t),
        ).map((row) => (
          <DataDisplayRow key={row.title} {...row} />
        ))}
      </div>
    );
  }, [client, t]);

  const ProjectsData = useMemo(() => {
    const FULFILLED = client.numberOfFulfilledProjects;
    const IN_PROGRESS = client.numberOfInProcessProjects;
    const EMPTY = client.numberOfEmptyProjects;
    const TOTAL_ITEMS = FULFILLED + IN_PROGRESS + EMPTY;

    return (
      <>
        <p className="client-details__total-projects">
          {`${TOTAL_ITEMS} ${t('Clients.totalProjects', {
            count: TOTAL_ITEMS,
          })}`}
        </p>
        <div className="client-details__projects-progress">
          <ProgressCard
            itemsTitle={t('Dashboard.project', { count: FULFILLED })}
            processedItems={FULFILLED}
            status={t('Clients.fullfilled')}
            totalItems={TOTAL_ITEMS}
          />
          <ProgressCard
            itemsTitle={t('Dashboard.project', { count: EMPTY })}
            processedItems={EMPTY}
            status={t('Clients.empty')}
            totalItems={TOTAL_ITEMS}
          />
          <ProgressCard
            itemsTitle={t('Dashboard.project', { count: IN_PROGRESS })}
            processedItems={IN_PROGRESS}
            status={t('Clients.inProgress')}
            totalItems={TOTAL_ITEMS}
          />
        </div>
      </>
    );
  }, [t, client]);

  return (
    <div className={classes}>
      <div className="client-details__upper">
        <div className="client-details__upper__info-accounts">
          {InfoCard}
          {ProjectsData}
        </div>
        <ClientDetailsAccountsCard clientId={client._id} />
      </div>
      <div className="client-details__line" />
      <Line
        className="client-details__chart"
        height={'auto'}
        width={'auto'}
        options={options}
        data={data}
      />
    </div>
  );
};

export default ClientDetails;
