import React from 'react';

const EyeNotVisibleIcon: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24">
      <path d="m16.125 13.2-1.7-1.7q.175-.975-.562-1.812-.738-.838-1.913-.663l-1.65-1.65q.4-.2.825-.287Q11.55 7 12 7q1.875 0 3.188 1.312Q16.5 9.625 16.5 11.5q0 .45-.087.875-.088.425-.288.825Zm3.375 3.325-1.575-1.55q.9-.7 1.613-1.537.712-.838 1.262-1.938-1.275-2.525-3.6-4.013Q14.875 6 12 6q-.725 0-1.387.1-.663.1-1.313.275l-1.7-1.7q1.025-.425 2.125-.638 1.1-.212 2.275-.212 3.8 0 6.812 2.112 3.013 2.113 4.363 5.563-.575 1.475-1.525 2.762-.95 1.288-2.15 2.263Zm.225 6.15-4.1-4.05q-.85.275-1.75.413-.9.137-1.875.137-3.825 0-6.825-2.113-3-2.112-4.35-5.562.5-1.325 1.313-2.475.812-1.15 1.812-2.075L1.225 4.175 2.7 2.725 21.2 21.2ZM5.5 8.475q-.725.65-1.287 1.388-.563.737-.988 1.637 1.25 2.525 3.575 4.012Q9.125 17 12 17q.45 0 .9-.05t.95-.125l-.9-.95q-.25.05-.475.087Q12.25 16 12 16q-1.875 0-3.188-1.312Q7.5 13.375 7.5 11.5q0-.25.025-.475.025-.225.1-.475Zm8.125 2.2Zm-3.95 1.975Z" />
    </svg>
  );
};

export default EyeNotVisibleIcon;
