import { useQuery } from '@tanstack/react-query';
import { API_ENDPOINTS } from 'config/endpoints';
import useDebounce from 'hooks/useDebounce';
import useSelect from 'hooks/useSelect';
import {
  Payment,
  PAYMENT_STATUS,
  PaymentSelectingMode,
  SelectedPayment,
} from 'models/Payment';
import { PaginationResponse } from 'models/Response';
import { useMemo, useState } from 'react';
import utils from 'utils';

type UsePaymentSlipsParams = {
  queryParams: Record<string, any>;
  paymentSelectingMode: PaymentSelectingMode;
};

export const selectingModeToPaymentStatus = {
  [PaymentSelectingMode.Error]: PAYMENT_STATUS.ERROR,
  [PaymentSelectingMode.Processing]: PAYMENT_STATUS.PROCESSED,
  [PaymentSelectingMode.Verifying]: PAYMENT_STATUS.CHECKED,
  [PaymentSelectingMode.None]: PAYMENT_STATUS.UNCHECKED,
  [PaymentSelectingMode.Editing]: PAYMENT_STATUS.UNCHECKED,
};

const usePaymentSlips = ({
  queryParams,
  paymentSelectingMode,
}: UsePaymentSlipsParams) => {
  const {
    data,
    refetch: refetchPayments,
    isLoading,
    isFetching,
  } = useQuery<PaginationResponse<Payment>>({
    queryKey: [API_ENDPOINTS.PAYMENT_SLIPS, queryParams],
  });

  const allSelectableItems = useMemo(() => {
    if (paymentSelectingMode === PaymentSelectingMode.None) return [];

    const { shouldPaymentBeSelectable } = utils;
    const selectableItems = data?.items.filter((p) =>
      shouldPaymentBeSelectable(paymentSelectingMode, p.status),
    );

    return selectableItems?.map(({ id, price }) => ({
      id,
      amount: price.amount,
    }));
  }, [data, paymentSelectingMode]);

  const initiallySelected = useMemo(() => {
    const emptySelectionCondition = [
      PaymentSelectingMode.Editing,
      PaymentSelectingMode.None,
    ].includes(paymentSelectingMode);

    if (emptySelectionCondition) return [];

    return data?.items
      .filter((p) =>
        selectingModeToPaymentStatus[paymentSelectingMode].includes(p.status),
      )
      .filter((p) =>
        utils.shouldPaymentBeSelectable(paymentSelectingMode, p.status),
      )
      .map(({ id, price }) => ({ id, amount: price.amount }));
  }, [data, paymentSelectingMode]);

  const selectPayments = useSelect<SelectedPayment>({
    initiallySelected,
    filterAttribute: 'id',
    allItems: allSelectableItems,
  });

  return {
    ...selectPayments,
    refetchPayments,
    isLoading,
    isFetching,
    data,
  };
};

export const usePaymentsQueryParams = ({ id }: { id: string }) => {
  const [search, setSearch] = useState('');
  const debouncedValue = useDebounce(search, 300);
  const queryParams = useMemo(
    () => ({
      projectId: id,
      ...(debouncedValue ? { searchBy: `payee.name:${debouncedValue}` } : {}),
      sortBy: 'updatedAt:-1',
    }),
    [debouncedValue, id],
  );

  return [search, setSearch, queryParams] as const;
};

export default usePaymentSlips;
