import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';

type UseDatePickerState = {
  value: DateTime;
  format?: string;
  onChange?: (val: DateTime) => void;
};

export const useDatePickerState = ({
  format = 'dd.MM.yyyy',
  value,
  onChange,
}: UseDatePickerState) => {
  const [internalValue, setInternalValue] = useState(
    value?.isValid ? value.toFormat(format) : '',
  );

  useEffect(() => {
    setInternalValue(value?.isValid ? value.toFormat(format) : '');
  }, [value, format]);

  const handleChange = (val: string) => {
    setInternalValue(val);
    if (!val) onChange?.(undefined);

    const formatted = DateTime.fromFormat(val, format);
    if (formatted.isValid) onChange?.(formatted);
  };

  return [internalValue, handleChange] as const;
};
