import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import storageService from 'services/storageService';

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    fallbackLng: () => {
      const code = storageService.getItem('language');
      return code || 'en';
    },
    interpolation: {
      escapeValue: false,
    },
  });

i18n.services.formatter.addCached('lowercase', (value) => value.toLowerCase());
export default i18n;
