import { useQueryClient } from '@tanstack/react-query';
import classNames from 'classnames';
import React, { useState } from 'react';
import { Form } from 'react-final-form';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

import InputField from 'components/InputField';
import LoadingButton from 'components/LoadingButton';
import RadioGroup from 'components/RadioGroup';
import { API_ENDPOINTS } from 'config/endpoints';
import useTanstackMutation from 'hooks/useTanstackMutation';
import BankIcon from 'icons/Bank.icon';
import BillIcon from 'icons/Bill.icon';
import EmailIcon from 'icons/Email.icon';
import UserIcon from 'icons/User.icon';
import { Client, ClientType, CreateClient } from 'models/User';
import { shouldDisableFormSubmit } from 'utils';
import {
  BankAccountRegExp,
  FirstNameRegExp,
  LastNameRegExp,
  VatNumberRegExp,
  ZipCodeRegExp,
} from 'validations/regularExpression';
import {
  composeValidators,
  validateBankAccountChecksum,
  validateBankAccountLength,
  validateRegex,
  validateRequired,
} from 'validations/validations';

import './ClientForm.styles.responsive.scss';
import './ClientForm.styles.scss';

type FormInputs = {
  firstName?: string;
  lastName?: string;
  companyName?: string;
  bankAccount: string;
  vatNumber?: string;
  zipCode: string;
  address: string;
};

export type ClientFormProps = {
  className?: string;
  buttonsPosition: 'left' | 'right' | 'center';
  initialValues?: Client;
  refetch?: () => void;
  onSuccess?: (data?: Client) => void | Promise<void>;
};

const ClientForm: React.FC<ClientFormProps> = (props) => {
  const { className, initialValues, refetch, onSuccess } = props;

  const classes = classNames(
    'client-form',
    { 'client-form--create': !initialValues },
    className,
  );

  const { t } = useTranslation();

  const initialClientType = initialValues
    ? initialValues.firstName
      ? ClientType.Individual
      : ClientType.LegalEntity
    : undefined;

  const [clientType, setClientType] = useState<ClientType>(
    initialClientType || ClientType.LegalEntity,
  );

  const queryClient = useQueryClient();

  const { mutate, isPending } = useTanstackMutation({
    onSuccess: async (data: Client) => {
      const notificationType = !initialValues ? 'Created' : 'Updated';

      queryClient.invalidateQueries({
        queryKey: [`${API_ENDPOINTS.CLIENTS}`],
      });

      await onSuccess?.(data);

      toast.success(t(`Messages.client${notificationType}Successfully`));

      if (initialValues) refetch();
    },
    onError: async (error) => {
      const err = await error.json();
      if (err['code']) {
        toast.error(t(`Messages.${err['code']}`));
        return;
      }

      const notificationType = !initialValues ? 'Creating' : 'Updating';

      toast.error(t(`Messages.error${notificationType}Client`));
    },
  });

  const handleSubmit = (data: FormInputs) => {
    const isIndividual = clientType === ClientType.Individual;

    const name = isIndividual
      ? { firstName: data.firstName, lastName: data.lastName }
      : { companyName: data.companyName };

    const payload: CreateClient = {
      ...name,
      vatNumber: data.vatNumber,
      bankAccount: data.bankAccount,
      address: {
        name: data.address,
        zipCode: data.zipCode,
      },
    };

    const base_path = API_ENDPOINTS.CLIENTS;
    const path = base_path + (initialValues ? `/${initialValues._id}` : '');

    const method = !initialValues ? 'POST' : 'PATCH';

    mutate({
      path,
      method,
      body: payload,
    });
  };

  return (
    <Form<FormInputs>
      initialValues={
        initialValues
          ? {
              companyName: initialValues.companyName,
              firstName: initialValues.firstName,
              lastName: initialValues.lastName,
              address: initialValues.address.name,
              zipCode: initialValues.address.zipCode,
              bankAccount: initialValues.bankAccount,
              vatNumber: initialValues.vatNumber,
            }
          : undefined
      }
      keepDirtyOnReinitialize={true}
      onSubmit={handleSubmit}
      render={({ handleSubmit, ...rest }) => (
        <form onSubmit={handleSubmit} className={classes}>
          {!initialValues && (
            <RadioGroup
              name="clientType"
              options={[
                { id: ClientType.LegalEntity, label: t('Fields.legalEntity') },
                { id: ClientType.Individual, label: t('Fields.individual') },
              ]}
              value={clientType}
              onChange={(value) => setClientType(value as ClientType)}
            />
          )}
          {clientType === ClientType.Individual ? (
            <div className="client-form__row">
              <InputField
                titleicon={<UserIcon />}
                title={t('Register.firstName')}
                name="firstName"
                validate={composeValidators(
                  validateRequired('Form.firstNameRequired'),
                  validateRegex('Form.firstNameNotValid', FirstNameRegExp),
                )}
                className="client-form__input"
              />
              <InputField
                titleicon={<UserIcon />}
                title={t('Register.lastName')}
                name="lastName"
                validate={composeValidators(
                  validateRequired('Form.lastNameRequired'),
                  validateRegex(t('Form.lastNameNotValid'), LastNameRegExp),
                )}
                className="client-form__input"
              />
            </div>
          ) : (
            <InputField
              titleicon={<BankIcon />}
              title={t('Register.companyName')}
              name="companyName"
              validate={validateRequired('Form.requiredCompanyName')}
              className="client-form__input"
            />
          )}
          <div className="client-form__row">
            <InputField
              titleicon={<BillIcon />}
              title={t('Register.bankAccount')}
              name="bankAccount"
              validate={composeValidators(
                validateRequired('Form.requiredBankAccount'),
                validateRegex('Form.invalidBankAccount', BankAccountRegExp),
                validateBankAccountLength('Form.invalidBankAccount'),
                validateBankAccountChecksum('Form.invalidBankAccount'),
              )}
              className="client-form__input"
            />
            <InputField
              title={t('Register.vatNumber')}
              titleicon={<BillIcon />}
              name="vatNumber"
              validate={composeValidators(
                validateRegex('Form.invalidVatNumber', VatNumberRegExp),
                validateRequired('Form.vatNumberRequired'),
              )}
              className="client-form__input"
            />
          </div>

          <InputField
            titleicon={<EmailIcon />}
            title={t('Register.address')}
            name="address"
            validate={validateRequired('Form.requiredAddress')}
            className="client-form__input"
          />
          <InputField
            titleicon={<EmailIcon />}
            title={t('Register.zipCode')}
            name="zipCode"
            validate={composeValidators(
              validateRequired('Form.requiredZipCode'),
              validateRegex('Form.invalidZipCode', ZipCodeRegExp),
            )}
            className="client-form__input"
          />
          <div className="client-form__footer">
            <LoadingButton
              isLoading={isPending}
              size="large"
              styleType={`solid-${initialValues ? 'bright' : 'black'}`}
              className="client-form__footer__button"
              disabled={initialValues && shouldDisableFormSubmit(rest)}
            >
              {t(`Buttons.${initialValues ? 'saveInformation' : 'create'}`)}
            </LoadingButton>
          </div>
        </form>
      )}
    />
  );
};

export default ClientForm;
