import classNames from 'classnames';
import React from 'react';

import CreditorDetails from 'components/CreditorDetails';
import PaginatedCreditorsTable from 'components/PaginatedCreditorsTable';
import { PaginatedCreditorsTableProps } from 'components/PaginatedCreditorsTable/PaginatedCreditorsTable.component';
import { SEARCH_PARAMS } from 'config/searchParams';
import useSearchParams from 'hooks/useSearchParams';

import './CreditorsOverview.styles.responsive.scss';
import './CreditorsOverview.styles.scss';

type CreditorsOverviewProps = {
  className?: string;
  clientId: string;
} & PaginatedCreditorsTableProps;

const CreditorsOverview: React.FC<CreditorsOverviewProps> = (props) => {
  const { className, clientId, ...paginatedTableConfig } = props;

  const classes = classNames('creditors-overview', className);

  const { getSearchParam } = useSearchParams();
  const selectedCreditorId = getSearchParam(SEARCH_PARAMS.CREDITOR_ID);

  return !selectedCreditorId ? (
    <PaginatedCreditorsTable className={classes} {...paginatedTableConfig} />
  ) : (
    <CreditorDetails creditorId={selectedCreditorId} clientId={clientId} />
  );
};

export default CreditorsOverview;
