import classNames from 'classnames';
import { FormApi } from 'final-form';
import React, { useState } from 'react';
import { Form } from 'react-final-form';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { shouldDisableFormSubmit } from 'utils';

import Button from 'components/Button';
import InputField from 'components/InputField';
import LoadingButton from 'components/LoadingButton';
import { API_ENDPOINTS } from 'config/endpoints';
import useTanstackMutation from 'hooks/useTanstackMutation';
import BankIcon from 'icons/Bank.icon';
import BillIcon from 'icons/Bill.icon';
import EditIcon from 'icons/Edit.icon';
import HouseIcon from 'icons/House.icon';
import PhoneIcon from 'icons/Phone.icon';
import UserIcon from 'icons/User.icon';
import {
  PhoneRegExp,
  VatNumberRegExp,
  ZipCodeRegExp,
} from 'validations/regularExpression';
import {
  composeValidators,
  validateRegex,
  validateRequired,
} from 'validations/validations';
import CardLayout from '../CardLayout';

import './AccountInformationCard.styles.responsive.scss';
import './AccountInformationCard.styles.scss';

type AccountInformationFormInputs = {
  address: string;
  zipCode: string;
  vatNumber: string;
  phone: string;
};

type AccountInformationCardProps = {
  className?: string;
  initialData: AccountInformationFormInputs;
  onSuccess?: () => Promise<void>;
};

const AccountInformationCard: React.FC<AccountInformationCardProps> = (
  props,
) => {
  const { className, initialData, onSuccess } = props;

  const classes = classNames('account-information-card', className);

  const { t } = useTranslation();

  const [isEditing, setIsEditing] = useState(false);

  const toggleEditing = () => {
    setIsEditing((old) => !old);
  };

  const { mutate, isPending } = useTanstackMutation({
    onSuccess: async () => {
      toast.success(t('Messages.successfullyUpdatedAccountInformation'));
      await onSuccess?.();
      setIsEditing(false);
    },
    onError: () => {
      toast.error('Messages.failedUpdatingAccountInformation');
    },
    onSettled: () => setIsEditing(false),
  });

  const handleSubmit = (
    data: AccountInformationFormInputs,
    methods: FormApi<
      AccountInformationFormInputs,
      Partial<AccountInformationFormInputs>
    >,
  ) => {
    const body: any = {
      ...data,
      address: {
        zipCode: data.zipCode,
        name: data.address,
      },
    };
    const method = 'PATCH';
    const path = API_ENDPOINTS.BOOKKEEPERS;

    mutate(
      {
        body,
        method,
        path,
      },
      {
        onError: () => methods.reset(),
      },
    );
  };

  return (
    <CardLayout
      className={classes}
      title={t('Register.accountInformation')}
      subtitle={t('Profile.viewAndUpdateYourAccountInformation')}
      titleIcon={<BankIcon />}
    >
      <Form<AccountInformationFormInputs>
        onSubmit={handleSubmit}
        initialValues={initialData}
        render={({ handleSubmit, ...methods }) => {
          const isDisabled = shouldDisableFormSubmit(methods);
          return (
            <form onSubmit={handleSubmit}>
              {!isEditing && (
                <Button
                  styleType="transparent"
                  icon={<EditIcon />}
                  iconPosition="left"
                  className="account-information-card__form__edit-trigger"
                  type="button"
                  onClick={toggleEditing}
                />
              )}
              <div className="account-information-card__form__row">
                <InputField
                  name="address"
                  title={t('Register.address')}
                  titleicon={<HouseIcon />}
                  className={classNames(
                    'account-information-card__form__row__field',
                    !isEditing &&
                      'account-information-card__form__row__field--disabled',
                  )}
                  disabled={!isEditing}
                  validate={validateRequired('Form.requiredAddress')}
                />
                <InputField
                  name="zipCode"
                  title={t('Register.zipCode')}
                  titleicon={<UserIcon />}
                  className={classNames(
                    'account-information-card__form__row__field',
                    !isEditing &&
                      'account-information-card__form__row__field--disabled',
                  )}
                  disabled={!isEditing}
                  validate={composeValidators(
                    validateRequired('Form.requiredZipCode'),
                    validateRegex('Form.invalidZipCode', ZipCodeRegExp),
                  )}
                />
              </div>
              <div className="account-information-card__form__row">
                <InputField
                  name="vatNumber"
                  title={t('Register.vatNumber')}
                  titleicon={<BillIcon />}
                  className={classNames(
                    'account-information-card__form__row__field',
                    !isEditing &&
                      'account-information-card__form__row__field--disabled',
                  )}
                  disabled={!isEditing}
                  validate={composeValidators(
                    validateRegex('Form.invalidVatNumber', VatNumberRegExp),
                    validateRequired('Form.vatNumberRequired'),
                  )}
                />
                <InputField
                  name="phone"
                  title={t('Register.phone')}
                  titleicon={<PhoneIcon />}
                  className={classNames(
                    'account-information-card__form__row__field',
                    !isEditing &&
                      'account-information-card__form__row__field--disabled',
                  )}
                  disabled={!isEditing}
                  validate={composeValidators(
                    validateRegex('Form.phoneNumberInvalid', PhoneRegExp),
                    validateRequired('Form.phoneNumberRequired'),
                  )}
                />
              </div>
              {isEditing && (
                <div className="personal-information-card__form__row">
                  <div className="personal-information-card__form__buttons">
                    <LoadingButton
                      isLoading={isPending}
                      type="submit"
                      styleType="solid"
                      className="personal-information-card__form__buttons__button"
                      disabled={isDisabled}
                    >
                      {t('Buttons.saveChanges')}
                    </LoadingButton>
                    <Button
                      type="button"
                      styleType="solid-bright"
                      className="personal-information-card__form__buttons__button"
                      onClick={toggleEditing}
                    >
                      {t('Buttons.cancel')}
                    </Button>
                  </div>
                </div>
              )}
            </form>
          );
        }}
      />
    </CardLayout>
  );
};

export default AccountInformationCard;
