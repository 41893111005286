import React from 'react';

type BackgroundSquaresProps = {
  className?: string;
};

const BackgroundSquares: React.FC<BackgroundSquaresProps> = ({ className }) => {
  return (
    <svg
      width="192"
      height="100"
      viewBox="0 0 192 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xlinkHref="http://www.w3.org/1999/xlink"
      className={className}
    >
      <rect
        x="27.1816"
        y="13.0664"
        width="3"
        height="4"
        transform="rotate(-31.1956 27.1816 13.0664)"
        fill="#B8BDD9"
      />
      <rect
        x="34"
        y="35.0498"
        width="5.04373"
        height="6.72497"
        transform="rotate(3.0605 34 35.0498)"
        fill="#B8BDD9"
      />
      <rect
        x="41.1855"
        y="65.8115"
        width="4.70115"
        height="6.2682"
        transform="rotate(-53.8292 41.1855 65.8115)"
        fill="#B8BDD9"
      />
      <rect
        width="2.40269"
        height="3.20359"
        transform="matrix(-0.998574 -0.0533905 -0.0533905 0.998574 40.5664 91.084)"
        fill="#B8BDD9"
      />
      <rect
        x="58.2129"
        width="4.34196"
        height="5.78928"
        transform="rotate(3.0605 58.2129 0)"
        fill="#B8BDD9"
      />
      <rect
        x="77.7969"
        y="94.4121"
        width="3.52236"
        height="4.69649"
        transform="rotate(-0.999156 77.7969 94.4121)"
        fill="#B8BDD9"
      />
      <rect
        x="57.2773"
        y="31.7998"
        width="4.80329"
        height="6.40439"
        transform="rotate(3.0605 57.2773 31.7998)"
        fill="#B8BDD9"
      />
      <rect
        x="134.482"
        y="28.5391"
        width="4.27104"
        height="5.69472"
        transform="rotate(77.0509 134.482 28.5391)"
        fill="#B8BDD9"
      />
      <rect
        x="160.277"
        y="75.8008"
        width="3.90055"
        height="5.20074"
        transform="rotate(3.0605 160.277 75.8008)"
        fill="#B8BDD9"
      />
      <rect
        x="12.1621"
        y="61.7422"
        width="1.25123"
        height="1.66831"
        transform="rotate(17.0767 12.1621 61.7422)"
        fill="#B8BDD9"
      />
      <rect
        x="1.17383"
        y="23"
        width="3"
        height="4"
        transform="rotate(17.0767 1.17383 23)"
        fill="#B8BDD9"
      />
      <rect
        x="146.174"
        y="14"
        width="3"
        height="4"
        transform="rotate(17.0767 146.174 14)"
        fill="#B8BDD9"
      />
      <rect
        x="163.26"
        y="48.2598"
        width="3.90441"
        height="5.20588"
        transform="rotate(76.1947 163.26 48.2598)"
        fill="#B8BDD9"
      />
      <rect
        x="188.174"
        y="27"
        width="3"
        height="4"
        transform="rotate(17.0767 188.174 27)"
        fill="#B8BDD9"
      />
      <rect
        x="187.174"
        y="63"
        width="3"
        height="4"
        transform="rotate(17.0767 187.174 63)"
        fill="#B8BDD9"
      />
      <g filter="url(#filter0_d_1_6848)">
        <rect
          x="78"
          y="23"
          width="50"
          height="50"
          fill="url(#pattern0_1_6848)"
          shapeRendering="crispEdges"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_1_6848"
          x="73"
          y="18"
          width="60"
          height="60"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="2.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.54 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1_6848"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1_6848"
            result="shape"
          />
        </filter>
        <pattern
          id="pattern0_1_6848"
          patternContentUnits="objectBoundingBox"
          width="1"
          height="1"
        >
          <use xlinkHref="#image0_1_6848" transform="scale(0.00195312)" />
        </pattern>
      </defs>
    </svg>
  );
};

export default BackgroundSquares;
