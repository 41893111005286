import React from 'react';

import SkeletonLoader from 'components/SkeletonLoader';
import './PaymentsList.styles.scss';

const PaymentsListLoading: React.FC = () => {
  return (
    <div className="payments-list-loading">
      {Array.from({ length: 12 }, (_, i) => i).map((i) => (
        <SkeletonLoader key={i} className="payments-list-loading__row" />
      ))}
    </div>
  );
};

export default PaymentsListLoading;
