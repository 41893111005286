import classNames from 'classnames';
import React from 'react';

type UserIconProps = {
  className?: string;
};

const UserIcon: React.FC<UserIconProps> = ({ className }) => {
  const classes = classNames('user-icon', className);

  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 8 8"
      xmlns="http://www.w3.org/2000/svg"
      className={classes}
    >
      <path d="M4 3.98749C3.45 3.98749 3 3.81249 2.65 3.46249C2.3 3.11249 2.125 2.66249 2.125 2.11249C2.125 1.56249 2.3 1.11249 2.65 0.762488C3 0.412488 3.45 0.237488 4 0.237488C4.55 0.237488 5 0.412488 5.35 0.762488C5.7 1.11249 5.875 1.56249 5.875 2.11249C5.875 2.66249 5.7 3.11249 5.35 3.46249C5 3.81249 4.55 3.98749 4 3.98749ZM0 7.99999V6.82499C0 6.50832 0.0791666 6.23749 0.2375 6.01249C0.395833 5.78749 0.6 5.61665 0.85 5.49999C1.40833 5.24999 1.94375 5.06249 2.45625 4.93749C2.96875 4.81249 3.48333 4.74999 4 4.74999C4.51667 4.74999 5.02917 4.81457 5.5375 4.94374C6.04583 5.0729 6.57917 5.25832 7.1375 5.49999C7.39583 5.61665 7.60417 5.78749 7.7625 6.01249C7.92083 6.23749 8 6.50832 8 6.82499V7.99999H0ZM0.75 7.24999H7.25V6.82499C7.25 6.69165 7.21042 6.56457 7.13125 6.44374C7.05208 6.3229 6.95417 6.23332 6.8375 6.17499C6.30417 5.91665 5.81667 5.73957 5.375 5.64374C4.93333 5.5479 4.475 5.49999 4 5.49999C3.525 5.49999 3.0625 5.5479 2.6125 5.64374C2.1625 5.73957 1.675 5.91665 1.15 6.17499C1.03333 6.23332 0.9375 6.3229 0.8625 6.44374C0.7875 6.56457 0.75 6.69165 0.75 6.82499V7.24999ZM4 3.23749C4.325 3.23749 4.59375 3.13124 4.80625 2.91874C5.01875 2.70624 5.125 2.43749 5.125 2.11249C5.125 1.78749 5.01875 1.51874 4.80625 1.30624C4.59375 1.09374 4.325 0.987488 4 0.987488C3.675 0.987488 3.40625 1.09374 3.19375 1.30624C2.98125 1.51874 2.875 1.78749 2.875 2.11249C2.875 2.43749 2.98125 2.70624 3.19375 2.91874C3.40625 3.13124 3.675 3.23749 4 3.23749Z" />
    </svg>
  );
};

export default UserIcon;
