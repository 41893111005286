import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Button from 'components/Button';
import PlusIcon from 'icons/Plus.icon';

import './EmptyProjectCard.styles.responsive.scss';
import './EmptyProjectCard.styles.scss';

type EmptyProjectCardProps = {
  className?: string;
  onClick?: () => void;
  isList?: boolean;
};

const EmptyProjectCard: React.FC<EmptyProjectCardProps> = (props) => {
  const { className, onClick, isList = false } = props;

  const classes = classNames(
    'empty-project-card',
    { 'empty-project-card--clickable': !!onClick },
    { 'empty-project-card--list-view': isList },
    className,
  );

  const { t } = useTranslation();

  return (
    <div className={classes} onClick={onClick}>
      <div className="empty-project-card__row">
        <div className="empty-project-card__row__element" />
      </div>
      <div className="empty-project-card__row">
        <div className="empty-project-card__row__element" />
        <div className="empty-project-card__row__element" />
      </div>
      <div className="empty-project-card__row">
        <div className="empty-project-card__row__element" />
        <div className="empty-project-card__row__element" />
      </div>
      <div className="empty-project-card__row">
        <div className="empty-project-card__row__element" />
        <div className="empty-project-card__row__element" />
      </div>
      {!!onClick && (
        <Button
          className="empty-project-card__button"
          icon={<PlusIcon />}
          size="cta"
        >
          {t('Buttons.addNewPaymentSlip')}
        </Button>
      )}
    </div>
  );
};

export default EmptyProjectCard;
