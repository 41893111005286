import { Suspense } from 'react';
import { Toaster } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { BrowserRouter } from 'react-router-dom';

import { KFQueryClientProvider } from 'providers/TanstackQueryProvider/TanstackQuery.provider';
import Routes from 'router/components/Routes/Routes';
import routes from 'router/routes';

function App() {
  return (
    <BrowserRouter>
      <Routes routes={routes} />
    </BrowserRouter>
  );
}

export default function WrappedApp() {
  const { t } = useTranslation();
  return (
    <Suspense fallback={`...${t('Index.loading')}`}>
      <KFQueryClientProvider>
        <>
          <Toaster position="top-center" toastOptions={{ duration: 2500 }} />
          <App />
        </>
      </KFQueryClientProvider>
    </Suspense>
  );
}
