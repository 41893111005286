import React, { useContext } from 'react';

import Header from 'components/Header';
import SkeletonLoader from 'components/SkeletonLoader';
import ProjectContext from '../provider/Project.context';
import ProjectBrowser from './ProjectBrowser.component';
import ProjectTitle from './ProjectTitle.component';

const ProjectHeader: React.FC = () => {
  const { isProjectFetching, isEmptyClientStatePresent } =
    useContext(ProjectContext);
  return (
    <Header
      className="project__header"
      title={
        isProjectFetching ? (
          <SkeletonLoader className="project__header__title__loading" />
        ) : (
          <ProjectTitle />
        )
      }
      browser={
        isProjectFetching ? (
          <SkeletonLoader className="project__header__browser__loading" />
        ) : !isEmptyClientStatePresent ? (
          <ProjectBrowser />
        ) : undefined
      }
    />
  );
};

export default ProjectHeader;
