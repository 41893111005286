import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { useSearchParams } from 'react-router-dom';

import RegisterStep from '../RegisterStep';
import CustomLink from 'components/CustomLink/CustomLink.component';
import { APP_ROUTES } from 'config/routes';
import ArrowBackIcon from 'icons/ArrowBack.icon';
import { SEARCH_PARAMS } from 'config/searchParams';

import './RegisterSuccessfully.styles.scss';

type RegisterStepProps = {
  className?: string;
};

const RegisterSuccessfully: React.FC<RegisterStepProps> = (props) => {
  const { className: classNameProp } = props;

  const className = classNames('is-register-successfully', classNameProp);

  const { t } = useTranslation();

  const [searchParams] = useSearchParams();

  return (
    <RegisterStep stepperIndex={3} title={t('Register.registerSuccess')}>
      <div className={className}>
        <h2 className="is-register-successfully__subtitle">
          {t('Register.youHaveRegisteredSuccessfully')}
        </h2>
        <p className="is-register-successfully__email">
          {t(
            'Register.toCompleteYourRegistrationPleaseCheckYourInboxToVerifyYourMail',
            { email: searchParams.get(SEARCH_PARAMS.EMAIL) },
          )}
        </p>
        <CustomLink
          to={APP_ROUTES.LOGIN}
          iconPosition="left"
          icon={<ArrowBackIcon />}
          title={t('Buttons.backToLogin')}
          className="is-register-successfully__link"
        />
      </div>
    </RegisterStep>
  );
};

export default RegisterSuccessfully;
