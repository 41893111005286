import classNames from 'classnames';
import React, { forwardRef } from 'react';
import CurrencyInput, { CurrencyInputProps } from 'react-currency-input-field';
import { Field, FieldRenderProps, UseFieldConfig } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import Input from 'components/Input';

import './CurrencyInputField.styles.responsive.scss';
import './CurrencyInputField.styles.scss';

type CurrencyInputFieldComponentProps = CurrencyInputProps &
  FieldRenderProps<string, HTMLElement>;

type CurrencyInputFieldProps = { name: string } & CurrencyInputProps &
  UseFieldConfig<string, string>;

const CurrencyInputFieldComponent = forwardRef<
  HTMLInputElement,
  CurrencyInputFieldComponentProps
>((props, ref) => {
  const {
    input: { onChange, ...input },
    meta,
    ...restOfProps
  } = props;

  const { t } = useTranslation();

  const error = (!meta.dirtySinceLastSubmit && meta.submitError) || meta.error;

  const translatedError = meta.touched && t(error);

  const className = classNames('currency-input-field', {
    'currency-input-field--error': !!translatedError,
  });

  return (
    <div className={className}>
      <CurrencyInput
        ref={ref}
        allowDecimals
        decimalsLimit={2}
        decimalSeparator="."
        groupSeparator=","
        customInput={Input}
        onValueChange={onChange}
        decimalScale={2}
        {...input}
        {...restOfProps}
      />
      <div className="is-input__footer">
        {!!translatedError && <p>{translatedError}</p>}
      </div>
    </div>
  );
});

const CurrencyInputField: React.FC<CurrencyInputFieldProps> = (props) => {
  return <Field component={CurrencyInputFieldComponent} {...props} />;
};

export default CurrencyInputField;
