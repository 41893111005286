import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { useCallback, useEffect, useState } from 'react';

import { API_ENDPOINTS } from 'config/endpoints';
import { xmlQueryFn } from 'providers/TanstackQueryProvider/utils/defaultQueryFn';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

const useDownloadPayments = (
  projectId: string,
  options?: Partial<UseQueryOptions>,
) => {
  const [downloadPayments, setDownloadPayments] = useState(false);

  const { t } = useTranslation();

  const response = useQuery({
    queryKey: [API_ENDPOINTS.GET_XML_PAYMENT_SLIPS(projectId)],
    queryFn: xmlQueryFn,
    enabled: downloadPayments,
    staleTime: 0,
    retry: false,
    ...options,
  });

  const download = useCallback(() => {
    setDownloadPayments(true);
  }, []);

  const stopDownload = useCallback(() => {
    setDownloadPayments(false);
  }, []);

  useEffect(() => {
    if (response.data || response.isError) stopDownload();
    if (response.isError)
      toast.error(t('Messages.errorDownloadingPaymentSlips'));
  }, [response.data, response.isError, stopDownload, t]);

  return { download, ...response };
};

export default useDownloadPayments;
