import React from 'react';
import { Icon } from 'models/Icon';

const PaymentsIcon: React.FC<Icon> = (props) => {
  const { className } = props;

  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M18.3669 17.2334C17.2558 17.2334 16.3113 16.8445 15.5336 16.0667C14.7558 15.2889 14.3669 14.3445 14.3669 13.2334C14.3669 12.1223 14.7558 11.1778 15.5336 10.4C16.3113 9.62227 17.2558 9.23338 18.3669 9.23338C19.478 9.23338 20.4224 9.62227 21.2002 10.4C21.978 11.1778 22.3669 12.1223 22.3669 13.2334C22.3669 14.3445 21.978 15.2889 21.2002 16.0667C20.4224 16.8445 19.478 17.2334 18.3669 17.2334ZM8.50023 22.6667C7.61134 22.6667 6.86134 22.3612 6.25023 21.75C5.63912 21.1389 5.33356 20.3889 5.33356 19.5V6.96672C5.33356 6.10005 5.63912 5.3556 6.25023 4.73338C6.86134 4.11116 7.61134 3.80005 8.50023 3.80005H28.3002C29.1669 3.80005 29.9058 4.11116 30.5169 4.73338C31.128 5.3556 31.4336 6.10005 31.4336 6.96672V19.5C31.4336 20.3889 31.128 21.1389 30.5169 21.75C29.9058 22.3612 29.1669 22.6667 28.3002 22.6667H8.50023ZM11.4336 19.9H25.3336C25.3336 18.9667 25.6558 18.1778 26.3002 17.5334C26.9447 16.8889 27.7336 16.5667 28.6669 16.5667V9.90005C27.7336 9.90005 26.9447 9.57227 26.3002 8.91672C25.6558 8.26116 25.3336 7.47783 25.3336 6.56672H11.4336C11.4336 7.47783 11.1113 8.26116 10.4669 8.91672C9.82245 9.57227 9.03356 9.90005 8.10023 9.90005V16.5667C9.03356 16.5667 9.82245 16.8889 10.4669 17.5334C11.1113 18.1778 11.4336 18.9667 11.4336 19.9ZM26.6669 27.4334H3.70023C2.83356 27.4334 2.09467 27.1278 1.48356 26.5167C0.87245 25.9056 0.566895 25.1667 0.566895 24.3V9.33338H3.70023V24.3H26.6669V27.4334ZM8.10023 19.9V6.56672V19.9Z"
        id="onHover"
      />
    </svg>
  );
};

export default PaymentsIcon;
