import classNames from 'classnames';
import Link from 'components/Link';
import ArrowIcon from 'icons/Arrow.icon';
import { Project } from 'models/Project';
import React from 'react';
import { useTranslation } from 'react-i18next';
import './ProjectsPreviewCard.style.scss';

export type ProjectsPreviewCardProps = {
  className?: string;
  project: Project;
  onClick?: () => void;
  selected?: boolean;
  linkTo?: string;
};

const ProjectsPreviewCard: React.FC<ProjectsPreviewCardProps> = (props) => {
  const { className, project, onClick, selected, linkTo } = props;

  const { t } = useTranslation();
  // calculation of processed projects in percentage
  const finishedProjects = (project.processed * 100) / project.payments;

  const projectsPreviewCardClasses = classNames(
    'projects-preview-card',
    { 'projects-preview-card--clickable': onClick },
    { 'projects-preview-card--selected': selected },
    className,
  );
  const proccessedClasses = classNames(
    'projects-preview-card__percentage__diagram',
    'projects-preview-card__percentage__diagram--proccessed',
  );

  return (
    <section className={projectsPreviewCardClasses} onClick={onClick}>
      <div className="projects-preview-card__info">
        <p> {project.name} </p>
        {linkTo && (
          <Link
            to={linkTo}
            variant="button"
            onClick={(e) => e.stopPropagation()}
            className="projects-preview-card__link"
          >
            {t('Buttons.goToProject')}
            <ArrowIcon className="projects-preview-card__link__icon" />
          </Link>
        )}
      </div>
      <div className="projects-preview-card__percentage">
        <p className="projects-preview-card__percentage__text">
          <span className="projects-preview-card__percentage__text--proccessed">
            {project.processed} {t('Index.proccessed')}
          </span>
          <span className="projects-preview-card__percentage__text--total">
            {project.payments} {t('Index.total')}
          </span>
        </p>

        <div className="projects-preview-card__percentage__diagram">
          <div
            className={proccessedClasses}
            style={{ width: `${finishedProjects}%` }}
          ></div>
        </div>
      </div>
    </section>
  );
};

export default ProjectsPreviewCard;
