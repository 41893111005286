import classNames from 'classnames';
import React, { useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';

import useDisclosure from 'hooks/useDisclosure';
import useInvalidateUI from 'hooks/useInvalidateUI';
import { calculateToolTipPosition } from './utils';

import './ToolTip.styles.responsive.scss';
import './ToolTip.styles.scss';

type ToolTipProps = {
  className?: string;
  contentClassName?: string;
  content: React.ReactNode | string;
  children: React.ReactNode;
  distance?: number;
  disabled?: boolean;
};

const ToolTip: React.FC<ToolTipProps> = (props) => {
  const {
    className,
    content,
    children,
    contentClassName,
    distance = 4,
    disabled = false,
  } = props;

  const classes = classNames(
    'tool-tip',
    { 'tool-tip--disabled': disabled },
    className,
  );

  const { isOpen, open, close } = useDisclosure();

  const containerRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isOpen) {
      window.addEventListener('scroll', close);
      return () => window.removeEventListener('scroll', close);
    }
  }, [isOpen, close]);

  useInvalidateUI();

  const position = calculateToolTipPosition(
    containerRef.current?.getBoundingClientRect(),
    contentRef.current?.getBoundingClientRect(),
    distance,
  );

  return (
    <div
      ref={containerRef}
      className={classes}
      onMouseMoveCapture={open}
      onMouseLeave={close}
    >
      {children}
      {!disabled &&
        createPortal(
          <div
            className={classNames(
              'tool-tip__content',
              {
                'tool-tip__content--hidden': !isOpen,
              },
              contentClassName,
            )}
            style={position}
            ref={contentRef}
          >
            {content}
          </div>,
          document.body,
        )}
    </div>
  );
};

export default ToolTip;
