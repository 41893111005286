import React from 'react';
import { Icon } from 'models/Icon';

const CreateClientIcon: React.FC<Icon> = (props) => {
  return (
    <svg
      viewBox="0 0 36 36"
      height="36"
      width="36"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M26.678 21.0301V16.2792H21.9271V14.0865H26.678V9.33569H28.8707V14.0865H33.6215V16.2792H28.8707V21.0301H26.678ZM13.1563 18.0699C11.5483 18.0699 10.2327 17.5583 9.20946 16.5351C8.1862 15.5118 7.67457 14.1962 7.67457 12.5882C7.67457 10.9802 8.1862 9.6646 9.20946 8.64134C10.2327 7.61808 11.5483 7.10645 13.1563 7.10645C14.7643 7.10645 16.0799 7.61808 17.1032 8.64134C18.1264 9.6646 18.6381 10.9802 18.6381 12.5882C18.6381 14.1962 18.1264 15.5118 17.1032 16.5351C16.0799 17.5583 14.7643 18.0699 13.1563 18.0699ZM1.46191 29.8009V26.3657C1.46191 25.5129 1.67509 24.7394 2.10145 24.0451C2.52781 23.3507 3.14298 22.833 3.94698 22.4919C5.77423 21.6879 7.40048 21.1215 8.82574 20.7926C10.251 20.4636 11.6945 20.2992 13.1563 20.2992C14.6181 20.2992 16.0556 20.4636 17.4686 20.7926C18.8817 21.1215 20.5019 21.6879 22.3291 22.4919C23.1331 22.8573 23.7544 23.3812 24.1929 24.0633C24.6315 24.7455 24.8507 25.5129 24.8507 26.3657V29.8009H1.46191ZM3.65462 27.6082H22.658V26.3657C22.658 25.9759 22.5484 25.6043 22.3291 25.251C22.1098 24.8978 21.8175 24.6359 21.452 24.4653C19.7222 23.6613 18.2604 23.1314 17.0666 22.8756C15.8728 22.6198 14.5694 22.4919 13.1563 22.4919C11.7432 22.4919 10.4337 22.6198 9.22773 22.8756C8.02174 23.1314 6.55385 23.6613 4.82406 24.4653C4.45861 24.6359 4.17234 24.8978 3.96525 25.251C3.75816 25.6043 3.65462 25.9759 3.65462 26.3657V27.6082ZM13.1563 15.8772C14.1065 15.8772 14.8922 15.5666 15.5135 14.9453C16.1347 14.3241 16.4454 13.5384 16.4454 12.5882C16.4454 11.638 16.1347 10.8523 15.5135 10.231C14.8922 9.60978 14.1065 9.29915 13.1563 9.29915C12.2061 9.29915 11.4204 9.60978 10.7992 10.231C10.1779 10.8523 9.86727 11.638 9.86727 12.5882C9.86727 13.5384 10.1779 14.3241 10.7992 14.9453C11.4204 15.5666 12.2061 15.8772 13.1563 15.8772Z" />
    </svg>
  );
};

export default CreateClientIcon;
