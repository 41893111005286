import { Field, FieldRenderProps, UseFieldConfig } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import AutoSuggest, {
  AutoSuggestProps,
} from 'components/AutoSuggest/AutoSuggest.component';

import './AutoSuggestField.styles.responsive.scss';
import './AutoSuggestField.styles.scss';

type InputMode = 'input' | 'select';

type AutoSuggestFieldProps<T> = {
  name: string;
  inputOrSelectMode?: InputMode;
} & AutoSuggestProps<T> &
  UseFieldConfig<T, T>;

type AutoSuggestFieldComponentProps<T> = {
  inputOrSelectMode?: InputMode;
} & AutoSuggestProps<T> &
  FieldRenderProps<T, HTMLElement>;

const AutoSuggestFieldComponent = <T,>(
  props: AutoSuggestFieldComponentProps<T>,
) => {
  const {
    input: { onChange, value, ...input },
    meta,
    onSelect,
    inputOrSelectMode = 'select',
    onChange: onOutsideChange,
    ...searchConfig
  } = props;

  const { t } = useTranslation();

  const error = (!meta.dirtySinceLastSubmit && meta.submitError) || meta.error;

  const handleChange = (item: T) => {
    onChange(item);
    onOutsideChange?.(item);
  };

  const translatedError = meta.touched && t(error);
  const autoSuggestConfig = {
    value: inputOrSelectMode === 'select' ? value : undefined,
    onChange: inputOrSelectMode === 'select' ? handleChange : undefined,
    inputValue: inputOrSelectMode === 'input' ? (value as string) : undefined,
    onInputChange: inputOrSelectMode === 'input' ? onChange : undefined,
  };

  return (
    <AutoSuggest<T>
      error={translatedError}
      {...autoSuggestConfig}
      {...input}
      {...searchConfig}
    />
  );
};

const AutoSuggestField = <T,>(props: AutoSuggestFieldProps<T>) => (
  <Field component={AutoSuggestFieldComponent<T>} {...props} />
);

export default AutoSuggestField;
