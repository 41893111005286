import animationData from 'assets/projects.json';
import Button from 'components/Button';
import EmptyStateLayout from 'components/EmptyStateLayout';
import PlusIcon from 'icons/Plus.icon';
import Lottie from 'lottie-react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

type CreditorsEmptyStateProps = {
  onClick: () => void;
};

const CreditorsEmptyState: FC<CreditorsEmptyStateProps> = (props) => {
  const { onClick } = props;

  const { t } = useTranslation();

  return (
    <EmptyStateLayout
      className="creditors--empty-state"
      title={t('Messages.youDontHaveAnyCreditorsYet')}
    >
      <EmptyStateLayout.Title className="creditors--empty-state__title" />
      <EmptyStateLayout.Content>
        <Lottie
          animationData={animationData}
          loop={false}
          className="creditors--empty-state__animation"
        />
      </EmptyStateLayout.Content>
      <EmptyStateLayout.Footer>
        <Button
          className="creditors--empty-state__button"
          icon={<PlusIcon className="creditors--empty-state__icon" />}
          onClick={onClick}
          styleType="solid-white"
          size="cta"
        >
          {t('Buttons.addNewCreditor')}
        </Button>
      </EmptyStateLayout.Footer>
    </EmptyStateLayout>
  );
};

export default CreditorsEmptyState;
