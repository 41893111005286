import Catalog from 'pages/Catalog';
import { Outlet } from 'react-router-dom';
import clientMembersPublicRoutes from './subrouters/ClientMembers/routes';
import Dashboard from './subrouters/Dashboard';
import ForgotPassword from './subrouters/ForgotPassword/ForgotPassword';
import forgotPasswordRoutes from './subrouters/ForgotPassword/routes';
import Home from './subrouters/Home';
import homeRoutes from './subrouters/Home/routes';
import InvalidInvitation from './subrouters/InvalidInvitation/InvalidInvitation';
import Login from './subrouters/Login';
import Profile from './subrouters/Profile';
import Register from './subrouters/Register/Register';
import registerRoutes from './subrouters/Register/routes';
import { ComplexRoute } from './types';

export default [
  {
    path: '',
    authorized: true,
    onlyPublic: false,
    element: Home,
    routes: homeRoutes,
  },
  {
    path: 'login',
    authorized: false,
    onlyPublic: true,
    element: Login,
  },
  {
    path: 'register',
    authorized: false,
    onlyPublic: true,
    element: Register,
    routes: registerRoutes,
  },
  {
    path: 'client-member',
    authorized: false,
    onlyPublic: true,
    element: () => <Outlet />,
    routes: clientMembersPublicRoutes,
  },
  {
    path: 'profile',
    authorized: true,
    onlyPublic: false,
    element: Profile,
  },

  {
    path: 'forgot-password',
    authorized: false,
    onlyPublic: true,
    element: ForgotPassword,
    routes: forgotPasswordRoutes,
  },
  {
    path: 'invalid-invitation',
    authorized: false,
    onlyPublic: true,
    element: InvalidInvitation,
  },
  {
    path: 'catalog',
    element: Catalog,
  },
  {
    path: 'dashboard',
    element: Dashboard,
    authorizedRoles: ['book_keeper'],
  },
  {
    path: 'home',
    element: Home,
  },
  {
    path: '*',
    element: () => `not found`,
  },
] as Array<ComplexRoute>;
