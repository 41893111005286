import { INVITATION_STATUS } from 'types';

export type Role = 'book_keeper' | 'client';

export type User = {
  _id: string;
  firstName: string;
  lastName: string;
  email: string;
  role: Role;
  clientId?: string;
};

export type Account = {
  _id: string;
  email: string;
  phone: string;
  firstName: string;
  lastName: string;
  inviteStatus: INVITATION_STATUS;
  updatedAt: string;
  createdAt: string;
  clientId: string;
  role?: 'client' | 'bookkeeper';
};

export type Address = {
  name: string;
  zipCode: string;
};
export type Bookkeeper = {
  firstName: string;
  lastName: string;
  vatNumber: string;
  email: string;
  password: string;
  phone: string;
  address: Address;
  role: string;
};

export enum ClientType {
  Individual = 'individual',
  LegalEntity = 'legalEntity',
}

export type Client = {
  _id: string;
  firstName?: string;
  lastName?: string;
  companyName?: string;
  vatNumber?: string;
  address: Address;
  bankAccount: string;
  numberProjects?: number;
  inviteStatus: INVITATION_STATUS;
};

export type ClientWithProjectsInformation = {
  amountCurrentMonth: number;
  amountPreviousMonth: number;
  numberCreditors: number;
  numberProjects: number;
  numberProjectsInCurrentYear: number;
  percentageDifference: string;
} & Client;

export type ClientWithProjectStatus = {
  numberOfEmptyProjects: number;
  numberOfInProcessProjects: number;
  numberOfFulfilledProjects: number;
} & Client;

export type Creditor = {
  _id: string;
  name: string;
  address: Address;
  bankAccount: string;
  firstPaymentOn?: Date | string;
  updatedAt?: Date | string;
  numberOfPaymentSlips: number;
};

export type CreateClient = {
  firstName?: string;
  lastName?: string;
  companyName?: string;
  address: Address;
  bankAccount: string;
  vatNumber?: string;
};
