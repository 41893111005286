import classNames from 'classnames';
import React from 'react';
import './TextArea.styles.responsive.scss';
import './TextArea.styles.scss';

type TextAreaProps = {
  className?: string;
} & React.DetailedHTMLProps<
  React.TextareaHTMLAttributes<HTMLTextAreaElement>,
  HTMLTextAreaElement
>;

const TextArea: React.FC<TextAreaProps> = (props) => {
  const { className, title, ...rest } = props;

  const classes = classNames('text-area', className);

  return <textarea className={classes} {...rest} />;
};

export default TextArea;
