import { useCallback, useEffect } from 'react';

export type IUseKeyDown = (
  key: string,
  action: () => void,
) => {
  onKeyDown: (e: KeyboardEvent) => void;
};

const useKeyDown: IUseKeyDown = (key, action) => {
  const onKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === key) action();
    },
    [action, key],
  );

  useEffect(() => {
    document.addEventListener('keydown', onKeyDown);
    return () => document.removeEventListener('keydown', onKeyDown);
  }, [onKeyDown]);

  return { onKeyDown };
};

export default useKeyDown;
