import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Button from 'components/Button';
import CheckBox from 'components/CheckBox';
import DeletePaymentModal from 'components/DeletePaymentModal';
import { APP_ROUTES } from 'config/routes';
import DeleteIcon from 'icons/Delete.icon';
import EditIcon from 'icons/Edit.icon';
import {
  Payment,
  PAYMENT_STATUS,
  PaymentSelectingMode,
  SelectedPayment,
} from 'models/Payment';

import Link from 'components/Link';
import ToolTip from 'components/ToolTip';
import { SEARCH_PARAMS } from 'config/searchParams';
import useSearchParams from 'hooks/useSearchParams';
import ErrorIcon from 'icons/Error.icon';
import SuccessCheckedIcon from 'icons/SuccessChecked.icon';
import { useTranslation } from 'react-i18next';
import authService from 'services/authService';
import credentialsService from 'services/credentialsService';
import storageService, { STORAGE_KEYS } from 'services/storageService';
import utils from 'utils';
import './PaymentTable.styles.scss';

export type PaymentInfo = {
  _id: string;
  payer: string;
  payee: string;
  project: string;
  status: PAYMENT_STATUS;
  amount: number | string;
  purpose: string;
  payeesAccount: string;
  model: string;
  paymentCode: number;
  referenceNumber?: string;
  button?: React.ReactNode;
  checkbox?: React.ReactNode;
};
export type HeaderTable = {
  heading?: string | React.ReactNode;
  value: keyof PaymentInfo;
};

type TableProps = {
  classNameTable?: string;
  paymentsList?: Payment[];
  headers: Array<HeaderTable>;
  hideButtons?: boolean;
  paymentSelectingMode?: PaymentSelectingMode;
  checked?: SelectedPayment[];
  onCheck?: (checkedPayment: SelectedPayment, newValue: boolean) => void;
  linkTo?: boolean;
};

type TableRowProps = {
  payment: PaymentInfo;
  headers: Array<HeaderTable>;
  checked?: boolean;
  isProcessing?: boolean;
  isChecked?: boolean;
  onClick?: () => void;
};

const TableRow = (props: TableRowProps) => {
  const { payment, headers, checked = false, isChecked, isProcessing } = props;

  const rowRef = useRef<HTMLTableRowElement>(null);
  const { getSearchParam, removeSearchParam } = useSearchParams();
  const selectedPaymentId = getSearchParam(SEARCH_PARAMS.PAYMENT_ID);
  const isScrolled = storageService.getItem(STORAGE_KEYS.IS_SCROLLED);
  useEffect(() => {
    if (payment._id === selectedPaymentId && !isScrolled)
      rowRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
  }, [payment, selectedPaymentId, removeSearchParam, isScrolled]);

  const checkedClasses = classNames(
    'table-payments__row',
    {
      'table-payments--checked': checked,
      'table-payments--not-selected': isProcessing && !isChecked,
      'table-payments--highlight':
        selectedPaymentId === payment._id && !isScrolled,
    },
    `table-payments__row--${payment.status.toLowerCase()}`,
  );

  return (
    <tr className={checkedClasses} ref={rowRef}>
      {headers.map((header: HeaderTable, index: number) => {
        return <td key={index}>{payment[`${header.value}`]}</td>;
      })}
    </tr>
  );
};

const TableHead = ({
  header,
}: {
  header: Record<string, string | React.ReactNode>;
}) => {
  return <th>{header.heading}</th>;
};

const PaymentTable: React.FC<TableProps> = (props) => {
  const {
    classNameTable,
    paymentsList,
    headers,
    hideButtons = false,
    onCheck,
    checked,
    paymentSelectingMode = PaymentSelectingMode.None,
    linkTo,
  } = props;
  const tableClasses = classNames(
    'table-payments',
    `table-payments--mode-${paymentSelectingMode}`,
    classNameTable,
  );

  const navigate = useNavigate();

  const { t } = useTranslation();

  const [deletingPayment, setDeletingPayment] = useState<Payment>();

  return (
    <table className={tableClasses}>
      <thead>
        <tr>
          {headers.map(
            (
              header: Record<string, string | React.ReactNode>,
              index: number,
            ) => (
              <TableHead header={header} key={index} />
            ),
          )}
        </tr>
      </thead>
      <tbody>
        {paymentsList.map((payment: Payment) => {
          const isChecked = !!checked?.find((check) => check.id === payment.id);

          const shouldBeSelectable = utils.shouldPaymentBeSelectable(
            paymentSelectingMode,
            payment.status,
          );

          const isBookkeeper = authService.checkRolesForUser(
            credentialsService.user,
            ['book_keeper'],
          );

          const handleCheck = () => {
            if (!shouldBeSelectable) return;
            onCheck(
              { id: payment.id, amount: payment.price.amount },
              !isChecked,
            );
          };

          return (
            <TableRow
              key={payment.id}
              isChecked={isChecked}
              isProcessing={shouldBeSelectable}
              onClick={handleCheck}
              payment={{
                checkbox: shouldBeSelectable && (
                  <CheckBox
                    value={isChecked}
                    onChange={() => {
                      handleCheck();
                    }}
                  />
                ),
                _id: payment._id,
                amount: `${payment?.price?.amount.toLocaleString()} ${
                  payment?.price?.currency
                }`,
                status: payment?.status,
                payee: payment?.payee?.name,
                payer: payment?.payer?.name,
                project: payment?.projects[0]?.name,
                purpose: payment?.purpose,
                model: payment?.model,
                paymentCode: payment?.paymentCode,
                referenceNumber: payment?.referenceNumber,
                payeesAccount: payment?.payeesAccount,
                button: linkTo ? (
                  <Link
                    className="table-payments__link"
                    variant="button"
                    to={`${APP_ROUTES.PROJECTS}/${payment?.projectId}?${SEARCH_PARAMS.PAYMENT_ID}=${payment?.id}`}
                  >
                    {t('Index.details')}
                  </Link>
                ) : (
                  <span className="table-payments__buttons">
                    {[
                      PAYMENT_STATUS.CHECKED,
                      PAYMENT_STATUS.PROCESSED,
                    ].includes(payment.status) && (
                      <ToolTip
                        className="table-payments__tool-tip-icon"
                        contentClassName="table-payments__tool-tip"
                        content={t(
                          `Index.${
                            payment.status === PAYMENT_STATUS.CHECKED
                              ? 'verified'
                              : 'processed'
                          }`,
                        )}
                      >
                        <SuccessCheckedIcon className="table-payments__icon" />
                      </ToolTip>
                    )}
                    {payment.status === PAYMENT_STATUS.ERROR && (
                      <ToolTip
                        className="table-payments__tool-tip-icon"
                        contentClassName="table-payments__tool-tip"
                        disabled={!payment.message}
                        content={payment.message}
                      >
                        <ErrorIcon className="table-payments__icon table-payments__icon--error" />
                      </ToolTip>
                    )}
                    {!hideButtons &&
                      !shouldBeSelectable &&
                      isBookkeeper &&
                      payment.status !== PAYMENT_STATUS.PROCESSED && (
                        <>
                          <Button
                            styleType="icon"
                            size="icon"
                            icon={<EditIcon />}
                            onClick={() => {
                              sessionStorage.setItem(
                                'initialValues',
                                JSON.stringify(payment),
                              );
                              navigate(APP_ROUTES.EDIT_PAYMENT);
                            }}
                          />
                          <Button
                            styleType="icon"
                            size="icon"
                            icon={<DeleteIcon />}
                            onClick={() => setDeletingPayment(payment)}
                          />
                        </>
                      )}
                  </span>
                ),
              }}
              headers={headers}
              checked={[
                PAYMENT_STATUS.CHECKED,
                PAYMENT_STATUS.PROCESSED,
              ].includes(payment?.status)}
            />
          );
        })}
        {deletingPayment && (
          <DeletePaymentModal
            close={() => setDeletingPayment(undefined)}
            payment={deletingPayment}
            onSuccess={() => setDeletingPayment(undefined)}
          />
        )}
      </tbody>
    </table>
  );
};

export default PaymentTable;
