import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { API_ENDPOINTS } from 'config/endpoints';
import { PaginationResponse } from 'models/Response';
import { Account } from 'models/User';

const useMembersForClients = (
  clientId: string,
  options?: Partial<UseQueryOptions<PaginationResponse<Account>>>,
) => {
  return useQuery<PaginationResponse<Account>>({
    queryKey: [API_ENDPOINTS.GET_MEMBERS_FOR_CLIENT(clientId)],
    ...options,
  });
};

export default useMembersForClients;
