import classNames from 'classnames';
import React from 'react';

import CreditorForm from 'components/Forms/CreditorForm';
import { CreditorFormProps } from 'components/Forms/CreditorForm/CreditorForm.component';
import Modal from 'components/Modal';
import { ModalProps } from 'components/Modal/Modal.component';
import SkeletonLoader from 'components/SkeletonLoader';
import { Creditor } from 'models/User';

import './CreditorDetailsForm.styles.responsive.scss';
import './CreditorDetailsForm.styles.scss';

type CreditorDetailsFormProps = {
  className?: string;
  isLoading: boolean;
  data: Creditor;
} & ModalProps &
  Omit<CreditorFormProps, 'initialValues'>;

const CreditorDetailsForm: React.FC<CreditorDetailsFormProps> = (props) => {
  const { className, isLoading, data, closeModal, onClose, ...formProps } =
    props;

  const classes = classNames('creditor-details-form', className);

  return (
    <Modal closeModal={closeModal} onClose={onClose} className={classes}>
      {isLoading ? (
        <div className="creditor-details-form__loader">
          <SkeletonLoader className="creditor-details-form__loader--thick" />
          <SkeletonLoader className="creditor-details-form__loader--thick" />
          <SkeletonLoader className="creditor-details-form__loader--thick" />
          <SkeletonLoader className="creditor-details-form__loader--thick" />
          <SkeletonLoader className="creditor-details-form__loader--button" />
        </div>
      ) : (
        <CreditorForm initialValues={data} {...formProps} />
      )}
    </Modal>
  );
};

export default CreditorDetailsForm;
