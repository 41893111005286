import { useEffect } from 'react';

export default function (
  cb: (...args: any[]) => void,
  triggerArray?: string[],
) {
  useEffect(() => {
    const onAnimationEnd = (e: AnimationEvent) => {
      if (!triggerArray || triggerArray.includes(e.animationName)) cb();
    };

    document.addEventListener('animationend', onAnimationEnd);
    return () => document.removeEventListener('animationend', onAnimationEnd);
  }, [cb, triggerArray]);
}
