import React, { forwardRef } from 'react';
import { Field, FieldRenderProps, UseFieldConfig } from 'react-final-form';
import Input, { InputProps } from 'components/Input/Input.component';
import { t } from 'i18next';

export type InputFieldProps = { name: string } & InputProps &
  UseFieldConfig<string, string>;

export type InputFieldComponentProps = InputProps &
  FieldRenderProps<string, HTMLElement>;

export const InputFieldComponent = forwardRef<
  HTMLInputElement,
  InputFieldComponentProps
>((props, ref) => {
  const { input, meta, ...restOfProps } = props;

  const translatedError = t(
    (!meta.dirtySinceLastSubmit && meta.submitError) || meta.error,
  );

  return (
    <Input
      {...input}
      {...restOfProps}
      error={meta.touched && translatedError}
      ref={ref}
    />
  );
});

const InputField: React.FC<InputFieldProps> = (props) => (
  <Field component={InputFieldComponent} {...props} />
);

export default InputField;
