import { Icon } from 'models/Icon';
import React from 'react';

const VectorArrowIcon: React.FC<Icon> = (props) => {
  const { className } = props;

  return (
    <svg
      width="12"
      height="8"
      viewBox="0 0 12 8"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path d="M10.59 0L6 4.58L1.41 0L0 1.41L6 7.41L12 1.41L10.59 0Z" />
    </svg>
  );
};

export default VectorArrowIcon;
