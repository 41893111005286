import classNames from 'classnames';
import React from 'react';

import './RadioButton.styles.responsive.scss';
import './RadioButton.styles.scss';

export type RadioButtonProps = {
  className?: string;
  label: string;
  name: string;
  id: string;
  value: string;
  onChange: (value: string) => void;
} & Omit<
  React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >,
  'type' | 'onChange'
>;

const RadioButton: React.FC<RadioButtonProps> = (props) => {
  const { className, id, label, value, onChange, ...inputProps } = props;

  const classes = classNames('radio-button', className);

  return (
    <label className={classes}>
      {label}
      <input
        id={id}
        value={id}
        type="radio"
        onChange={(e) => onChange(e.target.value)}
        checked={value === id}
        className="radio-button__input"
        {...inputProps}
      />
      <span className="radio-button__checkmark" />
    </label>
  );
};

export default RadioButton;
