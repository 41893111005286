import React from 'react';

import SkeletonLoader from 'components/SkeletonLoader';

const PaymentFormLoader = () => (
  <PaymentFormWrapper>
    <PaymentFormContent>
      <PaymentFormSide>
        {Array.from({ length: 4 }, (_, i) => (
          <PaymentFormRow key={i}>
            <PaymentFormField>
              <PaymentFormLabel />
              <PaymentFormInput />
            </PaymentFormField>
          </PaymentFormRow>
        ))}
      </PaymentFormSide>
      <PaymentFormSide>
        <PaymentFormRow>
          <PaymentFormField>
            <PaymentFormLabel />
            <PaymentFormCalendarWrapper>
              <PaymentFormInput />
              <PaymentFormCalendarButton />
            </PaymentFormCalendarWrapper>
          </PaymentFormField>
          <PaymentFormField>
            <PaymentFormLabel />
            <PaymentFormCalendarWrapper>
              <PaymentFormInput />
              <PaymentFormCalendarButton />
            </PaymentFormCalendarWrapper>
          </PaymentFormField>
        </PaymentFormRow>
        <PaymentFormRow>
          <PaymentFormRow>
            <PaymentFormField>
              <PaymentFormLabel />
              <PaymentFormInput />
            </PaymentFormField>
            <PaymentFormField>
              <PaymentFormLabel />
              <PaymentFormInput />
            </PaymentFormField>
          </PaymentFormRow>
          <PaymentFormField>
            <PaymentFormLabel />
            <PaymentFormInput />
          </PaymentFormField>
        </PaymentFormRow>
        <PaymentFormRow>
          <PaymentFormField>
            <PaymentFormLabel />
            <PaymentFormInput />
          </PaymentFormField>
        </PaymentFormRow>
        <PaymentFormRow>
          <PaymentFormField>
            <PaymentFormLabel />
            <PaymentFormInput />
          </PaymentFormField>
          <PaymentFormField>
            <PaymentFormLabel />
            <PaymentFormInput />
          </PaymentFormField>
        </PaymentFormRow>
      </PaymentFormSide>
    </PaymentFormContent>
    <PaymentFormFooter>
      <PaymentFormButton />
    </PaymentFormFooter>
  </PaymentFormWrapper>
);

type PaymentFormLoaderComponentProps = {
  children?: React.ReactNode;
};

const PaymentFormWrapper: React.FC<PaymentFormLoaderComponentProps> = ({
  children,
}) => <div className="payment-form__loading">{children}</div>;

const PaymentFormContent: React.FC<PaymentFormLoaderComponentProps> = ({
  children,
}) => <div className="payment-form__loading__content">{children}</div>;

const PaymentFormSide: React.FC<PaymentFormLoaderComponentProps> = ({
  children,
}) => <div className="payment-form__loading__content__side">{children}</div>;

const PaymentFormRow: React.FC<PaymentFormLoaderComponentProps> = ({
  children,
}) => (
  <div className="payment-form__loading__content__side__row">{children}</div>
);

const PaymentFormField: React.FC<PaymentFormLoaderComponentProps> = ({
  children,
}) => (
  <div className="payment-form__loading__content__side__row__field">
    {children}
  </div>
);
const PaymentFormLabel: React.FC<PaymentFormLoaderComponentProps> = () => (
  <SkeletonLoader className="payment-form__loading__content__side__row__field__label" />
);

const PaymentFormInput: React.FC = () => (
  <SkeletonLoader className="payment-form__loading__content__side__row__field__input" />
);

const PaymentFormCalendarWrapper: React.FC<PaymentFormLoaderComponentProps> = ({
  children,
}) => (
  <div className="payment-form__loading__content__side__row__field__input--calendar">
    {children}
  </div>
);

const PaymentFormCalendarButton: React.FC = () => (
  <SkeletonLoader className="payment-form__loading__content__side__row__field__calendar" />
);

const PaymentFormFooter: React.FC<PaymentFormLoaderComponentProps> = ({
  children,
}) => <div className="payment-form__loading__footer">{children}</div>;

const PaymentFormButton: React.FC = () => (
  <SkeletonLoader className="payment-form__loading__footer__button" />
);

export default PaymentFormLoader;
