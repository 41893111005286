import { DataDisplayRowProps } from 'components/DataDisplayRow/DataDisplayRow.component';

export type ConfigObject<T> = {
  [K in keyof T]: {
    property: K;
    mapper: (value: T[K]) => DataDisplayRowProps[];
    index: number;
  };
};

function createDisplayConfig<T>(
  object: T,
  configurationOrder: ConfigObject<T>,
): DataDisplayRowProps[] {
  return Object.values(configurationOrder as unknown)
    .sort((a, b) => a.index - b.index)
    .map(({ property, mapper }) => mapper(object[property as keyof object]))
    .reduce((acc, curr: DataDisplayRowProps[]) => [...acc, ...curr], []);
}

export { createDisplayConfig };
