import { Navigate } from 'react-router-dom';
import { EnhancedRouteProps } from 'router/types';
import ForgotPasswordConfirmation from './ForgotPasswordConfirmation';
import ForgotPasswordEmailConfirmation from './ForgotPasswordEmailConfirmation';

export default [
  {
    path: 'email-confirmation',
    element: ForgotPasswordEmailConfirmation,
  },
  {
    path: 'password-confirmation/*',
    element: ForgotPasswordConfirmation,
  },
  {
    path: '',
    element: () => <Navigate to="/" replace />,
  },
] as Array<EnhancedRouteProps>;
