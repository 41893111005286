import React from 'react';
import { Icon } from 'models/Icon';

const DashboardIcon: React.FC<Icon> = (props) => {
  const { className } = props;

  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M4.13438 18.9219C3.56563 18.9219 3.08073 18.7214 2.67969 18.3203C2.27865 17.9193 2.07812 17.4344 2.07812 16.8657V4.1344C2.07812 3.56565 2.27865 3.07711 2.67969 2.66877C3.08073 2.26044 3.56563 2.05627 4.13438 2.05627H16.8656C17.4344 2.05627 17.9229 2.26044 18.3313 2.66877C18.7396 3.07711 18.9438 3.56565 18.9438 4.1344V16.8657C18.9438 17.4344 18.7396 17.9193 18.3313 18.3203C17.9229 18.7214 17.4344 18.9219 16.8656 18.9219H4.13438ZM8.96875 16.8657V11.1563H4.13438V16.8657H8.96875ZM10.2813 16.8657H16.8656V11.1563H10.2813V16.8657ZM4.13438 9.84377H16.8656V4.1344H4.13438V9.84377Z"
        id="onHover"
      />
    </svg>
  );
};

export default DashboardIcon;
