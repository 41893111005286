import classNames from 'classnames';
import ArrowIcon from 'icons/Arrow.icon';
import React, { MouseEvent, ReactNode } from 'react';

import './Button.styles.scss';

export type ButtonProps = {
  className?: string;
  styleType?:
    | 'solid'
    | 'solid-bright'
    | 'solid-gray'
    | 'destructive'
    | 'confirmation-green'
    | 'confirmation-blue'
    | 'confirmation-red'
    | 'arrow'
    | 'transparent'
    | 'transparent-duke'
    | 'border'
    | 'border'
    | 'shadow'
    | 'add'
    | 'link'
    | 'icon'
    | 'dropdown'
    | 'solid-black'
    | 'pagination'
    | 'solid-white';
  size?: 'small' | 'default' | 'large' | 'icon' | 'xl' | 'cta' | 'table-small';
  iconPosition?: 'left' | 'right';
  icon?: ReactNode;
  rotateIcon?: 'left' | 'right' | 'top';
  disable?: boolean;
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
} & React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>;

const Button: React.FC<ButtonProps> = (props) => {
  const {
    icon,
    iconPosition = 'left',
    styleType = 'solid',
    size = 'default',
    children,
    className,
    rotateIcon,
    disabled,
    onClick,
    ...rest
  } = props;
  const classes = classNames(
    'is-button',
    `is-button--${styleType}`,
    `is-button--size-${size}`,
    `is-button-icon--${iconPosition}`,
    `is-button-icon--rotate-${rotateIcon}`,
    className,
    { 'is-button--disabled': disabled },
  );

  return (
    <button className={classes} onClick={onClick} disabled={disabled} {...rest}>
      {styleType === 'arrow' && !icon ? <ArrowIcon /> : icon}
      {children && <span className="is-button__label">{children}</span>}
    </button>
  );
};

export default Button;
