import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import BackLink from 'components/BackLink';
import CheckBox from 'components/CheckBox';
import SkeletonLoader from 'components/SkeletonLoader';
import { PaymentSelectingMode } from 'models/Payment';
import ProjectContext from '../provider/Project.context';

const ProjectControls: React.FC = () => {
  const {
    areAllSelected,
    selectAll,
    paymentSelectingMode,
    selectedView,
    isProjectFetching,
  } = useContext(ProjectContext);

  const { t } = useTranslation();

  if (isProjectFetching)
    return (
      <div className="project__body__controls__loading">
        <SkeletonLoader />
      </div>
    );

  return (
    <div className="project__body__controls">
      {paymentSelectingMode === PaymentSelectingMode.None ? (
        <BackLink className="project__body__controls__back" />
      ) : (
        selectedView === 'grid' && (
          <CheckBox
            value={areAllSelected}
            onChange={selectAll}
            label={t('Fields.selectAll')}
            className="project__body__controls__checkbox"
          />
        )
      )}
    </div>
  );
};

export default ProjectControls;
