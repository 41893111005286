import classNames from 'classnames';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import Button from 'components/Button';
import Dropdown from 'components/Dropdown';
import { SEARCH_PARAMS } from 'config/searchParams';
import useDisclosure from 'hooks/useDisclosure';
import useSearchParams from 'hooks/useSearchParams';
import VectorArrowIcon from 'icons/VectorArrow.icon';

import './Sort.styles.responsive.scss';
import './Sort.styles.scss';

type SortOptionType = {
  label: string;
  sortByAtribute: string;
  sortOrder: SortOrder;
};

type SortProps = {
  className?: string;
  options: SortOptionType[];
  defaultValue?: Omit<SortOptionType, 'label'>;
};

export enum SortOrder {
  Ascending = 'asc',
  Descending = 'desc',
}

const Sort: React.FC<SortProps> = (props) => {
  const { className, options, defaultValue } = props;

  const { setSearchParams, getSearchParam } = useSearchParams();
  const sortingOrder = getSearchParam(SEARCH_PARAMS.ORDER) as SortOrder;
  const sortByAtribute = getSearchParam(SEARCH_PARAMS.SORT_BY);

  const { isOpen, toggle, close } = useDisclosure();

  const classes = classNames('sort', className);
  const iconClasses = classNames('sort__select__icon', {
    'sort__select__icon--open': isOpen,
  });

  const { t } = useTranslation();

  useEffect(() => {
    if (defaultValue && !sortingOrder && !sortByAtribute) {
      const searchParams = new URLSearchParams(window.location.search);
      searchParams.set(SEARCH_PARAMS.SORT_BY, defaultValue.sortByAtribute);
      searchParams.set(SEARCH_PARAMS.ORDER, defaultValue.sortOrder);
      setSearchParams(searchParams, { replace: true });
    }
  }, [defaultValue, sortingOrder, sortByAtribute, setSearchParams]);

  return (
    <div className={classes}>
      <span className="sort__label">{t('Index.sortBy')}</span>
      <Dropdown
        isOpen={isOpen}
        trigger={
          <div className="sort__select">
            {
              options.find(
                (option) =>
                  option.sortByAtribute === sortByAtribute &&
                  option.sortOrder === sortingOrder,
              )?.label
            }
            <VectorArrowIcon className={iconClasses} />
          </div>
        }
        toggleOpenDropdown={toggle}
        className="sort__dropdown"
      >
        <div className="sort__dropdown__content">
          {options.map(({ sortByAtribute, sortOrder, label }) => (
            <Button
              key={label}
              styleType="dropdown"
              onClick={() => {
                const searchParams = new URLSearchParams(
                  window.location.search,
                );
                searchParams.set(SEARCH_PARAMS.SORT_BY, sortByAtribute);
                searchParams.set(SEARCH_PARAMS.ORDER, sortOrder);
                setSearchParams(searchParams, { replace: true });
                close();
              }}
            >
              {label}
            </Button>
          ))}
        </div>
      </Dropdown>
    </div>
  );
};

export default Sort;
