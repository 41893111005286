import Lottie from 'lottie-react';
import React from 'react';

import animationData from 'assets/projects.json';
import { useTranslation } from 'react-i18next';

const PaymentsNoData: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className="payments-list__body__empty">
      <p className="payments-list__body__empty__paragraph">
        {t('Messages.thereAreNoPaymentSlipsForTheGivenCreditor')}
      </p>
      <Lottie
        className="payments-list__body__empty__animation"
        animationData={animationData}
        loop={false}
      />
    </div>
  );
};

export default PaymentsNoData;
