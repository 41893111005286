import classNames from 'classnames';
import React from 'react';
import { Form } from 'react-final-form';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import Button from 'components/Button';
import InputField from 'components/InputField';
import LoadingButton from 'components/LoadingButton';
import { API_ENDPOINTS } from 'config/endpoints';
import { APP_ROUTES } from 'config/routes';
import useTanstackMutation from 'hooks/useTanstackMutation';
import ArrowIcon from 'icons/Arrow.icon';
import ArrowBackIcon from 'icons/ArrowBack.icon';
import EmailIcon from 'icons/Email.icon';
import { Bookkeeper } from 'models/User';
import storageService from 'services/storageService';
import {
  EmailRegExp,
  FirstNameRegExp,
  LastNameRegExp,
} from 'validations/regularExpression';
import {
  composeValidators,
  validateRegex,
  validateRequired,
} from 'validations/validations';
import RegisterStep from '../RegisterStep';

type RegisterPersonalProps = {
  className: string;
};

type FormInputs = Pick<Bookkeeper, 'email' | 'firstName' | 'lastName'>;

const RegisterPersonalInformation: React.FC<RegisterPersonalProps> = (
  props,
) => {
  const { className } = props;

  const classes = classNames('is-register-form', className);

  const { t } = useTranslation();
  const navigate = useNavigate();

  const { mutateAsync, isPending } = useTanstackMutation({});

  const handleSubmit = async (formData: FormInputs) => {
    try {
      const data = await mutateAsync({
        body: { email: formData.email },
        method: 'POST',
        path: API_ENDPOINTS.BOOKKEEPER_EXISTS,
      });

      if (data) return { email: t('Messages.emailExists') };

      storageService.setItem('user', formData);

      navigate(APP_ROUTES.REGISTER_ACCOUNT_INFORMATION);
    } catch (error) {
      const errorObject = await (error as Response).json();
      if (errorObject['message'])
        toast.error(errorObject['message'], {
          style: {
            wordBreak: 'break-word',
          },
        });
    }
  };

  return (
    <RegisterStep stepperIndex={0} title={t('Register.personalInformation')}>
      <Form<FormInputs>
        onSubmit={handleSubmit}
        initialValues={storageService.getItem('user')}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit} className={classes}>
            <div className="is-register-form__inputs">
              <InputField
                title={t('Register.firstName')}
                name="firstName"
                validate={composeValidators(
                  validateRequired('Form.firstNameRequired'),
                  validateRegex('Form.firstNameNotValid', FirstNameRegExp),
                )}
              />
              <InputField
                title={t('Register.lastName')}
                name="lastName"
                validate={composeValidators(
                  validateRequired('Form.lastNameRequired'),
                  validateRegex(t('Form.lastNameNotValid'), LastNameRegExp),
                )}
              />
              <InputField
                titleicon={<EmailIcon />}
                title={t('Register.email')}
                name="email"
                validate={composeValidators(
                  validateRequired('Form.emailRequired'),
                  validateRegex('Form.invalidEmail', EmailRegExp),
                )}
              />
            </div>
            <div className="is-register-form__buttons">
              <Button
                className="is-button--on-click-translate"
                styleType="transparent"
                type="button"
                icon={<ArrowBackIcon />}
                onClick={() => {
                  navigate(APP_ROUTES.LOGIN);
                }}
              >
                {t('Buttons.back')}
              </Button>
              <LoadingButton
                className="register__submit"
                styleType="solid-bright"
                iconPosition="right"
                icon={<ArrowIcon />}
                isLoading={isPending}
              >
                {t('Buttons.next')}
              </LoadingButton>
            </div>
          </form>
        )}
      />
    </RegisterStep>
  );
};

export default RegisterPersonalInformation;
