import classNames from 'classnames';
import React from 'react';

import './SkeletonLoader.styles.responsive.scss';
import './SkeletonLoader.styles.scss';

type SkeletonLoaderProps = {
  className?: string;
};

const SkeletonLoader: React.FC<SkeletonLoaderProps> = (props) => {
  const { className } = props;

  const classes = classNames('skeleton-loader skeleton', className);

  return <div className={classes}></div>;
};

export default SkeletonLoader;
