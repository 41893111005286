import classNames from 'classnames';
import React from 'react';

import ProjectInfoCard, {
  ProjectInfoCardProps,
} from 'components/ProjectInfoCard/ProjectInfoCard.component';
import SkeletonLoader from 'components/SkeletonLoader';

import './ProjectsInfoList.styles.responsive.scss';
import './ProjectsInfoList.styles.scss';

type ProjectsInfoListProps = {
  className?: string;
  projectsInfo: ProjectInfoCardProps[];
  isLoading: boolean;
};

const ProjectsInfoList: React.FC<ProjectsInfoListProps> = (props) => {
  const { className, projectsInfo, isLoading } = props;

  const classes = classNames('projects-info-list', className);

  return (
    <div className={classes}>
      {!isLoading
        ? projectsInfo.map((info) => (
            <ProjectInfoCard key={info.text} {...info} />
          ))
        : Array.from(projectsInfo).map((_, i) => (
            <SkeletonLoader
              key={i}
              className="projects-info-list__card-loader"
            />
          ))}
    </div>
  );
};

export default ProjectsInfoList;
