import React from 'react';
import { Icon } from 'models/Icon';

const FolderIcon: React.FC<Icon> = (props) => {
  const { className } = props;

  return (
    <svg
      width="14"
      height="11"
      viewBox="0 0 14 11"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path d="M2.18724 10.3842C1.80807 10.3842 1.48481 10.2481 1.21745 9.97589C0.950087 9.70367 0.816406 9.38284 0.816406 9.01339V1.53215C0.816406 1.1627 0.950087 0.839437 1.21745 0.562353C1.48481 0.28527 1.80807 0.146729 2.18724 0.146729H5.97891L7.01432 1.18215H11.8414C12.2108 1.18215 12.5341 1.32069 12.8112 1.59777C13.0883 1.87485 13.2268 2.19812 13.2268 2.56756V9.01339C13.2268 9.38284 13.0883 9.70367 12.8112 9.97589C12.5341 10.2481 12.2108 10.3842 11.8414 10.3842H2.18724ZM2.18724 1.53215V9.01339H11.8414V2.56756H6.44557L5.41016 1.53215H2.18724ZM2.18724 1.53215V9.01339V1.53215Z" />
    </svg>
  );
};

export default FolderIcon;
