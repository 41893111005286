import classNames from 'classnames';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import AccountForm from 'components/AccountForm';
import ClientForm from 'components/Forms/ClientForm';
import Modal, { ModalProps } from 'components/Modal/Modal.component';
import CreateClientIcon from 'icons/CreateClient.icon';

import './CreateClientModal.styles.responsive.scss';
import './CreateClientModal.styles.scss';

type CreateClientModalProps = {
  className?: string;
  closeModal?: (step?: number) => void;
} & Omit<ModalProps, 'closeModal'>;

const CreateClientModal: React.FC<CreateClientModalProps> = (props) => {
  const { className, closeModal, ...rest } = props;

  const classes = classNames('create-client-modal', className);

  const { t } = useTranslation();

  const [step, setStep] = useState(0);
  const id = useRef('');

  return (
    <Modal
      disableCloseButton
      className={classes}
      closeModal={() => closeModal(step)}
      {...rest}
      iconLeft={<CreateClientIcon />}
      heading={t('Dashboard.createClient')}
      subtitle={t(
        step === 0
          ? 'Dashboard.youCanAdditionallyAppendProjectsAndPaymentsToNewlyCreatedClient'
          : 'Messages.hereYouCanEnterDetails',
      )}
    >
      {step === 0 ? (
        <ClientForm
          buttonsPosition="right"
          onSuccess={(data) => {
            id.current = data?._id;
            setStep(1);
          }}
        />
      ) : (
        <AccountForm
          isSkippable
          onCancel={closeModal}
          id={id.current}
          accountType="client"
          onSuccess={() => closeModal()}
        />
      )}
    </Modal>
  );
};

export default CreateClientModal;
