import React from 'react';

type SidebarLogoMiniProps = {
  className?: string;
};

const SidebarLogoMini: React.FC<SidebarLogoMiniProps> = (props) => {
  const { className } = props;

  return (
    <svg
      className={className}
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.9855 33.0835L18.8574 32.1456C21.239 30.9524 23.2599 29.1465 24.7124 26.9136L33.5259 13.3642C34.0794 12.5133 35.0256 12 36.0407 12L43.4995 12L25.1136 40.3516L16.9855 33.0835Z"
        fill="#F7F7FF"
      />
      <path
        d="M8.49836 20.7341C9.46607 19.7661 10.9663 19.5758 12.145 20.2716L21.0024 25.5L19.5958 27.1401C18.557 28.3514 17.1741 29.2178 15.631 29.6242C14.3359 29.9652 12.9563 29.6233 11.9703 28.717L6.00235 23.2309L8.49836 20.7341Z"
        fill="#F7F7FF"
      />
    </svg>
  );
};

export default SidebarLogoMini;
