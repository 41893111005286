import LanguagePicker from 'components/LanguagePicker';
import React from 'react';
import { Outlet } from 'react-router-dom';

const ForgotPassword = () => {
  return (
    <>
      <LanguagePicker className="is-language-picker--absolute is-language-picker--top-right" />
      <Outlet />
    </>
  );
};

export default ForgotPassword;
