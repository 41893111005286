const login = '/login';
const logout = '/logout';

const clientMember = '/client-member';
const client = '/client';

const register = '/register';
const registerPersonalInformation = `${register}/personal-information`;
const registerAccountInformation = `${register}/account-information`;
const registerSecurityInformation = `${register}/security-information`;
const registerSuccess = `${register}/register-success`;
const registerClientMember = `${clientMember}${register}`;

const forgotPassword = '/forgot-password';
const forgotPasswordEmailConfirmation = `${forgotPassword}/email-confirmation`;
const forgotPasswordPasswordConfirmation = `${forgotPassword}/password-confirmation`;

const dashboard = '/dashboard';

const profile = '/profile';

const invalidInvitation = '/invalid-invitation';

const payments = '/payments';
const createPayment = '/create-payment';
const editPayment = '/edit-payment';

const clients = '/clients';
const creditors = '/creditors';

const projects = '/projects';

export const APP_ROUTES = {
  LOGIN: login,
  LOGOUT: logout,
  REGISTER_PERSONAL_INFORMATION: registerPersonalInformation,
  REGISTER_ACCOUNT_INFORMATION: registerAccountInformation,
  REGISTER_SECURITY_INFORMATION: registerSecurityInformation,
  REGISTER_SUCCESS: registerSuccess,
  REGISTER_CLIENT_MEMBER: registerClientMember,
  DASHBOARD: dashboard,
  FORGOT_PASSWORD_EMAIL_CONFIRMATION: forgotPasswordEmailConfirmation,
  FORGOT_PASSWORD_PASSWORD_CONFIRMATION: forgotPasswordPasswordConfirmation,
  INVALID_INVITATION: invalidInvitation,
  PAYMENTS: payments,
  PROFILE: profile,
  PROJECTS: projects,
  CLIENT: client,
  CLIENTS: clients,
  CREDITORS: creditors,
  CREATE_PAYMENT: `${payments}${createPayment}`,
  EDIT_PAYMENT: `${payments}${editPayment}`,
};
