import classNames from 'classnames';
import i18n from 'i18n';
import React, { useCallback, useState } from 'react';

import Dropdown from 'components/Dropdown';
import { languages } from 'config';
import storageService from 'services/storageService';

import './LanguagePicker.styles.responsive.scss';
import './LanguagePicker.styles.scss';

type LanguagePickerProps = {
  className?: string;
};

const LanguagePicker: React.FC<LanguagePickerProps> = (props) => {
  const { className } = props;

  const [isOpen, setIsOpen] = useState(false);
  const toggleOpen = useCallback(() => setIsOpen((old) => !old), [setIsOpen]);

  const classes = classNames('is-language-picker', className);
  return (
    <div className={classes}>
      <Dropdown
        isOpen={isOpen}
        trigger={
          <div className="is-language-picker__trigger">
            {languages[i18n.language as keyof typeof languages].nativeName}
          </div>
        }
        toggleOpenDropdown={toggleOpen}
      >
        <ul className="dropdown-header-profile-menu__list is-language-picker__content">
          {Object.entries(languages).map(([key, value]) => (
            <li
              key={key}
              className="dropdown-header-profile-menu__list-item is-language-picker__content__item"
              onClick={() => {
                i18n.changeLanguage(key);
                storageService.removeItem('language');
                storageService.setItem('language', key);
                toggleOpen();
              }}
            >
              {value.nativeName}
            </li>
          ))}
        </ul>
      </Dropdown>
    </div>
  );
};

export default LanguagePicker;
