import classNames from 'classnames';
import { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Button from 'components/Button';
import CreditorsOverview from 'components/CreditorsOverview';
import CreditorForm from 'components/Forms/CreditorForm';
import Header from 'components/Header';
import LoadingSpinner from 'components/LoadingSpinner';
import Modal from 'components/Modal';
import PreviewCard from 'components/PreviewCard';
import SearchInput from 'components/SearchInput';
import { DEFAULT_PAGE } from 'constant';
import useDebounce from 'hooks/useDebounce';
import useDisclosure from 'hooks/useDisclosure';
import usePageParam from 'hooks/usePageParam';
import PlusIcon from 'icons/Plus.icon';
import ThreeClientsIcon from 'icons/ThreeClients.icon';
import useCreditorsForClient from 'queries/CreditorsForClient';
import credentialsService from 'services/credentialsService';
import CreditorsEmptyState from './components/CreditorsEmptyState.component';

import './Creditors.styles.responsive.scss';
import './Creditors.styles.scss';

type CreditorsProps = {
  className?: string;
};

const SIZE = 12;

const Creditors: FC<CreditorsProps> = (props) => {
  const { className } = props;

  const classes = classNames('creditors', className);

  const { t } = useTranslation();

  const [search, setSearch] = useState('');
  const debouncedValue = useDebounce(search, 500);

  const { isOpen, close, open } = useDisclosure();

  const { page, setPage } = usePageParam();
  const { data, isFetching, isFetched, isLoading } = useCreditorsForClient(
    credentialsService.user.clientId,
    {
      page,
      size: SIZE,
      searchBy: `name:${debouncedValue}`,
    },
  );

  useEffect(() => {
    setPage(DEFAULT_PAGE);
    // eslint-disable-next-line
  }, [debouncedValue]);

  const newTotalPages = useRef(0);

  if (data && newTotalPages.current !== data.totalPages) {
    newTotalPages.current = data.totalPages;
  }

  return (
    <div className={classes}>
      <Header
        className="creditors__header"
        title={t('Index.creditors')}
        browser={
          <SearchInput
            className="creditors__header__search"
            placeholder={t('Fields.search') + '...'}
            value={search}
            onChange={setSearch}
            disabled={
              isFetched && !debouncedValue && (!data || data.totalItems === 0)
            }
          />
        }
      />
      <div className="creditors__body">
        {isLoading && !isFetched && !newTotalPages.current ? (
          <LoadingSpinner className="projects__body__empty-state__loading" />
        ) : (!data || data.totalItems === 0) &&
          !isFetching &&
          !debouncedValue ? (
          <CreditorsEmptyState onClick={open} />
        ) : (
          <PreviewCard
            title={t('Index.creditors')}
            titleIconLeft={<ThreeClientsIcon />}
            button={
              <Button styleType="shadow" icon={<PlusIcon />} onClick={open}>
                {t('Buttons.addNewCreditor')}
              </Button>
            }
          >
            <CreditorsOverview
              className="creditors__body__overview"
              emptyMessage={t('Messages.thereAreNoCreditorsUnderThatName')}
              loaderLength={SIZE}
              clientId={credentialsService.user.clientId}
              creditorsList={data?.items || []}
              isLoading={isFetching}
              paginationConfig={{
                totalPages: data?.totalPages || newTotalPages.current,
                currentPage: page,
                onChange: setPage,
              }}
            />
          </PreviewCard>
        )}
        {isOpen && (
          <Modal closeModal={close}>
            <CreditorForm
              clientId={credentialsService.user.clientId}
              onSuccess={close}
            />
          </Modal>
        )}
      </div>
    </div>
  );
};

export default Creditors;
