import { MutationFunction } from '@tanstack/react-query';

import bus from 'bus';
import credentialsService from 'services/credentialsService';
import { BASE_PATH, handleInvalidAccessToken } from './defaultQueryFn';

export type MutationVariables = {
  path: string;
  method: 'PUT' | 'POST' | 'PATCH' | 'DELETE';
  body?: Record<string, unknown> | Array<any>;
  queryParams?: Record<string, string>;
};

export const defaultMutationFn: MutationFunction = async (variables) => {
  const { path, method, body, queryParams } = variables as MutationVariables;

  const { expiringMoment } = credentialsService;
  try {
    if (expiringMoment && expiringMoment < Date.now())
      await handleInvalidAccessToken();

    const { token } = credentialsService;

    const headers: Record<string, string> = {
      'Content-Type': 'application/json',
    };

    if (token) {
      headers.Authorization = `Bearer ${token}`;
    }
    const queryString = new URLSearchParams(queryParams).toString();
    const basePath = `${BASE_PATH}${path}`;

    const url = queryString ? `${basePath}?${queryString}` : basePath;

    const res = await fetch(url, {
      method,
      headers,
      credentials: 'include',
      ...(body && { body: JSON.stringify(body) }),
    });

    if (!res.ok) throw res;

    if (res.status === 204) return;

    const json = await res.json();

    return json;
  } catch (err: any) {
    if (err && typeof err === 'object' && 'status' in err) {
      const errorWithStatus = err as { status: number };
      if (errorWithStatus.status === 401) handleInvalidAccessToken();
      if (errorWithStatus.status === 403) bus.broadcastEvent('logout');
    } else if (typeof err === 'string') throw Error(err);

    throw err;
  }
};
