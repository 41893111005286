import classNames from 'classnames';
import React from 'react';

import ProjectBody from './components/ProjectBody.component';
import ProjectBulkPayment from './components/ProjectBulkPayment.component';
import ProjectControls from './components/ProjectControls.component';
import ProjectFooter from './components/ProjectFooter.component';
import ProjectHeader from './components/ProjectHeader.component';
import ProjectSubtitle from './components/ProjectSubtitle.component';
import ProjectDataProvider from './provider/Project.provider';

import './Project.styles.responsive.scss';
import './Project.styles.scss';

type ProjectProps = {
  className?: string;
};

const Project: React.FC<ProjectProps> = (props) => {
  const { className } = props;

  const classes = classNames('project', className);

  return (
    <div className={classes}>
      <ProjectDataProvider>
        <ProjectHeader />
        <ProjectSubtitle />
        <ProjectControls />
        <ProjectBody />
        <ProjectFooter />
        <ProjectBulkPayment />
      </ProjectDataProvider>
    </div>
  );
};

export default Project;
