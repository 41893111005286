import classNames from 'classnames';
import React, { useDeferredValue, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import Button from 'components/Button';
import ClientOverviewEdit from 'components/ClientOverviewEdit';
import CreditorsOverview from 'components/CreditorsOverview';
import CreditorForm from 'components/Forms/CreditorForm';
import LoadingSpinner from 'components/LoadingSpinner';
import Modal from 'components/Modal';
import PreviewCardWithTabs from 'components/PreviewCardWithTabs';
import ProjectFormModal from 'components/ProjectFormModal';
import ProjectsCard from 'components/ProjectsCard';
import { SEARCH_PARAMS } from 'config/searchParams';
import { DEFAULT_PAGE } from 'constant';
import useDisclosure from 'hooks/useDisclosure';
import useSearchParams from 'hooks/useSearchParams';
import CrossIcon from 'icons/Cross.icon';
import EditIcon from 'icons/Edit.icon';
import OpenFolderIcon from 'icons/OpenFolder.icon';
import PlusIcon from 'icons/Plus.icon';
import ThreeClientsIcon from 'icons/ThreeClients.icon';
import UserIcon from 'icons/User.icon';
import { useClientData } from './ClientPreviewCard.hooks';

import ClientDetails from 'components/ClientDetails';
import usePageParam from 'hooks/usePageParam';
import './ClientPreviewCard.styles.responsive.scss';
import './ClientPreviewCard.styles.scss';

type ClientPreviewCardProps = {
  className?: string;
  clientId?: string;
};

export enum PageState {
  Creditors = 'creditors',
  Information = 'information',
  Projects = 'projects',
}

const ClientPreviewCard: React.FC<ClientPreviewCardProps> = (props) => {
  const { className, clientId } = props;

  const classes = classNames('client-preview-card', className);

  const { t } = useTranslation();

  const {
    isOpen: isProjOpen,
    open: openProj,
    close: closeProj,
  } = useDisclosure();
  const {
    isOpen: isCredOpen,
    open: openCred,
    close: closeCred,
  } = useDisclosure();

  const { getSearchParam, setSearchParam, setSearchParams } = useSearchParams();

  const { page, setPage } = usePageParam();
  const pageState = getSearchParam(SEARCH_PARAMS.PAGE_STATE);

  useEffect(() => {
    if (!pageState)
      setSearchParam(SEARCH_PARAMS.PAGE_STATE, PageState.Projects, {
        replace: true,
      });
    // eslint-disable-next-line
  }, []);

  const oldClientId = useDeferredValue(clientId);

  const { creditors, projects, singleClient } = useClientData({
    clientId,
    page,
  });

  const newTotalPages = useRef(0);

  if (oldClientId !== clientId) {
    newTotalPages.current = 0;
  }

  if (
    pageState === PageState.Projects &&
    projects.data &&
    newTotalPages.current !== projects.data.totalPages
  ) {
    newTotalPages.current = projects.data.totalPages;
  }

  if (
    pageState === PageState.Creditors &&
    creditors.data &&
    newTotalPages.current !== creditors.data.totalPages
  ) {
    newTotalPages.current = creditors.data.totalPages;
  }

  const { isOpen: isEditingMode, toggle: toggleEditingMode } = useDisclosure();

  return (
    <>
      <PreviewCardWithTabs
        className={classes}
        tab={pageState}
        options={[
          {
            tab: PageState.Projects,
            onTabSelect: () => {
              const searchParams = new URLSearchParams(window.location.search);
              searchParams.set(SEARCH_PARAMS.PAGE_STATE, PageState.Projects);
              searchParams.set(SEARCH_PARAMS.PAGE, `${DEFAULT_PAGE}`);
              searchParams.delete(SEARCH_PARAMS.SORT_BY);
              searchParams.delete(SEARCH_PARAMS.ORDER);
              setSearchParams(searchParams, { replace: true });
            },
            headerButton: (
              <Button
                styleType="shadow"
                icon={<PlusIcon />}
                iconPosition="left"
                onClick={openProj}
              >
                {t('Buttons.addNewProject')}
              </Button>
            ),
            component:
              projects?.data?.totalItems || projects.isFetching ? (
                <ProjectsCard
                  client={singleClient.data}
                  projectsList={projects.data?.items || []}
                  isLoading={projects.isFetching}
                  paginationConfig={{
                    totalPages:
                      projects?.data?.totalPages || newTotalPages.current,
                    currentPage: page,
                    onChange: (val) =>
                      setPage(val, {
                        replace: true,
                      }),
                  }}
                />
              ) : (
                <div className="client-preview-card__projects__empty-state">
                  <Button
                    className="client-preview-card__projects__empty-state__button"
                    icon={<PlusIcon />}
                    iconPosition="left"
                    onClick={openProj}
                    size="cta"
                  >
                    {t('Buttons.addNewProject')}
                  </Button>
                </div>
              ),
            icon: <OpenFolderIcon />,
          },
          {
            tab: PageState.Information,
            onTabSelect: () => {
              const searchParams = new URLSearchParams(window.location.search);
              searchParams.set(SEARCH_PARAMS.PAGE_STATE, PageState.Information);
              searchParams.set(SEARCH_PARAMS.PAGE, `${DEFAULT_PAGE}`);
              searchParams.delete(SEARCH_PARAMS.SORT_BY);
              searchParams.delete(SEARCH_PARAMS.ORDER);
              setSearchParams(searchParams, { replace: true });
            },
            headerButton: (
              <Button
                styleType="shadow"
                icon={
                  isEditingMode ? (
                    <CrossIcon className="client-preview-card__client-overview__header__icon" />
                  ) : (
                    <EditIcon className="client-preview-card__client-overview__header__icon" />
                  )
                }
                iconPosition="left"
                onClick={toggleEditingMode}
              />
            ),
            component: !singleClient.isFetching ? (
              !isEditingMode ? (
                <ClientDetails client={singleClient.data} />
              ) : (
                <ClientOverviewEdit
                  key={singleClient.data?._id}
                  data={singleClient.data}
                  formConfig={{
                    buttonsPosition: 'right',
                    refetch: singleClient.refetch,
                    onSuccess: async () => {
                      await singleClient.refetch();
                      toggleEditingMode();
                    },
                  }}
                  className="client-preview-card__client-overview__edit"
                />
              )
            ) : (
              <div className="client-preview-card__client-overview__loader">
                <LoadingSpinner className="client-preview-card__client-overview__loader__spinner" />
              </div>
            ),
            icon: <ThreeClientsIcon />,
          },
          {
            tab: PageState.Creditors,
            onTabSelect: () => {
              const searchParams = new URLSearchParams(window.location.search);
              searchParams.set(SEARCH_PARAMS.PAGE_STATE, PageState.Creditors);
              searchParams.set(SEARCH_PARAMS.PAGE, `${DEFAULT_PAGE}`);
              searchParams.delete(SEARCH_PARAMS.SORT_BY);
              searchParams.delete(SEARCH_PARAMS.ORDER);
              setSearchParams(searchParams, { replace: true });
            },
            headerButton: (
              <Button
                styleType="shadow"
                icon={<PlusIcon />}
                iconPosition="left"
                onClick={openCred}
              >
                {t('Buttons.addNewCreditor')}
              </Button>
            ),
            component:
              creditors?.data?.totalItems || projects.isFetching ? (
                <CreditorsOverview
                  clientId={clientId}
                  creditorsList={creditors.data?.items || []}
                  isLoading={creditors.isFetching}
                  paginationConfig={{
                    totalPages:
                      creditors?.data?.totalPages || newTotalPages.current,
                    currentPage: page,
                    onChange: setPage,
                  }}
                />
              ) : (
                <div className="client-preview-card__creditors__empty-state">
                  <Button
                    className="client-preview-card__creditors__empty-state__button"
                    icon={<PlusIcon />}
                    iconPosition="left"
                    onClick={openCred}
                    size="cta"
                  >
                    {t('Buttons.addNewCreditor')}
                  </Button>
                </div>
              ),
            icon: <UserIcon className="client-preview-card__creditors__icon" />,
          },
        ]}
      />
      {isCredOpen && (
        <Modal closeModal={closeCred}>
          <CreditorForm clientId={clientId} onSuccess={closeCred} />
        </Modal>
      )}
      {isProjOpen && singleClient.data && (
        <ProjectFormModal
          modalConfig={{
            closeModal: closeProj,
          }}
          formConfig={{
            client: singleClient.data,
          }}
        />
      )}
    </>
  );
};

export default ClientPreviewCard;
