import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavigateOptions, useNavigate } from 'react-router-dom';

import ArrowBackIcon from 'icons/ArrowBack.icon';

import Button from 'components/Button';
import './BackLink.styles.responsive.scss';
import './BackLink.styles.scss';

type BackLinkProps = {
  className?: string;
  options?: NavigateOptions;
  to?: string;
  onClick?: () => void;
};

const BackLink: React.FC<BackLinkProps> = (props) => {
  const { className, to, options, onClick } = props;

  const classes = classNames('back-link', className);

  const { t } = useTranslation();

  const navigate = useNavigate();

  const handleOnClick = () => {
    onClick?.();
    !!to && navigate(to, options);
    !onClick && navigate(-1);
  };

  return (
    <Button styleType="link" className={classes} onClick={handleOnClick}>
      <ArrowBackIcon />
      {t('Buttons.back')}
    </Button>
  );
};

export default BackLink;
