import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import AuthWrapper from 'components/AuthWrapper';
import Button from 'components/Button';
import { APP_ROUTES } from 'config/routes';
import ArrowIcon from 'icons/Arrow.icon';

import './InvalidInvitation.styles.responsive.scss';
import './InvalidInvitation.styles.scss';

type InvalidInvitationProps = {
  className?: string;
};

const InvalidInvitation: React.FC<InvalidInvitationProps> = (props) => {
  const { className } = props;

  const classes = classNames('invalid-invitation', className);

  const { t } = useTranslation();

  const navigate = useNavigate();

  return (
    <AuthWrapper className={classes} title={t('Index.invalidInvitation')}>
      <p className="invalid-invitation__text-row">
        {t('Authentication.sorryButYourInvitation')}
      </p>
      <p className="invalid-invitation__text-row">
        {t('Authentication.theReasonMightBeDueToExpiration')}
      </p>
      <p className="invalid-invitation__text-row">
        {t('Authentication.forMoreInformation')}
      </p>
      <Button
        icon={<ArrowIcon />}
        rotateIcon="top"
        className="invalid-invitation__button"
        styleType="solid-bright"
        onClick={() => navigate(APP_ROUTES.LOGIN, { replace: true })}
      >
        {t('Buttons.backToLogin')}
      </Button>
    </AuthWrapper>
  );
};

export default InvalidInvitation;
