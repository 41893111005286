import useAnimationEndCallback from 'hooks/useAnimationEndCallback';
import { PaymentView } from 'models/Payment';
import React, { useEffect, useRef } from 'react';
import storageService, { STORAGE_KEYS } from 'services/storageService';
import PaymentsGridView from './PaymentsGridView.component';
import PaymentsListView, {
  PaymentsListViewProps,
} from './PaymentsListView.component';

type PaymentViewProps = {
  paymentView: PaymentView;
} & PaymentsListViewProps;

const PaymentsView: React.FC<PaymentViewProps> = (props) => {
  const { paymentView, ...rest } = props;

  const isScrolled = useRef(false);
  useAnimationEndCallback(() => {
    if (!isScrolled.current) {
      isScrolled.current = true;
      storageService.setItem(STORAGE_KEYS.IS_SCROLLED, { isScrolled: 'true' });
    }
  }, ['highlight', 'highlight-selected', 'highlight-error']);

  useEffect(() => {
    return storageService.removeItem(STORAGE_KEYS.IS_SCROLLED);
  }, []);

  return paymentView === 'list' ? (
    <PaymentsListView {...rest} />
  ) : (
    <PaymentsGridView {...rest} />
  );
};

export default PaymentsView;
