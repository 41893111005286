import React from 'react';
import { Icon } from 'models/Icon';

const OpenFolderIcon: React.FC<Icon> = (props) => {
  const { className } = props;

  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M3.64551 20.8334C3.2462 20.8334 2.88596 20.6728 2.56478 20.3516C2.2436 20.0304 2.08301 19.6702 2.08301 19.2709V5.72919C2.08301 5.32988 2.2436 4.96964 2.56478 4.64846C2.88596 4.32728 3.2462 4.16669 3.64551 4.16669H10.9632L12.5257 5.72919H21.3538C21.7531 5.72919 22.1134 5.88978 22.4346 6.21096C22.7558 6.53214 22.9163 6.89238 22.9163 7.29169H11.8486L10.2861 5.72919H3.64551V19.2709L6.30176 8.85419H24.4788L21.6924 19.6354C21.5882 20.0521 21.3972 20.3559 21.1195 20.5469C20.8417 20.7379 20.4858 20.8334 20.0518 20.8334H3.64551ZM5.28613 19.2709H20.182L22.3695 10.4167H7.47363L5.28613 19.2709ZM5.28613 19.2709L7.47363 10.4167L5.28613 19.2709ZM3.64551 7.29169V5.72919V7.29169Z"
        fill="black"
      />
    </svg>
  );
};

export default OpenFolderIcon;
