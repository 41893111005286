import { QueryClient } from '@tanstack/react-query';

import { defaultMutationFn, defaultQueryFn } from './utils';

const DEFAULT_STALE_TIME = 5 * 60 * 1000; // milliseconds

export default new QueryClient({
  defaultOptions: {
    queries: {
      queryFn: defaultQueryFn,
      staleTime: DEFAULT_STALE_TIME,
    },
    mutations: {
      mutationFn: defaultMutationFn,
    },
  },
});
