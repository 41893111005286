import { useQueryClient } from '@tanstack/react-query';
import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { API_ENDPOINTS } from 'config/endpoints';
import { APP_ROUTES } from 'config/routes';
import useTanstackMutation from 'hooks/useTanstackMutation';
import useTimerCallback from 'hooks/useTimerCallback';
import credentialsService from 'services/credentialsService';

export const useLogout = (timer = 5) => {
  const queryClient = useQueryClient();

  const navigate = useNavigate();

  const { mutate, ...rest } = useTanstackMutation({
    onSuccess: () => {
      credentialsService.removeAuthBody();
      queryClient.clear();
      navigate(APP_ROUTES.LOGIN);
    },
  });

  const logout = useCallback(
    () => mutate({ path: API_ENDPOINTS.LOGOUT, method: 'POST' }),
    [mutate],
  );
  const time = useTimerCallback(logout, [logout, timer], timer);
  useEffect(() => {
    return logout;
    // eslint-disable-next-line
  }, []);

  return { time, ...rest };
};
