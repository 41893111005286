import classNames from 'classnames';
import { DateTime } from 'luxon';
import React from 'react';
import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import CheckBoxField from 'components/CheckBoxField';
import { DatePickerField } from 'components/DatePickerField';
import { DatePickerFieldProps } from 'components/DatePickerField/DatePickerField.component';
import {
  composeValidators,
  validateMinDateTime,
  validateRequiredDateTime,
} from 'validations/validations';

import './ProcessDateField.styles.responsive.scss';
import './ProcessDateField.styles.scss';

type ProcessDateFieldProps = {
  className?: string;
  subtitle?: string;
  isChecked: boolean;
  initialValues?: {
    processmentDate: DateTime;
    instant: boolean;
  };
  onDateChange?: (val: DateTime) => void;
} & Partial<DatePickerFieldProps>;

const ProcessDateField: React.FC<ProcessDateFieldProps> = (props) => {
  const {
    className,
    subtitle,
    isChecked,
    onDateChange,
    initialValues,
    ...fieldConfig
  } = props;

  const classes = classNames('process-date-field', className);

  const { t } = useTranslation();

  return (
    <div className={classes}>
      <label className="process-date-field__label">
        {t('Fields.processDate')}
      </label>
      {subtitle && <p className="process-date-field__subtitle">{subtitle}</p>}
      <div className="process-date-field__content">
        <DatePickerField
          key={`${isChecked}`}
          name="processmentDate"
          calendarSize="medium"
          validate={composeValidators(
            validateRequiredDateTime(t('Form.required'), isChecked),
            validateMinDateTime(t('Form.dateInvalid'), DateTime.now()),
          )}
          minDate={DateTime.now()}
          disabled={isChecked}
          {...fieldConfig}
        />
        <Field
          name="instant"
          component={CheckBoxField}
          onChange={(val: boolean) => {
            val
              ? onDateChange?.(DateTime.now())
              : onDateChange?.(initialValues?.processmentDate);
          }}
          label={t('Fields.instantPayment')}
        />
      </div>
    </div>
  );
};

export default ProcessDateField;
