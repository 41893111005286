import classNames from 'classnames';
import { FC, ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';

import PaginationBar from 'components/PaginationBar';
import { PaginationBarProps } from 'components/PaginationBar/PaginationBar.component';
import ProjectFormModal from 'components/ProjectFormModal';
import ProjectTable from 'components/ProjectTable';
import { ProjectTableHeader } from 'components/ProjectTable/ProjectTable.component';
import SkeletonLoader from 'components/SkeletonLoader';
import SortProjects from 'components/Sort/components/SortProjects.component';
import { DateTime } from 'luxon';
import { Project } from 'models/Project';
import { Client, Role } from 'models/User';
import credentialsService from 'services/credentialsService';

import './ProjectsCard.styles.responsive.scss';
import './ProjectsCard.styles.scss';

type ProjectsCardProps = {
  className?: string;
  projectsList: Project[];
  client?: Client;
  paginationConfig: PaginationBarProps;
  isLoading?: boolean;
  showSort?: boolean;
  projectsLength?: number;
  emptyState?: ReactNode | string;
};

const headers = (
  t: (message: string, options?: { count: number }) => string,
  role: Role,
): Array<ProjectTableHeader> => [
  { heading: t('Dashboard.project', { count: 1 }), value: 'project' },
  { heading: t('Dashboard.processed'), value: 'processed' },
  { heading: t('Dashboard.payment', { count: 0 }), value: 'payments' },
  ...(role.includes('client')
    ? ([
        { heading: t('Fields.processedAmount'), value: 'processedAmount' },
      ] as const)
    : []),
  { heading: t('Dashboard.amount'), value: 'amount' },
  ...(role.includes('book_keeper') ? ([{ value: 'button' }] as const) : []),
];

const ProjectsCard: FC<ProjectsCardProps> = (props) => {
  const {
    className,
    projectsList,
    paginationConfig,
    client,
    emptyState,
    isLoading = false,
    showSort = true,
    projectsLength = 9,
  } = props;

  const classes = classNames('projects-card', className);

  const { t } = useTranslation();

  const [copyProject, setCopyProject] = useState<Project>(undefined);

  const { userRole } = credentialsService;

  return (
    <div className={classes}>
      {projectsList.length !== 0 && !isLoading && (
        <ProjectTable
          projectsList={projectsList}
          headers={headers(t, userRole)}
          onButtonClick={setCopyProject}
        />
      )}

      {isLoading && (
        <>
          <SkeletonLoader className="projects-card__loading__header" />
          {Array.from({ length: projectsLength }).map((_, i) => (
            <SkeletonLoader className="projects-card__loading__row" key={i} />
          ))}
        </>
      )}

      {!isLoading && projectsList.length === 0 && (
        <div className="projects-card__no-data">
          {emptyState ?? t('Messages.noProjectsData')}
        </div>
      )}

      <footer className="projects-card__footer">
        {!(
          paginationConfig.currentPage === 0 || !paginationConfig.totalPages
        ) &&
          showSort && <SortProjects />}
        <PaginationBar
          className="projects-card__footer__pagination-bar"
          {...paginationConfig}
        />
      </footer>

      {copyProject && (
        <ProjectFormModal
          modalConfig={{ closeModal: () => setCopyProject(undefined) }}
          formConfig={{
            copyFromProject: copyProject,
            client,
            initialValues: {
              name: copyProject?.name,
              processmentDate: DateTime.now(),
            },
          }}
        />
      )}
    </div>
  );
};

export default ProjectsCard;
