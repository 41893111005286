export type PaginationParams = {
  page?: number;
  limit?: number;
};

export enum INVITATION_STATUS {
  PENDING = 'PENDING',
  ACCEPTED = 'ACCEPTED',
  NOT_INVITED = 'NOT_INVITED',
}
