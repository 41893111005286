import { DateTime } from 'luxon';
import { Project } from './Project';
import { Address } from './User';

export const paymentCodes = {
  220: 'Promet robe i usluga - medjufazna potrosnja',
  221: 'Promet robe i usluga - finalna potrosnja',
  222: 'Usluge javnih preduzeca',
  223: 'Investicije u objekte i opremu',
  224: 'Investicije - ostalo',
  225: 'Zakupnine stvari u javnoj svojini',
  226: 'Zakupnine',
  227: 'Subvencije, regresi i premije s posebnih racuna',
  228: 'Subvencije, regresi i premije sa ostalih racuna',
  231: 'Carine i druge uvozne dazbine',
  240: 'Zarade i druga primanja zaposlenih',
  241: 'Neoporeziva primanja zaposlenih, socijalna i druga davanja izuzeta od oporezivanja i obustava od zarada',
  242: 'Naknade zarada na teret poslodavca',
  244: 'Isplate preko omladinskih i studentskih zadruga',
  245: 'Penzije',
  246: 'Obustave od penzija i zarada',
  247: 'Naknade zarada na teret drugih isplatilaca',
  248: 'Prihodi fizickih lica od kapitala drugih imovinskih prava',
  249: 'Ostali prihodi fizickih lica',
  253: 'Uplata javnih prihoda izuzev poreza i doprinosa po odbitku',
  254: 'Uplata poreza i doprinosa po odbitku',
  257: 'Povracaj vise naplacenih ili pogresno naplacenih tekucih prihoda',
  258: 'Preknjizavanje vise uplacenih ili pogresno uplacenih tekucih racuna',
  260: 'Premije osiguranja i nadoknada stete',
  261: 'Raspored tekucih prihoda',
  262: 'Transferi u okviru drzavnih organa',
  263: 'Ostali transferi',
  264: 'Prenos sredstava iz budzeta za obezbedjenje povracaja vise neplacenih tekucih prihoda',
  265: 'Uplata pazara',
  266: 'Isplata gotovine',
  270: 'Kratkorocni krediti',
  271: 'Dugorocni krediti',
  272: 'Aktivna kamata',
  273: 'Polaganje orocenih depozita',
  275: 'Ostali plasmani',
  276: 'Otplata kratkorocnih kredita',
  277: 'Otplata dugorocnih kredita',
  278: 'Povracaj orocenih depozita',
  279: 'Pasivna kamata',
  280: 'Eskont hartija od vrednosti',
  281: 'Pozajmice osnivaca za likvidnost',
  282: 'Povracaj pozajmice za likvidnost osnivacu',
  283: 'Naplata cekova gradjana',
  284: 'Platne kartice',
  285: 'Menjacki poslovi',
  286: 'Kupoprodaja deviza',
  287: 'Donacije i sponzorstva',
  288: 'Donacije',
  289: 'Transakcije po nalogu gradjana',
  290: 'Druge transakcije',
};

export const models = ['00', '97', '99'];

export type PaymentUser = {
  address: Address;
  name: string;
};

export enum PaymentSelectingMode {
  Processing = 'processing',
  Error = 'error',
  Verifying = 'verifying',
  Editing = 'editing',
  None = 'none',
}

export type SelectedPayment = {
  id: string;
  amount: number;
};

export type PaymentView = 'grid' | 'list';

export enum PAYMENT_STATUS {
  UNCHECKED = 'UNCHECKED',
  CHECKED = 'CHECKED',
  PROCESSED = 'PROCESSED',
  ERROR = 'ERROR',
}

export type Payment = {
  payer: PaymentUser;
  payee: PaymentUser;
  payerAccount: string;
  price: {
    amount: number;
    currency: string;
  };
  bookkeeperId?: string;
  projectId?: string;
  purpose: string;
  paymentCode: number;
  payeesAccount: string;
  model: '00' | '97' | '99';
  message?: string;
  referenceNumber: string;
  status: PAYMENT_STATUS;
  changable?: false;
  copyToXml?: false;
  receiveDate?: DateTime;
  processmentDate?: DateTime;
  instant: boolean;
  projects: Project[];
  id: string;
  _id: string;
  clientId: string;
  creditorId: string;
};

export type PurposeSuggestion = {
  purpose: string;
};
