import React from 'react';

import classNames from 'classnames';
import EmptyProjectState from 'components/EmptyProjectState';
import PaymentCard from 'components/PaymentCard';
import { Payment, PaymentSelectingMode, SelectedPayment } from 'models/Payment';
import utils from 'utils';
import PaymentsGridLoading from './PaymentsGridLoading.component';
import PaymentsNoData from './PaymentsNoData.component';

type PaymentsGridViewProps = {
  data?: Payment[];
  isEmpty: boolean;
  isLoading: boolean;
  projectId: string;
  paymentSelectingMode?: PaymentSelectingMode;
  selected?: SelectedPayment[];
  onSelect?: (selectedPayment: SelectedPayment, newValue: boolean) => void;
};

const PaymentsGridView: React.FC<PaymentsGridViewProps> = (props) => {
  const {
    isEmpty,
    isLoading,
    data,
    projectId,
    selected,
    onSelect,
    paymentSelectingMode = PaymentSelectingMode.None,
  } = props;

  return isLoading ? (
    <PaymentsGridLoading />
  ) : isEmpty ? (
    <EmptyProjectState projectId={projectId} />
  ) : data.length ? (
    <div className="payments-list__body--grid">
      {data.map((payment) => {
        const shouldBeSelectable = utils.shouldPaymentBeSelectable(
          paymentSelectingMode,
          payment.status,
        );
        return (
          <PaymentCard
            key={payment.id}
            hideButtons={paymentSelectingMode !== PaymentSelectingMode.None}
            isChecked={!!selected?.find((check) => check.id === payment.id)}
            onCheck={
              shouldBeSelectable
                ? (newValue) =>
                    onSelect(
                      { id: payment.id, amount: payment.price.amount },
                      newValue,
                    )
                : undefined
            }
            paymentInfo={payment}
            isProcessing={shouldBeSelectable}
            className={classNames(
              'payments-list__body--grid__card',
              `payments-list__body--grid__card--${paymentSelectingMode}`,
            )}
          />
        );
      })}
    </div>
  ) : (
    <PaymentsNoData />
  );
};

export default PaymentsGridView;
