import classNames from 'classnames';
import React, { forwardRef, LegacyRef, ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';

import './Input.styles.scss';

export type InputProps = {
  className?: string;
  titleicon?: ReactNode;
  title?: string;
  additionalLink?: { path: string; name: string };
  error?: string;
  placeholder?: string;
  suffixButton?: ReactNode;
  variant?: 'default' | 'squared' | 'invisible';
  footerRef?: LegacyRef<HTMLDivElement>;
} & React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>;

const Input = forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  const {
    suffixButton,
    placeholder,
    titleicon,
    className,
    additionalLink,
    title,
    error,
    variant = 'default',
    footerRef,
    onChange,
    ...rest
  } = props;

  const { t } = useTranslation();
  const [value, setValue] = useState('');
  const InputClassName = classNames(
    'is-input',
    `is-input--${variant}`,
    { 'is-input--error': error },
    { 'is-input--disabled': rest.disabled },
    className,
  );

  return (
    <div className={InputClassName}>
      <div className="is-input__header">
        <div className="is-input__header__left">
          {title && <label className="is-input__label">{title}</label>}
          {titleicon && titleicon}
        </div>
        <div className="is-input__header__right">
          {additionalLink && (
            <a href={additionalLink.path}>{additionalLink.name}</a>
          )}
        </div>
      </div>
      <div className="is-input__content">
        <input
          ref={ref}
          className="is-input__input"
          onChange={(event) => {
            setValue(event.target.value);
            onChange(event);
          }}
          placeholder={t(placeholder)}
          value={value}
          {...rest}
        />
        {suffixButton && suffixButton}
      </div>

      <div className="is-input__footer" ref={footerRef}>
        {error && <p>{error}</p>}
      </div>
    </div>
  );
});

export default Input;
