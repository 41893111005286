import React from 'react';

type ListViewIconProps = {
  className?: string;
};

const ListViewIcon: React.FC<ListViewIconProps> = (props) => {
  const { className } = props;
  return (
    <svg
      width="14"
      height="12"
      viewBox="0 0 14 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M1 2C1.27614 2 1.5 1.77614 1.5 1.5C1.5 1.22386 1.27614 1 1 1C0.723858 1 0.5 1.22386 0.5 1.5C0.5 1.77614 0.723858 2 1 2Z"
        stroke="#4D4D4D"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.5 1.5H13.5"
        stroke="#4D4D4D"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1 6.5C1.27614 6.5 1.5 6.27614 1.5 6C1.5 5.72386 1.27614 5.5 1 5.5C0.723858 5.5 0.5 5.72386 0.5 6C0.5 6.27614 0.723858 6.5 1 6.5Z"
        stroke="#4D4D4D"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.5 6H13.5"
        stroke="#4D4D4D"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1 11C1.27614 11 1.5 10.7761 1.5 10.5C1.5 10.2239 1.27614 10 1 10C0.723858 10 0.5 10.2239 0.5 10.5C0.5 10.7761 0.723858 11 1 11Z"
        stroke="#4D4D4D"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.5 10.5H13.5"
        stroke="#4D4D4D"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ListViewIcon;
