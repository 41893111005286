import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import Button from 'components/Button';
import Combobox from 'components/Combobox';
import Header from 'components/Header';
import LoadingButton from 'components/LoadingButton';
import ToggleGroup from 'components/ToggleGroup';
import { API_ENDPOINTS } from 'config/endpoints';
import { APP_ROUTES } from 'config/routes';
import { SEARCH_PARAMS } from 'config/searchParams';
import useInfiniteScroll from 'hooks/useInfiniteScroll';
import useSearchParams from 'hooks/useSearchParams';
import GridViewIcon from 'icons/GridView.icon';
import ListViewIcon from 'icons/ListView.icon';
import { Payment, PaymentView } from 'models/Payment';
import { Project } from 'models/Project';
import { Client } from 'models/User';
import PaymentsGridView from './PaymentsGridView.component';
import useFilteredData from './PaymentsList.hooks';
import PaymentsListView from './PaymentsListView.component';

import './PaymentsList.styles.responsive.scss';
import './PaymentsList.styles.scss';

type PaymentsListProps = {
  className?: string;
};

const PaymentsList: React.FC<PaymentsListProps> = (props) => {
  const { className } = props;

  const classes = classNames('payments-list', className);

  const { t } = useTranslation();

  const {
    clientId,
    projectId,
    selectedClient,
    selectedProject,
    onClientSelect,
    onProjectSelect,
    clearFilters,
  } = useFilteredData();

  const queryParams = projectId ? { projectId } : clientId ? { clientId } : {};

  const {
    data,
    isLoading,
    isFetching,
    isRefetching,
    items,
    hasMore,
    fetchNextPage,
  } = useInfiniteScroll<Payment>({
    API_ENDPOINT: API_ENDPOINTS.PAYMENT_SLIPS,
    size: 12,
    queryParams,
  });
  const navigate = useNavigate();

  const createPaymentParams = projectId
    ? `?${SEARCH_PARAMS.PROJECT_ID}=${projectId}`
    : '';

  const URL = `${APP_ROUTES.CREATE_PAYMENT}${createPaymentParams}`;

  const { getSearchParam, setSearchParam } = useSearchParams();

  const selectedView: PaymentView =
    (getSearchParam(SEARCH_PARAMS.PAYMENT_VIEW) as PaymentView) || 'list';

  return (
    <>
      <Header
        title={t('Payments.payments')}
        browser={
          <div className="payments-list__header">
            <ToggleGroup
              exclusive
              options={[
                {
                  icon: <ListViewIcon />,
                  isToggled: selectedView === 'list',
                  onToggle: () =>
                    setSearchParam(SEARCH_PARAMS.PAYMENT_VIEW, 'list'),
                },
                {
                  icon: <GridViewIcon />,
                  isToggled: selectedView === 'grid',
                  onToggle: () =>
                    setSearchParam(SEARCH_PARAMS.PAYMENT_VIEW, 'grid'),
                },
              ]}
            />
          </div>
        }
        className="payments__header"
      />
      <div className={classes}>
        <div className="payments-list__browsers">
          <Combobox<Client>
            value={selectedClient}
            onChange={onClientSelect}
            queryKey={[API_ENDPOINTS.CLIENTS]}
            searchAttribute="name"
            renderTriggerContent={(data) => (
              <>{data.companyName || `${data.firstName} ${data.lastName}`}</>
            )}
            renderListItem={(data) => (
              <div>
                {data.companyName || `${data.firstName} ${data.lastName}`}
              </div>
            )}
            title={t('Fields.client')}
            className="payments-list__browsers__browser"
          />
          <Combobox<Project>
            value={selectedProject}
            onChange={onProjectSelect}
            queryKey={[
              API_ENDPOINTS.PROJECTS,
              { clientId: selectedClient?._id },
            ]}
            disabled={!selectedClient}
            searchAttribute="name"
            renderTriggerContent={(data) => <div>{data.name}</div>}
            title={t('Fields.project')}
            className="payments-list__browsers__browser"
          />
          <Button
            onClick={clearFilters}
            styleType="border"
            className="payments-list__browsers__button"
          >
            {t('Buttons.clearFilters')}
          </Button>
          {!isLoading && !isRefetching && data && !!items.length && (
            <Button
              onClick={() => navigate(URL)}
              styleType="solid-black"
              className="payments-list__browsers__add-new-payment"
            >
              {t('Buttons.addNewPayment')}
            </Button>
          )}
        </div>
        {selectedView === 'grid' ? (
          <PaymentsGridView
            isLoading={isLoading || isRefetching}
            projectId={projectId}
            data={items}
            isEmpty={
              !(isLoading || isRefetching) && (!data || items.length === 0)
            }
          />
        ) : (
          <PaymentsListView
            isLoading={isLoading || isRefetching}
            projectId={projectId}
            data={items}
            isEmpty={
              !(isLoading || isRefetching) && (!data || items.length === 0)
            }
          />
        )}
        <div className="payments-list__footer">
          {hasMore && (
            <LoadingButton
              isLoading={isFetching}
              onClick={() => fetchNextPage()}
              className="payments-list__footer__button"
            >
              {t('Buttons.loadMore')}
            </LoadingButton>
          )}
        </div>
      </div>
    </>
  );
};

export default PaymentsList;
