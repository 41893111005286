import classNames from 'classnames';
import React, { MouseEventHandler, useCallback, useRef } from 'react';
import ReactDOM from 'react-dom';

import Button from 'components/Button';
import useKeyDown from 'hooks/useKeyDown';
import CrossIcon from 'icons/Cross.icon';

import './Modal.style.scss';

export type ModalProps = {
  closeModal: () => void;
  disableCloseButton?: boolean;
  className?: string;
  children?: React.ReactNode;
  heading?: string | React.ReactNode;
  subtitle?: string;
  parent?: React.RefObject<HTMLElement>;
  iconLeft?: React.ReactNode;
  iconRight?: React.ReactNode;
  onClose?: () => void;
};

const Modal: React.FC<ModalProps> = (props) => {
  const {
    closeModal,
    className,
    heading,
    subtitle,
    children,
    parent,
    iconLeft,
    iconRight,
    onClose,
    disableCloseButton = false,
  } = props;

  const overlay = useRef(null);
  const container = useRef(null);

  const onDismiss = useCallback(() => {
    onClose?.();
    closeModal();
  }, [closeModal, onClose]);

  useKeyDown('Escape', onDismiss);

  const onClick: MouseEventHandler = useCallback(
    (e) => {
      if (e.target === overlay.current || e.target === container.current)
        onDismiss();
    },
    [overlay, container, onDismiss],
  );

  const modalClasses = classNames('modal', className);
  return ReactDOM.createPortal(
    <div className={modalClasses} ref={overlay} onClick={onClick}>
      <div className="modal__container" ref={container}>
        <div className="modal__container__wrapper">
          <div className="modal__container__wrapper__header">
            <div className="modal__container__wrapper__header__title">
              {iconLeft && (
                <span
                  className={classNames(
                    'modal__container__wrapper__header__title__icon',
                    'modal__container__wrapper__header__title__icon--left',
                  )}
                >
                  {iconLeft}
                </span>
              )}
              <p className="modal__container__wrapper__header__title__heading">
                {heading}
              </p>

              {iconRight && (
                <span
                  className={classNames(
                    'modal__container__wrapper__header__title__icon',
                    'modal__container__wrapper__header__title__icon--right',
                  )}
                >
                  {iconRight}
                </span>
              )}
            </div>
            {!disableCloseButton && (
              <Button
                className="modal__container__wrapper__header__close-btn"
                styleType="solid-gray"
                icon={<CrossIcon />}
                onClick={closeModal}
              />
            )}
          </div>
          {subtitle && (
            <p className="modal__container__wrapper__subtitle">{subtitle}</p>
          )}
          <div className="modal__container__wrapper__body">{children}</div>
        </div>
      </div>
    </div>,
    parent ? parent.current : document.body,
  );
};

export default Modal;
