import classNames from 'classnames';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import CustomLink from 'components/CustomLink/CustomLink.component';
import Dropdown from 'components/Dropdown';
import HeaderProfileMenu from 'components/HeaderProfileMenu';
import LanguagePicker from 'components/LanguagePicker';
import LoadingButton from 'components/LoadingButton';
import { API_ENDPOINTS } from 'config/endpoints';
import { APP_ROUTES } from 'config/routes';
import useTanstackMutation from 'hooks/useTanstackMutation';
import LogoutIcon from 'icons/Logout.icon';
import SettingIcon from 'icons/Settings.icon';
import UserIcon from 'icons/User.icon';
import UserAvatarIcon from 'icons/UserAvatar.icon';
import VectorArrowIcon from 'icons/VectorArrow.icon';
import credentialsService from 'services/credentialsService';

import { useQueryClient } from '@tanstack/react-query';
import './Header.styles.scss';

type HeaderProps = {
  className?: string;
  title: string | React.ReactNode;
  browser?: React.ReactNode;
};

type userProfileNavigationListItemType = {
  value: string;
  label: string;
  icon: React.ReactNode;
  to?: string;
  isLoading?: boolean;
  onClick?: () => void;
};

const Header: React.FC<HeaderProps> = (props) => {
  const { className, title, browser } = props;
  const classesForHeader = classNames('header', className);

  const [isOpenDropdown, setIsOpenDropdown] = useState(false);
  const toggleOpenDropdown = useCallback(
    () => setIsOpenDropdown((old) => !old),
    [setIsOpenDropdown],
  );

  const navigate = useNavigate();

  const queryClient = useQueryClient();

  const { mutate, isPending } = useTanstackMutation({
    onSuccess: () => {
      credentialsService.removeAuthBody();
      queryClient.clear();
      navigate(APP_ROUTES.LOGIN);
    },
  });

  const userProfileNavigationList: userProfileNavigationListItemType[] =
    useMemo(
      () => [
        {
          value: 'profile',
          label: 'profile',
          icon: <UserIcon />,
          to: APP_ROUTES.PROFILE,
        },
        {
          value: 'settings',
          label: 'settings',
          icon: <SettingIcon />,
          to: '/dashboard',
        },
        {
          value: 'log-out',
          label: 'logout',
          icon: <LogoutIcon />,
          isLoading: isPending,
          onClick: () => {
            mutate({
              path: API_ENDPOINTS.LOGOUT,
              body: {},
              method: 'POST',
            });
          },
        },
      ],
      [isPending, mutate],
    );

  const { user } = credentialsService;

  const { t } = useTranslation();

  return (
    <header className={classesForHeader}>
      <div className="header__info">
        <label className="header__info__title">{title}</label>
        {browser && (
          <>
            <span className="header__info__line" />
            <div className="header__info__browser">{browser}</div>
          </>
        )}
      </div>
      <LanguagePicker className="is-language-picker__dashboard-header" />

      <Dropdown
        isOpen={isOpenDropdown}
        toggleOpenDropdown={toggleOpenDropdown}
        trigger={
          <HeaderProfileMenu
            name={`${user?.firstName} ${user?.lastName}`}
            userIcon={<UserAvatarIcon />}
            dropdownIcon={<VectorArrowIcon />}
            open={isOpenDropdown}
          />
        }
        className="dropdown-header-profile-menu"
      >
        <ul className="dropdown-header-profile-menu__list">
          {userProfileNavigationList.map((item) => (
            <li
              key={item.value}
              className="dropdown-header-profile-menu__list-item"
            >
              {item.to ? (
                <CustomLink
                  to={item.to}
                  title={t(`Header.${item.label}`)}
                  icon={item.icon}
                />
              ) : (
                <LoadingButton
                  icon={item.icon}
                  iconPosition="left"
                  styleType="dropdown"
                  onClick={item.onClick}
                  isLoading={item.isLoading}
                >
                  {t(`Header.${item.label}`)}{' '}
                </LoadingButton>
              )}
            </li>
          ))}
        </ul>
      </Dropdown>
    </header>
  );
};

export default Header;
