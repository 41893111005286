import classNames from 'classnames';
import React, { useEffect, useMemo, useState } from 'react';

import Button from 'components/Button';
import PreviewCard from 'components/PreviewCard/PreviewCard.component';

import { useTranslation } from 'react-i18next';
import './PreviewCardWithTabs.styles.responsive.scss';
import './PreviewCardWithTabs.styles.scss';

type TabOption = {
  tab: string;
  component: React.ReactNode;
  icon?: React.ReactNode;
  onTabSelect?: () => void;
  headerButton?: React.ReactNode;
};

type PreviewCardWithTabsProps = {
  className?: string;
  options: TabOption[];
  tab?: string;
};

const PreviewCardWithTabs: React.FC<PreviewCardWithTabsProps> = (props) => {
  const { className, options, tab } = props;

  const classes = classNames('preview-card-with-tabs', className);

  const { t } = useTranslation();

  const [selectedTab, setSelectedTab] = useState(tab || options[0].tab);

  const renderingComponent = useMemo(
    () => options.find((option) => option.tab === selectedTab).component,
    [selectedTab, options],
  );

  const renderingHeaderButton = useMemo(
    () => options.find((option) => option.tab === selectedTab).headerButton,
    [selectedTab, options],
  );

  useEffect(() => {
    if (tab) setSelectedTab(tab);
  }, [tab]);

  return (
    <PreviewCard
      isContentInline
      className={classes}
      button={renderingHeaderButton}
      headerContent={
        <div className="preview-card-with-tabs__tabs">
          {options.map((option) => (
            <Button
              key={option.tab}
              className={classNames('preview-card-with-tabs__tabs__tab', {
                'preview-card-with-tabs__tabs__tab--selected':
                  option.tab === selectedTab,
              })}
              icon={option.icon}
              styleType="shadow"
              size="large"
              onClick={() => {
                if (selectedTab !== option.tab) {
                  setSelectedTab(option.tab);
                  option.onTabSelect?.();
                }
              }}
            >
              {t(`Index.${option.tab}`)}
            </Button>
          ))}
        </div>
      }
    >
      {renderingComponent}
    </PreviewCard>
  );
};

export default PreviewCardWithTabs;
