import classNames from 'classnames';
import Lottie from 'lottie-react';
import React, { useContext } from 'react';

import animationData from 'assets/projects.json';
import { PaymentSelectingMode } from 'models/Payment';
import { useTranslation } from 'react-i18next';
import PaymentsView from 'router/subrouters/PaymentsList/PaymentsView.component';
import ProjectContext from '../provider/Project.context';

const ProjectBody: React.FC = () => {
  const {
    isFetched,
    paymentSelectingMode,
    selectedView,
    isPaymentSlipsFetching,
    projectId: id,
    paymentSlipsData,
    selected,
    onSelect,
    selectAll,
    areAllSelected,
    projectData,
    isEmptyClientStatePresent,
  } = useContext(ProjectContext);
  const isSelecting = paymentSelectingMode !== PaymentSelectingMode.None;

  const { t } = useTranslation();

  if (isEmptyClientStatePresent && !isPaymentSlipsFetching)
    return (
      <div className="project__body--empty-state">
        <p>{t('Messages.yourBookkeeperDidntAddAnyPaymentOrdersYet')}</p>
        <Lottie
          animationData={animationData}
          loop={false}
          className="project__body--empty-state__animation"
        />
      </div>
    );

  return (
    <div className="project__body">
      <PaymentsView
        paymentView={selectedView}
        className={classNames({
          'project__body__selecting-table':
            isSelecting && selectedView === 'list',
        })}
        selectAll={selectAll}
        areAllSelected={areAllSelected}
        isLoading={isPaymentSlipsFetching}
        projectId={id}
        data={paymentSlipsData?.items}
        selected={selected}
        onSelect={onSelect}
        paymentSelectingMode={paymentSelectingMode}
        isEmpty={
          !isPaymentSlipsFetching &&
          isFetched &&
          (!paymentSlipsData || projectData?.payments === 0)
        }
      />
    </div>
  );
};

export default ProjectBody;
