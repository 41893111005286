import classNames from 'classnames';
import React from 'react';

import Button, { ButtonProps } from 'components/Button/Button.component';
import LoadingSpinner from 'components/LoadingSpinner';

import './LoadingButton.styles.responsive.scss';
import './LoadingButton.styles.scss';

export type LoadingButtonProps = {
  isLoading: boolean;
} & ButtonProps;

const LoadingButton: React.FC<LoadingButtonProps> = (props) => {
  const { className, isLoading, children, icon, ...rest } = props;

  const classes = classNames(
    'loading-button',
    { 'loading-button--loading': isLoading },
    className,
  );

  return (
    <Button
      className={classes}
      {...rest}
      disabled={isLoading || rest.disabled}
      icon={isLoading ? null : icon}
    >
      {!isLoading ? children : <LoadingSpinner />}
    </Button>
  );
};

export default LoadingButton;
