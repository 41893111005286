import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Sort, { SortOrder } from '../Sort.component';

type SortProjectsProps = {
  className?: string;
};

const SortProjects: FC<SortProjectsProps> = (props) => {
  const { className } = props;

  const { t } = useTranslation();
  const defaultSortingObject = useMemo(
    () => ({
      sortByAtribute: 'lastPayment',
      sortOrder: SortOrder.Descending,
    }),
    [],
  );

  return (
    <Sort
      className={className}
      defaultValue={defaultSortingObject}
      options={[
        {
          label: t('Fields.sortByCreatedAt', {
            order: t('Fields.oldestFirst'),
          }),
          sortByAtribute: 'createdAt',
          sortOrder: SortOrder.Ascending,
        },
        {
          label: t('Fields.sortByCreatedAt', {
            order: t('Fields.latestFirst'),
          }),
          sortByAtribute: 'createdAt',
          sortOrder: SortOrder.Descending,
        },
        {
          label: t('Fields.sortByLastPayment', {
            order: t('Fields.latestFirst'),
          }),
          sortByAtribute: 'lastPayment',
          sortOrder: SortOrder.Descending,
        },
      ]}
    />
  );
};

export default SortProjects;
