import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import Button from 'components/Button';
import { APP_ROUTES } from 'config/routes';
import { Project } from 'models/Project';
import utils from 'utils';

import './ProjectTable.styles.responsive.scss';
import './ProjectTable.styles.scss';

export type ProjectInfo = {
  project: string;
  processed: string;
  payments: number;
  amount: string;
  processedAmount?: string;
  button?: React.ReactNode;
};

type TableRowProps = {
  className?: string;
  project: ProjectInfo;
  headers: Array<ProjectTableHeader>;
  onClick?: () => void;
};

const TableRow = (props: TableRowProps) => {
  const { project, headers, className, onClick } = props;

  return (
    <tr className={className} onClick={onClick}>
      {headers.map((header: ProjectTableHeader, index: number) => {
        return <td key={index}>{project[`${header.value}`]}</td>;
      })}
    </tr>
  );
};

export type ProjectTableHeader = {
  heading?: string;
  value: keyof ProjectInfo;
};

const TableHead = ({ header }: { header: Record<string, string> }) => {
  return <th>{header.heading}</th>;
};

export type ProjectTableProps = {
  className?: string;
  projectsList?: Project[];
  headers: Array<ProjectTableHeader>;
  onButtonClick?: (project: Project) => void;
};

const ProjectTable: React.FC<ProjectTableProps> = (props) => {
  const { className, headers, projectsList, onButtonClick } = props;

  const classes = classNames('project-table', className);

  const navigate = useNavigate();

  const { t } = useTranslation();

  return (
    <table className={classes}>
      <thead>
        <tr>
          {headers.map((header: Record<string, string>, index: number) => (
            <TableHead header={header} key={index} />
          ))}
        </tr>
      </thead>
      <tbody>
        {projectsList.map((project: Project, index: number) => {
          const URL = `${APP_ROUTES.PROJECTS}/${project._id}`;
          return (
            <TableRow
              className="project-table__row"
              project={{
                amount: `${project.amount.toLocaleString()} RSD`,
                payments: project.payments,
                processed: t('Index.processedPayments', {
                  percentage: (
                    utils.calculatePercentage(
                      project.processed,
                      project.payments,
                    ) || 0
                  ).toFixed(0),
                }),
                project: project.name,
                processedAmount: `${project.processedAmount.toLocaleString()} RSD`,
                button: onButtonClick && (
                  <Button
                    onClick={(e) => {
                      e.stopPropagation();
                      onButtonClick(project);
                    }}
                    styleType="solid-gray"
                    size="table-small"
                    className="project-table__copy-button"
                  >
                    {t('Buttons.copy')}
                  </Button>
                ),
              }}
              key={index}
              headers={headers}
              onClick={() => navigate(URL)}
            />
          );
        })}
      </tbody>
    </table>
  );
};

export default ProjectTable;
