import React from 'react';
import classNames from 'classnames';
import { Form } from 'react-final-form';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

import InputField from 'components/InputField';
import LoadingButton from 'components/LoadingButton';
import useTanstackMutation from 'hooks/useTanstackMutation';
import { shouldDisableFormSubmit } from 'utils';
import { ZipCodeRegExp } from 'validations/regularExpression';
import {
  composeValidators,
  validateRegex,
  validateRequired,
} from 'validations/validations';

import './AddressForm.styles.responsive.scss';
import './AddressForm.styles.scss';

type FormInputs = {
  name: string;
  zipCode: string;
};

type AddressFormProps<T> = {
  className?: string;
  initialValues: FormInputs;
  onSuccess: (data: T) => void;
  path: string;
};

const AddressForm = <T,>(props: AddressFormProps<T>) => {
  const { className, initialValues, onSuccess, path } = props;

  const classes = classNames('address-form', className);

  const { t } = useTranslation();

  const { mutate, isPending } = useTanstackMutation({
    onSuccess: (data: T) => {
      onSuccess(data);
      toast.success(t('Messages.creditorUpdatedSuccessfully'));
    },
    onError: () => toast.error(t('Messages.errorUpdatingCreditor')),
  });

  const onSubmit = (address: FormInputs) =>
    mutate({
      body: { address },
      method: 'PATCH',
      path,
    });

  return (
    <Form
      initialValues={initialValues}
      onSubmit={onSubmit}
      render={({ handleSubmit, ...methods }) => (
        <form onSubmit={handleSubmit} className={classes}>
          <InputField
            name="name"
            title={t('Fields.address')}
            validate={composeValidators(
              validateRequired('Form.requiredAddress'),
            )}
            className="address-form__input"
          />
          <InputField
            name="zipCode"
            title={t('Fields.zipCode')}
            validate={composeValidators(
              validateRequired('Form.requiredZipCode'),
              validateRegex('Form.invalidZipCode', ZipCodeRegExp),
            )}
            className="address-form__input"
          />
          <LoadingButton
            isLoading={isPending}
            styleType="solid-black"
            className="address-form__button"
            disabled={shouldDisableFormSubmit(methods)}
          >
            {t('Buttons.save')}
          </LoadingButton>
        </form>
      )}
    />
  );
};

export default AddressForm;
