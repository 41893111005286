import React from 'react';
import { Field, FieldRenderProps, UseFieldConfig } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import DatePicker, {
  DatePickerProps,
} from 'components/DatePicker/DatePicker.component';
import { DateTime } from 'luxon';

import './DatePickerField.styles.responsive.scss';
import './DatePickerField.styles.scss';

export type DatePickerFieldProps = {
  name: string;
  value?: DateTime;
} & DatePickerProps &
  UseFieldConfig<DateTime, DateTime>;

export type DatePickerFieldComponentProps = DatePickerProps &
  FieldRenderProps<DateTime, HTMLElement>;

const DatePickerFieldComponent: React.FC<DatePickerFieldComponentProps> = (
  props,
) => {
  const { input, meta, ...restOfprops } = props;

  const { t } = useTranslation();

  const error = (!meta.dirtySinceLastSubmit && meta.submitError) || meta.error;

  const translatedError = meta.touched && t(error);

  return <DatePicker {...input} {...restOfprops} error={translatedError} />;
};

const DatePickerField: React.FC<DatePickerFieldProps> = (props) => (
  <Field component={DatePickerFieldComponent} {...props} />
);

export default DatePickerField;
