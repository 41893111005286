import React from 'react';
import { useTranslation } from 'react-i18next';

import DashboardBody from 'components/DashboardBody';
import Header from 'components/Header';

import './Dashboard.style.scss';

type DashboardProps = Record<string, any>;

const Dashboard: React.FC<DashboardProps> = () => {
  const { t } = useTranslation();
  return (
    <div className="dashboard">
      <Header title={t('Dashboard.dashboard')} className="dashboard__header" />
      <main className="dashboard__main">
        <DashboardBody />
      </main>
    </div>
  );
};

export default Dashboard;
