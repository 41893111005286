import { useCallback, useEffect, useState } from 'react';

type UseSelectionParams<T> = {
  allItems?: T[];
  initiallySelected?: T[];
  filterAttribute: keyof T;
};

/**
 * Custom hook to manage selected and unselected items.
 *
 * @param initiallySelected - An array of items that are initially selected.
 * @param filterAttribute - The attribute used to uniquely identify items.
 *
 * @returns An object containing the selected, unselected and newly selected items arrays,
 *          a flag to select if all items are selected,
 *          a flag to select if none of the items are selected,
 *          a function to update the selected status of an item,
 *          a function to reset to the initial state, a function to
 *          select/unselect all the values.
 *
 */
export default function <T>({
  allItems,
  initiallySelected,
  filterAttribute,
}: UseSelectionParams<T>) {
  const [unselected, setUnselected] = useState<T[]>([]);
  const [selected, setSelected] = useState<T[]>(initiallySelected);
  const [newlySelected, setNewlySelected] = useState<T[]>([]);

  const onSelect = useCallback(
    (selectedItem: T, newValue: boolean) => {
      const filterList = (list: T[]) =>
        list.filter(
          (item) => item[filterAttribute] !== selectedItem[filterAttribute],
        );

      const addToList = (list: T[]) => [...list, selectedItem];

      const isInitiallySelected = initiallySelected?.some(
        (ch) => ch[filterAttribute] === selectedItem[filterAttribute],
      );

      if (newValue) {
        setUnselected(filterList);
        setSelected(addToList);
        if (!isInitiallySelected) setNewlySelected(addToList);
      } else {
        setSelected(filterList);
        setNewlySelected(filterList);
        if (isInitiallySelected) setUnselected(addToList);
      }
    },
    [initiallySelected, filterAttribute],
  );

  const selectAll = useCallback(
    (select: boolean) => {
      if (select) {
        setSelected(allItems);
        setNewlySelected(
          allItems.filter(
            (item) =>
              !initiallySelected
                .map((item) => item[filterAttribute])
                .includes(item[filterAttribute]),
          ),
        );
        setUnselected([]);
      } else {
        setSelected([]);
        setNewlySelected([]);
        setUnselected(initiallySelected);
      }
    },
    [allItems, initiallySelected, filterAttribute],
  );

  const resetSelected = useCallback(() => {
    setSelected(initiallySelected);
    setNewlySelected([]);
    setUnselected([]);
  }, [initiallySelected]);

  useEffect(() => {
    if (initiallySelected) {
      setSelected(initiallySelected);
      setUnselected([]);
      setNewlySelected([]);
    }
  }, [initiallySelected]);

  return {
    selected,
    unselected,
    newlySelected,
    onSelect,
    selectAll,
    resetSelected,
    areAllSelected: selected?.length === allItems?.length,
    areNoneSelected: selected?.length === 0,
  };
}
