import { useQueryClient } from '@tanstack/react-query';
import bus from 'bus';
import Button from 'components/Button';
import LoadingButton from 'components/LoadingButton';
import Modal from 'components/Modal/Modal.component';
import { API_ENDPOINTS } from 'config/endpoints';
import useDisclosure from 'hooks/useDisclosure';
import useTanstackMutation from 'hooks/useTanstackMutation';
import { Account } from 'models/User';
import { FC } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import credentialsService from 'services/credentialsService';

type AccountPreviewRemoveClientModalProps = { account: Account };

const AccountPreviewRemoveClientModal: FC<
  AccountPreviewRemoveClientModalProps
> = (props) => {
  const { account } = props;

  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const {
    isOpen: isOpenDelete,
    open: openDelete,
    close: closeDelete,
  } = useDisclosure();

  const { isPending: isPendingDelete, mutate: mutateDelete } =
    useTanstackMutation({
      onSuccess: () => {
        toast.success(t('Messages.clientInvitationRemovedSuccessfully'));

        const { user } = credentialsService;
        if (account._id === user._id) bus.broadcastEvent('logout');

        queryClient.invalidateQueries({
          queryKey: [API_ENDPOINTS.GET_MEMBERS_FOR_CLIENT(account.clientId)],
        });
      },
      onError: () => toast.error(t('Messages.clientInvitationRemovalFailed')),
    });

  const removeInvitation = () =>
    mutateDelete({
      method: 'DELETE',
      path: `${API_ENDPOINTS.CLIENT_MEMBERS}/${account._id}`,
    });

  return (
    <>
      <Button
        onClick={openDelete}
        styleType="dropdown"
        className="account-preview__options__button"
      >
        {t('Buttons.remove')}
      </Button>
      {isOpenDelete && (
        <Modal
          disableCloseButton
          closeModal={closeDelete}
          heading={t('Index.removingUser')}
          subtitle={t('Messages.areYouSureYouWantToRemoveUser', {
            user: account.email,
          })}
          className="account-preview__options__modal"
        >
          <div className="account-preview__options__modal__buttons">
            <Button
              onClick={closeDelete}
              styleType="solid-gray"
              className="account-preview__options__modal__buttons__button"
            >
              {t('Buttons.cancel')}
            </Button>
            <LoadingButton
              isLoading={isPendingDelete}
              onClick={removeInvitation}
              className="account-preview__options__modal__buttons__button"
            >
              {t('Buttons.confirm')}
            </LoadingButton>
          </div>
        </Modal>
      )}
    </>
  );
};

export default AccountPreviewRemoveClientModal;
