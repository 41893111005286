import SkeletonLoader from 'components/SkeletonLoader';
import React from 'react';

const DashboardBodyLoading: React.FC = () => {
  return (
    <>
      <div className="dashboard-body__loading-title">
        <SkeletonLoader />
      </div>
      <div className="dashboard-body__min-cards">
        <div className="dashboard-body__loading-card">
          <SkeletonLoader />
        </div>
        <div className="dashboard-body__loading-card">
          <SkeletonLoader />
        </div>
      </div>
      <div className="dashboard-body__max-card">
        <div className="dashboard-body__loading-card">
          <SkeletonLoader />
        </div>
      </div>
    </>
  );
};

export default DashboardBodyLoading;
